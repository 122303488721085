
import React from 'react'
// import amandeep from 'assets/investors/board/amandeep.webp'
// import rishab from 'assets/investors/board/rishab.webp'
import mukesh from 'assets/investors/board/mukesh.webp'
import alka from 'assets/investors/board/non-executive/Alka.jpeg';
import sarita from 'assets/investors/board/non-executive/SARITABAHL.jpeg'
import vijay from 'assets/investors/board/non-executive/VIJAYNADIMINTI.jpeg'
import rajamani from 'assets/investors/board/non-executive/RSPHOTOSHOOT5.jpg'

const nonExecutiveDirectorsList = [
    {
      id: 'alka',
      title: 'Alka Jagdish Singh Dangash',
      post: 'Independent Director',
      desc:[
        `Dr. Alka Dangash is the Founder and Director of Baudinia Quintessence (OPC) Private Limited
        and serves as an advisor and freelance consultant in the field of medicinal and aromatic plants.
        With over 20 years of experience, she has been instrumental in promoting the cultivation of
        medicinal and aromatic crops to farmers and analyzing their commercial feasibility. Dr. Dangash
        specializes in the cultivation and processing of medicinal and aromatic plants, as well as
        environment and ecology management.
        She has successfully led impact studies on flora and fauna, environmental impact assessments,
        management plans, environmental audits, and urban health management systems. Dr. Dangash
        holds a Doctorate in Philosophy (Botany) from MS University of Baroda and has been associated
        with notable organizations, including the Center for Excellence in Environment Management at
        Vikram University, IPCA Laboratories Ltd., Centre for Environmental Planning and Technology,
        and MS University`
      ],
      image: alka,
      // linkedin : 'https://www.linkedin.com/in/alka-dangash-5136714/'
    },
    {
      id: 'sarita',
      title: 'Sarita Bahl',
      post: 'Independent Director',
      desc: [
        `Ms. Sarita Bahl is an alumnus of Tata Institute of Social Sciences and the Swedish Institute of
            Management Program. She superannuated in April 2024 as Director of Bayer Foundation India
            and Country Head of Corporate Social Responsibility for Bayer South Asia. With over three
            decades of experience, she has held leadership roles across diverse sectors, including MNCs,
            public sector, and civil society.
            Specializing in strategy, sustainability, societal development, and stakeholder engagement, Sarita
            has successfully enhanced organizational reputation through holistic narratives. She is a certified
            Independent Board Director from IICA and served as Chairperson of Bayer Foundation India.
            Currently, she is a Non-Executive Director at Etherwire.Ai and an advisory board member to
            Pepperdine University, Synergy Technofin Pvt Ltd, and For Ethical AI (FEAi). She is also a
            mentor at Mentor My Board and Kerala Startup Mission.
            `
        ],
      image: sarita,
      linkedin: 'https://www.linkedin.com/in/saritabahl/'
    },
    {
        id: 'rajamani',
        
        title: 'Rajamani Shankar',
        post: 'Independent Director',
        desc: [
          `
          Mr. R Shankar is a highly accomplished Commercial Banker, Finance Professional, and C-Suite Advisor with over 30 years of experience in the international banking, technology, and consulting sectors including large conglomerates. He has held global leadership positions across Assets, Liabilities, Operations, Audit, Compliance, Risk, Finance, People Management and Administration with significant expertise in fund-raising, turnarounds, restructuring, mergers & acquisitions, and process improvements. He is a professionally qualified banker as a Certified Associate Member of the Indian Institute of Bankers (CAIIB) and also holds the International Membership of the Chartered Institute of Bankers, Scotland (MCIBS). He is also certified by the Indian Institute of Corporate Affairs (IICA) and is an empanelled Independent Director with the Ministry of Corporate Affairs, Government of India. Additionally, he serves as a visiting faculty at various academic institutions and universities, wherein he mentors and coaches budding management students on industry expertise and thought leadership.
          `          
        ],
        image: rajamani,
        linkedin: 'https://www.linkedin.com/in/r-shankar-chartered-banker-independent-director-advisor-6395b614?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
      }, 
      // {
      //   id: 'mukesh',
      //   title: 'Mukesh Panwar',
      //   post: 'Non Independent Director',
      //   desc: [
      //     'He served DRDO for 10 years as a Senior Scientific Assistant before running and scaling his family leather manufacturing businesses in Delhi and Chennai. Mukesh is a seasoned businessman with a core understanding of supply chain business. He later joined BharatRohan and supported the young entrepreneurs in establishing the company.',
      //   ],
      //   image: mukesh
      // },
      {
        id: 'vijay',
        title: 'Vijay Nadiminti',
        post: 'Non-Independent Director',
        desc: [
          `Vijay Nadiminti, an agribusiness leader with over 15+ years of experience, currently serves as CEO & Director of AgHub, PJTSAU. He brings a vast and diverse experience in working for Agribusiness Corporates, Non-Profits, Govt & Startup ecosystems. Nationally recognized for his leadership in managing early-stage startups, Incubators, Agritech Accelerators, Co-innovation, Agritech innovation pilots and Equity seed investments, Grants and Portfolio Management, Student Entrepreneurship and Rural Entrepreneurship & Start-up Mentoring.
            Vijay was awarded the National Award Winner for Emerging Technology Business Incubator- 2017, NSTEDB, DST, GoI accolade.  He also serves on boards of Agritech incubators & Academia such as Director at AgHub, Board of Governor of Baba Farid Group of Institutions, Member of the Governing Body of Baba Farid School of Entrepreneurship. He is also a member of the CII National Startup Council. With a proven track record of building entrepreneurial ecosystems, Vijay brings invaluable expertise and involvement in building BharatRohan since its inception, would help BharatRohan to scale its agritech innovations and expand market reach.`,
        ],
        image: vijay,
        // linkedin : 'https://www.linkedin.com/in/amandeeppanwar/'
      },
  ];

  

export default nonExecutiveDirectorsList