import React, { useEffect } from 'react'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

import topRight from 'assets/technology/benefits/top-right.png'
import bottomLeft from 'assets/technology/benefits/bottom-left.png'

import icon01 from 'assets/technology/benefits/icon01.svg'
import icon02 from 'assets/technology/benefits/icon02.svg'
import icon03 from 'assets/technology/benefits/icon03.svg'
import icon04 from 'assets/technology/benefits/icon04.svg'
import icon05 from 'assets/technology/benefits/icon05.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

const benefitsList = [
  {
    text: 'Plant health status',
    icon: icon01,
  },
  {
    text: 'Nutrient management',
    icon: icon02,
  },
  {
    text: 'Pest and disease management',
    textJSX: (
      <>
        Pest and disease <br /> management
      </>
    ),
    icon: icon03,
  },
  {
    text: 'Water management',
    icon: icon04,
  },
  {
    text: 'Acreage estimation',
    icon: icon05,
  },
]

const Benefits = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.tech-benefit-card-container',
        {
          // y: 100,
          opacity: 0,
          // scale: 0.5,
          // rotateY: 180,
        },
        {
          // y: 0,
          opacity: 1,
          // scale: 1,
          // rotateY: 0,
          duration: 1.5,
          ease: '',
          stagger: 0.1,
          scrollTrigger: {
            trigger: '.tech-benefits',
            start: 'top 90%',
            toggleActions: 'play none none reverse',
          },
        },
      )
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className="bg-lightCream py-16 md:py-20 lg:py-28 relative">
      <img
        src={topRight}
        alt="Background element"
        className="absolute w-auto top-6 right-0 hidden sm:block h-32 lg:h-56 xl:h-64 2xl:h-80"
      />
      <img
        src={bottomLeft}
        alt="Background element"
        className="absolute w-auto bottom-0 left-0 hidden sm:block h-60 lg:h-64 2xl:h-80"
      />
      <section className="w-container-lg">
        <h2 className="font-semibold lg:-mt-2 text-green text-center text-3xl max-w-lg mx-auto leading-tight lg:text-left lg:mx-0 xl:text-4xl 2xl:text-[3rem] xl:max-w-2xl 2xl:max-w-3xl">
          Elevating{' '}
          <span className="text-yellow">precision agriculture advisories</span>{' '}
          with UAV-based hyperspectral imagery
        </h2>

        {/* For mobile */}
        <section className="tech-benefits-swiper pt-16 lg:hidden">
          <Swiper
            className="px-3"
            // autoplay={{ delay: 2000 }}
            speed={400}
            autoHeight
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }}
            centeredSlides
            initialSlide={0}
            slidesPerView={1}
            loop
            spaceBetween={20}
          >
            {benefitsList.map((item, index: number) => {
              return (
                <SwiperSlide key={index} className="pb-[3.5rem]">
                  <BenefitCard icon={item.icon} text={item.text} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>

        {/* For desktop */}
        <section className="hidden tech-benefits pt-16 pb-4 lg:flex flex-wrap justify-center gap-12 xl:gap-y-16 min-[1800px]:gap-24 min-[1800px]:pt-24">
          {benefitsList.map((item, index: number) => {
            return (
              <BenefitCard
                icon={item.icon}
                text={item.text}
                key={index}
                textJSX={item.textJSX}
              />
            )
          })}
        </section>
      </section>
    </section>
  )
}

export default Benefits

function BenefitCard({
  text,
  icon,
  textJSX,
}: {
  text: string
  icon: string
  textJSX?: JSX.Element
}) {
  return (
    <article className="tech-benefit-card-container max-w-md mx-auto lg:mx-0 lg:grow lg:w-[45%] xl:w-[30%] xl:max-w-[30%] min-[1800px]:max-w-[27%] self-stretch">
      <div className="tech-benefit-card bg-green text-white flex flex-col items-center justify-center w-full p-8 lg:flex-row lg:gap-x-8 min-[1800px]:gap-x-10">
        <img src={icon} alt={text} className="w-auto h-[4.5rem]" />
        <p className="text-xl pt-5 text-center lg:text-left lg:pt-0 ">
          {textJSX ? textJSX : text}
        </p>
      </div>
    </article>
  )
}
