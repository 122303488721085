import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import routes from './router'
import { PopupProvider } from 'store/context'

const RenderRoutes = () => {
  const routeTree = useRoutes(routes())
  return routeTree
}

function App() {
  return (
    <BrowserRouter>
      <PopupProvider>
        <RenderRoutes />
      </PopupProvider>
    </BrowserRouter>
  )
}

export default App
