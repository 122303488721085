import React from 'react'

import image01 from 'assets/seedAssure/gallery/image01.jpg'
import image02 from 'assets/seedAssure/gallery/image02.jpg'
import image03 from 'assets/seedAssure/gallery/image03.jpg'
import image04 from 'assets/seedAssure/gallery/image04.jpg'
import image05 from 'assets/seedAssure/gallery/image05.jpg'
import image06 from 'assets/seedAssure/gallery/image06.jpg'
import image07 from 'assets/seedAssure/gallery/image07.jpg'
import image08 from 'assets/seedAssure/gallery/image08.jpg'

import Gallery from 'organisms/gallery'

const galleryList = [
  {
    title: 'Plant Count',
    image: image01,
    desc: (
      <>
        Unlocking precise data of good quality seeds with the plant count per
        row or plot alongside the identification of damaged, unhealthy, healthy,
        and non-performing seeds for better assessment.
      </>
    ),
  },
  {
    title: 'Chlorophyll Content',
    image: image02,
    desc: (
      <>
        Tracking chlorophyll levels to identify genetically superior seed
        varieties and take informed decisions citing variable behavioural traits
        such as growth, stress tolerance, drought and disease resistance, etc.
      </>
    ),
  },
  {
    title: 'Canopy Coverage',
    image: image03,
    desc: (
      <>
        Assessment of canopy area and ground cover area percentage to gauge seed
        performance under practical conditions alongside associated variables
        like weed growth, temperature regulation, stress mitigation,
        evapotranspiration rate, etc.
      </>
    ),
  },
  {
    title: 'Pest and Disease Severity',
    image: image04,
    desc: (
      <>
        Spotting and evaluating pest and disease severity and response patterns
        to develop sustainable, economical, and resilient cultivars for seed
        companies. It helps analyse the ecological relations between crops and
        pests and diseases.
      </>
    ),
  },
  {
    title: 'Plant Height',
    image: image05,
    desc: (
      <>
        Monitoring height patterns for vital criteria insights such as nutrition
        management, environmental adaptability, agronomic practices, spacing
        requirements and planting density, and ease of harvesting.
      </>
    ),
  },
  {
    title: 'Crop Specific Traits',
    image: image06,
    desc: (
      <>
        Gathering segmented insights from each micro plot throughout the crop
        cycle (panicle, flowering, fruiting, etc.) to analyse and conclude on
        the best varieties along with planning future breeding prospects.
      </>
    ),
  },
  {
    title: 'Germination Rate',
    image: image07,
    desc: (
      <>
        Supplementing seed research with historical germination data (seeding to
        emergence) to better understand growth and genetic potential, yield
        prediction, sustainability, etc.
      </>
    ),
  },
  {
    title: 'Water Stress',
    image: image08,
    desc: (
      <>
        Analysing water stress response in seeds and the ripple effect of pests,
        diseases, and other yield-limiting factors to develop superior
        drought-resistant varieties with high yield capacity.
      </>
    ),
  },
]

function SeedGallery() {
  return <Gallery divider="What it covers" galleryList={galleryList} />
}

export default SeedGallery
