import React from 'react'

const Franchise = ({
  title,
  bgImage,
  bgColor,
}: {
  title: JSX.Element
  bgImage: string
  bgColor: string
}) => {
  return (
    <section
      className={`py-16 md:py-20 px-8 bg-cover ${bgColor}`}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <h2 className="font-semibold lg:-mt-2 text-white text-center text-[1.85rem] sm:text-3xl lg:text-[1.95rem] leading-tight xl:text-4xl 2xl:text-[3rem]">
        {title}
      </h2>
    </section>
  )
}

export default Franchise
