import React, { useEffect, useState } from 'react'
import Partition from 'molecules/partition'
import defaultImg from 'assets/blogsAndUpdates/updates/default.jpg'

const apiUrl =
  'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/bharatrohan-blog'

export default function BlogsSection() {
  const [blogs, setBlogs] = useState<any>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((resData) => {
        setBlogs(resData.items || [])
        setLoading(false)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  return (
    <section className="bg-lightCream">
      <section className="w-container-lg py-16">
        <section className="pb-5 lg:pb-10 ">
          <Partition
            color="dark"
            text="Blogs and updates"
            title="Agriculture, technology, and more"
          />
        </section>

        <section className="pt-8 xl:pt-4 grid  gap-y-10 xl:gap-y-16 md:gap-x-12 xl:gap-x-10 2xl:gap-x-20 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 pb-2">
          {loading && <div>Loading...</div>}
          {!loading &&
            blogs.map((elem: any, index: number) => {
              const key = `${index}`
              return <Blogcard data={elem} key={key} theme={'light'} />
            })}
        </section>
      </section>
    </section>
  )
}

export function Blogcard({ data, theme }: { data: any; theme: string }) {
  const { title, thumbnail, link } = data

  return (
    <article className="max-w-sm md:max-w-md xl:max-w-none mx-auto w-full flex flex-col">
      <a
        href={link}
        target="_blank"
        className="h-52 2xl:h-60 w-full overflow-hidden rounded-md"
      >
        <img
          loading="lazy"
          className="h-full w-full object-cover object-center rounded-md hover:scale-110 transition-all duration-500"
          src={thumbnail || defaultImg}
          alt={title}
        />
      </a>
      <div className="pt-3 md:pt-3 gap-3 flex-1 flex flex-col pb-3">
        <div className="md:h-24 flex">
          <h4
            className={`font-bold text-2xl -mt-1.5 mb-3 pt-6  lg:text-[1.4rem] xl:text-[1.45rem] line-clamp-2
      `}
          >
            {title}
          </h4>
        </div>
        <div className="grid place-content-start">
          <button
            onClick={() => {
              window.open(link, '_blank')
            }}
            aria-label="Read more about this article"
            className={`outline-none border-1 border-transparent font-semibold  rounded-full px-8 py-2 cursor-pointer text-sm transition 
        ${
          theme === 'dark'
            ? 'font-bold text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white'
            : 'font-bold text-green bg-yellow hover:bg-green hover:text-white'
        }
      `}
          >
            Read more
          </button>
        </div>
      </div>
    </article>
  )
}
