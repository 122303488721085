import React from 'react'

import Products, { products } from 'organisms/products'
import topRight from 'assets/product/top-right.svg'
import bottomLeft from 'assets/product/bottom-left.svg'

const OtherProducts = () => {
  return (
    <section className="bg-lightCream relative pt-16 pb-6 lg:pb-16">
      {/* <img
        src={topRight}
        alt="plants"
        className="absolute top-0 right-0 h-60 xl:h-72 2xl:h-96"
      /> */}
      <img
        src={bottomLeft}
        alt="plants"
        className="absolute bottom-0 left-0 h-60 xl:h-72"
      />
      <Products
        isSingleSection={true}
        partitionText="Products"
        partitionTheme="dark"
        products={products}
        title={
          <div className="text-green max-w-xs mx-auto md:mx-0 xl:max-w-lg 2xl:max-w-xl">
            Residue-free excellence for you
          </div>
        }
      />
    </section>
  )
}

export default OtherProducts
