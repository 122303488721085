import React, { useState, useRef, useEffect, useCallback } from 'react'
import Partition from 'molecules/partition'

import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectCreative, Pagination, Autoplay } from 'swiper'
import 'swiper/css'

import cropAssure from 'assets/home/solutions/cropAssure.webp'
import seedAssure from 'assets/home/solutions/seedAssure.webp'
import sourceAssure from 'assets/home/solutions/sourceAssure.webp'

const elems = [
  {
    cover: cropAssure,
    title: 'CropAssure®',
    target: '/crop-assure',
    subtitle: 'Leading farmers to a new era of agriculture. ',
    desc: 'CropAssure® combines hyperspectral imaging technology via drone farm scouting to recommend farm-specific actionable advisories for farmers. Supported by agronomists, this decision support system employs sustainable practices to minimise input, labour, and crop loss and maximise yield quality and quantity.',
    points: [
      'Recommends suitable crop and cultivar.',
      'Prevents pest and disease infestation.',
      'Enables a residue-free harvest.',
      'Reduces agri inputs application and overall operational costs.',
    ],
  },

  {
    cover: sourceAssure,
    target: '/source-assure',
    title: 'SourceAssure®',
    subtitle: 'Where transparency augments traceability.',
    desc: 'SourceAssure® converges accurate data insights starting right from pre-sowing up to harvest to forge a strong and forward-looking supply chain management (SCM). It ensures the enduring trust of corporate buyers by sorting regulatory compliance issues through transparent data sharing on crop history.',
    points: [
      'Facilitates residue-free procurement.	',
      'Provides farm-level traceability data.',
      'Ensures transparent and efficient market linkage.',
    ],
  },

  {
    cover: seedAssure,
    target: '/seed-assure',
    title: 'SeedAssure®',
    subtitle: 'Where breakthroughs find shape.',
    desc: 'SeedAssure® provides accurate phenotypic data sets through drone monitoring to help seed growers and researchers fast-track yield trials. The drones collect the data and prescription maps are generated to provide information on the phenotypic traits detected from the plots during hybrid seed evaluation for accurate R&D. ',
    points: [
      'Simplifies crop data management.',
      'Improves efficient R&D through out with unmanned scouting.',
      'Issues timeline-centric data insights for easy data management.',
      'Shares well-structured hybrid evaluation plot-specific data insights.',
    ],
  },
]

export default function Solutions() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [instance, setInstance] = useState<any>(null)
  const swiperRef = useRef<any>(null)

  const getSwiperInstance = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper
    }
    return null
  }

  useEffect(() => {
    const ins = getSwiperInstance()
    setInstance(ins)
  }, [])

  const wrapperRef = useRef<any>()
  return (
    <section className="bg-cream3 py-16">
      <section className="w-container-lg blade-bottom-padding">
        <div>
          <Partition color="dark" text="Solutions" />
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1  gap-y-6 md:gap-10 lg:gap-16 xl:gap-20 pt-8">
          <div>
            <h3 className="font-semibold text-green leading-tight md:max-w-md lg:max-w-none">
              Driving transformation with innovation and technology excellence
            </h3>
          </div>
          <div>
            <h6 className=" text-greeb  pb-2 md:pb-3 leading-normal font-semibold max-w-[600px] lg:ml-auto ">
              “A healthy outside starts from the inside” – Robert Urich
            </h6>
            <h6 className="leading-normal font-light max-w-[600px] lg:ml-auto pr-5 ">
              We are disrupting conventional agriculture with tech-centric
              integration across all touchpoints from pre-sowing to harvest. The
              ambit caters to farmers, seed growers, researchers, and corporate
              buyers across the{' '}
              <b className="font-semibold"> agri value chain</b>.
            </h6>
          </div>
        </div>
      </section>

      <section
        className="w-container-lg flex flex-col-reverse lg:grid grid-cols-1 lg:grid-cols-2 lg:min-h-[600px] xl:min-h-min"
        onMouseEnter={() => instance?.autoplay.stop()}
        onMouseLeave={() => instance?.autoplay.start()}
      >
        <div className="relative w-full flex flex-col justify-between min-h-[680px] min-[380px]:min-h-[625px] min-[400px]:min-h-[610px] sm:min-h-[500px] md:min-h-[560px] lg:min-h-[560px] xl:min-h-[540px]">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeIndex + 'slide'}
              timeout={500}
              classNames={'fade-show'}
              nodeRef={wrapperRef}
            >
              <div ref={wrapperRef}>
                <h3 className="text-green font-bold pb-1 md:pb-2 md:block hidden ">
                  {elems[activeIndex].title}
                </h3>
                <h2 className=" md:hidden block text-green font-bold  pb-1 md:pb-2 ">
                  {elems[activeIndex].title}
                </h2>
                <h5 className="font-semibold pb-2 md:pb-5 md:block hidden">
                  {elems[activeIndex].subtitle}
                </h5>
                <span className="font-semibold text-sm pb-2 md:pb-5 md:hidden block">
                  {elems[activeIndex].subtitle}
                </span>
                <span className=" font-normal md:block hidden leading-normal text-base 2xl:text-xl max-w-xl 2xl:max-w-2xl">
                  {elems[activeIndex].desc}
                </span>
                <small className="block md:hidden text-sm font-normal leading-normal max-w-xl">
                  {elems[activeIndex].desc}
                </small>
                <ul className="flex flex-col gap-1 pt-6 2xl:pt-10 max-w-xl 2xl:max-w-2xl">
                  {elems[activeIndex].points.map((elem, index: number) => {
                    const key = `${index}`
                    return (
                      <li
                        key={key}
                        className="flex text-green items-center gap-2 first-of-type:border-none py-1.5 2xl:py-2.5 border-t-1 border-opacity-40 border-dashed border-green text-sm md:text-base 2xl:text-xl font-semibold"
                      >
                        <div className="grid place-content-center pr-3 basis-10 grow-0 shrink">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1272_7006)">
                              <path
                                d="M23.0728 10.9231C23.561 11.4114 23.561 12.2029 23.0728 12.6909L14.687 21.0769C14.1987 21.5649 13.4075 21.5649 12.9192 21.0769L8.92725 17.0847C8.43896 16.5967 8.43896 15.8052 8.92725 15.3171C9.41528 14.8289 10.2068 14.8289 10.6948 15.3171L13.803 18.4253L21.3049 10.9231C21.7932 10.4351 22.5847 10.4351 23.0728 10.9231ZM32 16C32 24.844 24.8428 32 16 32C7.15601 32 0 24.8428 0 16C0 7.15601 7.15723 0 16 0C24.844 0 32 7.15723 32 16ZM29.5 16C29.5 8.53784 23.4612 2.5 16 2.5C8.53784 2.5 2.5 8.53882 2.5 16C2.5 23.4622 8.53882 29.5 16 29.5C23.4622 29.5 29.5 23.4612 29.5 16Z"
                                fill="#FDC20C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1272_7006">
                                <rect width="32" height="32" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        {elem}
                      </li>
                    )
                  })}
                </ul>
                <div className="py-3">
                  <button
                    onClick={() => {
                      window.open(elems[activeIndex].target, '_blank')
                    }}
                    aria-label="Know more about this article"
                    className="outline-none border-1 border-transparent font-semibold mt-6 rounded-full px-8 py-2 cursor-pointer text-sm transition text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white"
                  >
                    Know more
                  </button>
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>

          {/* buttons */}
          <div className="lg:absolute lg:bottom-0 lg:left-full lg:-translate-x-1/2 grid place-content-start pt-6 pb-1 z-20 ">
            <div className=" p-[6px] md:p-2 flex gap-8 rounded-full border-1 border-solid border-green">
              <button
                aria-label="Slide to previous"
                onClick={() => {
                  instance.slidePrev()
                }}
                className="stroke-green active:bg-[#ffdb86] hover:fill-green hover:stroke-green rounded-full focus-visible:fill-green focus-visible:stroke-green focus-visible:bg-yellow fill-yellow hover:bg-yellow transition-colors duration-150 solutions-prev-btn outline-none "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="inherit"
                  className="h-5 w-5 2xl:w-6 2xl:h-6 m-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
              </button>

              <button
                aria-label="Slide to next"
                onClick={() => {
                  instance.slideNext()
                }}
                className="stroke-green active:bg-[#ffdb86] hover:fill-green hover:stroke-green rounded-full focus-visible:fill-green focus-visible:stroke-green focus-visible:bg-yellow fill-yellow hover:bg-yellow transition-colors duration-150 outline-none solutions-next-btn "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="inherit"
                  className="h-5 w-5 2xl:w-6 2xl:h-6 m-2 rotate-180"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="home-swiper-container w-full h-full relative max-h-min">
          <Swiper
            className="w-full max-h-min relative overflow-visible mb-16 md:mb-0 sm:h-[436px] sm:w-[546px] md:h-[600px] lg:h-[500px] md:w-[600px] lg:w-[500px] xl:h-[550px] xl:w-[550px] 2xl:h-[660px] 2xl:w-[660px]"
            autoHeight
            noSwiping
            touchRatio={0}
            draggable={false}
            speed={600}
            initialSlide={0}
            navigation={{
              nextEl: '.sol-next-btn',
              prevEl: '.sol-prev-btn',
            }}
            loop
            creativeEffect={{
              limitProgress: 1,
              prev: {
                scale: 0.25,
                translate: ['-30%', '-30%', 0],
              },
              next: {
                translate: ['30%', '30%', 0],
                scale: 0.25,
              },
            }}
            modules={[EffectCreative, Pagination, Autoplay, Navigation]}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.realIndex % elems.length)
            }}
            autoplay={{
              delay: 3000,
            }}
            pagination
            ref={swiperRef}
            effect="creative"
            centeredSlides
            slidesPerView={1}
          >
            <div
              className="absolute top-0 left-0 md:top-[11.5%] md:left-[11.5%] h-[22%] md:h-[17%] md:w-[17%] rounded-full cursor-pointer z-10"
              onClick={() => {
                instance.slidePrev()
              }}
            >
              <img
                className="h-full w-auto"
                src={
                  elems[activeIndex === 0 ? elems.length - 1 : activeIndex - 1]
                    .cover
                }
                alt={
                  elems[activeIndex === 0 ? elems.length - 1 : activeIndex - 1]
                    .title
                }
              />
            </div>
            <div
              className="absolute bottom-0 right-0 md:bottom-[11.5%] md:right-[11.5%] h-[22%] md:h-[17%] md:w-[17%] rounded-full cursor-pointer z-10"
              onClick={() => {
                instance.slideNext()
              }}
            >
              <img
                className="h-full w-auto"
                src={
                  elems[activeIndex === elems.length - 1 ? 0 : activeIndex + 1]
                    .cover
                }
                alt={
                  elems[activeIndex === elems.length - 1 ? 0 : activeIndex + 1]
                    .title
                }
              />
            </div>
            {elems.map((elem, index) => {
              const key = `${index}`
              return (
                <SwiperSlide className="w-full scale-75" key={key}>
                  <div className="rounded-full scale-100 md:scale-[0.65] relative flex justify-center items-center">
                    <img
                      src={elem.cover}
                      alt={elem.title}
                      className="h-[80%] w-[80%] md:h-full md:w-full object-cover object-center"
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </section>
    </section>
  )
}
