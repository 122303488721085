import React from 'react'
import ctaImage from 'assets/cropAssure/cta/cta.png'
import Cta from 'organisms/cta'
import bg from 'assets/cropAssure/cta/left.png'

const CropCTA = () => {
  return (
    <section className="bg-lightCream py-16 px-4 relative overflow-hidden cta-section xl:bg-[url(assets/globals/cta-bg.svg)] bg-[-80px_-150px] min-[1600px]:bg-[-100px_-200px] bg-contain bg-no-repeat">
      {/* <img
        src={bg}
        alt="plants"
        className="absolute bottom-[20%] left-0 -translate-x-[5%] hidden h-full w-fit opacity-[15%] md:block "
      /> */}
      <Cta
        title={
          <span className="text-green">
            Looking to
            <span className="font-bold text-yellow"> Assure </span>
            yourself for a grand season?
          </span>
        }
        image={ctaImage}
        target="/contact-us"
      />
    </section>
  )
}

export default CropCTA
