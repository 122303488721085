import React, { useEffect, useRef, useState } from 'react'

import icon1 from 'assets/ipm/management/icon01.svg'
import icon2 from 'assets/ipm/management/icon02.svg'
import icon3 from 'assets/ipm/management/icon03.svg'
import icon4 from 'assets/ipm/management/icon04.svg'
import icon5 from 'assets/ipm/management/icon05.svg'
import icon6 from 'assets/ipm/management/icon06.svg'

import management from 'assets/ipm/management/management.png'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})
ScrollTrigger.config({ limitCallbacks: true })

const managementList = [
  {
    icon: icon1,
    text: 'Advisory on the right chemical dose',
  },
  {
    icon: icon2,
    text: 'Minimised pesticide usage in farms',
  },
  {
    icon: icon3,
    text: 'Reduced agri input usage',
  },
  {
    icon: icon4,
    text: 'Reduced environmental effects',
  },
  {
    icon: icon5,
    text: 'Stable income to smallholders',
  },
  {
    icon: icon6,
    text: 'Ensuring safe food for consumers',
  },
]

const ManagementCrop = () => {
  const scrollParentRef = useRef<any>(null)

  // useEffect(() => {
  //   function mouseMoveFunc(evt: any) {
  //     const maxX: any = gsap.getProperty('.ipm-management-cards', 'width')
  //     const scrollMaxX: any = gsap.getProperty(
  //       '.ipm-management-scrollable',
  //       'width',
  //     )
  //     const percent = gsap.utils.normalize(
  //       0,
  //       window.innerWidth,
  //       evt.pageX - (screenWidth < 1500 ? 250 : 300),
  //     )
  //     gsap.to('.ipm-management-cards', {
  //       duration: 0.4,
  //       x: -percent * maxX * (screenWidth < 1800 ? 0.8 : 0.6),
  //       overwrite: true,
  //     })
  //     gsap.to('.ipm-management-scrollbar', {
  //       duration: 0.4,
  //       x: percent < 0 ? 0 : (percent + 0.1) * scrollMaxX,
  //       overwrite: true,
  //     })
  //   }
  //   scrollParentRef?.current?.addEventListener('mousemove', mouseMoveFunc)
  //   window.addEventListener('resize', () => {
  //     if (window.innerWidth < 768) {
  //       scrollParentRef?.current?.removeEventListener(
  //         'mousemove',
  //         mouseMoveFunc,
  //       )
  //     }
  //   })
  // }, [screenWidth])

  // useEffect(() => {
  //   window.addEventListener('resize', resizeListener)

  //   return () => {
  //     window.removeEventListener('resize', resizeListener)
  //   }
  // }, [])

  // function resizeListener() {
  //   setScreenWidth(window.innerWidth)
  // }

  return (
    <section className="bg-cream3">
      <section className="ipm-management-scrollable py-16 w-container sm:w-container-sm md:w-container lg:w-container-lg 2xl:w-container-2xl">
        <section>
          <h2 className="pb-8 hidden leading-tight text-3xl md:block xl:hidden font-semibold tracking-tighter text-green md:max-w-md lg:text-4xl lg:pb-12 lg:max-w-xl">
            <span className="text-yellow">IPM for Indian farms</span> -
            Cultivating residue-free harvests with six simple ways
          </h2>
          <section className="md:grid grid-cols-2 md:gap-10 2xl:gap-12">
            <article className="max-w-md mx-auto lg:mx-0 lg:max-w-xl 2xl:max-w-2xl">
              <h2 className="font-semibold leading-tight -mt-1 tracking-tight text-green text-[1.9rem] md:hidden lg:text-[36px] pb-6 lg:pb-6 xl:block 2xl:text-[3rem] xl:pb-7">
                <span className="text-yellow">IPM for Indian farms</span> -
                Cultivating residue-free harvests with six simple ways
              </h2>
              <div className="md:text-sm lg:text-base xl:text-base lg:max-w-2xl xl:max-w-[33rem] 2xl:max-w-2xl 2xl:text-lg text-black font-medium text-opacity-80">
                <p className="pb-4  xl:pb-6">
                  BharatRohan backs eco-friendly crop protection management
                  across every Indian farm.
                </p>
                <p className="">
                  Empowered by drones and hyperspectral imagery, our partner
                  farms are strengthening the entire agri value chain with
                  targeted crop protection management.
                </p>
                <p className="pt-4  xl:pt-6">
                  Data-based identification of pest-affected zones by drones
                  coupled with the data-driven application of agri inputs to the
                  crop induces mindful preventive measures. IPM secures the agri
                  value chain holistically with residue-free and increased
                  yield, higher profits for farmers, and consumer wellness
                  without ecological damage.
                </p>
              </div>
            </article>
            <div className="pt-10 md:p-0 max-w-md mx-auto lg:max-w-none lg:pt-2.5 grid place-content-start">
              <img src={management} alt="IPM Management" />
            </div>
          </section>
        </section>

        <section>
          {/* For phone */}
          <section className="ipm-management-swiper relative lg:hidden">
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              speed={600}
              spaceBetween={20}
              className=""
              autoHeight
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
            >
              {managementList.map((management, index) => {
                return (
                  <SwiperSlide key={index} className="pt-12 lg:pt-16 pb-12">
                    <ManagementCard
                      index={index}
                      icon={management.icon}
                      text={management.text}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </section>

          {/* For desktop */}
          <section
            ref={scrollParentRef}
            className="hidden lg:block md:mb-4 pt-16"
          >
            {/* <div className="ipm-management-cards flex min-w-max gap-x-20"> */}
            <div className="ipm-management-cards grid gap-12 md:grid-cols-2 xl:grid-cols-3 2xl:gap-16">
              {managementList.map((management, index) => {
                return (
                  <ManagementCard
                    key={index}
                    index={index}
                    icon={management.icon}
                    text={management.text}
                  />
                )
              })}
            </div>
          </section>
        </section>

        {/* <div className="ipm-management-scrollbar w-20 h-3 bg-green rounded-full hidden lg:block mt-8"></div> */}
      </section>
    </section>
  )
}

export default ManagementCrop

function ManagementCard({
  index,
  icon,
  text,
}: {
  index: number
  icon: string
  text: string
}) {
  return (
    <article className="bg-white transition-all duration-300 self-stretch relative hover:shadow-[rgba(232, 186, 58, 0.4)] hover:shadow-card-hover rounded-xl p-6 pt-8 flex flex-col items-center max-w-md mx-auto h-56 justify-start w-full lg:max-w-none lg:h-auto lg:mx-0">
      <img src={icon} alt={text} className="h-20 md:h-24 lg:h-20" />
      <h6 className="font-bold text-green text-opacity-70 text-2xl md:text-opacity-60 absolute top-3 left-5">
        0{index + 1}
      </h6>
      <p className="text-lg text-center font-medium max-w-[16rem] pt-8 md:pt-5 2xl:text-xl">
        {text}
      </p>
    </article>
  )
}
