import React, { useEffect } from 'react'
import Partition from 'molecules/partition'

import main from 'assets/home/impact/main.png'
import bg from 'assets/home/impact/bg.png'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

export default function Impact() {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.impact-article',
        {
          y: 150,
        },
        {
          duration: 1.3,
          y: 0,
          ease: 'back.out',
          stagger: 0.1,
          scrollTrigger: {
            trigger: '.impact-figures',
            start: 'top 75%',
            toggleActions: 'play none none reverse',
          },
        },
      )

      if (window.innerWidth < 1024) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.mobile-verticals-globe .img-container',
            start: 'center bottom',
            toggleActions: 'restart none none reverse',
          },
        })
        tl.from('.mobile-verticals-globe .bharatrohan-logo', {
          duration: 1,
          y: 100,
          scale: 0,
          ease: 'none',
        }).from('.mobile-verticals-globe .bharatrohan-verticals', {
          scale: 0,
          duration: 2,
          ease: 'back.out',
        })
      } else {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.desktop-verticals-globe .img-container',
            start: 'center bottom',
            toggleActions: 'restart none none reverse',
          },
        })
        tl.from('.desktop-verticals-globe .bharatrohan-logo', {
          duration: 1,
          y: 100,
          scale: 0,
          ease: 'none',
        }).from('.desktop-verticals-globe .bharatrohan-verticals', {
          scale: 0,
          duration: 2,
          ease: 'back.out',
        })
      }
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className="">
      <div className="bg-green py-16">
        <div className="w-container-lg">
          <Partition text="Impact" color="light" />
        </div>

        <div className="flex flex-col lg:flex-row gap-4 w-container-lg ">
          <div className="flex-1">
            <div className="pt-4 md:pt-6 xl:pt-8 2xl:pt-10 max-w-xl">
              <div>
                <h3 className={`text-cream3 font-medium`}>
                  Pushing the envelope with tech proficiency
                </h3>
              </div>
            </div>

            <div className="lg:hidden block pt-5 mobile-verticals-globe">
              <Globe />
            </div>
            <div className="impact-figures pt-12">
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-5 gap-y-10">
                <DataCard stat="50,000" text="farmers enrolled" />
                <DataCard stat="2,00,000" text="acres of farmland coverage" />
                <DataCard
                  stat="32%"
                  text="reduction in input costs per acre for farmers"
                />
                <DataCard stat="26%" text="increase in profits for farmers" />
              </div>
            </div>
          </div>
          <div className="flex-1 lg:block hidden desktop-verticals-globe">
            <Globe />
          </div>
        </div>
      </div>
    </section>
  )
}

function DataCard({ stat, text }: { stat: string; text: string }) {
  return (
    <article className="impact-article">
      <div className="grid gap-2 w-full">
        <h2 className="font-semibold tracking-wide text-yellow">{stat}</h2>
        <h6 className="text-cream3 text-opacity-90 max-w-[250px] leading-tight">
          {text}{' '}
        </h6>
      </div>
    </article>
  )
}

function Globe() {
  return (
    <div className="img-container grid relative place-content-center max-w-xl mx-auto mt-4">
      <img
        src={bg}
        alt="Globe shoowing verticals of the BharatRohan"
        className="bharatrohan-verticals w-auto h-auto object-contain object-center px-10 mt-4 lg:mt-0 md:px-10 md:py-2"
      />
      <img
        src={main}
        alt="Bahartrohan logo"
        className="bharatrohan-logo w-[28%] h-[28%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 object-contain object-center "
      />
    </div>
  )
}
