import React from 'react'
import Partition from 'molecules/partition'

import image1 from 'assets/seedAssure/01.svg'
import image2 from 'assets/seedAssure/02.png'
import image3 from 'assets/seedAssure/03.png'
import bg1 from 'assets/seedAssure/clients/top-left.png'
import bg2 from 'assets/seedAssure/clients/bottom-left.png'

import './seedAssure.css'

const clientImages = [image1, image2]
const logos = [
  image1,
  image2,
  // image3,
  image1,
  image2,
  // image3,
  image1,
  image2,
  // image3,
  image1,
  image2,
  // image3,
]

import './seedAssure.css'

const SeedClients = () => {
  return (
    <section className="py-12 bg-lightCream relative">
      <img
        src={bg1}
        aria-hidden
        alt="leaves"
        className="absolute top-0 left-0 h-48 xl:h-52 2xl:h-80 w-auto"
      />
      <img
        src={bg2}
        aria-hidden
        alt="leaves"
        className="absolute bottom-0 right-0 h-48 xl:h-52 2xl:h-80 w-auto"
      />
      <section className=" py-16 px-4 w-container sm:w-container-sm md:w-container">
        <Partition color="dark" text="Clients" />
        <div className="pb-8 blade-bottom-padding-sm"></div>
        <section className="">
          <div className="hidden sm:flex justify-evenly items-center gap-4 max-w-6xl m-auto">
            {clientImages.map((logo, index) => {
              return (
                <img
                  className="h-8 md:h-16 xl:h-20"
                  src={logo}
                  alt={logo}
                  key={index}
                />
              )
            })}
          </div>
          <div className="flex gap-4 md:gap-8 select-none w-full overflow-hidden sm:hidden">
            <div className="flex gap-4 md:gap-8 custom-marquee">
              {logos.map((elem, index) => {
                return (
                  <div
                    key={index + Math.random()}
                    className="h-fit grid place-content-center"
                  >
                    <img
                      src={elem}
                      aria-hidden
                      alt="dummy icon"
                      className="max-h-12 sm:max-h-24 mx-6"
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </section>
    </section>
  )
}

export default SeedClients
