import React, { useState } from 'react'

import Partition from 'molecules/partition'
import image from 'assets/seedAssure/strength/strength.png'
import bg from 'assets/seedAssure/strength/top-right.png'

import logo01 from 'assets/seedAssure/strength/logo01.svg'
import logo02 from 'assets/seedAssure/strength/logo02.svg'
import logo03 from 'assets/seedAssure/strength/logo03.svg'

const seedStrengthList = [
  {
    title: 'End to End at farm level',
    desc: (
      <>
        We <strong>provide</strong> transparent plot-level data with systematic
        timeline sequencing to enable better analysis and data driven decisions.
      </>
    ),
    image: logo01,
  },
  {
    title: 'Quality and regulatory compliance',
    desc: (
      <>
        We <strong>enhance</strong> adherence to national and international seed
        quality mandates with transparent field level data. The data eases early
        sighting of red flags during trials enabling the development of globally
        compliant seeds.
      </>
    ),
    image: logo02,
  },
  {
    title: 'Data accuracy',
    desc: (
      <>
        {/* <br className="hidden lg:block" /> */}
        We <strong>ensure</strong> clear-cut data collection through reliable
        and up to date drone-based hyperspectral imagery technology that leaves
        nothing to chance.
      </>
    ),
    image: logo03,
  },
]

const SeedStrength = () => {
  return (
    <section className="bg-lightCream relative">
      <img
        src={bg}
        alt="leaves"
        aria-hidden
        className="absolute top-0 right-0 h-48 md:h-80 xl:h-96 2xl:h-96 w-auto"
      />
      <section className="py-16 px-4 w-container sm:w-container-sm md:w-container">
        <Partition color="dark" text="Strengths" />
        <div className="pb-8 "></div>
        <h2 className="pb-8 hidden text-3xl md:block lg:hidden font-semibold tracking-tighter text-darkGrey md:text-4xl md:max-w-xl lg:text-4xl lg:pb-12 lg:max-w-2xl">
          The difference we offer
        </h2>
        <section className="md:grid grid-cols-2  md:gap-12 2xl:gap-20">
          <article className="lg:max-w-2xl">
            <h2 className="font-semibold tracking-tight text-darkGrey max-w-md text-3xl md:hidden lg:text-[36px] pb-6 lg:pb-6 lg:block 2xl:text-[3rem] xl:max-w-xl xl:pb-7">
              The difference we offer
            </h2>
            <p className="text-darkGrey pb-4 xl:text-lg 2xl:text-xl lg:max-w-2xl xl:pb-8">
              As changemakers working to revitalise the agri value chain, we
              intend to aid seed companies in accurate yield trials so as to
              mobilise resistant, productive, and good quality seeds development
              for farmers.
            </p>
            <p className="text-darkGrey xl:text-lg 2xl:text-xl lg:max-w-2xl">
              Our data collection from trial plots using drone technology
              underlines three interconnected key differentiators in the seed
              development cycle for seeds manufacturers.
            </p>
          </article>
          <div className="pt-10 md:p-0 sm:max-w-md ml-auto lg:max-w-none lg:pt-2.5">
            <img
              src={image}
              alt="Crop Management "
              className="h-auto w-full lg:max-h-[21.5rem] ml-auto 2xl:w-[100%]"
            />
          </div>
        </section>

        <section className="pt-16 grid items-center flex-col md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-x-12 lg:pt-12">
          {seedStrengthList.map((list, index) => {
            return (
              <StrengthList
                key={index}
                title={list.title}
                image={list.image}
                desc={list.desc}
              />
            )
          })}
        </section>
      </section>
    </section>
  )
}

export default SeedStrength

function StrengthList({
  image,
  title,
  desc,
}: {
  image: string
  title: string
  desc: JSX.Element
}) {
  return (
    <article className="group border-2 max-w-lg py-6 px-6 flex-1 border-green rounded-2xl self-stretch min-w-md hover:bg-green hover:text-white hover:cursor-pointer transition-colors duration-300">
      <img className="pb-4 h-32 stroke-green" src={image} alt={title} />
      <h6 className="font-bold text-[1.25rem] xl:text-[1.5rem] pb-3 max-w-xs">
        {title}
      </h6>
      <div>
        <p className={`text-sm lg:text-base xl:text-lg`}>{desc}</p>
      </div>
    </article>
  )
}
