export default function Banner() {
  return (
    <section className="min-h-screen overflow-hidden text-white bg-green bg-[url(assets/aboutUs/banner/hero-bg.webp)] bg-cover bg-no-repeat bg-center flex items-center justify-center">
      <div className="w-container-lg pt-32 pb-16 md:pb-20 md:pt-40 mx-auto">
        <div className="relative h-full">
          <h1 className="text-appear-anim px-5 md:pl-0 font-medium text-3xl text-center max-w-sm mx-auto sm:max-w-xl md:text-left md:mx-0 xl:text-4xl xl:max-w-2xl 2xl:text-[3rem] 2xl:max-w-3xl">
            Between creating and piloting drones and rising up to the challenges
            with them, we discovered <SlidingWords />
          </h1>
          <svg
            className="text-appear-anim-delayed hidden md:block h-60 w-80 max-w-min px-5 absolute -bottom-28 left-1/2 !-translate-x-1/2 sm:h-80 sm:w-96 sm:-bottom-36 sm:!-translate-x-[25%] md:!-translate-x-0 md:left-[7rem] xl:h-96 xl:w-[28rem] xl:left-[10rem] xl:-bottom-44 2xl:h-[32rem] 2xl:w-[34rem] 2xl:left-[12rem] 2xl:-bottom-64"
            viewBox="0 0 549 366"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M539.525 30.211C546.545 42.3686 546.72 58.2742 540.101 77.2031C533.488 96.1172 520.239 117.559 501.257 140.189C463.312 185.425 403.005 234.758 329.29 277.317C255.575 319.876 182.697 347.438 124.55 357.681C95.4604 362.805 70.2672 363.558 50.5803 359.828C30.878 356.096 17.191 347.991 10.1718 335.834C3.15269 323.676 2.97727 307.77 9.5961 288.841C16.2097 269.927 29.4578 248.486 48.4407 225.855C86.3852 180.62 146.693 131.287 220.408 88.7276C294.122 46.1683 367 18.607 425.147 8.36373C454.237 3.23923 479.43 2.48696 499.117 6.21645C518.819 9.94883 532.506 18.0535 539.525 30.211Z"
              stroke="url(#paint0_linear_2201_2784)"
              strokeWidth="7.58392"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2201_2784"
                x1="350.959"
                y1="275.91"
                x2="283.293"
                y2="109.165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#E8BA3A" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div>
          <p className="text-appear-anim-delayed pt-36 text-center mx-auto sm:max-w-sm md:text-left md:ml-auto md:mr-0 md:max-w-xs font-light md:pt-24 lg:pt-10 xl:text-xl xl:max-w-sm 2xl:text-2xl 2xl:max-w-md">
            We are optimists endeavouring to globalise undivided impact in the
            agri value chain... with increased crop production, sustainable
            practices, and food security.
          </p>
        </div>
      </div>
    </section>
  )
}

function SlidingWords() {
  return (
    <span className="font-semibold text-yellow inline-flex flex-col min-h-[70px] max-h-[70px] h-[70px] overflow-hidden">
      <span className="animate-words min-h-full max-h-full h-full">
        purpose
      </span>
      <span className="animate-words min-h-full max-h-full h-full">
        passion
      </span>
      <span className="animate-words min-h-full max-h-full h-full">people</span>
      <span className="animate-words min-h-full max-h-full h-full">
        ambition
      </span>
      <span className="animate-words min-h-full max-h-full h-full">
        purpose
      </span>
    </span>
  )
}
