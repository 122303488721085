import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import AWDVideo from './awdVideo'
// import CarbonCaseStudy from './carbonCaseStudy'
// import CarbonTestimonials from './carbonTestimonials'
import Benefits from './benefits'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

import './index.css'
import Strengths from './strengths'

const CarbonFarming = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })
    return () => ctx.revert()
  }, [])

  return (
    <section>
      <Banner />
      <AWDVideo />
      <Strengths />
      <Benefits />
      {/* <CarbonCaseStudy />
      <CarbonTestimonials /> */}
    </section>
  )
}

export default CarbonFarming
