import React, { useLayoutEffect,useRef } from 'react'
import Banner from './banner'
import Values from './values'
import Form from './form'
import './careers.css'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

export default function Careers() {

  const formRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  const scrollToForm = () => {
    // console.log("function hitting scroll to form")
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Banner scrollToForm={scrollToForm} />
      <Values />
      <div ref={formRef}>
        <Form />
      </div>
    </>
  )
}
