import WhatsNew, { updatesList } from 'organisms/WhatsNew'
import React from 'react'

const Updates = () => {
  return (
    <section>
      <WhatsNew
        title="What's new"
        desc="<strong class='font-semibold'>Keep abreast of the BharatRohan family's whereabouts</strong> - from happenings to achievements and beyond."
        theme="light"
        list={updatesList}
      />
    </section>
    // <section className="py-16 md:py-20 bg-lightCream">
    //   <section className="w-container-lg xl:w-container 2xl:w-container-lg">
    //     <h2 className="text-3xl text-center text-green font-semibold md:text-left xl:text-4xl 2xl:text-[3rem]">
    //       What's new
    //     </h2>
    //     <p className="text-center pt-4 mx-auto max-w-sm md:text-left md:mx-0 xl:text-lg xl:max-w-md 2xl:max-w-xl">
    //       Keep abreast of the BharatRohan family's whereabouts - from happenings
    //       to achievements and beyond.
    //     </p>

    //     <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 pt-6 pb-3 xl:gap-x-12 xl:gap-y-10">
    //       {updatesList.map((update, index) => {
    //         return (
    //           <BlogCard
    //             key={index}
    //             title={update.title}
    //             image={update.image}
    //             desc={update.desc}
    //             tag={update.tag}
    //             postedOn={update.postedOn}
    //             theme={'light'}
    //             // large={true} // Uncomment this to make 2 column UI
    //             link={update.link}
    //           />
    //         )
    //       })}
    //     </section>
    //   </section>
    // </section>
  )
}

export default Updates
