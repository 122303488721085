import { useState, useEffect, useRef, useCallback } from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const content = [
  <div className="">
    Our vision is to see India realise its fullest potential as a key
    contributor to the global economy, underpinned by access to safe food,
    cutting-edge technology, quality education, advanced healthcare, and
    sustainable infrastructure.
  </div>,
  <div className="flex flex-col gap-y-8">
    <p>
      BharatRohan's mission is to revolutionise agriculture into a dignified,
      sustainable livelihood for future generations. We strive to make
      cutting-edge technology accessible in rural markets, empowering farmers
      with tools for resilient, chemical residue-free farming. Our goal is not
      only to enhance farmer profitability but also to incentivise sustainable,
      residue-free cultivation practices. This approach ensures the long-term
      viability of agriculture, bridging the technology gap and fostering a
      culture where farmers are rewarded for their commitment to sustainability
      and excellence.
    </p>
  </div>,
]

const tabs = [
  {
    tab: 'Vision',
    className: 'vision-prev-btn',
  },
  {
    tab: 'Mission',
    className: 'vision-next-btn',
  },
]

export default function Vision() {
  const [activeSlide, setActiveSlide] = useState(0)
  const swiperRef = useRef<any>(null)
  const [instance, setInstance] = useState<any>(null)
  const [scrollController, setScrollController] = useState<any>(null)
  const visionRef = useRef<any>()
  const [reset, setReset] = useState(false)

  const descRef = useRef<any>()

  useEffect(() => {
    // VVI for refresh gsap so that it will work properly on safari
    setTimeout(() => {
      setReset(true)
    }, 2000)
  }, [])

  useEffect(() => {
    if (window.innerWidth < 1024) {
      return
    }

    const ctx = gsap.context(() => {
      const anim = gsap.from(visionRef.current, {})
      const controller = ScrollTrigger.create({
        animation: anim,
        trigger: visionRef.current,
        start: 'top top',
        end: 'bottom -50%',
        scrub: true,
        pin: true,
        onUpdate: (e: any) => {
          const progress: number = +(e.progress * 100).toFixed(2) ?? 0
          progressHandler(progress)
        },
      })
      setScrollController(controller)
    }, visionRef.current)

    return () => {
      ctx.revert()
    }
  }, [instance, reset])

  useEffect(() => {
    setInstance(() => {
      if (swiperRef?.current && swiperRef.current?.swiper) {
        return swiperRef.current.swiper
      }
      return null
    })
  }, [])

  const slidePosHandler = useCallback(
    (update: number) => {
      instance?.slideTo(update)
      setActiveSlide(update)
    },
    [instance],
  )

  const scrollPosHandler = (type: string) => {
    const onePartOfWholeProgress = +(100 / content.length).toFixed(2)
    switch (type) {
      case '-':
        scrollController?.scroll(
          scrollController.start +
            (scrollController.progress - onePartOfWholeProgress / 100) *
              (scrollController.end - scrollController.start),
        )
        break

      case '+':
        scrollController?.scroll(
          scrollController.start +
            (scrollController.progress + onePartOfWholeProgress / 100) *
              (scrollController.end - scrollController.start),
        )
        break

      default:
        break
    }
  }

  const progressHandler = useCallback(
    (progress: number) => {
      const onePartOfWholeProgress = +(100 / content.length).toFixed(2)
      const progressIndex = Math.round(progress / onePartOfWholeProgress)
      if (progressIndex === content.length) {
        return
      }
      slidePosHandler(progressIndex)
    },
    [instance, slidePosHandler],
  )

  return (
    <section className="bg-green overflow-hidden">
      <div
        ref={visionRef}
        className="vision-section overflow-hidden py-16 md:py-20 relative bg-cover bg-center bg-no-repeat lg:h-screen md:min-h-[30rem]"
      >
        {/* For mobile */}
        <div className="absolute  inset-0 bg-black bg-opacity-50 md:hidden pointer-events-none touch-none" />
        <section className="lg:hidden w-container-lg relative text-white">
          <div className="flex justify-center gap-x-4">
            {tabs.map((tab, index) => {
              return (
                <button
                  aria-label={tab.tab}
                  key={index}
                  onClick={() => {
                    setActiveSlide(index)
                  }}
                  className={`py-2 w-full font-medium text-xl text-center border-white border rounded-lg ${
                    activeSlide === index && 'bg-cream text-green border-green'
                  } ${tab.className}`}
                >
                  {tab.tab}
                </button>
              )
            })}
          </div>

          <div>
            <Swiper
              modules={[Navigation]}
              draggable={false}
              allowTouchMove={false}
              speed={600}
              spaceBetween={20}
              navigation={{
                nextEl: '.vision-next-btn',
                prevEl: '.vision-prev-btn',
              }}
              className=""
            >
              {content.map((elem, index) => {
                return (
                  <SwiperSlide key={index} className="h-full">
                    <div className="pt-12 md:pt-20 text-lg">{elem}</div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </section>

        {/* For desktop */}
        <section className="hidden lg:grid grid-cols-2 text-white w-container-lg h-full">
          <div className="grid grid-cols-[3rem_10rem] xl:grid-cols-[4rem_10rem] relative pt-12">
            <div className="relative ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 28 32"
                fill="inherit"
                className={`h-6 absolute transition-all duration-500 top-3 xl:top-5 2xl:h-8 ${
                  activeSlide === 1 &&
                  'top-[3.8rem] xl:top-[5.2rem] 2xl:top-[6.2rem]'
                }`}
              >
                <rect
                  y="0.536377"
                  width="10.4199"
                  height="10.9161"
                  fill="white"
                />
                <rect
                  x="-0.000183105"
                  y="20.3101"
                  width="10.4199"
                  height="10.9161"
                  fill="white"
                />
                <rect
                  x="11.442"
                  y="12.1414"
                  width="16.1252"
                  height="7.48477"
                  fill="white"
                />
              </svg>
              <div className="h-8 w-8"></div>
            </div>
            <div className="flex flex-col w-max xl:gap-y-3">
              <button
                onClick={() => {
                  scrollPosHandler('-')
                }}
                className={`text-left text-white vision-prev-btn-desk font-semibold text-3xl 2xl:text-4xl transition-all duration-300 ${
                  activeSlide !== 0 && 'text-opacity-50'
                }`}
              >
                Vision
              </button>
              <div className=" h-[2px] bg-white w-40 xl:w-52"></div>
              <button
                onClick={() => {
                  scrollPosHandler('+')
                }}
                className={`text-left text-white vision-next-btn-desk font-semibold text-3xl 2xl:text-4xl transition-all transition-duration-300 ${
                  activeSlide !== 1 && 'text-opacity-50'
                }`}
              >
                Mission
              </button>
            </div>
          </div>
          <div className="h-full relative flex flex-col justify-center">
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={activeSlide + 'article'}
                timeout={300}
                classNames={'fade-show'}
                nodeRef={descRef}
              >
                <div
                  ref={descRef}
                  className="pt-12 md:pt-20 text-lg lg:pt-0 2xl:text-[1.5rem] min-[1800px]:text-[1.7rem]"
                >
                  {content[activeSlide]}
                </div>
              </CSSTransition>
            </SwitchTransition>
            {/* <Swiper
              ref={swiperRef}
              direction="vertical"
              modules={[Navigation]}
              draggable={false}
              allowTouchMove={false}
              speed={600}
              navigation={{
                nextEl: '.vision-next-btn-desk',
                prevEl: '.vision-prev-btn-desk',
              }}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.activeIndex)
              }}
              className=" d self-stretch 2xl:max-h-[40rem]"
            >
              {content.map((elem, index) => {
                return (
                  <SwiperSlide key={index} className="h-full flex items-end">
                    <div className="pt-12 md:pt-20 text-lg lg:pt-0 2xl:text-[1.5rem] min-[1800px]:text-[1.7rem]">
                      {elem}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper> */}
          </div>
        </section>
      </div>
    </section>
  )
}
