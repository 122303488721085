import React, { useLayoutEffect } from 'react'
import Banner from './layout/banner'
import Details from './layout/details'
import banner from 'assets/product/mustard/mustard-bg.webp'
import aside from 'assets/product/mustard/mustard.webp'

// use types to ensure correct props
import { IGeoData } from './layout/types'
import OtherProducts from './layout/products'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const geoData: IGeoData = [
  {
    type: 'Country',
    value: 'India',
  },
  {
    type: 'State',
    value: 'Rajasthan',
  },
  {
    type: 'Region',
    value: 'Jodhpur',
  },
]

const tableHeadings = [
  {
    title: 'Variant',
    classNames: 'w-12 text-left pl-2 lg:min-w-[9vw] xl:min-w-[12vw] xl:pl-7',
  },
  {
    title: 'Package Size',
    classNames: 'w-20 text-center lg:min-w-[9vw] xl:min-w-[10vw]',
  },
  {
    title: 'Moisture',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[6vw]',
  },
  {
    title: 'Purity',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[7vw]',
  },
  {
    title: 'Description',
    classNames: 'text-left',
  },
]

const variants = [
  {
    varient: 'sarso medium',
    package: 'Custom',
    moisture: '8%',
    purity: '99.7%',
    desc: 'Average size seeds with yellow colour, 0.3% max. impurity & oil content 44%',
  },
  {
    varient: 'sarso bold',
    package: 'Custom',
    moisture: '8%',
    purity: '99.7%',
    desc: 'Sortex clean big size seeds with black colour seeds, 0.3% max. impurity & oil content 39%',
  },
  // {
  //   varient: 'sarso split (raikurai) - for pickle and cooking purpose',
  //   package: '40 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Useful to make Indian pickles and cousins',
  // },
  // {
  //   varient: 'sarso pili micro mustard (yellow mustard)',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Small size seeds with yellow colour, 0.3% max. impurity & oil content 39%',
  // },
  // {
  //   varient: 'arso pili va (yellow mustard)',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Similar size seeds with yellow colour, purity 0.3% max. impurity, oil contant 44',
  // },
  // {
  //   varient: 'sarso pili regular (yellow mustard)',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Average size seeds with yellow colour, 0.3% max. impurity & oil content 44%',
  // },
  // {
  //   varient: 'sarso kali (rai) 22 carat regular',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Small size seeds with black & some red colour seeds 99.70% purity, 0.3% max. impurity & 8% max. moisture',
  // },
  // {
  //   varient: 'sarso kali bombay bold (extra bold)',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Sortex clean big size seeds with black colour seeds, 0.3% max. impurity & oil content 39%',
  // },
  // {
  //   varient: 'sarso kali a one',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Sortex clean similar size seeds with black colour seeds, 0.3% max. impurity & oil content 39',
  // },
  // {
  //   varient: 'sarso kali double elephant',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Average size seeds with black & some red colour seeds, 0.3% max. impurity & oil content 39%',
  // },
  // {
  //   varient: 'sarso kali diamond',
  //   package: '50 kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Average size seeds with black & some red colour seeds, 0.3% max. impurity & oil content 39%',
  // },
]

export default function Mustard() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <div>
      <Banner
        bgImage={banner}
        title="Mustard (Whole)"
        desc="Accounting for around 40% of India’s mustard production, Rajasthan houses the best mustard varieties. We bring the finest quality mustard seeds extracted from the best mustard flowers from our partner farms in Rajasthan. These residue-free mustard seeds are rich in allyl isothiocyanate and will add a zesty mustard flavour to preparations."
      />
      <Details
        tableHeading={tableHeadings}
        variantDetails={variants}
        asideImage={aside}
        title="Mustard (Whole)"
        geoData={geoData}
      />
      <OtherProducts />
    </div>
  )
}
