import React from 'react'
import ctaImage from 'assets/technology/cta/cta.webp'
import Cta from 'organisms/cta'

const TechnologyCTA = () => {
  return (
    <section className="bg-cream3 py-16 px-4 md:py-20 relative overflow-hidden cta-section xl:bg-[url(assets/globals/cta-bg.svg)] bg-[-80px_-150px] min-[1600px]:bg-[0px_-200px] bg-contain bg-no-repeat">
      <Cta
        title={
          <div className="text-green xl:max-w-sm 2xl:max-w-xl">
            Interested to know more about our
            <span className="text-yellow"> tech</span> and
            <span className="text-yellow"> innovation</span>?
          </div>
        }
        image={ctaImage}
        target="/contact-us"
      />
    </section>
  )
}

export default TechnologyCTA
