import React, { useRef, useState } from 'react'
import Partition from 'molecules/partition'
import droneImage from 'assets/home/tech/drone-new.jpg'
import saas from 'assets/home/tech/saas-full.jpg'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { MasterAnchor } from 'atoms/links'
import { MasterBtn } from 'atoms/buttons'

const techList = [
  {
    title: 'UAVs (Unmanned Aerial Vehicles) and UAV cameras',
    coverImage: droneImage,
    target: '/technology?tech=uav',
    desc: (
      <>
        Taking agricultural advisories off the shelf and into the skies, our
        drones equipped with multispectral and hyperspectral sensors hover over
        the farms and trial plots to gather data for the agronomy engines.
      </>
    ),
  },
  {
    title: 'SaaS',
    coverImage: saas,
    target: '/technology?tech=saas',
    desc: (
      <ul className="list-disc pl-6">
        <li>
          <strong>UAV-based Hyperspectral Imaging Technology</strong> derives
          key data points from the data collected by the drone sensors.
        </li>
        <li className=" py-5 lg:py-4 2xl:py-7">
          <strong>Data-driven agronomy models</strong> analyse the data points
          and match them with pre-set parameters and benchmarks. The models then
          generate suitable <strong>agronomy</strong> advisories (vetted by
          agronomists) with prescription maps for growers.
        </li>
        <li>
          <strong>Dashboard</strong> features the historical backdrop of all
          cluster farms. Each farm’s unique <strong>QR code</strong> adds new
          dimensions to holistic tracking, comparison, and analysis of the crop.
        </li>
      </ul>
    ),
  },
]

export default function Technology() {
  const [currActiveIndex, setCurrActiveIndex] = useState<number>(0)

  function slideHandler(index: number) {
    setCurrActiveIndex(index)
  }

  const nodeRef = useRef<any>()
  const imageRef = useRef<any>()
  const desktopContentRef = useRef<any>()

  return (
    <section className="bg-lightCream lg:bg-cream3 py-16 lg:pb-0">
      <section className="w-container-lg max-w-lg md:max-w-xl lg:w-container-lg">
        <div className=" pb-8">
          <Partition color="dark" text="Technology" />
        </div>

        <div className="flex flex-col lg:flex-row lg:gap-x-8 lg:justify-between gap-y-4 lg:gap-y-10 xl:gap-20">
          <div>
            <h3 className="font-semibold text-green leading-tight max-w-md lg:max-w-xl ">
              From ground to skies and back, making the most out of technology
            </h3>
          </div>
          <div>
            <h6 className="leading-normal font-light text-base lg:text-lg lg:max-w-xl 2xl:text-lg">
              Harnessing the true potential of hyperspectral imaging, cameras,
              drones, and data-driven decision models on farms, our growers are
              reaping the fruits of precision farming. The combination unlocks
              deep, multi-dimensional assessment of farms from which an accurate
              and versatile database of advisories is derived.
            </h6>
          </div>
        </div>
      </section>

      {/* Slider part */}
      <section className="pt-10 lg:pt-16 w-container-lg max-w-lg md:max-w-xl lg:max-w-none lg:w-full lg:px-0">
        <section className="">
          <section className="grid grid-cols-2 place-content-center w-full gap-2 lg:hidden pb-2">
            <Tab
              activeIndex={currActiveIndex}
              index={0}
              text="UAVs & UAV cameras"
              handleClick={slideHandler}
            />
            <Tab
              activeIndex={currActiveIndex}
              index={1}
              text="SaaS"
              handleClick={slideHandler}
            />
          </section>
          <div className="relative lg:bg-green">
            <SwitchTransition>
              <CSSTransition
                key={currActiveIndex}
                nodeRef={imageRef}
                timeout={300}
                classNames="fade-show"
                unmountOnExit
              >
                <div ref={imageRef} className="pt-2 lg:pt-0">
                  <img
                    src={techList[currActiveIndex].coverImage}
                    alt={techList[currActiveIndex].title}
                    className="rounded-lg lg:rounded-none w-full h-auto lg:h-[500px] min-[1200px]:h-[600px] min-[1400px]:h-[700px] 2xl:min-h-[750px] min-[1700px]:h-[830px] min-[1800px]:h-[900px] min-[2000px]:h-auto"
                  />
                </div>
              </CSSTransition>
            </SwitchTransition>
            <div className="hidden lg:flex absolute top-0 left-0 h-full w-[45%] flex-col justify-center">
              <div className="bg-lightCream h-[75%] p-6 rounded-r-xl xl:py-10 xl:px-16 min-[1800px]:px-28">
                {/* Buttons for desktop */}
                <section className="grid-cols-2 place-content-center w-full gap-2 lg:gap-4 pt-2 hidden lg:grid">
                  <Tab
                    activeIndex={currActiveIndex}
                    index={0}
                    text="UAVs & UAV cameras"
                    handleClick={slideHandler}
                  />
                  <Tab
                    activeIndex={currActiveIndex}
                    index={1}
                    text="SaaS"
                    handleClick={slideHandler}
                  />
                </section>
                <div className="h-6 xl:h-8 hidden lg:block"></div>
                <div className="technology-desc-scrollbar h-[73%] overflow-auto pr-6 min-[1800px]:h-[78%]">
                  <SwitchTransition>
                    <CSSTransition
                      key={currActiveIndex}
                      nodeRef={desktopContentRef}
                      timeout={300}
                      classNames="slide"
                      unmountOnExit
                    >
                      <div
                        ref={desktopContentRef}
                        className="pt-5 lg:pt-0 min-[1800px]:pt-4 "
                      >
                        <h3 className="font-semibold text-green lg:text-2xl xl:text-3xl leading-tight max-w-2xl -mt-1.5">
                          {techList[currActiveIndex].title}
                        </h3>
                        <h6 className="max-w-xl mt-4 text-base 2xl:text-xl pb-6 xl:pb-0">
                          {techList[currActiveIndex].desc}
                        </h6>
                        <div className="pt-2 pb-6 xl:pb-10 xl:pt-8">
                          <button
                            onClick={() => {
                              window.open(
                                techList[currActiveIndex].target,
                                '_blank',
                              )
                            }}
                            aria-label="Know more about this article"
                            className={`outline-none border-1 border-transparent font-semibold rounded-full px-8 py-2 
                              cursor-pointer text-sm transition text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white `}
                          >
                            Know more
                          </button>
                        </div>
                      </div>
                    </CSSTransition>
                  </SwitchTransition>
                </div>
              </div>
            </div>
          </div>
          {/* Content for phone */}
          <div className="lg:hidden">
            <SwitchTransition>
              <CSSTransition
                key={currActiveIndex}
                nodeRef={nodeRef}
                timeout={300}
                classNames="slide"
                unmountOnExit
              >
                <div ref={nodeRef} className="pt-5">
                  <h3 className="font-semibold text-green leading-tight max-w-2xl">
                    {techList[currActiveIndex].title}
                  </h3>
                  <h6 className="max-w-xl mt-4 text-base lg:text-lg 2xl:text-xl">
                    {techList[currActiveIndex].desc}
                  </h6>
                  <button
                    onClick={() => {
                      window.open(techList[currActiveIndex].target, '_blank')
                    }}
                    aria-label="Know more about this article"
                    className={`outline-none border-1 mt-6 border-transparent font-semibold rounded-full px-8 py-2 
                              cursor-pointer text-sm transition text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white `}
                  >
                    Know more
                  </button>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </section>
      </section>
    </section>
  )
}

function Tab({
  index,
  text,
  handleClick,
  activeIndex,
}: {
  text: string
  index: number
  activeIndex: number
  handleClick: (index: number) => void
}) {
  return (
    <button
      aria-label={text}
      onClick={() => handleClick(index)}
      type="button"
      className={`h-[90%] py-1.5 border-green border rounded-md ${
        index == activeIndex
          ? 'bg-green stroke-yellow fill-yellow text-yellow'
          : 'stroke-green fill-green text-green'
      } group outline-none focus-visible:bg-green hover:bg-green grid place-content-center transition-colors duration-200`}
    >
      <div className="flex flex-nowrap items-center gap-7 justify-center  py-2 2xl:py-4 px-3">
        <div className="font-medium text-inherit group-focus:text-yellow  group-hover:text-yellow transition-colors duration-200">
          <h6 className="text-sm md:text-base leading-tight xl:text-lg xl:py-1 2xl:text-xl">
            {text}
          </h6>
        </div>
      </div>
    </button>
  )
}
