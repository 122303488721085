import React, { useLayoutEffect } from 'react'
import SourceProducts from './products'
import './index.css'
import { ComingSoon } from './comingSoon'
import { Accordian } from './accordian'
import { Process } from './process'
import SourceBanner from './sourceBanner'
import SourceManagement from './sourceManagement'
import SourceCaseStudy from './sourceCaseStudy'
import SourceCTA from './sourceCTA'
import SourceTestimonials from './sourceTestimonials'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)
const SourceAssure = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <main>
      <SourceBanner />
      <SourceManagement />
      <SourceProducts />
      <ComingSoon />
      <Accordian />
      <Process />
      <SourceTestimonials />
      {/* <SourceCaseStudy /> */}
      <SourceCTA />
    </main>
  )
}

export default SourceAssure
