import React from 'react'

import Products, { products } from 'organisms/products'
import bg from 'assets/sourceAssure/products/top-right.png'

const SourceProducts = () => {
  return (
    <section className="bg-lightCream relative pt-16 pb-6 lg:pb-16">
      <img
        src={bg}
        alt="plants"
        className="absolute top-0 left-0 h-52 xl:h-52 2xl:h-64 -rotate-90 w-auto"
      />
      <Products
        partitionText="Products"
        partitionTheme="dark"
        products={products}
        title={
          <div className="text-green max-w-xs mx-auto md:mx-0 xl:max-w-lg 2xl:max-w-xl">
            Residue-free excellence for you
          </div>
        }
      />
    </section>
  )
}

export default SourceProducts
