import React from 'react'
import ctaImage from 'assets/seedAssure/cta.png'
import Cta from 'organisms/cta'
import bg from 'assets/seedAssure/cta/left.png'

const SeedCTA = () => {
  return (
    <section className="bg-lightCream cta py-16 px-4 relative overflow-hidden cta-section xl:bg-[url(assets/globals/cta-bg.svg)] bg-[-80px_-150px] min-[1600px]:bg-[0px_-200px] bg-contain bg-no-repeat">
      {/* <img
        src={bg}
        alt="plants"
        className="absolute bottom-[20%] left-0 -translate-x-[5%] hidden h-full w-fit md:block"
      /> */}
      <Cta
        title={
          <>
            A worthwhile
            <span className="font-bold text-yellow">
              {' '}
              yield <br /> trial{' '}
            </span>
            awaits...
          </>
        }
        image={ctaImage}
        target="/contact-us"
      />
    </section>
  )
}

export default SeedCTA
