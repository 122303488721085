import Partition from 'molecules/partition'
import bg from 'assets/cropAssure/pricing/top-right.png'
import React, { useLayoutEffect } from 'react'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
import { useNavigate } from 'react-router-dom'

gsap.registerPlugin(ScrollToPlugin)

type MonthlyOption = {
  duration: string
  price: string
}

type ListItem = {
  list: string
  checked: boolean
}

type CropPlan = {
  text: string
  monthlyOptions: MonthlyOption[]
  list: ListItem[]
}

type CropPlans = {
  [key: string]: CropPlan
}

const cropPlans: CropPlans = {
  basic: {
    text: 'Obtain village-level farm insights through satellite data.',
    monthlyOptions: [
      {
        duration: '3 - 4 months crop',
        price: '₹ 149',
      },
      {
        duration: '5 months crop',
        price: '₹ 249',
      },
      {
        duration: '10 months crop',
        price: '₹ 449',
      },
    ],
    list: [
      {
        list: 'Satellite image',
        checked: true,
      },
      {
        list: 'Advisory - Chat bot',
        checked: true,
      },
      {
        list: 'Online delivery - Input',
        checked: true,
      },
      {
        list: 'Field assistant',
        checked: false,
      },
      {
        list: 'Drone survey',
        checked: false,
      },
    ],
  },
  standard: {
    text: 'Get precise crop-specific farm insights from drone surveys along with on-ground support.',
    monthlyOptions: [
      {
        duration: '3 months crop',
        price: '₹ 399',
      },
      {
        duration: '5 months crop',
        price: '₹ 699',
      },
      {
        duration: '10 months crop',
        price: '₹ 1199',
      },
    ],
    list: [
      {
        list: 'Drone Survey - 2 flights per month',
        checked: true,
      },
      {
        list: 'Advisory - Chat bot',
        checked: true,
      },
      {
        list: 'Online delivery of farm inputs at 5% discount',
        checked: true,
      },
      {
        list: 'Agronomist service',
        checked: true,
      },
      {
        list: 'Field assistant',
        checked: true,
      },
      {
        list: 'Soil testing',
        checked: true,
      },
      {
        list: 'Market linkage',
        checked: false,
      },
      {
        list: 'Parametric crop insurance',
        checked: false,
      },
    ],
  },
  premium: {
    text: 'Avail comprehensive support for your crop, from drone-based crop monitoring to market linkage and crop insurance.',
    monthlyOptions: [
      {
        duration: '3 months crop',
        price: '₹ 549',
      },
      {
        duration: '5 months crop',
        price: '₹ 849',
      },
      {
        duration: '10 months crop',
        price: '₹ 1349',
      },
    ],
    list: [
      {
        list: 'Drone Survey - 3 flights per month',
        checked: true,
      },
      {
        list: 'Advisory - Chat bot',
        checked: true,
      },
      {
        list: 'Agronomist service',
        checked: true,
      },
      {
        list: 'Online delivery of farm inputs at 5% discount',
        checked: true,
      },
      {
        list: 'Field assistant',
        checked: true,
      },
      {
        list: 'Soil testing',
        checked: true,
      },
      {
        list: 'Certified seed delivery at 10% discount',
        checked: true,
      },
      {
        list: 'Parametric crop insurance at 10% discount',
        checked: true,
      },
      {
        list: 'Drone spray at ₹ 700',
        checked: true,
      },
    ],
  },
}

const serviceCriteria = [
  'Service fee is charged per acre per season basis.',
  'Parametric crop insurance through partnering with insurance provided at 10% discount.',
  'Minimum area - 1000 acres.',
  'No GST applicable - DSS.',
]

const Pricing = () => {
  const navigate = useNavigate()
  // useLayoutEffect(() => {
  //   const ctx = gsap.context(() => {
  //     if (location?.hash == '#crop-pricing') {
  //       gsap.to(window, { scrollTo: '#crop-pricing' })
  //     }
  //   })

  //   return () => ctx.revert()
  // })

  return (
    <section className="py-16 bg-green relative" id="crop-pricing">
      {/* bg image for top right */}
      {/* <img
        src={bg}
        alt="pricings"
        className="absolute top-0 right-0 h-64 lg:h-72 2xl:h-auto"
      /> */}

      <div className="w-container lg:w-container-lg">
        <Partition text="Pricing" color="light" />
        <h2 className="text-white py-8 text-3xl md:text-4xl lg:pb-12 lg:text-[36px] 2xl:text-[3rem]">
          Different Needs, Different Plans
        </h2>

        {/* pricing plans */}
        <div className="flex flex-col lg:flex-row justify-between gap-10 lg:gap-x-8 xl:gap-x-10 items-center">
          {Object.keys(cropPlans).map((item, key) => {
            return (
              <article
                className={`max-w-md pb-24 mx-auto lg:hover:scale-[1.015] relative rounded-[10px] rounded-tr-[142px] py-6 px-6 lg:px-4 xl:px-6 2xl:px-8 border-2 transition-all duration-300 border-lightCream self-stretch w-full lg:pb-20 xl:pb-24 lg:max-w-[26rem] 2xl:max-w-[27rem] lg:mx-2 ${
                  item === 'standard'
                    ? ' bg-lightCream text-white pricing-highlighted'
                    : ' bg-green'
                }`}
                key={key}
              >
                <Header
                  text={item}
                  themeColor={item === 'standard' ? '#FDC20C' : '#163E33'}
                />
                <div
                  className={`text-base font-light max-w-[15.5rem] md:max-w-xs lg:text-sm lg:max-w-[13rem] xl:max-w-[15rem] 2xl:max-w-xs ${
                    item === 'standard'
                      ? 'text-black '
                      : 'text-white text-opacity-90'
                  }`}
                >
                  {cropPlans[item].text}
                </div>
                <table className="my-5">
                  <tbody>
                    {cropPlans[item].monthlyOptions.map((option, index) => {
                      return (
                        <tr
                          className=""
                          key={option.duration + option.price + index}
                        >
                          <td>
                            <span
                              className={`font-semibold text-[1.2rem] lg:text-[1rem] xl:text-[1.3rem] 2xl:text-2xl ${
                                item === 'standard'
                                  ? 'text-green font-bold'
                                  : 'text-white font-normal '
                              }`}
                            >
                              {option.duration}{' '}
                            </span>
                          </td>
                          <td className="">
                            <div
                              className={`w-[1.4rem] h-[2px] lg:w-[1rem] mx-4 lg:mx-2 ${
                                item === 'standard' ? 'bg-green' : 'bg-white'
                              }`}
                            ></div>
                          </td>
                          <td>
                            <span
                              className={`text-2xl lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-yellow`}
                            >
                              {' '}
                              {option.price}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                {/* lists */}
                <div className="">
                  {cropPlans[item].list.map((listItem, index) => {
                    return (
                      <div
                        className="flex gap-x-2 items-start py-2"
                        key={listItem.list + Math.random()}
                      >
                        {listItem.checked ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 21 21"
                            fill="none"
                            className="h-4 w-4 xl:h-5 xl:w-5 mt-1.5 lg:mt-1"
                          >
                            <path
                              d="M5.90224 10.2241L9.7258 13.4105L14.8239 7.03784M10.3631 19.1458C9.19146 19.1458 8.03132 18.915 6.94889 18.4667C5.86647 18.0183 4.88295 17.3612 4.0545 16.5327C3.22605 15.7043 2.56888 14.7207 2.12053 13.6383C1.67217 12.5559 1.44141 11.3958 1.44141 10.2241C1.44141 9.05254 1.67217 7.8924 2.12053 6.80998C2.56888 5.72755 3.22605 4.74404 4.0545 3.91558C4.88295 3.08713 5.86647 2.42997 6.94889 1.98161C8.03132 1.53326 9.19146 1.30249 10.3631 1.30249C12.7292 1.30249 14.9985 2.24245 16.6716 3.91558C18.3448 5.58872 19.2847 7.85798 19.2847 10.2241C19.2847 12.5903 18.3448 14.8596 16.6716 16.5327C14.9985 18.2058 12.7292 19.1458 10.3631 19.1458Z"
                              stroke={`#E8BA3A`}
                              strokeWidth="1.78433"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 21 21"
                            fill="none"
                            className="h-4 w-4 xl:h-5 xl:w-5 mt-1 lg:mt-0.5"
                          >
                            <path
                              d="M14.4544 14.6407L14.4544 14.6407C14.2498 14.8257 13.9713 14.9303 13.6802 14.9303C13.3896 14.9303 13.1116 14.8261 12.9071 14.6417L10.3957 12.0467L10.3637 12.0136L10.3316 12.0467L7.82011 14.6409C7.71933 14.7333 7.59913 14.807 7.4663 14.8576C7.33304 14.9084 7.18985 14.9349 7.04502 14.9354C6.90019 14.9359 6.75676 14.9105 6.62305 14.8607C6.48933 14.811 6.36812 14.7378 6.2663 14.6458C6.16449 14.5537 6.08412 14.4446 6.02957 14.3249C5.97503 14.2052 5.94733 14.077 5.94791 13.9479C5.94849 13.8187 5.97735 13.6908 6.03297 13.5714C6.0886 13.4521 6.16996 13.3436 6.27261 13.2523L6.27269 13.2524L6.27501 13.25L8.88882 10.5511L8.91887 10.52L8.88882 10.489L6.27406 7.78839L6.27415 7.7883L6.27166 7.78608C6.16902 7.69478 6.08765 7.58626 6.03202 7.46691C5.9764 7.34758 5.94754 7.21966 5.94696 7.09049C5.94638 6.96132 5.97408 6.8332 6.02862 6.71347C6.08317 6.59373 6.16355 6.48462 6.26536 6.39258C6.36718 6.30053 6.48839 6.2274 6.6221 6.17762C6.75582 6.12783 6.89924 6.10243 7.04407 6.10297C7.1889 6.1035 7.33209 6.12996 7.46535 6.18072C7.59818 6.23132 7.71838 6.30503 7.81916 6.39741L10.3316 8.9934L10.3637 9.02653L10.3957 8.9934L12.9072 6.39741C13.008 6.30503 13.1282 6.23132 13.261 6.18072C13.3943 6.12996 13.5374 6.1035 13.6823 6.10297C13.8271 6.10243 13.9705 6.12783 14.1042 6.17762C14.238 6.2274 14.3592 6.30053 14.461 6.39258C14.5628 6.48462 14.6432 6.59373 14.6977 6.71347C14.7523 6.8332 14.78 6.96132 14.7794 7.09049C14.7788 7.21966 14.7499 7.34758 14.6943 7.46691C14.6387 7.58626 14.5573 7.69478 14.4547 7.78608L14.4546 7.78599L14.4523 7.78839L11.8385 10.489L11.8084 10.52L11.8385 10.5511L14.4523 13.25L14.4522 13.25L14.4544 13.252C14.5558 13.3436 14.636 13.4521 14.6906 13.5712C14.7452 13.6903 14.7733 13.8178 14.7733 13.9464C14.7733 14.075 14.7452 14.2024 14.6906 14.3215C14.636 14.4406 14.5558 14.5491 14.4544 14.6407Z"
                              fill="#FF4D00"
                              stroke="white"
                              strokeWidth="0.0892166"
                            />
                            <path
                              d="M10.3631 19.4407C9.19146 19.4407 8.03132 19.21 6.94889 18.7616C5.86647 18.3133 4.88295 17.6561 4.0545 16.8276C3.22605 15.9992 2.56888 15.0157 2.12053 13.9332C1.67217 12.8508 1.44141 11.6907 1.44141 10.5191C1.44141 9.34746 1.67217 8.18733 2.12053 7.1049C2.56888 6.02247 3.22605 5.03896 4.0545 4.21051C4.88295 3.38205 5.86647 2.72489 6.94889 2.27653C8.03132 1.82818 9.19146 1.59741 10.3631 1.59741C12.7292 1.59741 14.9985 2.53737 16.6716 4.21051C18.3448 5.88364 19.2847 8.1529 19.2847 10.5191C19.2847 12.8852 18.3448 15.1545 16.6716 16.8276C14.9985 18.5008 12.7292 19.4407 10.3631 19.4407Z"
                              stroke="#FF4D00"
                              strokeWidth="1.78433"
                            />
                          </svg>
                        )}

                        <span
                          className={`text-[1.05rem] lg:text-sm xl:text-base font-light ${
                            item === 'standard'
                              ? ' text-black '
                              : 'text-white text-opacity-90 '
                          }`}
                        >
                          {listItem.list}
                        </span>
                      </div>
                    )
                  })}
                </div>

                <button
                  onClick={() => {
                    navigate('/contact-us')
                  }}
                  aria-label={item + 'plan'}
                  className={`absolute bottom-6 left-1/2 -translate-x-1/2 font-semibold 2xl:font-bold text-[1.1rem] 2xl:text-[1.4rem] rounded-full py-2.5 2xl:py-3 px-20 lg:px-16 w-max transition-colors duration-300 ${
                    item === 'standard'
                      ? 'text-yellow bg-green hover:text-green hover:bg-yellow'
                      : 'text-green bg-yellow hover:bg-lightCream'
                  }`}
                >
                  Get in touch
                </button>
              </article>
            )
          })}
        </div>

        {/* bottom section */}
        <div className="flex flex-col md:flex-row md:gap-x-6 lg:gap-x-12 xl:gap-x-20 lg:px-2">
          <div className="relative pb-24 border-2 border-white text-white border-opacity-50 rounded-[10px] mt-12 py-6 px-6 lg:px-4 xl:px-6 2xl:px-8 w-full md:max-w-[22rem] xl:max-w-[29rem]">
            <Header text={'Trial pack'} themeColor={'#163E33'} />

            <div className="flex items-center pt-3">
              <span className="font-semibold text-[1.3rem] lg:text-[1rem] xl:text-[1.3rem] 2xl:text-2xl">
                1 month crop
              </span>
              <div
                className={`w-[1.4rem] h-[2px] lg:w-[1rem] mx-4 lg:mx-2 bg-white`}
              ></div>
              <span
                className={`text-2xl lg:text-lg xl:text-xl 2xl:text-2xl font-bold`}
              >
                ₹ 149
              </span>
            </div>

            <div className="flex gap-x-2 flex-col xl:flex-row items-start justify-center py-2 xl:justify-start xl:gap-x-8">
              <Trials text={'Satellite image'} />
              <Trials text={'Online delivery - Input'} />
            </div>

            <button
              onClick={() => {
                navigate('/contact-us')
              }}
              aria-label={'Trial pack'}
              className={`absolute left-1/2 -translate-x-1/2 bottom-6 mt-10 font-semibold 2xl:font-bold text-[1.1rem] 2xl:text-[1.4rem] rounded-full py-2.5 2xl:py-3 px-12 lg:px-8 xl:px-12 w-max transition-colors duration-300 text-green bg-yellow hover:bg-lightCream `}
            >
              Get in touch
            </button>
          </div>
          <div className="relative border-2 border-yellow border-opacity-50 rounded-[10px] mt-12 py-6 px-6 lg:px-4 xl:px-6 2xl:px-8 w-full">
            <Header text={'Services criteria'} themeColor={'#FF6E00'} />

            <ul className="lg:grid xl:grid-cols-2 gap-x-6 pricing-services list-disc list-outside text-yellow">
              {serviceCriteria.map((item, index) => {
                return (
                  <li
                    className="py-2 list-item xl:text-lg ml-6 md:pt-8"
                    key={index}
                  >
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing

function Header({ text, themeColor }: { text: string; themeColor: string }) {
  return (
    <div className="flex pb-1">
      <h3
        className={`pr-6 lg:pr-3 font-bold text-2xl 2xl:text-3xl text-yellow `}
      >
        {text.toUpperCase()}
      </h3>
      <div
        className={`h-[2px] self-center justify-self-center w-full  
        bg-yellow 
        ${
          text !== 'standard'
            ? 'max-w-[4rem] lg:max-w-[3rem] xl:max-w-[4rem]'
            : 'max-w-[6rem] lg:max-w-[4rem] xl:max-w-[6rem]'
        }
        ${
          text === 'Services criteria' &&
          'max-w-[2rem] lg:max-w-[3rem] xl:max-w-[5rem]'
        }
      `}
      ></div>
    </div>
  )
}

function Trials({ text }: { text: string }) {
  return (
    <div className="flex items-center gap-2 py-1.5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 21"
        fill="none"
        className="h-4 w-4 xl:h-5 xl:w-5"
      >
        <path
          d="M5.90224 10.2241L9.7258 13.4105L14.8239 7.03784M10.3631 19.1458C9.19146 19.1458 8.03132 18.915 6.94889 18.4667C5.86647 18.0183 4.88295 17.3612 4.0545 16.5327C3.22605 15.7043 2.56888 14.7207 2.12053 13.6383C1.67217 12.5559 1.44141 11.3958 1.44141 10.2241C1.44141 9.05254 1.67217 7.8924 2.12053 6.80998C2.56888 5.72755 3.22605 4.74404 4.0545 3.91558C4.88295 3.08713 5.86647 2.42997 6.94889 1.98161C8.03132 1.53326 9.19146 1.30249 10.3631 1.30249C12.7292 1.30249 14.9985 2.24245 16.6716 3.91558C18.3448 5.58872 19.2847 7.85798 19.2847 10.2241C19.2847 12.5903 18.3448 14.8596 16.6716 16.5327C14.9985 18.2058 12.7292 19.1458 10.3631 19.1458Z"
          stroke="#E8BA3A"
          strokeWidth="1.78433"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className={`text-[1.05rem] lg:text-sm xl:text-base`}>{text}</span>
    </div>
  )
}
