import Banner, { BannerHighlightedText } from 'organisms/banner'
import banner from 'assets/supplyChain/banner.webp'

function CropBanner() {
  return (
    <Banner
      highlighted="Assure"
      align="left"
      title={
        <span className="inline-block font-semibold max-w-[20.5rem] sm:max-w-xl lg:max-w-md 2xl:max-w-xl">
          Building a transparent{' '}
          <span className="text-yellow">agri supply chain</span> with end-to-end
          traceability
        </span>
      }
      desc={
        <span className="inline-block max-w-[18rem] sm:max-w-[28rem] lg:max-w-[30rem] xl:max-w-[34rem] 2xl:max-w-md">
          Redefining agri-food sourcing with accurate traceability insights
          covering every step of crop production.
        </span>
      }
      banner={banner}
    />
  )
}

export default CropBanner
