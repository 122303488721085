import React, { useEffect, useRef } from 'react'
// import bg from 'assets/supplyChain/meaning_bg.webp'
import dashboard from 'assets/supplyChain/dashboard.png'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const pointers = [
  {
    title: 'Produce origin',
    subtitle:
      'Comprehensive details of the produce to ensure authenticity with access to farm location, sowing and harvest data, sorting-packaging data, and more.',
  },
  {
    title: "Farmer's details",
    subtitle:
      'A panoramic view of the farmers’ profile including their crop portfolio, current crop grown, irrigation cycles, etc.',
  },
  {
    title: 'Agri inputs used',
    subtitle:
      'A comprehensive data point spanning the type and quantity of seeds, fertilisers, pesticides, biocides, herbicides and other chemicals used.',
  },
  {
    title: 'Other details',
    subtitle:
      'Listing of key insights on monitoring and evaluation framework, methods, and agriculture practices followed in the entire procurement process.',
  },
]

export default function Meaning() {
  const wrapper = useRef<any>()

  useEffect(() => {
    gsap.fromTo(
      '.traciability-opacity-anim',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: 'back.out',
        stagger: 0.2,
        scrollTrigger: {
          trigger: '.traciability-opacity-anim',
          start: 'top 70%',
          toggleActions: 'play none none reverse',
        },
      },
    )

    gsap.fromTo(
      '.traciability-card',
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'back.out',
        stagger: 0.2,
        scrollTrigger: {
          trigger: '.traciability-card-container',
          start: 'top 90%',
          toggleActions: 'play none none reverse',
        },
      },
    )

    gsap.fromTo(
      '.traciability-electronic-showcase',
      {
        opacity: 0,
        y: 100,
        scale: 0.5,
      },
      {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 1,
        ease: 'back.out',
        stagger: 0.2,
        scrollTrigger: {
          trigger: '.traciability-electronic-showcase',
          start: 'top 70%',
          toggleActions: 'play none none reverse',
        },
      },
    )
  }, [])

  return (
    <section className='bg-[url("assets/supplyChain/meaning_bg.webp")] bg-cover bg-center bg-no-repeat relative overflow-hidden'>
      <div
        className="absolute inset-0 bg-black"
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.80) 35.56%, rgba(0, 0, 0, 0.00) 100%)',
        }}
      />
      <div className="blade-top-padding-lg lg:max-w-none blade-bottom-padding  flex flex-col-reverse lg:grid relative grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-3 w-container-lg xl:w-container-lg">
        <div className="max-w-lg md:max-w-2xl mx-auto 2xl:max-w-none lg:mx-0">
          <div className="blade-top-padding-sm">
            <h2 className="traciability-opacity-anim font-semibold tracking-tight 2xl:text-[3rem] text-white pb-6 max-w-lg 2xl:max-w-xl text-center lg:text-left">
              What supply chain traceability means to us
            </h2>

            <p className="traciability-opacity-anim pb-4 text-center w-11/12 mx-auto lg:mx-0 lg:text-left text-base md:text-lg xl:text-xl 2xl:max-w-xl max-w-xl xl:max-w-md lg:ml-0  leading-normal  text-white  xl:pb-8">
              With Supply Chain Traceability, we are accentuating transparency
              across the agri-value chain while bolstering efficiency and
              restoring trust in agricultural practices.
            </p>
            <p className="traciability-opacity-anim text-base text-center w-11/12 mx-auto lg:mx-0 lg:text-left md:text-lg xl:text-xl 2xl:max-w-xl max-w-xl xl:max-w-md lg:ml-0 leading-normal  text-white  ">
              Our work focuses on building an impregnable agri-value chain that
              promotes sustainable agricultural practices, informed
              decision-making, and healthy consumers.
            </p>
          </div>
        </div>
        <div className="traciability-electronic-showcase grid place-content-center place-items-center">
          <img
            src={dashboard}
            alt="Dashboard screenshot for bharatrohan"
            className="h-auto w-full object-center py-3 max-w-md 2xl:max-w-2xl"
          />
        </div>
      </div>
      <div
        ref={wrapper}
        className="traciability-card-container grid px-3 md:px-0 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6 xl:gap-8 2xl:gap-x-16 relative blade-top-padding-sm"
      >
        {pointers.map((elem, index: number) => {
          return (
            <article
              key={index}
              className="traciability-card opacity-0 xl:rounded-tr-[7rem] 2xl:rounded-tr-[8rem] rounded-tr-[2rem]  md:rounded-tr-[5rem] odd:bg-green max-w-sm lg:max-w-xl mx-auto even:bg-white odd:text-white even:text-green  py-6 px-4 md:p-5 lg:p-8 2xl:p-9"
            >
              <h3 className="pb-4 font-semibold ">{elem.title}</h3>
              <span className="leading-normal text-sm md:text-base 2xl:text-lg block w-11/12 ">
                {elem.subtitle}{' '}
              </span>
            </article>
          )
        })}
      </div>
    </section>
  )
}
