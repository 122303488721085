import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import nonExecutiveDirectorsList from 'utils/nonExecutiveDirectorsList';
import Banner from './shared/banner'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

const NonExecutiveDirectorProfile = () => {
  let { id } = useParams<{ id: string }>();
  const director = nonExecutiveDirectorsList.find(director => director.id === id);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  if (!director) {
    return <div>Director not found</div>;
  }

  return (
    <>
        <Banner />
        <div className="flex flex-col items-center py-10 px-4">
          <div className="flex flex-col md:flex-row items-center md:items-start mx-auto">
            <FoundersLister name={director.title} post={director.post} image={director.image} desc={director.desc} linkedin={director.linkedin}
            />
          </div>
        </div>
    </>
  );
}

export default NonExecutiveDirectorProfile;


function FoundersLister({
  name,
  post,
  image,
  desc,
  linkedin,
}: {
  linkedin?: string;
  name: string;
  image: string;
  post: string;
  desc: string[];
}) {
  return (
    <article
      className={`pt-16 lg:flex lg:gap-x-8 xl:gap-x-16 2xl:pt-20 2xl:pb-8 `}
    >
      <img
        src={image}
        alt={post}
        className="w-full max-w-lg h-auto max-h-128 mx-auto lg:w-3/4 lg:max-w-md lg:max-h-128 lg:mx-0 rounded-[1.25rem] object-cover"
      />
      <div className="text-left">
        <div className="py-6 max-w-[39rem] mx-auto lg:pt-0 lg:mx-0 2xl:pb-8">
          <h4 className="font-semibold text-green text-[1.7rem] lg:-mt-1.5 2xl:text-3xl">
            {name}
          </h4>
          <h5 className="text-green font-medium md:font-semibold text-base lg:font-bold 2xl:text-lg">
            {post}
          </h5>
        </div>
        <div className="max-w-[39rem] lg:max-w-md xl:max-w-[38.5rem] flex flex-col gap-y-5 text-base mx-auto xl:text-lg xl:leading-8 2xl:text-[1.4rem] 2xl:font-medium 2xl:max-w-2xl 2xl:gap-y-8">
          {desc.map((item, index) => {
            return (
              <p key={index}>
                <i>{item}</i>
              </p>
            );
          })}
        </div>
        <div className="py-3  flex items-start">
          {linkedin && (
            <a
              href={linkedin}
              target="_blank"
              className="fill-green  transition-colors duration-300 ease-in-out  outline-offset-4 hover:fill-yellow"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x={0}
                y={0}
                viewBox="0 0 382 382"
                xmlSpace="preserve"
                className="h-7 w-7 fill-inherit"
              >
                <g>
                  <path
                    d="M347.445 0H34.555C15.471 0 0 15.471 0 34.555v312.889C0 366.529 15.471 382 34.555 382h312.889C366.529 382 382 366.529 382 347.444V34.555C382 15.471 366.529 0 347.445 0zM118.207 329.844c0 5.554-4.502 10.056-10.056 10.056H65.345c-5.554 0-10.056-4.502-10.056-10.056V150.403c0-5.554 4.502-10.056 10.056-10.056h42.806c5.554 0 10.056 4.502 10.056 10.056v179.441zM86.748 123.432c-22.459 0-40.666-18.207-40.666-40.666S64.289 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.91 330.654a9.247 9.247 0 0 1-9.246 9.246H286.73a9.247 9.247 0 0 1-9.246-9.246v-84.168c0-12.556 3.683-55.021-32.813-55.021-28.309 0-34.051 29.066-35.204 42.11v97.079a9.246 9.246 0 0 1-9.246 9.246h-44.426a9.247 9.247 0 0 1-9.246-9.246V149.593a9.247 9.247 0 0 1 9.246-9.246h44.426a9.247 9.247 0 0 1 9.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.131 68.716 73.131 106.472v86.846z"
                    style={{}}
                    fill="inherit"
                    data-original="#0077b7"
                    className=""
                  />
                </g>
              </svg>
            </a>
          )}
        </div>
      </div>
    </article>
  );
}

// <div className="flex flex-col items-center py-10 px-4">
{/* <div className="flex flex-col md:flex-row items-center md:items-start max-w-4xl mx-auto">
<img src={director.image} alt={director.title} className="w-80 h-100 rounded-md mt-5 md:mb-0 md:mr-8" />
<div className="text-left">
    <h1 className="text-3xl font-bold mt-4 md:mt-0">{director.title}</h1>
    <h2 className="text-xl mb-2">{director.post}</h2>
    {director.desc.map((paragraph, index) => (
        <p key={index} className="text-base mb-4">{paragraph}</p>
    ))}
</div>
</div>
</div> */}
