import Partition from 'molecules/partition'
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay, Pagination, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import 'swiper/css/autoplay'
import '../index.css'

import award1 from 'assets/investors/awards/1.jpg'
import award2 from 'assets/investors/awards/2.jpg'
import award3 from 'assets/investors/awards/3.jpg'
import award4 from 'assets/investors/awards/4.jpg'
import award5 from 'assets/investors/awards/5.jpg'
import award6 from 'assets/investors/awards/6.jpg'
import award7 from 'assets/investors/awards/7.jpg'
// import award8 from 'assets/investors/awards/8.jpg'

const awards = [
  {
    title: 'Award for Top Startup in Agriculture and Food',
    year: 2018,
    coverImage: award1,
  },
  {
    title: 'Food Loss Challenge Asia Judging Panel Award',
    year: 2018,
    coverImage: award2,
  },
  {
    title: '2019 AFI Best Social Enterprises (Agriculture)',
    year: 2019,
    coverImage: award3,
  },
  {
    title: 'TSS Innovative Social Enterprises Of the Year',
    year: 2021,
    coverImage: award4,
  },
  {
    title: 'ICAR-NAARM Best Agri Start Up',
    year: 2020,
    coverImage: award5,
  },
  {
    title: 'MANAGE Samunnati-Agri Startup Award',
    year: 2022,
    coverImage: award6,
  },
  {
    title: 'India International Crop Protection Expo',
    year: 2023,
    coverImage: award7,
  },
]

export default function Awards() {
  const [activeSlide, setActiveSlide] = useState(0)
  return (
    <section className="bg-green py-16 lg:pb-0">
      <section className="w-container ">
        <div>
          <Partition
            color="light"
            text="Achievements"
            title="From where pride emanates"
          />
        </div>
        <div className="flex justify-end pb-10">
          <div className="rounded-full hidden md:flex items-center gap-6 text-white max-w-fit px-3 py-2 border border-white md:ml-auto lg:px-4 lg:py-3 mt-8">
            <button
              aria-label="Previous slide"
              disabled={activeSlide === 0}
              className="group disabled:cursor-not-allowed awards-swiper-prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
                className={`h-9 w-9 lg:h-10 lg:w-10 2xl:h-11 2xl:w-11 rounded-full p-1.5 transition-colors duration-300 ${
                  activeSlide === 0
                    ? 'bg-none'
                    : 'bg-green hover:bg-yellow active:bg-green'
                }`}
              >
                <path
                  className="transition-colors duration-300"
                  fill="none"
                  stroke={`${activeSlide === 0 ? '#F7F3EA' : '#F7F3EA'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M244 400L100 256l144-144M120 256h292"
                />
              </svg>
            </button>
            <h6 className="flex items-center gap-2">
              <span className="text-yellow">0{activeSlide + 1}</span> -{' '}
              <span className="text-cream3 opacity-70">0{awards.length}</span>
            </h6>
            <button
              aria-label="Next slide"
              disabled={activeSlide === awards.length - 1}
              className="group disabled:cursor-not-allowed awards-swiper-next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
                className={`h-9 w-9 lg:h-10 lg:w-10 2xl:h-11 2xl:w-11 rounded-full p-1.5 rotate-180  transition-colors duration-300 ${
                  activeSlide === awards.length - 1
                    ? 'bg-none'
                    : 'bg-green hover:bg-yellow active:bg-green'
                }`}
              >
                <path
                  fill="none"
                  className=" transition-colors duration-300"
                  stroke={`${
                    activeSlide === awards.length - 1 ? '#F7F3EA' : '#F7F3EA'
                  }`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M244 400L100 256l144-144M120 256h292"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section>
        <Swiper
          className="cursor-grab"
          id="swiper"
          autoHeight
          modules={[Pagination, Autoplay, A11y, Navigation]}
          slidesPerView={1.2}
          spaceBetween={30}
          centeredSlides
          navigation={{
            prevEl: '.awards-swiper-prev',
            nextEl: '.awards-swiper-next',
          }}
          pagination={{
            clickable: true,
          }}
          onSlideChange={(swiper) => {
            const progressIndex = Math.round(swiper.progress * awards.length)
            setActiveSlide(
              progressIndex > awards.length - 1
                ? awards.length - 1
                : progressIndex,
            )
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            512: {
              centeredSlides: true,
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              centeredSlides: false,
              spaceBetween: 5,
              slidesPerView: 3,
            },
            1024: {
              centeredSlides: false,
              spaceBetween: 5,
              slidesPerView: 2.5,
              pagination: false,
            },
            1280: {
              centeredSlides: false,
              slidesPerView: 3.2,
              pagination: false,
            },
            1440: {
              centeredSlides: false,
              slidesPerView: 3.6,
              pagination: false,
            },
            1536: {
              centeredSlides: false,
              slidesPerView: 3.8,
              pagination: false,
            },
          }}
        >
          {awards.map((elem, index: number) => {
            return (
              <SwiperSlide key={index}>
                <article className="relative pb-4 lg:pb-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-0 right-0 lg:block hidden bottom-0 top-20 w-full"
                    viewBox="0 0 518 375"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_4525_544)">
                      <path
                        d="M30.1797 29.6196H407.969L468.77 104.834V468.21H89.6037L30.1797 396.181V29.6196Z"
                        stroke="#E8BA3A"
                        strokeWidth={3}
                        shapeRendering="crispEdges"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_4525_544"
                        x="0.552891"
                        y="-0.00716782"
                        width="516.595"
                        height="516.595"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="9.3756" dy="9.3756" />
                        <feGaussianBlur stdDeviation="18.7512" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_4525_544"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_4525_544"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                  <div className="relative">
                    <div className=" h-auto md:px-3 lg:px-0 lg:h-48  w-full  lg:w-60 xl:-translate-x-10 lg:-translate-x-8 mx-auto grid place-content-center">
                      <img
                        src={elem.coverImage}
                        alt={elem.title}
                        className="w-full h-full object-contain object-center"
                      />
                    </div>
                    <div className="text-white text-center md:text-left px-3 lg:w-10/12 pt-4 lg:pt-3  xl:w-10/12 mx-auto  pb-6 ">
                      <h6 className="font-normal">{elem.year}</h6>
                      <h5 className="leading-snug  pt-2 font-semibold">
                        {elem.title}
                      </h5>
                    </div>
                  </div>
                </article>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </section>
    </section>
  )
}
