import React, { useEffect, useLayoutEffect } from 'react'
import BlogsSection from 'organisms/blogs'
import Testimonials from './testimonials'
import Sustainability from './sustainability'
import Technology from './technology'
import Solutions from './solutions'
import Clients from './clients'
import EcoSystem from './ecosystem'
import './home.css'
import Impact from './impact'
import Banner from './banner'
import Investors from './investors'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import HomeFranchise from './franchise'
import HomeWhatsNew from './whatsNew'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
ScrollTrigger.defaults({})

export default function PageHome() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  useEffect(() => {
    const ctx = gsap.context(() => {
      const partitions = gsap.utils.toArray('.partition-line')

      partitions.forEach((elem: any) => {
        gsap.fromTo(
          elem,
          {
            scale: 0,
            transformOrigin: 'left',
          },
          {
            duration: 1.2,
            scale: 1,
            ease: 'power1.in',
            scrollTrigger: {
              trigger: elem,
              start: 'top 95%',
              toggleActions: 'restart none none reverse',
            },
          },
        )
      })
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <main>
      <Banner />
      <Solutions />
      <Clients />
      <EcoSystem />
      <Technology />
      <HomeFranchise />
      <Impact />
      <Testimonials />
      <Sustainability />
      <Investors />
      <HomeWhatsNew />
      {/* <BlogsSection /> */}
    </main>
  )
}
