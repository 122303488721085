import React, { useLayoutEffect } from 'react'

import './index.css'
import Banner from './banner'
import Intent from './intent'
import ClientBase from './clientBase'
import Journey from './journey'
import TimeLine from './timeLine'
import Vision from './vision'
import ValueSystem from './valueSystem'
import ChangeMakers from './changeMakers'
import JoinUs from './joinUs'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

export default function PageAboutUs() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <main>
      <Banner />
      <Intent />
      <ClientBase />
      <Journey />
      <TimeLine />
      <Vision />
      <ValueSystem />
      <ChangeMakers />
      <JoinUs />
    </main>
  )
}
