import React from 'react'
import { useNavigate } from 'react-router-dom'

const Cta = ({
  title,
  target,
  image,
}: {
  title: JSX.Element
  target: string
  image: string
}) => {
  const navigate = useNavigate()

  return (
    <section
      id="cta"
      className="flex flex-col-reverse place-content-center lg:place-content-start md:grid md:grid-cols-[40%_60%] w-container-lg gap-8 lg:gap-16 xl:gap-4 2xl:gap-22 font-normal"
    >
      <div className="grid place-content-center">
        <h2 className="font-semibold text-center md:text-left tracking-tight text-green pb-6 text-[1.75rem] sm:text-3xl max-w-[27rem] mx-auto md:max-w-md lg:text-4xl lg:max-w-4xl xl:max-w-full 2xl:text-5xl 2xl:pb-7">
          {title}
        </h2>
        <button
          aria-label="Call to action"
          onClick={() => {
            navigate(target)
          }}
          className=" max-w-max mx-auto h-min mt-auto md:mx-0 border-2 font-bold text-md md:text-lg bg-yellow outline-yellow text-green transition-all hover:bg-green hover:text-yellow  px-16 py-3 rounded-full md:mt-4 lg:text-xl lg:py-2.5 xl:py-3.5"
        >
          Get in touch
        </button>
      </div>
      <img
        src={image}
        alt="Get in touch with us"
        className="sm:px-28 md:px-0 lg:pr-16 xl:px-4"
      />
    </section>
  )
}

export default Cta
