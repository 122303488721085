
import React, { Fragment, useState } from 'react'

import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Listbox, Transition } from '@headlessui/react'
import { MasterBtn } from 'atoms/buttons'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import phone from 'assets/contact/phone.png'
import location from 'assets/contact/location.png'
import message from 'assets/contact/message.png'
import Partition from 'molecules/partition'
import emailjs from '@emailjs/browser'
import { PostContactForm } from 'api'

type FormProps = {
  isPurposeField: boolean
  formTitle?: string
}

const formSchema = z.object({
  name: z
    .string()
    .min(2, 'Name should have atleast 2 alphabets')
    .refine(
      (value) => /^[a-zA-Z]+[-'s]?[a-zA-Z ]+$/.test(value),
      'Name should contain only alphabets',
    )
    .refine(
      (value) => /^[a-zA-Z]+\s+[a-zA-Z]+$/.test(value),
      'Please enter both firstname and lastname',
    ),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string().length(10, 'Phone number should be of 10 digits.'),
  message: z.string(),
  newsletter : z.boolean().refine((value) => value === true, 'Newsletter must be checked'),
  // userType: z.string().refine((userType) => userType !== 'select', {
  //   message: 'Please select who are you.',
  // }),
})

const userTypes = [
  // {
  //   title: 'select',
  //   id: 'null',
  // },
  {
    title: 'Student',
    id: 'student',
  },
  {
    title: 'Farmer',
    id: 'farmer',
  },
  {
    title: 'FPO',
    id: 'fpo',
  },
  {
    title: 'Corporate',
    id: 'corporate',
  },
  {
    title: 'Investor',
    id: 'investor',
  },
]

const purposes = [
  // {
  //   title: 'select',
  //   id: 'null',
  // },
  {
    title: 'Services',
    id: 'services',
  },
  {
    title: 'Products',
    id: 'products',
  },
  {
    title: 'Others',
    id: 'others',
  },
]

export type FormFieldSchema = z.infer<typeof formSchema>

export default function FormWrapper() {
  const [purpose, setPurpose] = useState(purposes[0])
  const [userType, setuserType] = useState(userTypes[0])
  const [isTouched, setTouched] = useState(false) // avoid error while mounting the component
  const [isPurposeTouched, setPurposeTouched] = useState(false) // avoid error while mounting the component
  const [isLoading, setLoading] = useState(false);
  
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    
    console.log(data);
    
    
    const formData: any = Object.assign(data, {
      userType: userType.title,
      // purpose: purpose.title === 'select' ? purposes[1].title : purpose.title,
      // userType: userType.title === 'select' ? userTypes[1].title : userType.title,
      purpose: purpose.title
    })
    setLoading(true);
    PostContactForm(formData).then(response => {
      setLoading(false);
      if(response.message === "success"){
        reset()
        toast.success("Your form is submitted successfully")
      }
    }).catch((error) => {
      setLoading(false);
      toast.error("Internal Server Error")
    })
    
  }

  return (
    <>
      <ToastContainer />
      <section className="bg-cream2 blade-top-padding blade-bottom-padding-lg">
        <section className="w-container-lg 2xl:w-container flex blade-top-padding-sm blade-bottom-padding flex-col-reverse gap-y-5 gap-x-10 xl:flex-row">
          <section className=" pt-10 flex-1 max-w-2xl mx-auto w-full md:px-4 xl:px-0 ">
            <div className="flex flex-col px-1 gap-7 md:gap-9">
              <div className="flex items-center gap-6 md:gap-6  lg:gap-9">
                <div className="grid place-content-center bg-yellow shadow-contact-points rounded-full h-10 w-10  lg:h-12 lg:w-12">
                  <img
                    className="h-6"
                    src={phone}
                    alt="Mobile phone of the BharatRohan"
                  />
                </div>
                <div className="flex flex-col items-center justify-center ">
                  <a href="tel:+91 92661 09914" aria-label="Telephone">
                    <h5 className=" md:block hidden font-medium text-black hover:text-green focus-visible:text-green outline-none  hover:underline underline-offset-4 decoration-from-font">
                      +91 92661 09914
                    </h5>
                    <h6 className=" md:hidden block font-medium text-black hover:text-green focus-visible:text-green outline-none  hover:underline underline-offset-4 decoration-from-font">
                      +91 92661 09914
                    </h6>
                  </a>
                </div>
              </div>
              <div className="flex items-center  gap-6 md:gap-6  lg:gap-9">
                <div className="grid place-content-center bg-yellow shadow-contact-points rounded-full h-10 w-10  lg:h-12 lg:w-12">
                  <img
                    src={message}
                    alt="EmailID of the BharatRohan"
                    className="h-6"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <a
                    href="mailto:sales@bharatrohan.in"
                    className="font-medium text-black hover:text-green focus-visible:text-green outline-none  hover:underline underline-offset-4 decoration-from-font"
                  >
                    <h5 className="text-green md:block hidden ">
                      sales@bharatrohan.in
                    </h5>
                    <h6 className="text-green md:hidden block ">
                      sales@bharatrohan.in
                    </h6>
                  </a>
                  <a
                    href="mailto:marketing@bharatrohan.in"
                    className="font-medium text-black hover:text-green focus-visible:text-green outline-none  hover:underline underline-offset-4 decoration-from-font"
                  >
                    <h5 className="md:block hidden text-green ">
                      marketing@bharatrohan.in
                    </h5>
                    <h6 className="text-green md:hidden block ">
                      marketing@bharatrohan.in
                    </h6>
                  </a>
                </div>
              </div>
              <div className="flex items-center gap-6 md:gap-6  lg:gap-9">
                <div className="grid place-content-center bg-yellow shadow-contact-points rounded-full h-10 min-w-[40px] lg:min-w-[48px]  lg:h-12 lg:w-12 ">
                  <img
                    src={location}
                    alt="Office address of BharatRohan"
                    className="h-6"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h5 className="text-green font-medium 2xl:max-w-lg max-w-md md:block hidden">
                    BharatRohan Airborne Innovations Private Limited, 301, Tower
                    4, DLF Corporate Greens, Sector 74A, Gurgaon-122004.
                  </h5>
                  <h6 className="text-green font-medium max-w-lg md:hidden block">
                    BharatRohan Airborne Innovations Private Limited, 301, Tower
                    4, DLF Corporate Greens, Sector 74A, Gurgaon-122004.
                  </h6>
                </div>
              </div>

              <div className="flex-1 mt-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.424571804108!2d76.9976926!3d28.4064431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3d452f9ad691%3A0xb6d4520f6f2013e2!2sDLF%20CORPORATE%20GREENS%2C%20Southern%20Peripheral%20Rd%2C%20Sector%2074A%2C%20Gurugram%2C%20Haryana%20122004!5e0!3m2!1sen!2sin!4v1694250030734!5m2!1sen!2sin"
                  className="w-full h-full min-h-[250px] lg:min-h-[380px]"
                  style={{ border: 0 }}
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </section>
          <section className="flex-1  max-w-2xl mx-auto w-full">
            <div className="bg-white px-3  md:px-4 lg:px-8 2xl:px-12   pb-6 xl:pb-8 2xl:pb-12 pt-8 md:pt-10 2xl:pt-16 rounded-xl">
              <div>
                <Partition text="Connect with us!" color="dark" />
              </div>
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className="pt-8 flex flex-col gap-5"
              >
                <div className="grid md:grid-cols-2 gap-5">
                  <div>
                    <label htmlFor="userType">
                      <span className="text-base md:text-lg">I am</span>
                      <Listbox
                        value={userType}
                        onChange={(elem) => {
                          setuserType(elem)
                          setTouched(true)
                        }}
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className=" rounded-md bg-cream2  px-2 md:px-3 py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40 w-full text-left focus:outline-violet">
                            <span className="block truncate ">
                              <span>{userType.title}</span>
                            </span>
                            <span className="p-[2px]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="3"
                                stroke="currentColor"
                                className=" h-4 w-4 md:w-5 md:h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
                              {userTypes.map((elem) => (
                                <Listbox.Option
                                  key={elem.id}
                                  className={({ active }) =>
                                    `relative cursor-default hover:bg-lightOrange select-none text-base ${
                                      active ? 'bg-yellow' : 'text-black'
                                    }`
                                  }
                                  value={elem}
                                >
                                  {({ selected }) => (
                                    <span
                                      className={`block truncate  pl-6 pr-4 py-3 ${
                                        selected
                                          ? 'font-semibold text-white bg-green bg-opacity-90'
                                          : 'font-normal'
                                      }`}
                                    >
                                      {elem.title}
                                    </span>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                      {purpose.id === 'none' && isTouched && (
                        <span className="text-red-800 text-sm font-medium block mt-1">
                          Please select a valid input.
                        </span>
                      )}
                    </label>
                  </div>
                  <div>
                    <label htmlFor="purpose">
                      <span className="text-base md:text-lg">
                        I am looking for
                      </span>
                      <Listbox
                        value={purpose}
                        onChange={(elem) => {
                          setPurpose(elem)
                          setTouched(true)
                        }}
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className=" rounded-md bg-cream2  px-2 md:px-3  py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40   w-full text-left focus:outline-violet">
                            <span className="block truncate ">
                              <span>{purpose.title}</span>
                            </span>
                            <span className="  p-[2px]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="3"
                                stroke="currentColor"
                                className=" h-4 w-4 md:w-5 md:h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
                              {purposes.map((elem) => (
                                <Listbox.Option
                                  key={elem.id}
                                  className={({ active }) =>
                                    `relative cursor-default hover:bg-lightOrange select-none text-base ${
                                      active ? 'bg-yellow' : 'text-black'
                                    }`
                                  }
                                  value={elem}
                                >
                                  {({ selected }) => (
                                    <span
                                      className={`block truncate  pl-6 pr-4 py-3 ${
                                        selected
                                          ? 'font-semibold text-white bg-green bg-opacity-90'
                                          : 'font-normal'
                                      }`}
                                    >
                                      {elem.title}
                                    </span>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                      {purpose.id === 'none' && isPurposeTouched && (
                        <span className="text-red-800 text-sm font-medium block mt-1">
                          Please select a purpose of contact.
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="name"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Name
                    <input
                      className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-cream2 font-normal text-base"
                      id="name"
                      placeholder="Enter Name"
                      {...register('name')}
                    />
                    {errors.name && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.name?.message}
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Email Address
                    <input
                      className="py-2 md:py-3 px-2  placeholder:font-light  rounded-md  md:px-3 border border-solid  bg-cream2 font-normal text-base"
                      id="email"
                      type="email"
                      inputMode="email"
                      placeholder="Enter Email"
                      {...register('email')}
                    />
                    {errors.email && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.email?.message}
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Phone Number
                    <input
                      className="py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-cream2 font-normal text-base"
                      id="phone"
                      type="string"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="Enter Phone"
                      {...register('phone')}
                    />
                    {errors.phone && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.phone?.message}
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="newsletter"
                    className="gap-1 text-base md:text-lg"
                  >
                    
                     <input
                      id="newsletter"
                      type="checkbox"
                      className="scale-125 mr-1"
                      {...register('newsletter')}
                    />  Subscribe to the newsletter
                     {errors.newsletter && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.newsletter?.message}
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Message
                    <textarea
                      rows={4}
                      cols={5}
                      id="message"
                      className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-cream2 font-normal text-base"
                      placeholder="Enter your message"
                      {...register('message')}
                    />
                  </label>
                </div>

                <div className="grid place-content-start">
                  <button
                    aria-label="Submit form"
                    disabled={isLoading}
                    type="submit"
                    className=" disabled:opacity-30  disabled:cursor-not-allowed focus-visible:outline-offset-2 outline-yellow bg-yellow focus-visible:bg-green focus-visible:text-yellow hover:bg-green hover:text-yellow active:bg-opacity-70 transition-colors duration-150 ease-in-out rounded-full px-12 py-[12px] text-green font-medium text-base md:text-lg"
                  >
                    {isLoading ? 'Loading' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </section>
        </section>
      </section>
    </>
  )
}
