import React, { useState, useRef, useEffect } from 'react'

import image01 from 'assets/aboutUs/timeline/2016.webp'
import image02 from 'assets/aboutUs/timeline/2017.webp'
import image03 from 'assets/aboutUs/timeline/2018.webp'
import image04 from 'assets/aboutUs/timeline/2019.webp'
import image05 from 'assets/aboutUs/timeline/2020.webp'
import image06 from 'assets/aboutUs/timeline/2021.webp'
import image07 from 'assets/aboutUs/timeline/2022.webp'
import image08 from 'assets/aboutUs/timeline/2023.webp'
import image09 from 'assets/aboutUs/timeline/2024.webp'
import ringImage from 'assets/aboutUs/banner/Ellipse 101.svg'
import '../cropAssure/cropAssure.css'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

type ListItem = string | JSX.Element

const timelineList = [
  {
    image: image01,
    year: '2016',
    listTitle: 'The inception of BharatRohan in Lucknow.',
    list: [],
  },
  {
    image: image02,
    year: '2017',
    listTitle:
      'Operations flag-off with 42 farms and farmers as a pilot in partnership with CSIR-CIMAP Lucknow.',
    list: [],
  },
  {
    image: image03,
    year: '2018',
    listTitle: 'Raises grants from:',
    list: ['a-IDEA.', 'BIRAC-BIG.'],
  },
  {
    image: image04,
    year: '2019',
    listTitle: '',
    list: [
      'Outreach expands to 1,000 farmers.',
      'Onboards ITC as a client for 2 crop cycles.',
      'Raises convertible note from Startup Oasis.',
    ],
  },
  {
    image: image05,
    year: '2020',
    listTitle: '',
    list: [
      'Farmer count jumps to 2,000 followed by 3,600 within 4 months.',
      'Enters Rajasthan with cumin and moong bean crops.',
    ],
  },
  {
    image: image06,
    year: '2021',
    listTitle: '',
    list: [
      'Raises revolving credit from Samunnati.',
      '6,000 heads-strong farmer network spanning 30,000 acres becomes a reality.',
    ],
  },
  {
    image: image07,
    year: '2022',
    listTitle: '',
    list: [
      'Raises capital from Acumen and Upaya Social Ventures.',
      'Launches franchise for Drone stations to FPCs and rural youth.',
    ],
  },
  {
    image: image08,
    year: '2023',
    listTitle: '',
    list: [
      'Broadens crop portfolio with spices, pulses, oilseeds, aromatic crops, and plantation crops with individual Spectral Vegetation Indices (SVI).',
      'Enters the international markets with access to exporters.',
      <>
        The launch of{' '}
        <strong className="font-extrabold">carbon farming program</strong> with
        Alternate Wetting and Drying system (AWD) project in UP to incentivise
        farmers for reducing GHG emissions through BharatRohan's advisory.
      </>,
    ],
  },
  {
    image: image09,
    year: '2024',
    listTitle: '',
    list: [
      'Scale operations to 5,00,000 acres across 10 states.',
      'Get Fair Trade International and FSSC22000 certifications.',
    ],
  },
]

export default function TimeLine() {
  const [activeSlide, setActiveSlide] = useState(0)
  const [buttonsContainerWidth, setButtonsContainerWidth] = useState(0)

  const [mode, setMode] = useState<string>('slide-right')
  const imageRef = useRef<HTMLImageElement>(null)
  const descRef = useRef<HTMLDivElement>(null)
  const buttonsRef = useRef<any>(null)

  useEffect(() => {
    setButtonsContainerWidth(buttonsRef.current.clientWidth ?? 60 - 60)
  }, [activeSlide])

  function activeSlideHandler(index: number) {
    if (index > activeSlide) {
      setMode('slide-right')
    } else {
      setMode('slide-left')
    }
    setActiveSlide(index)
  }
  return (
    <section className="bg-green py-16 md:py-20 relative ">
      {/* For mobile */}
      <section className="timeline relative w-container-lg select-none lg:hidden">
        <Swiper
          modules={[Navigation, Pagination]}
          centeredSlides
          autoHeight
          pagination={{
            clickable: true,
          }}
          // breakpoints={{
          //   768: {
          //     pagination: false,
          //   },
          // }}
          draggable={false}
          speed={600}
          loop
          slidesPerView={1}
          spaceBetween={20}
          className="text-cream3"
        >
          {timelineList.map((timeline, index) => {
            return (
              <SwiperSlide
                key={index}
                className="h-full grid place-content-center pb-12 overflow-hidden"
              >
                <Image index={index} image={timeline.image} />
                <article className="max-w-[30rem] mx-auto">
                  <h2 className="pt-6 pb-1 text-center text-yellow font-bold">
                    {timeline.year}
                  </h2>
                  {timeline.listTitle && (
                    <p className="py-3 text-xl">{timeline.listTitle}</p>
                  )}
                  <Lister list={timeline.list} />
                </article>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </section>

      {/* For desktop */}
      <section className="w-container-lg hidden lg:block">
        <div className="flex text-white gap-x-12 xl:gap-x-20 xl:max-w-[75rem] xl:mx-auto 2xl:max-w-[90rem] ">
          <div
            className={`${mode} self-stretch overflow-hidden h-[250px] w-[32rem] xl:w-[42rem] xl:h-[350px] 2xl:h-[500px] 2xl:w-[58rem]  -translate-y-32 xl:-translate-y-36 2xl:-translate-y-48`}
          >
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={activeSlide + 'image'}
                timeout={600}
                classNames={'fade-slide'}
                nodeRef={imageRef}
              >
                <div className="relative mx-auto">
                  <img
                    ref={imageRef}
                    src={timelineList[activeSlide].image}
                    alt={`About ${timelineList[activeSlide].year}`}
                    className="rounded-full px-8"
                  />
                  <img
                    src={ringImage}
                    alt="Ring"
                    aria-hidden
                    className="absolute inset-0 h-full w-full z-10 px-4"
                  />
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
          <div
            className={`${mode} min-h-[236px] xl:min-h-[280px] w-full overflow-hidden -mt-10 2xl:mt-0`}
          >
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={activeSlide + 'image'}
                timeout={600}
                classNames={'fade-slide'}
                nodeRef={descRef}
              >
                <div className="" ref={descRef}>
                  <h2 className="pb-1 -mt-2 font-bold xl:pb-3 xl:text-5xl text-yellow">
                    {timelineList[activeSlide].year}
                  </h2>
                  {timelineList[activeSlide].listTitle && (
                    <p className="pb-3 text-xl xl:text-2xl xl:max-w-xl">
                      {timelineList[activeSlide].listTitle}
                    </p>
                  )}
                  <Lister list={timelineList[activeSlide].list} />
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
      </section>

      {/* Navigations for desktop */}
      <section className="hidden lg:block overflow-hidden relative   -mt-32 xl:-mt-36 2xl:-mt-48">
        <section
          ref={buttonsRef}
          className="flex justify-center relative max-w-max py-1.5 mx-auto mt-16"
        >
          {timelineList.map((timeline, index) => {
            return (
              <button
                aria-label={timeline.year}
                key={index}
                className={` text-2xl z-10 px-5 xl:px-7 2xl:px-8 2xl:text-3xl transition-all duration-700
                  ${index === activeSlide && ' !pr-16'}
                  ${
                    index <= activeSlide
                      ? 'text-green font-bold'
                      : 'text-white font-semibold text-opacity-50 hover:text-opacity-100'
                  }
                `}
                onClick={() => {
                  activeSlideHandler(index)
                }}
              >
                {timeline.year}
              </button>
            )
          })}
          <div
            className="absolute inset-0 bg-white bg-opacity-90 rounded-full transition-all duration-700"
            style={{
              width: `calc( ${
                ((buttonsContainerWidth - 36) / timelineList.length) *
                  (activeSlide + 1) +
                'px'
              } + 36px )`,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              onClick={() => {
                setActiveSlide((prev) => {
                  if (prev === timelineList.length - 1) return 0

                  return ++prev
                })
              }}
              className={`absolute right-5 z-10 top-1/2 -translate-y-1/2 transition-all duration-700 cursor-pointer`}
            >
              <path
                d="M2.13721 8.896C1.44685 8.896 0.887207 9.45564 0.887207 10.146C0.887207 10.8364 1.44685 11.396 2.13721 11.396V8.896ZM23.5228 11.0299C24.011 10.5417 24.011 9.75027 23.5228 9.26211L15.5679 1.30716C15.0797 0.819006 14.2883 0.819006 13.8001 1.30716C13.312 1.79532 13.312 2.58677 13.8001 3.07493L20.8712 10.146L13.8001 17.2171C13.312 17.7052 13.312 18.4967 13.8001 18.9848C14.2883 19.473 15.0797 19.473 15.5679 18.9848L23.5228 11.0299ZM2.13721 11.396H22.6389V8.896H2.13721V11.396Z"
                fill="#163E33"
              />
            </svg>
          </div>
        </section>
      </section>
    </section>
  )
}

function Image({ index, image }: { index: number; image: string }) {
  return (
    <div className="relative max-w-[22rem] mx-auto">
      <img
        src={image}
        alt={`About ${timelineList[index].year}`}
        className="rounded-full px-8"
      />
      <img
        src={ringImage}
        alt="Ring"
        className="absolute inset-0 h-full w-full z-10 px-4"
      />
    </div>
  )
}

function Lister({ list }: { list: ListItem[] }) {
  return (
    <>
      {list.map((item, index) => {
        return (
          <div key={index} className="flex py-1.5">
            <div className="rounded-full bg-yellow p-1 mr-3 max-h-1 mt-2.5 2xl:max-h-1.5 2xl:p-1.5"></div>
            <p className="font-light xl:text-lg 2xl:text-xl">{item}</p>
          </div>
        )
      })}
    </>
  )
}
