import React, { useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

import DeskImg1 from 'assets/careers/banner_images/desktop_set (1).png'
import DeskImg2 from 'assets/careers/banner_images/desktop_set (2).png'
import DeskImg3 from 'assets/careers/banner_images/desktop_set (3).png'
import DeskImg4 from 'assets/careers/banner_images/desktop_set (4).png'

import group from 'assets/careers/banner_images/group.webp'

const slides = [DeskImg1, DeskImg2, DeskImg3, DeskImg4]

interface BannerProps {
  scrollToForm: () => void;
}

export default function Values({scrollToForm}:BannerProps) {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.careers-banner-group',
        {
          y: 100,
          // opacity: 0,
          scale: 0.1,
          // rotateY: 180,
        },
        {
          y: 0,
          // opacity: 1,
          scale: 1,
          // rotateY: 0,
          duration: 1.5,
          ease: 'back.out',
          // scrollTrigger: {
          //   trigger: '.career-perks',
          //   start: 'top 85%',
          //   toggleActions: 'play none none reverse',
          // },
        },
      )
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className=" bg-lightCream blade-top-padding-lg bg-[url('./assets/careers/banner.png')] bg-cover ">
      <div className="h-full pt-24 lg:pt-0 xl:py-8 2xl:py-36 lg:w-container-2xl px-3">
        <div className="grid lg:grid-cols-2 gap-x-10">
          <div className="text-center w-full lg:text-left lg:py-36">
            <h2 className="text-appear-anim font-semibold text-green leading-tight ">
              Create the career <br className="sm:hidden lg:block xl:hidden" />{' '}
              you love
            </h2>
            <h3 className="text-appear-anim-delayed text-green leading-normal text-base xl:text-xl 2xl:text-[1.6rem] px-3 py-4 max-w-sm m-auto md:max-w-[25rem] xl:max-w-[32rem] 2xl:max-w-[36rem] lg:m-0 lg:px-0">
              Join us on the transformative journey to create a cleaner and more
              sustainable agri value chain for a prosperous tomorrow.
            </h3>

            <div className="text-appear-anim-delayed pt-4 md:pt-8 ">
              <a
                onClick={scrollToForm}
                // href="#"
                target="_blank"
                aria-label="Join us"
                className="bg-yellow hover:bg-green hover:text-yellow focus-visible:outline-green outline-offset-2 focus-visible:text-green text-green text-base xl:text-lg 2xl:text-xl px-16 py-2.5 xl:py-3 rounded-full font-bold transition-colors duration-300"
              >
                Join us
              </a>
            </div>
          </div>

          <div className="items-center h-full w-full self-center  hidden lg:flex">
            {/* Enable this if client wants separated image layout */}
            {/* <div className="d hidden lg:block relative w-full h-full lg:h-[20rem] lg:max-w-[504px] xl:max-w-[620px] xl:h-[25rem] 2xl:max-w-[850px] 2xl:h-[34rem]">
              <img
                src={DeskImg1}
                className="absolute top-0 left-[20%] w-auto h-[36%] border-green rounded-xl border-8"
                alt="Founders"
              />
              <img
                src={DeskImg2}
                className="absolute top-[30%] left-[45%] w-auto h-[41%] z-10 border-green rounded-xl border-8"
                alt="Team"
              />
              <img
                src={DeskImg3}
                className="absolute left-[40%] bottom-[0%] w-auto h-[33%] z-20 border-green rounded-xl border-8"
                alt="Team members"
              />
              <img
                src={DeskImg4}
                className="absolute top-[34%] w-auto h-[42%] border-green rounded-xl border-8"
                alt="Careers"
              />
            </div> */}
            <img
              src={group}
              alt="Our group"
              className="careers-banner-group h-auto w-full"
            />
          </div>
        </div>

        <div className="pt-10 md:pt-20 lg:hidden">
          <Swiper
            className="h-full mt-8"
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 1.5,
              },
              1024: {
                slidesPerView: 2,
              },
              1440: {
                slidesPerView: 2.5,
              },
              1536: {
                slidesPerView: 3,
              },
            }}
            slidesPerView={1.2}
            speed={10000}
            grabCursor
            keyboard
            spaceBetween={30}
            freeMode
            autoHeight
            modules={[Autoplay]}
          >
            {slides.map((elem, index: number) => {
              const key = `${index}`
              return (
                <SwiperSlide key={key}>
                  <div className="h-60 md:h-72">
                    <img
                      className="w-full h-auto object-contain object-bottom border-green border-8 rounded-xl"
                      src={elem}
                      alt="Grahics showing the culture of the BharatRohan group"
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
