import WhatsNew, { updatesList } from 'organisms/WhatsNew'
import React from 'react'

const HomeWhatsNew = () => {
  return (
    <section>
      <WhatsNew
        title="What's new"
        desc="<strong class='font-semibold'>Keep abreast of the BharatRohan family's whereabouts</strong> - from happenings to achievements and beyond."
        theme="light"
        list={updatesList.slice(0, 6)}
        toShowButton
      />
    </section>
  )
}

export default HomeWhatsNew
