import React, { useState, useRef, useEffect } from 'react'
// import image from 'assets/caseStudies/blog.jpg'
// import BlogCard from 'molecules/blogCard'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

const tabs = [
  'CropAssure®',
  'Seed Companies',
  'Research Institutes',
  'Supply Chain Traceability',
  'IPM Implementation',
  'Carbon Farming',
]

type studiesListArray = {
  title: string
  image: string // Assuming 'image' is of type string, replace it with the actual type if it's something else
  desc: string
  tag: string
  postedOn: string
  link: string
}

type StudiesList = {
  title: string
  desc: string
  list: any[]
  // list: studiesListArray[]
}

const studiesList: StudiesList[] = [
  {
    title: 'CropAssure®',
    desc: 'Stay on top of your farming practices with interesting and beneficial agricultural insights and know-how.',
    list: [
      // {
      //   title: 'How to build a carbon credit portfolio that impact',
      //   image: image,
      //   desc: 'Fasal Amrit helped reduce the leaching losses and achieve a 5% decrement in fertilizer use. ',
      //   tag: 'agriculture',
      //   postedOn: 'posted on 2023',
      //   link: 'https://agrospectrumindia.com/2023/10/17/bharatrohan-icrisat-collaborates-to-revolutionise-crop-monitoring-through-hyperspectral-tech.html',
      // },
      { link: '/case-studies/FPO case study for CropAssure.pdf' },
    ],
  },
  {
    title: 'Seed Companies',
    desc: '',
    list: [],
  },
  {
    title: 'Research Institutes',
    desc: '',
    list: [],
  },
  {
    title: 'Supply Chain Traceability',
    desc: '',
    list: [],
  },
  {
    title: 'IPM Implementation',
    desc: '',
    list: [],
  },
  {
    title: 'Carbon Farming',
    desc: '',
    list: [],
  },
]

const Studies = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const studiesRef = useRef<any>(null)

  function slideToSectionTop() {
    gsap.to(window, { duration: 1, scrollTo: '.scrollPos' })
  }

  return (
    <section className="pb-16 pt-16 md:pb-20 lg:pt-6 xl:pt-10 bg-cream">
      <section className="scrollPos">
        {/* Tab buttons */}
        <div className="sticky -top-[1px] z-[1000]">
          <div className=" pt-6 pb-4 lg:pb-2 lg:pt-[3.25rem] bg-cream ">
            <div className="w-container-lg ">
              <div className="overflow-x-auto studies-scrollbar ">
                <section className="flex lg:divide-x-2 gap-x-6 lg:gap-x-0 divide-green border-[#afafaf] lg:border-b-1 pb-3.5 mb-2">
                  {tabs.map((tab, index) => {
                    return (
                      <div
                        onClick={() => {
                          setActiveSlide(index)
                          slideToSectionTop()
                        }}
                        key={index}
                        className={`text-base min-w-max lg:min-w-[auto] lg:bg-transparent lg:text-base xl:text-lg text-dark text-opacity-60 font-semibold px-4 py-2 lg:py-0 rounded-lg border border-dark lg:border-0 lg:rounded-none lg:px-6 2xl:px-10 relative cursor-pointer text-center transition-all duration-300
                  ${
                    activeSlide === index &&
                    ' !text-white bg-green lg:!text-green border-green !text-opacity-100'
                  }
                `}
                      >
                        {tab}
                        <div
                          className={`hidden lg:block absolute h-[2px] w-0 bg-green -bottom-4 left-1/2 -translate-x-1/2 transition-all duration-500
                    ${activeSlide === index && ' !w-[60%]'}
                `}
                        ></div>
                      </div>
                    )
                  })}
                </section>
              </div>
            </div>
          </div>

          <div className="h-6 w-full bg-gradient-to-b from-cream to-transparent"></div>
        </div>

        {/* Tab details */}
        <div className="w-container-lg">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeSlide + 'study'}
              timeout={300}
              classNames={'fade-show'}
              nodeRef={studiesRef}
            >
              <section ref={studiesRef}>
                <h2 className="text-3xl text-center text-green font-semibold md:text-left xl:text-4xl 2xl:text-[3rem]">
                  {studiesList[activeSlide].title}
                </h2>
                {studiesList[activeSlide].desc && (
                  <p className="text-center pt-4 mx-auto max-w-sm md:text-left md:mx-0 xl:text-lg xl:max-w-md 2xl:max-w-xl">
                    {studiesList[activeSlide].desc}
                  </p>
                )}
                {studiesList[activeSlide].list.length !== 0 && (
                  <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 pt-4 pb-3 xl:gap-x-12 place-content-center">
                    {/* {studiesList[activeSlide]?.list?.map((item, index) => {
                      return (
                        <BlogCard
                          key={index}
                          title={item.title}
                          image={item.image}
                          desc={item.desc}
                          tag={item.tag}
                          postedOn={item.postedOn}
                          theme={'light'}
                          // large={true}
                        />
                      )
                    })} */}
                    {studiesList[activeSlide]?.list?.map((item, index) => {
                      return (
                        <a
                          key={index}
                          href={item.link}
                          aria-label="Download the brochure"
                          target="_blank"
                          className="outline-none border-1 border-transparent font-semibold mt-2 rounded-full px-8 py-3 cursor-pointer text-sm transition text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white w-fit"
                        >
                          Download brochure
                        </a>
                      )
                    })}
                  </section>
                )}
                {studiesList[activeSlide].list.length == 0 && (
                  <h6 className="pt-28 pb-16 flex justify-center items-center text-green font-semibold">
                    Coming soon...
                  </h6>
                )}
              </section>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </section>
    </section>
  )
}

export default Studies
