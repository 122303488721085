import Franchise from 'organisms/franchise'
import React from 'react'
import bg from 'assets/technology/franchise/franchise.png'

const TechFranchise = () => {
  return (
    <Franchise
      title={
        <>
          We are evolving - <br />
          BharatRohan's tech franchise is{' '}
          <span className="text-yellow">coming soon</span>. <br />
          Stay tuned.
        </>
      }
      bgImage={bg}
      bgColor={'bg-green'}
    />
  )
}

export default TechFranchise
