import React, { ReactNode, useRef, useState } from 'react'
import slide1 from 'assets/sustainability/process/slide01.webp'
import ipmIcon1 from 'assets/sustainability/process/ipm/icon01.svg'
import ipmIcon2 from 'assets/sustainability/process/ipm/icon02.svg'
import ipmIcon3 from 'assets/sustainability/process/ipm/icon03.svg'

import slide2 from 'assets/sustainability/process/slide02.webp'
import carbonFarmingIcon1 from 'assets/sustainability/process/carbonFarming/icon01.svg'
import carbonFarmingIcon2 from 'assets/sustainability/process/carbonFarming/icon02.svg'
import carbonFarmingIcon3 from 'assets/sustainability/process/carbonFarming/icon03.svg'

import slide3 from 'assets/sustainability/process/slide03.webp'
import supplyChainIcon1 from 'assets/sustainability/process/supplyChain/icon01.svg'
import supplyChainIcon2 from 'assets/sustainability/process/supplyChain/icon02.svg'
import supplyChainIcon3 from 'assets/sustainability/process/supplyChain/icon03.svg'

// import icon1 from 'assets/sustainability/process/icon.svg'

import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { useNavigate } from 'react-router-dom'
import Partition from 'molecules/partition'

type listDetails = {
  icon: string
  text: string
}

const theme = 'dark'

const processList = [
  {
    bg: slide1,
    title: 'Integrated Pest Management (IPM)',
    subTitle: (
      <>Promoting judicious use of pesticides and chemicals to ensure</>
    ),
    navigation: '/ipm',
    list: [
      {
        icon: ipmIcon1,
        text: 'Residue-free produce.',
      },
      {
        icon: ipmIcon2,
        text: 'Safe food for consumers.',
      },
      {
        icon: ipmIcon3,
        text: 'Savings in farming expenses.',
      },
    ],
    listJSX: [
      <>
        Residue-free <br className="lg:block hidden" /> produce.
      </>,
      <>
        Safe food for <br className="lg:block hidden" /> consumers.
      </>,
      <>
        Savings in <br className="lg:block hidden" /> farming expenses.
      </>,
    ],
  },
  {
    bg: slide2,
    title: 'Carbon farming',
    subTitle: (
      <>
        Sequestering carbon dioxide (CO<sub>2</sub>) and methane{' '}
        <br className="lg:block hidden" /> (CH<sub>4</sub>) into the soil to
        ensure
      </>
    ),
    navigation: '/carbon-farming',
    list: [
      {
        icon: carbonFarmingIcon1,
        text: 'Enhanced soil health and fertility.',
      },
      {
        icon: carbonFarmingIcon2,
        text: 'Reduction of CO2 and CH4 from the atmosphere.',
      },
      {
        icon: carbonFarmingIcon3,
        text: 'Agri input savings.',
      },
    ],
    listJSX: [
      <>
        Enhanced soil <br className="lg:block hidden" /> health and fertility.
      </>,
      <>
        Reduction of CO<sub>2</sub> and CH<sub>4</sub>{' '}
        <br className="lg:block hidden" /> from the atmosphere.
      </>,
      <>Agri input savings.</>,
    ],
  },
  {
    bg: slide3,
    title: 'Supply chain traceability',
    subTitle: (
      <>
        Creating a secure and transparent agri-food{' '}
        <br className="lg:block hidden" /> supply chain to ensure
      </>
    ),
    navigation: '/supply-chain',
    list: [
      {
        icon: supplyChainIcon1,
        text: 'Safe growing and handling of produce.',
      },
      {
        icon: supplyChainIcon2,
        text: 'Access to farm insights.',
      },
      {
        icon: supplyChainIcon3,
        text: 'Enhanced transparency and trust.',
      },
    ],
    listJSX: [
      <>
        Safe growing and <br className="lg:block hidden" /> handling of produce.
      </>,
      <>
        Access to <br className="lg:block hidden" /> farm insights.
      </>,
      <>
        Enhanced transparency <br className="lg:block hidden" /> and trust.
      </>,
    ],
  },
]

const Process = () => {
  const [activeProcess, setActiveProcess] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  return (
    <section className="bg-green">
      {/* For mobile */}
      <section className="lg:hidden process-section-mob py-16 w-container">
        <Partition text="How we do it" color="light" />
        <div className="">
          {processList.map((process, index: number) => {
            return (
              <ProcessDetailer
                key={index}
                bg={process.bg}
                list={process.list}
                listJSX={process.listJSX}
                subTitle={process.subTitle}
                title={process.title}
                navigationLink={process.navigation}
                onNavigate={navigate}
                index={index}
              />
            )
          })}
        </div>
      </section>

      {/* For desktop */}
      <section className="hidden process-section-desk relative lg:block bg-green overflow-hidden">
        {/* Buttons */}
        <div>
          <button
            className={`process-next-btn rounded-full absolute top-1/2 right-[4.7rem] -translate-y-1/2 z-10
            ${activeProcess === processList.length - 1 && 'opacity-50'}
          `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-auto h-12 xl:h-16 group"
              viewBox="0 0 63 64"
              fill="none"
            >
              <circle
                cx="28.1926"
                cy="28.1926"
                r="28.1926"
                transform="matrix(1 0 0 -1 3.30737 60.1929)"
                fill="#FDC20C"
                className="group-hover:fill-green transition-colors group-active:fill-[#0d4500]"
              />
              <path
                d="M20.4153 30.5002C19.5869 30.5002 18.9153 31.1718 18.9153 32.0002C18.9153 32.8287 19.5869 33.5002 20.4153 33.5002L20.4153 30.5002ZM43.6458 33.0609C44.2316 32.4751 44.2316 31.5254 43.6458 30.9396L34.0999 21.3936C33.5141 20.8079 32.5643 20.8079 31.9786 21.3936C31.3928 21.9794 31.3928 22.9292 31.9786 23.515L40.4638 32.0002L31.9786 40.4855C31.3928 41.0713 31.3928 42.0211 31.9786 42.6068C32.5643 43.1926 33.5141 43.1926 34.0999 42.6068L43.6458 33.0609ZM20.4153 33.5002L42.5852 33.5002L42.5852 30.5002L20.4153 30.5002L20.4153 33.5002Z"
                fill="#193C34"
                className="group-hover:fill-yellow transition-colors"
              />
            </svg>
          </button>
          <button
            className={`process-prev-btn rounded-full absolute top-1/2 left-12 -translate-y-1/2 rotate-180 z-10
            ${activeProcess === 0 && 'opacity-50'}
          `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-auto h-12 xl:h-16 group"
              viewBox="0 0 63 64"
              fill="none"
            >
              <circle
                cx="28.1926"
                cy="28.1926"
                r="28.1926"
                transform="matrix(1 0 0 -1 3.30737 60.1929)"
                fill="#FDC20C"
                className="group-hover:fill-green transition-colors group-active:fill-[#0d4500]"
              />
              <path
                d="M20.4153 30.5002C19.5869 30.5002 18.9153 31.1718 18.9153 32.0002C18.9153 32.8287 19.5869 33.5002 20.4153 33.5002L20.4153 30.5002ZM43.6458 33.0609C44.2316 32.4751 44.2316 31.5254 43.6458 30.9396L34.0999 21.3936C33.5141 20.8079 32.5643 20.8079 31.9786 21.3936C31.3928 21.9794 31.3928 22.9292 31.9786 23.515L40.4638 32.0002L31.9786 40.4855C31.3928 41.0713 31.3928 42.0211 31.9786 42.6068C32.5643 43.1926 33.5141 43.1926 34.0999 42.6068L43.6458 33.0609ZM20.4153 33.5002L42.5852 33.5002L42.5852 30.5002L20.4153 30.5002L20.4153 33.5002Z"
                fill="#193C34"
                className="group-hover:fill-yellow transition-colors"
              />
            </svg>
          </button>
        </div>
        {/* Swiper */}
        <div className={`overflow-hidden`}>
          <Swiper
            className="relative "
            autoHeight
            speed={700}
            navigation={{
              nextEl: '.process-next-btn',
              prevEl: '.process-prev-btn',
            }}
            onSlideChange={(swiper) => {
              setActiveProcess(swiper.activeIndex % processList.length)
            }}
            modules={[Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            initialSlide={0}
            slidesPerView={1}
          >
            {processList.map((process, index: number) => {
              return (
                <SwiperSlide
                  key={index}
                  className="h-[230px] md:h-[320px] 2xl:h-[350px]"
                >
                  <img
                    src={process.bg}
                    alt={process.title}
                    className="h-screen w-screen object-cover"
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        {/* Details */}
        <div className="text-white absolute bottom-0 left-0 z-20">
          <p className="bg-yellow text-green rounded-tr-[1.5rem] max-w-fit px-12 py-2 text-sm xl:text-lg font-bold">
            How do we do it
          </p>
          <article className="bg-green relative rounded-tr-[8rem] 2xl:rounded-tr-[12.5rem] w-auto px-12">
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={activeProcess + 'article'}
                timeout={300}
                classNames={'fade-show'}
                nodeRef={contentRef}
              >
                <div ref={contentRef}>
                  <h2 className="font-medium pt-6 2xl:pt-10 text-3xl 2xl:text-4xl 2xl:max-w-lg 2xl:leading-[4rem] lg:pr-12 2xl:pr-0">
                    {processList[activeProcess].title}
                  </h2>
                  <p className="pt-3 font-light text-sm xl:text-base 2xl:pt-1 2xl:text-lg">
                    {processList[activeProcess].subTitle}
                  </p>
                  <div className="flex py-6 xl:py-8 2xl:py-12 gap-x-8 2xl:gap-x-12">
                    {processList[activeProcess].list.map((item, index) => {
                      return (
                        <div className="flex gap-x-2 items-center" key={index}>
                          <img
                            src={item.icon}
                            alt={item.icon}
                            className="h-auto w-20 xl:w-[6rem] 2xl:w-[7rem]"
                          />
                          <p className="text-sm xl:text-base 2xl:text-lg">
                            {processList[activeProcess].listJSX[index]}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </CSSTransition>
            </SwitchTransition>

            <div
              onClick={() => {
                navigate(processList[activeProcess].navigation)
              }}
              className="text-white bg-green hover:text-green text-sm h-24 w-24 2xl:h-28 2xl:w-28 border-2 grid place-content-center transition-colors duration-300 border-yellow font-semibold hover:bg-yellow rounded-full absolute -top-3 -right-3 2xl:top-0 2xl:right-0 cursor-pointer"
            >
              Know more
            </div>
          </article>
        </div>
      </section>
    </section>
  )
}

export default Process

function ProcessDetailer({
  title,
  navigationLink,
  subTitle,
  list,
  listJSX,
  onNavigate,
  bg,
  index,
}: {
  title: string
  navigationLink: string
  subTitle: ReactNode
  list: listDetails[]
  listJSX: ReactNode[]
  onNavigate: any
  bg: string
  index: number
}) {
  return (
    <>
      {/* <p className="bg-yellow text-green rounded-tr-[1.5rem] max-w-fit px-12 py-2 text-sm xl:text-lg 2xl:text-xl font-bold">
        How do we do it
      </p> */}
      <article className=" text-white relative w-auto pt-3">
        <div className="">
          <img
            src={bg}
            alt={title}
            className="w-full max-w-md rounded-xl mt-10 mx-auto md:max-w-lg"
          />
        </div>
        <div>
          <h2 className="text-center font-medium pt-6 max-w-sm text-[1.75rem] mx-auto md:max-w-lg">
            {title}
          </h2>
          <p className="pt-3 font-light text-base text-center max-w-sm mx-auto">
            {subTitle}
          </p>
          <div className="pt-10">
            <Swiper
              className="relative h-full rounded-xl pb-6"
              autoHeight
              speed={700}
              modules={[Navigation, Pagination, Autoplay]}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
              }}
              initialSlide={0}
              slidesPerView={1}
              spaceBetween={20}
            >
              {list.map((process, index: number) => {
                return (
                  <SwiperSlide key={index} className="h-full pb-4">
                    <div
                      className="flex flex-col gap-3 items-center"
                      key={index}
                    >
                      <img
                        src={process.icon}
                        alt={process.text}
                        className="h-auto w-32"
                      />
                      <p className="text-lg text-center">{listJSX[index]}</p>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div className="flex justify-center mb-12">
            <button
              onClick={() => {
                onNavigate(navigationLink)
              }}
              aria-label="Read more"
              className={`outline-none border-1 border-transparent font-semibold mt-6 rounded-full px-8 py-2 cursor-pointer text-sm transition 
        ${
          theme === 'dark'
            ? 'font-bold text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white'
            : 'font-bold text-green bg-yellow hover:bg-green hover:text-white'
        }
      `}
            >
              Know more
            </button>
          </div>
          <div
            className={`h-[2px] rounded-full w-[80%] mx-auto
              ${index === processList.length - 1 && 'opacity-0 -mt-10'}
            `}
            style={{
              background:
                'linear-gradient(90deg, rgba(255,253,225,0) 0%, rgba(255,253,228,0.7679446778711485) 22%, rgba(255,253,229,0.773546918767507) 80%, rgba(255,253,239,0) 100%)',
            }}
          ></div>
        </div>

        {/* <div
          onClick={() => {
            onNavigate(navigationLink)
          }}
          className="text-white bg-green hover:text-green text-sm h-24 w-24 2xl:h-32 2xl:w-32 border-2 grid place-content-center transition-colors duration-300 border-yellow font-medium hover:bg-yellow rounded-full absolute top-2 right-2 2xl:top-0 2xl:right-0"
        >
          Know more
        </div> */}
      </article>
    </>
  )
}
