import processMain from 'assets/cropAssure/process/process-main.svg'
import process01 from 'assets/seedAssure/process/process01.png'
import process02 from 'assets/seedAssure/process/process02.png'
import process03 from 'assets/seedAssure/process/process03.png'
import process04 from 'assets/seedAssure/process/process04.png'

import Process, { ProcessList } from 'organisms/process'

const processList: ProcessList[] = [
  {
    desc: (
      <>
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          Securing tie-ups with <br />
        </span>{' '}
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Seed Companies
        </strong>{' '}
      </>
    ),

    image: process01,
    navText: 'Seed companies',
  },
  {
    desc: (
      <>
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Routine Data Collection <br />
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          with hyperspectral imaging drones
        </span>
      </>
    ),

    image: process03,
    navText: 'Data collection',
  },
  {
    desc: (
      <>
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Regular Data Sharing <br />
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          with researchers for better yield trials
        </span>
      </>
    ),

    image: process04,
    navText: 'Data sharing',
  },
]

const SeedProcess = () => {
  return (
    <Process
      partition="How it happens"
      title="Raising the bar in seed quality with drone-backed data precision"
      processList={processList}
    />
  )
}

export default SeedProcess
