import Banner, { BannerHighlightedText } from 'organisms/banner'
import banner from 'assets/sourceAssure/banner.webp'

function SourceBanner() {
  return (
    <Banner
      highlighted="SourceAssure banner"
      title={
        <div className="max-w-sm sm:max-w-lg md:max-w-2xl lg:max-w-none">
          <BannerHighlightedText highlighted="Assure" />
          -ing a revitalised supply <br className="lg:block hidden" />
          chain at the horizon of quality,
          <br className="lg:block hidden" /> traceability, and transparency
        </div>
      }
      desc={
        <span className="inline-block max-w-sm sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg 2xl:max-w-[37rem]">
          Reimagining informed decision-making for <b>procurers</b> with crystal
          clear farm-level traceability data and export ready produce.
        </span>
      }
      banner={banner}
      align="left"
    />
  )
}

export default SourceBanner
