import React from 'react'

export default function Aside({ productImage }: { productImage: string }) {
  return (
    <section className="bg-[url(assets/product/product-bg.png)] bg-cover w-full h-[60vh] z-10 md:h-[70vh] xl:sticky xl:top-0 xl:h-screen grid place-content-center xl:w-[38vw] lg:w-[45vw] lg:h-screen lg:sticky lg:top-0 bg-green">
      <img
        className="h-auto w-11/12 object-cover object-center lg:object-center m-auto 2xl:w-auto mt-8"
        src={productImage}
        alt="product image"
      />
    </section>
  )
}
