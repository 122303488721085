import Partition from 'molecules/partition'
import React, { useEffect, useRef, useState } from 'react'
import Image01 from 'assets/sourceAssure/process/image-01.jpg'
import Image02 from 'assets/sourceAssure/process/image-02.jpg'
import Image03 from 'assets/sourceAssure/process/image-03.jpg'
import Image04 from 'assets/sourceAssure/process/image-04.jpg'
import Image05 from 'assets/sourceAssure/process/image-05.jpg'
import Image06 from 'assets/sourceAssure/process/image-06.jpg'
import Image07 from 'assets/sourceAssure/process/image-07.jpg'
import Image08 from 'assets/sourceAssure/process/image-08.jpg'
import Image09 from 'assets/sourceAssure/process/image-09.jpg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const data = [
  {
    title: (
      <TextContainer>
        <StrongText text={'Partnering'} />
        <NormalText text=" with farmers" />
      </TextContainer>
    ),
    image: Image01,
    text: 'Partnering with farmers',
  },
  {
    title: (
      <TextContainer>
        <NormalText text="Regular data collection with " />
        <br />
        <StrongText text={'drone surveys'} />
      </TextContainer>
    ),
    image: Image02,
    text: 'Regular data collection with drone surveys',
  },
  {
    title: (
      <TextContainer>
        <StrongText text={'Advisory generation'} />
        <NormalText text=" by agronomists and " />
        <StrongText text={'implementation '} />
        <NormalText text="by the farmers" />
      </TextContainer>
    ),
    image: Image03,
    text: 'Advisory generation by agronomists and implementation by the farmers',
  },
  {
    title: (
      <TextContainer>
        <StrongText text={'Buyback'} />
        <NormalText text=" of the harvest" />
      </TextContainer>
    ),
    image: Image04,
    text: 'Buyback of the harvest',
  },
  {
    title: (
      <TextContainer>
        <StrongText text={'Aggregation'} />
        <NormalText text=" at Procurement Centre" />
      </TextContainer>
    ),
    image: Image05,
    text: 'Aggregation at Procurement Centre',
  },
  {
    title: (
      <TextContainer>
        <StrongText text={'Storage'} />
        <NormalText text=" in warehouse" />
      </TextContainer>
    ),
    image: Image06,
    text: 'Storage in warehouse',
  },
  {
    title: (
      <TextContainer>
        <StrongText text={'Processing'} /> <br />
        <NormalText text=" (Machine cleaning and sortex)" />
      </TextContainer>
    ),
    image: Image07,
    text: 'Processing (Machine cleaning and sortex)',
  },
  {
    title: (
      <TextContainer>
        <NormalText text="Labelling and QR coding of the produce for " />
        <StrongText text={'traceability'} />
      </TextContainer>
    ),
    image: Image08,
    text: 'Labelling and QR coding of the produce for traceability',
  },
  {
    title: (
      <TextContainer>
        <StrongText text={'Delivery'} />
        <NormalText text=" to customer" />
      </TextContainer>
    ),
    image: Image09,
    text: 'Delivery to customer',
  },
]

function StrongText({ text }: { text: string }) {
  return <strong className="">{text}</strong>
}

function NormalText({ text }: { text: string }) {
  return (
    <span className=" text-base xl:text-[1.3rem] text-white font-medium tracking-normal text-opacity-70">
      {text}
    </span>
  )
}

function TextContainer(props: any) {
  return (
    <span className="text-lg lg:text-[1.3rem] xl:text-[1.8rem] text-white text-opacity-90 font-regular">
      {props.children}
    </span>
  )
}

export const Process = () => {
  const processContainerRef = useRef<any>(null)
  const pinnedElemRef = useRef<any>(null)
  const containerElemRef = useRef<any>()

  const [reset, setReset] = useState(false)

  useEffect(() => {
    // VVI for refresh gsap so that it will work properly on safari
    setTimeout(() => {
      setReset(true)
    }, 2000)
  }, [])

  useEffect(() => {
    const selector = gsap.utils.selector(processContainerRef)
    const ctx = gsap.context(() => {
      const articles = selector('.opacity-controller')

      //? animation for article scroll opacity
      articles.forEach((elem) => {
        gsap.fromTo(
          elem,
          {
            opacity: 0.8,
          },
          {
            // duration: 1,
            opacity: 0.15,
            scrollTrigger: {
              trigger: elem,
              start: 'top 50%',
              end: 'bottom 50%',
              toggleActions: 'play reverse play reverse',
            },
          },
        )
      })

      //? animation for pinning section to top
      gsap.to(pinnedElemRef.current, {
        scrollTrigger: {
          trigger: pinnedElemRef.current,
          pin: true,
          start: 'top top',
          pinSpacing: false,
          endTrigger: containerElemRef.current,
          end: 'bottom bottom',
          // toggleActions: 'play reverse play reverse',
          // markers: true,
        },
      })

      // ? Scrollbar animation
      gsap.fromTo(
        '.scroller',
        {
          y: 0,
        },
        {
          duration: 5,
          y: 240,
          scrollTrigger: {
            trigger: '.scroll-container',
            start: 'top 25%',
            scrub: true,
            end: 'bottom 80%',
            // toggleActions: 'play none none reverse',
            // markers: true,
          },
        },
      )
    })

    return () => {
      ctx.revert()
    }
  }, [reset])

  return (
    <section className="relative">
      <section
        ref={processContainerRef}
        className="pb-16 bg-green relative bg-[url(assets/sourceAssure/process/bg.png)] bg-cover bg-fixed "
      >
        <div className="h-10 bg-green w-full"></div>
        <div ref={containerElemRef} className="container-elem">
          <div ref={pinnedElemRef} className="pinned-elem z-20 ">
            <div className="bg-green ">
              <div className="w-container-lg pt-4 relative">
                <Partition text="How it happens" color="light" />
                <h2 className="font-semibold leading-tight pb-3 lg:pb-10 text-white text-[1.8rem] pt-6 max-w-xs xl:max-w-md 2xl:max-w-xl xl:text-4xl 2xl:text-[3rem]">
                  A bird's eye view of our sourcing process
                </h2>

                {/* side scroller */}
                <div className="w-[5px] lg:w-2 absolute top-[50vh] rounded-full bg-opacity-25 h-[300px] bg-white right-1 lg:-right-8 xl:-right-10 -translate-y-1/2">
                  <div
                    className={`w-full bg-white scroller rounded-full absolute h-[60px] `}
                  />
                </div>
              </div>
            </div>
            <div className="h-6 w-full bg-gradient-to-b from-green to-transparent"></div>
          </div>

          <div className="bg-transparent w-container-lg scroll-container relative flex flex-row pr-4  ">
            <div className="grid w-full md:grid-cols-2 justify-between gap-x-12 2xl:gap-x-16 gap-y-6 md:gap-y-16 lg:gap-y-24 xl:gap-y-28 2xl:gap-y-36">
              {data.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  index={index}
                  img={item.image}
                  text={item.text}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

const Card = ({
  title,
  index,
  img,
  text,
}: {
  title: JSX.Element
  index: number
  img: string
  text: string
}) => {
  return (
    <article
      className={`${index % 2 !== 0 && 'md:-translate-y-[70%]'} ${
        index === 0 && 'md:col-start-2'
      } relative process-card `}
    >
      <img className="w-full h-56 md:h-full" src={img} alt={text} />
      <div className="z-10 p-4 flex flex-col top-0 justify-between w-full h-full absolute text-white">
        <h4 className="tracking-tighter text-[1.3rem] xl:text-[2.5rem] font-bold text-white text-opacity-70">
          {`0${index + 1}`}
        </h4>
        <div className="flex lg:justify-end ">
          <h6 className="font-normal text-left max-w-md leading-5 lg:leading-7">
            {title}
          </h6>
        </div>
      </div>
      <div className="opacity-controller absolute top-0 left-0 bg-black w-full h-full opacity-40"></div>
      {/* gradient for text background to make it visible  */}
      <div
        style={{
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 212, 255, 0) 40%)',
        }}
        className="absolute top-0 left-0 h-full w-full"
      ></div>
    </article>
  )
}
