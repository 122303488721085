import Banner from 'organisms/banner'
import banner from 'assets/caseStudies/banner/banner.jpg'

function CropBanner() {
  return (
    <Banner
      highlighted="Assure"
      align="left"
      title={
        <span className="inline-block font-semibold max-w-[20rem] md:max-w-md lg:max-w-none">
          Proving the mettle of{' '}
          <span className="text-yellow">drone-backed</span>{' '}
          <br className="hidden lg:block" />
          precision agriculture
        </span>
      }
      desc={
        <span className="inline-block max-w-[18rem] sm:max-w-[28rem] xl:max-w-xl 2xl:max-w-[39rem]">
          Take a plunge into BharatRohan's on-ground effectiveness with
          real-world use cases and data insights.
        </span>
      }
      banner={banner}
    />
  )
}

export default CropBanner
