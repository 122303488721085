import React, { Fragment, useEffect, useState } from 'react'

import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Listbox, Transition } from '@headlessui/react'
import { MasterBtn } from 'atoms/buttons'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import emailjs from '@emailjs/browser'
import Partition from 'molecules/partition'
import topLeft from 'assets/careers/contact/topLeft.png'
import bottomRight from 'assets/careers/contact/bottomRight.png'
import { PostCareerForm } from 'api'

const formSchema = z.object({
  fullName: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  // linkedInProfile: z.string().url('Please input a valid URL.'),
  linkedInProfile: z.string(),
  // resume: z.string().url('Please input a valid URL.'),
  // resume: z
  // .instanceof(File)
  // .refine((file) => file.type === 'application/pdf', {
  //   message: 'Only PDF files are allowed',
  // })
  // .refine((file) => file.size <= 1 * 1024 * 1024, {
  //   message: 'File size should be less than 5MB',
  // }),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string().length(10, 'Phone number should be of 10 digits.'),
  message: z.string(),
})

export type FormFieldSchema = z.infer<typeof formSchema>

const designations = [
  {
    title: 'Administration',
    id: 'admin',
  },
  {
    title: 'Sales',
    id: 'sales',
  },
  {
    title: 'Marketing',
    id: 'marketing',
  },
  {
    title: 'Drone pilot',
    id: 'drone_pilot',
  },
  {
    title: 'UAV system engineer',
    id: 'syseng',
  },
  {
    title: 'Software developer',
    id: 'software_developer',
  },
  {
    title: 'Finance',
    id: 'finance',
  },
  {
    title: 'Remote sensing',
    id: 'remote_sensing',
  },
]

export default function Values() {
  const [designation, setDesignation] = useState(designations[0])
  const [isTouched, setTouched] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [fileObject, setFileObject] = useState({});
  const [fileError, setFileError] = useState('');
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const handleFileChange = (file : File) => {
    console.log(file);
    if(file){
      if(file.size>1*1024*1024){
        setFileError('File should be less than 1mb');
      }else if(file.type != 'application/pdf'){
        setFileError('File should be in pdf.')
      }else{
        setFileObject(file);
        setFileError('')
      }
    }
  }
  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    if(!fileObject){
      setFileError('File is required');
    }

    const formData: any = Object.assign(data, {
      designation: designation.title,
      resume : fileObject
    })

    // formData api-call

    try {
      setLoading(true)
      const response = (await PostCareerForm(formData) as any);
      console.log(response);
      setLoading(false);
      if(response?.status === 201){
        toast.success("Your form is submitted successfully");
      }
      reset()
    } catch (error) {
      setLoading(false);
      console.log(error);
    }


    // setLoading(true)

    // emailjs
    //   .send(
    //     'service_bsz0ve7',
    //     'template_4dyl4oh',
    //     formData,
    //     'aU1mgEtDta0qcM271',
    //   )
    //   .then(
    //     function (response) {
    //       toast.success('Form has been submitted successfully.')
    //       setLoading(false)
    //     },
    //     function (err) {
    //       toast.error('Failed to submit the form. Please try again later.')
    //       setLoading(false)
    //     },
    //   )

    
  }
  
  return (
    <section className="bg-lightCream px-2 py-16 md:py-20 relative" id="form">
      <img
        src={topLeft}
        alt="Contact us"
        className="absolute top-0 left-0 hidden xl:block h-auto w-60 2xl:w-96 min-[1800px]:w-auto"
      />
      <img
        src={bottomRight}
        alt="Contact us"
        className="absolute bottom-0 right-0 hidden xl:block h-auto w-60 2xl:w-96 min-[1800px]:w-auto"
      />
      <section className="relative xl:max-w-[70%] mx-auto max-[1800px]:max-w-none">
        <section className="bg-white max-w-5xl mx-auto w-full pt-8 pb-4 md:pb-1.5 lg:pt-10 xl:pt-12 px-3 md:px-6 lg:px-12  xl:px-14 rounded-lg">
          <div className="max-w-sm">
            <Partition color="dark" text="Join the team" />
          </div>
          <section className="pt-2 md:pt-4 lg:pt-2">
            <ToastContainer />
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              className="pt-3 md:py-5 lg:pt-8 flex flex-col gap-3 md:gap-5"
            >
              <div className="grid  grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 lg:gap-7 md:pt-2">
                <div>
                  <label
                    htmlFor="name"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Name
                    <input
                      className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-lightCream font-normal text-base"
                      id="name"
                      placeholder="Enter Name"
                      {...register('fullName')}
                    />
                    {errors.fullName && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.fullName?.message}
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Email address
                    <input
                      className="py-2 md:py-3 px-2  placeholder:font-light  rounded-md  md:px-3 border border-solid  bg-lightCream font-normal text-base"
                      id="email"
                      type="email"
                      inputMode="email"
                      placeholder="Enter Email"
                      {...register('email')}
                    />
                    {errors.email && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.email?.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>

              <div className="grid  grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 lg:gap-7 md:pt-2">
                <div>
                  <label
                    htmlFor="phone"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Phone number
                    <input
                      className="py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-lightCream font-normal text-base"
                      id="phone"
                      type="number"
                      inputMode="numeric"
                      placeholder="Enter Phone"
                      {...register('phone')}
                    />
                    {errors.phone && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.phone?.message}
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="linkedinProfile"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    LinkedIn profile
                    <input
                      className="py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-lightCream font-normal text-base"
                      id="linkedinProfile"
                      placeholder="Enter LinkedIn Profile"
                      {...register('linkedInProfile')}
                    />
                    {errors.linkedInProfile && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {errors.linkedInProfile?.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="grid  grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 lg:gap-7 md:pt-2">
                <div>
                  <label htmlFor="userType">
                    <span className="text-base md:text-lg">I am from</span>
                    <Listbox
                      value={designation}
                      onChange={(elem) => {
                        setDesignation(elem)
                        setTouched(true)
                      }}
                    >
                      <div className="relative mt-1">
                        <Listbox.Button className=" rounded-md bg-lightCream  px-2 md:px-3 py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40 w-full text-left focus:outline-violet">
                          <span className="block truncate ">
                            <span>{designation.title}</span>
                          </span>
                          <span className="p-[2px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="3"
                              stroke="currentColor"
                              className=" h-4 w-4 md:w-5 md:h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
                            {designations.map((elem) => (
                              <Listbox.Option
                                key={elem.id}
                                className={({ active }) =>
                                  `relative cursor-default hover:bg-lightOrange select-none text-base ${
                                    active ? 'bg-yellow' : 'text-black'
                                  }`
                                }
                                value={elem}
                              >
                                {({ selected }) => (
                                  <span
                                    className={`block truncate  pl-6 pr-4 py-3 ${
                                      selected
                                        ? 'font-semibold text-white bg-green bg-opacity-90'
                                        : 'font-normal'
                                    }`}
                                  >
                                    {elem.title}
                                  </span>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                    {designation.id === 'none' && isTouched && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        Please select a valid input.
                      </span>
                    )}
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="resume"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Link for your resume
                    <input
                      type='file'
                      className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-lightCream font-normal text-base"
                      id="resume"
                      placeholder="Enter Resume"
                      // {...register('resume')}
                      onChange={(event : any) => handleFileChange(event.target.files[0])}
                    />
                    {fileError!='' && (
                      <span className="text-red-800 text-sm font-medium block mt-1">
                        {fileError}
                      </span>
                    )}
                  </label>
                </div>
              </div>

              <div className="pt-2">
                <label
                  htmlFor="message"
                  className="grid gap-1 text-base md:text-lg"
                >
                  Message
                  <textarea
                    rows={5}
                    cols={5}
                    id="message"
                    className=" py-2 md:py-3 px-2 placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-lightCream font-normal text-base"
                    placeholder="Enter your message"
                    {...register('message')}
                  />
                </label>
              </div>

              <div className="grid place-content-center my-6">
                <button
                  aria-label="Submit form"
                  disabled={isLoading}
                  type="submit"
                  className=" disabled:opacity-30  disabled:cursor-not-allowed focus-visible:text-yellow focus-visible:outline-offset-2 text-green font-semibold outline-green bg-yellow focus-visible:bg-green focus-visible::text-yellow hover:bg-green hover:text-yellow transition-colors duration-300 ease-in-out rounded-full px-12 py-[12px] text-base md:text-lg"
                >
                  Send message
                </button>
              </div>
            </form>
          </section>
        </section>
      </section>
    </section>
  )
}
