import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Benefits from './benefits'

import './index.css'
import TechFranchise from './franchise'
import TechnologyCTA from './cta'
import Technologies from './technologies'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

const Technology = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (!window.location.search.includes('tech')) {
        gsap.to(window, { scrollTo: 0 })
      }
    })
    return () => ctx.revert()
  }, [])

  return (
    <main>
      <Banner />
      <Technologies />
      <Benefits />
      <TechFranchise />
      <TechnologyCTA />
    </main>
  )
}

export default Technology
