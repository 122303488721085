import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Form from './form'
import './contact.css'

export default function Pagecontact() {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <Banner />
      <Form />
    </>
  )
}
