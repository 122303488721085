import React, { useEffect } from 'react'

import Partition from 'molecules/partition'
import CuminSeeds from 'assets/sourceAssure/products/cumin-seeds.png'
import FennelSeeds from 'assets/sourceAssure/products/fennel-seeds.png'
import CorianderSeeds from 'assets/sourceAssure/products/coriander-seeds.png'
import MustardSeeds from 'assets/sourceAssure/products/mustard-seeds.png'
import FenugreekSeeds from 'assets/sourceAssure/products/fenugreek-seeds.png'
import GingerSeeds from 'assets/sourceAssure/products/ginger-seeds.webp'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { NavigateFunction, useNavigate } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

type Product = {
  heading: string
  para: string
  image: string
  path: string
}

export const products: Product[] = [
  {
    heading: 'Cumin',
    para: 'Pungent-flavoured excellence from Rajasthan’s Marwar region for that warm flavoursome touch that makes food foodsome.',
    image: CuminSeeds,
    path: '/products/cumin',
  },
  {
    heading: 'Fennel',
    para: 'Polyphenol antioxidant-rich seeds for that distinct refreshing sweetness sourced from Jodhpur, Rajasthan.',
    image: FennelSeeds,
    path: '/products/fennel',
  },
  {
    heading: 'Coriander',
    para: 'Signature aromatic flavour fetched from farms near Chambal River (Rajasthan) that adds zing to Indian, Latin American, and Middle Eastern cuisines.',
    image: CorianderSeeds,
    path: '/products/coriander',
  },
  {
    heading: 'Fenugreek',
    para: 'Brownish-yellow goodness and a staple in Middle Eastern, Mediterranean, and Indian cuisines sourced from India’s fenugreek centre, Rajasthan.',
    image: FenugreekSeeds,
    path: '/products/fenugreek',
  },
  {
    heading: 'Mustard',
    para: 'A leading favourite in global cuisines for their irreplaceable presence sourced from mustard farms in Pali, Rajasthan.',
    image: MustardSeeds,
    path: '/products/mustard',
  },
  {
    heading: 'Ginger',
    para: 'Sourced from Meghalaya, our ginger boasts an exceptional quality, with its pungent and aromatic rhizome enhancing both cuisine and traditional medicine.',
    image: GingerSeeds,
    path: '/products/ginger',
  },
]

type CardProps = {
  heading: string
  para: string
  image: string
  path: string
  onNavigate: NavigateFunction
}

const Products = ({
  title,
  partitionText,
  products,
  partitionTheme,
  isSingleSection,
}: {
  title: JSX.Element
  partitionText: string
  products: Product[]
  partitionTheme: 'light' | 'dark'
  isSingleSection?: boolean
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.product-card-container',
        {
          y: 100,
          opacity: 0,
          scale: 0.5,
          rotateY: 180,
        },
        {
          y: 0,
          opacity: 1,
          scale: 1,
          rotateY: 0,
          duration: 1.5,
          ease: 'back.out',
          stagger: 0.2,
          scrollTrigger: {
            trigger: '.product-card-container',
            start: 'top 80%',
            toggleActions: 'play none none reverse',
          },
        },
      )
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className={`${isSingleSection && 'pb-8 md:pb-12 lg:pb-4'}`}>
      <div className="w-container-lg">
        <Partition text={partitionText} color={partitionTheme} />
        <h2 className="font-semibold text-3xl text-center pt-4 pb-10 md:text-left xl:pt-6 xl:pb-12 xl:text-4xl 2xl:text-[3rem]">
          {title}
        </h2>
      </div>

      {/* lg screen */}
      <div className="hidden md:grid md:grid-cols-2 xl:grid-cols-3 w-container lg:w-container-lg 2xl:w-container gap-y-8 md:gap-12 lg:gap-y-16 lg:gap-x-0 xl:gap-12 2xl:gap-y-16">
        {products.map((item, index) => (
          <Card
            heading={item.heading}
            para={item.para}
            image={item.image}
            key={index}
            path={item.path}
            onNavigate={navigate}
          />
        ))}
      </div>

      {/* sm screens */}
      <div className="md:hidden products-swiper ">
        <Swiper
          className="px-3"
          // autoplay={{ delay: 2000 }}
          speed={400}
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{ clickable: true }}
          centeredSlides
          initialSlide={0}
          slidesPerView={1}
          loop
          spaceBetween={20}
        >
          {products.map((item, index: number) => {
            return (
              <SwiperSlide key={index} className="grid">
                <Card
                  heading={item.heading}
                  para={item.para}
                  image={item.image}
                  key={index}
                  path={item.path}
                  onNavigate={navigate}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default Products

const Card = ({ heading, para, image, path, onNavigate }: CardProps) => {
  return (
    <article className="product-card-container self-stretch cursor-pointer mx-auto max-w-[24rem] 2xl:max-w-[26rem] h-[28rem] sm:h-[30rem] md:h-auto">
      <div
        className={`product-card relative transition-all bg-green lg:bg-white lg:hover:bg-green group py-2 self-stretch w-full max-w-[460px] sm:w-[420px] md:w-auto h-full duration-300 
          ${location.href.includes(heading.toLowerCase()) && '!bg-green'}
        `}
      >
        <div className="px-6 xl:px-10 sm:px-8 h-[40%] sm:h-[35%] md:h-[45%] lg:h-[35%] my-6 sm:my-8">
          <h4
            className={`font-semibold text-2xl transition-all text-white lg:text-green lg:font-bold lg:group-hover:text-cream duration-300 2xl:text-[1.85rem]
              ${location.href.includes(heading.toLowerCase()) && '!text-white'}
            `}
          >
            {heading}
          </h4>
          <span
            className={`font-normal transition-all pt-6 lg:pt-4 inline-block text-white lg:text-black lg:group-hover:text-white duration-300
              ${location.href.includes(heading.toLowerCase()) && '!text-white'}
            `}
          >
            {para}
          </span>
        </div>
        <div className="absolute z-10 opacity-100 lg:opacity-0 lg:group-hover:opacity-100 hover:scale-105 transition-all duration-300 sm:top-[50%] md:top-[60%] lg:top-[50%] -translate-y-1/2 right-10 group-focus-within:!opacity-100">
          <button
            onClick={() => {
              onNavigate(path)
            }}
            className="bg-white text-green px-7 sm:px-8 py-2.5 rounded-full font-bold text-[0.95rem] outline-lime-400 outline-offset-4"
          >
            Know more
          </button>
        </div>
        <div className="w-full h-[60%] overflow-hidden">
          <img
            src={image}
            alt={heading}
            className="w-full h-full object-cover object-center transition-all group-hover:scale-110 duration-500"
          />
        </div>
      </div>
    </article>
  )
}
