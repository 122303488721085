import React, { useEffect, useState } from 'react'
import nonExecutiveDirectorsList from 'utils/nonExecutiveDirectorsList'
import { useNavigate } from 'react-router-dom'
// class="scroll-container h-64 overflow-y-auto"
export default function RishabhChoudhary() {
  const navigate = useNavigate()
  return (
    <section>
      <div className=" grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-6">
        {nonExecutiveDirectorsList.map((item, index) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              key={index}
              onClick={() => {
                navigate(`non-executive/${item.id}`)
              }}
            >
              <DirectorCard
                key={index}
                title={item.title}
                image={item.image}
                post={item.post}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

function DirectorCard({
  title,
  post,
  // desc,
  image,
}: {
  title: string
  post: string
  // desc: string[]
  image: string
}) {
  const [lineClamper, setLineClamper] = useState(false)
  const [lineClampTimer, setLineClampTimer] = useState(false)

  return (
    <div className="bg-cream shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-1.5">
      <img
        src={image}
        alt={title}
        className="md:h-[15rem] w-[100%] rounded-xl md:rounded-[1.25rem] md:border-x-8 border-none 2xl:h-[16rem] xl:h-[14rem]"
      />
      <div className="mt-2 text-center">
        <h5 className="text-sm xl:text-[12px] font-bold">{title}</h5>
        <p className="text-sm">{post}</p>
      </div>
    </div>
  )
}
