import React, { useLayoutEffect } from 'react'
import Banner from './layout/banner'
import Details from './layout/details'
import banner from 'assets/product/fenugreek/fenugreek-bg.webp'
import aside from 'assets/product/fenugreek/fenugreek.webp'

// use types to ensure correct props
import { IGeoData } from './layout/types'
import OtherProducts from './layout/products'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const geoData: IGeoData = [
  {
    type: 'Country',
    value: 'India',
  },
  {
    type: 'State',
    value: 'Rajasthan',
  },
  {
    type: 'Region',
    value: 'Nagaur',
  },
]

const tableHeadings = [
  {
    title: 'Variant',
    classNames: 'w-12 text-left pl-2 lg:min-w-[9vw] xl:min-w-[12vw] xl:pl-7',
  },
  {
    title: 'Package Size',
    classNames: 'w-20 text-center lg:min-w-[9vw] xl:min-w-[10vw]',
  },
  {
    title: 'Moisture',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[6vw]',
  },
  {
    title: 'Purity',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[7vw]',
  },
  {
    title: 'Description',
    classNames: 'text-left',
  },
]

const variants = [
  {
    varient: 'methi taj',
    package: 'Custom',
    moisture: '8-10%',
    purity: '99.7%',
    desc: 'Average & small size seeds with good yellow colour seeds, 0.3% max. impurity.',
  },
  {
    varient: 'methi mango',
    package: 'Custom',
    moisture: '8-10%',
    purity: '99.7%',
    desc: 'Average & small size seeds with average yellow colour & some red colour seeds, 0.3% max. impurity.',
  },
  // {
  //   varient: 'methi mango',
  //   package: '50 Kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Average & small size seeds with average yellow colour & some red colour seeds, 0.3% max. impurity',
  // },
  // {
  //   varient: 'methi taj',
  //   package: '50 Kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Average & small size seeds with good yellow colour seeds , 0.3% max. impurit',
  // },
  // {
  //   varient: 'methi horse',
  //   package: '50%',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: 'Sortex clean big size seeds with bright yellow colour, 0.3% max. impurity',
  // },
  // {
  //   varient: 'methi for powder purpose (gulab)',
  //   package: '50 Kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: '-',
  // },
  // {
  //   varient: 'methikuria for pickle and cooking purpose',
  //   package: '50 Kg',
  //   moisture: '8%',
  //   purity: '99.7%',
  //   desc: '-',
  // },
]

export default function Fenugreek() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <div>
      <Banner
        bgImage={banner}
        title="Fenugreek (Whole)"
        desc="With as much as 99.7% purity, our partner farms supply us with the best quality fenugreek seeds from india’s fenugreek seeds capital, rajasthan. home to a variety of protein, starch, sugar, mucilage, minerals, volatile oil, fixed oil, vitamins, and enzymes, the bitter yet renowned seed spice marks its presence across leading global preparations."
      />
      <Details
        tableHeading={tableHeadings}
        variantDetails={variants}
        asideImage={aside}
        title="Fenugreek (Whole)"
        geoData={geoData}
      />
      <OtherProducts />
    </div>
  )
}
