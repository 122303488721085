import React from 'react'
import bannerCenter from 'assets/contact/banner-updated.png'
import bannerElem1 from 'assets/contact/banner_elsms (1).png'
import bannerElem2 from 'assets/contact/banner_elsms (2).png'
import bannerElem3 from 'assets/contact/banner_elsms (3).png'
import bannerElem4 from 'assets/contact/banner-elem_4.png'

export default function Banner() {
  return (
    <section className=" overflow-hidden  min-h-[550px] md:min-h-[750px] lg:min-h-[750px] 2xl:min-h-[800px] bg-green blade-top-padding-lg bg-[url('./assets/footer/front.jpg')] bg-no-repeat bg-top grid  ">
      <div className="flex items-center justify-end flex-col text-center gap-3 h-full blade-top-padding">
        <h2 className="text-appear-anim font-medium text-white leading-tight w-11/12 max-w-sm md:max-w-none">
          Where ideas converge and <br className="md:block hidden" />
          success emerges
        </h2>
        <h6 className="text-appear-anim-delayed font-medium text-white text-opacity-80">
          Let's transform the agri <br className="md:hidden block" /> value
          chain together.
        </h6>
        <div className="text-appear-anim-delayed h-64 sm:h-72 md:h-96 relative  max-w-2xl xl:max-w-5xl w-full realtive overflow-hidden">
          <img
            src={bannerCenter}
            alt=""
            className="left-0 scale-[0.75] min-[400px]:scale-[0.6] sm:scale-75 md:scale-60  xl:scale-100 mx-auto top-auto right-0 absolute  -bottom-24 md:-bottom-24 lg:-bottom-32"
          />
          <img
            src={bannerElem1}
            alt=""
            className="left-[10%] bottom-auto right-auto absolute top-20 md:top-24 md:block hidden"
          />
          <img
            src={bannerElem3}
            alt=""
            className="left-[2%] top-auto right-auto absolute bottom-14 md:block hidden"
          />
          <img
            src={bannerElem4}
            alt=""
            className="right-[10%] top-auto left-auto absolute bottom-16 md:block hidden"
          />
          <img
            src={bannerElem2}
            alt=""
            className="right-[12%] bottom-auto left-auto absolute top-20 md:block hidden"
          />
        </div>
      </div>
    </section>
  )
}
