import React from 'react'
import Banner from 'organisms/banner'

export default function ProductBanner({
  title,
  desc,
  bgImage,
}: {
  title: string
  bgImage: string
  desc: string
}) {
  return (
    <Banner
      highlighted="Assure"
      title={
        <div className="lg:w-[88vw]">
          <span className="inline-block max-w-sm sm:max-w-lg md:max-w-2xl lg:float-left 2xl:max-w-3xl text-yellow">
            {title}
          </span>
        </div>
      }
      desc={
        <div className="w-[88vw]">
          <span className="inline-block max-w-xs sm:max-w-[24rem] lg:float-left lg:text-left lg:max-w-[26rem] xl:max-w-2xl 2xl:max-w-2xl">
            {desc}
          </span>
        </div>
      }
      banner={bgImage}
    />
  )
}
