import React from 'react'
import ctaImage from 'assets/seedAssure/cta.png'
import Cta from 'organisms/cta'

const SourceCTA = () => {
  return (
    <section className="bg-lightCream py-16 px-4 relative overflow-hidden cta-section xl:bg-[url(assets/globals/cta-bg.svg)] bg-[-80px_-150px] min-[1600px]:bg-[0px_-200px] bg-contain bg-no-repeat">
      <Cta
        title={
          <span className="text-green">
            Looking to source
            <span className="text-yellow"> high-quality</span> and
            <span className="text-yellow"> sustainably </span>
            sourced produce?{' '}
          </span>
        }
        image={ctaImage}
        target="/contact-us"
      />
    </section>
  )
}

export default SourceCTA
