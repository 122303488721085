import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Updates from './updates'
import Blogs from './blogs'

const BlogsAndUpdates = () => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <main>
      <Banner />
      <Updates />
      <Blogs />
    </main>
  )
}

export default BlogsAndUpdates
