import BlogCard from 'molecules/blogCard'
import React from 'react'
import { CaseStudyType } from './caseStudy'

import image0 from 'assets/blogsAndUpdates/updates/image00.jpg'
import image1 from 'assets/blogsAndUpdates/updates/image01.jpg'
import image2 from 'assets/blogsAndUpdates/updates/image02.jpg'
import image3 from 'assets/blogsAndUpdates/updates/image03.jpg'
import defaultImage from 'assets/blogsAndUpdates/updates/image04.jpg'
import image5 from 'assets/blogsAndUpdates/updates/image05.jpg'
import image6 from 'assets/blogsAndUpdates/updates/Image06.jpg'
import image7 from 'assets/blogsAndUpdates/updates/image07.jpg'
import image8 from 'assets/blogsAndUpdates/updates/Image08.jpg'
import image9 from 'assets/blogsAndUpdates/updates/image09.jpg'
import image10 from 'assets/blogsAndUpdates/updates/image10.webp'
import image11 from 'assets/blogsAndUpdates/updates/image11.webp'
import image12 from 'assets/blogsAndUpdates/updates/image12.webp'
import image13 from 'assets/blogsAndUpdates/updates/image13.jpg'
import image14 from 'assets/blogsAndUpdates/updates/image14.webp'
import image15 from 'assets/blogsAndUpdates/updates/image15.webp'
import image16 from 'assets/blogsAndUpdates/updates/image16.webp'
import image17 from 'assets/blogsAndUpdates/updates/image17.webp'
import image18 from 'assets/blogsAndUpdates/updates/image18.webp'
import image19 from 'assets/blogsAndUpdates/updates/image19.webp'
import image20 from 'assets/blogsAndUpdates/updates/image20.webp'
import image21 from 'assets/blogsAndUpdates/updates/image21.webp'
import image22 from 'assets/blogsAndUpdates/updates/image22.webp'
import image23 from 'assets/blogsAndUpdates/updates/image23.webp'
import image24 from 'assets/blogsAndUpdates/updates/image24.webp'
import image25 from 'assets/blogsAndUpdates/updates/image25.webp'
import image26 from 'assets/blogsAndUpdates/updates/image26.webp'
import image27 from 'assets/blogsAndUpdates/updates/image27.webp'
import image28 from 'assets/blogsAndUpdates/updates/image28.webp'
import image29 from 'assets/blogsAndUpdates/updates/image29.jpg'
import image30 from 'assets/blogsAndUpdates/updates/image30.jpg'
import image31 from 'assets/blogsAndUpdates/updates/image31.jpg'
import image32 from 'assets/blogsAndUpdates/updates/image32.webp'
import image33 from 'assets/blogsAndUpdates/updates/image33.png'
import image34 from 'assets/blogsAndUpdates/updates/image30.jpg'
import image35 from 'assets/blogsAndUpdates/updates/image30.jpg'
import image36 from 'assets/blogsAndUpdates/updates/image30.jpg'
import image40 from 'assets/blogsAndUpdates/updates/image40.webp'


import logo1 from 'assets/blogsAndUpdates/updates/logos/01.svg'
import logo2 from 'assets/blogsAndUpdates/updates/logos/02.png'
import logo3 from 'assets/blogsAndUpdates/updates/logos/03.webp'
import logo4 from 'assets/blogsAndUpdates/updates/logos/04.svg'
import logo5 from 'assets/blogsAndUpdates/updates/logos/05.png'
import logo6 from 'assets/blogsAndUpdates/updates/logos/06.svg'
import logo7 from 'assets/blogsAndUpdates/updates/logos/07.png'
import logo8 from 'assets/blogsAndUpdates/updates/logos/08.jpg'
import logo9 from 'assets/blogsAndUpdates/updates/logos/09.png'
import logo10 from 'assets/blogsAndUpdates/updates/logos/10.webp'
import logo11 from 'assets/blogsAndUpdates/updates/logos/11.svg'
import logo12 from 'assets/blogsAndUpdates/updates/logos/12.webp'
import logo13 from 'assets/blogsAndUpdates/updates/logos/13.jpg'
import logo14 from 'assets/blogsAndUpdates/updates/logos/14.png'
import logo15 from 'assets/blogsAndUpdates/updates/logos/15.webp'
import logo16 from 'assets/blogsAndUpdates/updates/logos/16.webp'
import logo17 from 'assets/blogsAndUpdates/updates/logos/17.webp'
import logo18 from 'assets/blogsAndUpdates/updates/logos/18.png'
import logo19 from 'assets/blogsAndUpdates/updates/logos/19.png'
import logo20 from 'assets/blogsAndUpdates/updates/logos/20.webp'
import logo21 from 'assets/blogsAndUpdates/updates/logos/21.svg'
import logo22 from 'assets/blogsAndUpdates/updates/logos/22.webp'
import logo23 from 'assets/blogsAndUpdates/updates/logos/23.png'
import logo24 from 'assets/blogsAndUpdates/updates/logos/24.webp'
import logo25 from 'assets/blogsAndUpdates/updates/logos/25.jpg'
import logo26 from 'assets/blogsAndUpdates/updates/logos/26.png'
import logo27 from 'assets/blogsAndUpdates/updates/logos/27.webp'
import logo28 from 'assets/blogsAndUpdates/updates/logos/28.png'
import logo29 from 'assets/blogsAndUpdates/updates/logos/29.png'
import logo30 from 'assets/blogsAndUpdates/updates/logos/30.png'
import logo31 from 'assets/blogsAndUpdates/updates/logos/31.png'
import logo32 from 'assets/blogsAndUpdates/updates/logos/32.png'

import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'

export const updatesList: CaseStudyType[] = [
  // {
  //   title: 'Tech innovations reshaping sustainable agriculture',
  //   image: image12,
  //   desc: 'The infusion of data into agriculture has the potential to redefine the industry',
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Nov 27, 2023',
  //   link: 'https://www.financialexpress.com/business/digital-transformation-tech-innovations-reshaping-sustainable-agriculture-3318780/',
  //   logo: logo1,
  //   alt: 'Financial Express',
  // },
  // {
  //   title: 'Indian Agtech Startup Aims to Strengthen Rural Communities',
  //   image: image12,
  //   desc: 'Indian Agtech startup, BharatRohan has entered into a partnership with the United Nations Economic and Social Commission for Asia and the Pacific (ESCAP).',
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Nov 23, 2023',
  //   link: 'https://www.geospatialworld.net/prime/interviews/indian-agtech-startup-rural-communities/',
  //   logo: logo2,
  //   alt: 'Geo Spatial World',
  // },
  // {
  //   title:
  //     'BharatRohan and ESCAP collaborate to transform agricultural methods in Telangana and Haryana',
  //   image: image11,
  //   desc: 'With the support of ESCAP, BharatRohan aims to incorporate inclusive business practices and expand its reach and mission in the states of Telangana and Haryana.',
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Nov 21, 2023',
  //   link: 'https://agrospectrumindia.com/2023/11/21/bharatrohan-and-escap-collaborate-to-transform-agricultural-methods-in-telangana-and-haryana.html',
  //   logo: logo3,
  //   alt: 'Agro Spectrum India',
  // },
  // {
  //   title: 'BharatRohan to diversify into exports of farm produce',
  //   image: image10,
  //   desc: 'Eyes markets in European Union, China for cumin, coriander, chilli and ginger.',
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Nov 20, 2023',
  //   link: 'https://www.thehindubusinessline.com/economy/agri-business/bharatrohan-to-diversify-into-exports-of-farm-produce/article67554455.ece',
  //   logo: logo4,
  //   alt: 'The Hindu Business Line',
  // },
  // {
  //   title:
  //     'How Technology Is Helping Farmers In boosting India’s Economy | Exclusive',
  //   image: image9,
  //   desc: 'The integration of UAV technology and hyperspectral imagery is revolutionizing Indian agriculture.',
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Nov 15, 2023',
  //   link: 'https://english.jagran.com/technology/how-technology-is-helping-farmers-in-boosting-indias-economy-exclusive-10114078',
  //   logo: logo5,
  //   alt: 'English Jagran',
  // },
  // {
  //   title: 'How drones are tranforming Indian agriculture practices',
  //   image: image0,
  //   desc: 'Drones or UAVs are converting the abstract concept of precision agriculture into a tangible reality. Let’s delve into the multifaceted ways drones are transforming agriculture through compelling data and actionable insights.',
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Nov 06, 2023',
  //   link: 'https://yourstory.com/2023/11/how-drones-are-transforming-indian-agriculture-practices',
  //   logo: logo6,
  //   alt: 'Your Story',
  // },
  // {
  //   title:
  //     'BharatRohan and ABI-ICRISAT Forge Innovative Partnership to Empower Farmers with Drone Technology and Sustainable Solutions',
  //   image: image1,
  //   desc: 'BharatRohan, a pioneering agri-tech startup specializing in UAV/Drone Spectral Imaging-based Decision Support System (DSS) for farmers, is thrilled to announce a strategic collaboration with ABI-ICRISAT, the Agri-Business Incubator of ICRISAT. This dynamic alliance marks a significant milestone in the field of agricultural technology, poised to revolutionize crop monitoring through hyperspectral technology.',
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Oct 17, 2023',
  //   link: 'https://www.cxotoday.com/press-release/bharatrohan-and-icrisat-to-join-forces-to-empower-farmers-with-drone-technology-and-sustainable-solutions/',
  //   logo: logo7,
  //   alt: 'CXO Today',
  // },
  // {
  //   title:
  //     'BharatRohan: Weaving Innovation Across India | The Open Innovator Show',
  //   image: image2,
  //   desc: 'Embark on a journey into the future of agriculture with Amandeep Panwar, Founder of BharatRohan, on Episode 1 of The Open Innovator Show! Witness the unveiling of agricultural secrets through the lens of Drone Hyperspectral Imaging Technology, as BharatRohan meticulously weaves a tapestry of progress, pixel by pixel. ',
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Oct 14, 2023',
  //   link: 'https://www.youtube.com/watch?v=y8-DWm3FmDM',
  //   logo: logo8,
  //   alt: 'Open Innovator',
  // },

  // {
  //   title:
  //     'BharatRohan Partners with Kosher Climate to Transform Sustainable Rice Cultivation in Barabanki District, Uttar Pradesh',
  //   image: defaultImage,
  //   desc: 'BharatRohan Airborne Innovations, a pioneer in drone crop monitoring, has joined forces with Kosher Climate to promote sustainable paddy farming. Their collaborative initiative addresses both environmental and economic challenges faced by farmers, especially the rice growing small holders.',
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Aug 28, 2023',
  //   link: 'https://aninews.in/news/business/business/bharatrohan-partners-with-kosher-climate-to-transform-sustainable-rice-cultivation-in-barabanki-district-uttar-pradesh20230828112642/',
  //   logo: logo9,
  //   alt: 'ANI News',
  // },
  // {
  //   title:
  //     'BharatRohan and Smart Village Movement Partner to Bring Drone Crop Monitoring to Ginger Farmers in Meghalaya',
  //   image: image3,
  //   desc: 'This collaboration is set to revolutionize ginger farming in Meghalaya, by reducing their crop losses and improving their income and livelihoods. The project will leverage advanced drone-based hyperspectral imaging and data-driven insights, ensuring affordability for smallholder farmers.',
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Aug 08, 2023',
  //   link: 'https://theprint.in/ani-press-releases/bharatrohan-and-smart-village-movement-partner-to-bring-drone-crop-monitoring-to-ginger-farmers-in-meghalaya/1705380/',
  //   logo: logo10,
  //   alt: 'The Print',
  // },
  // {
  //   title: 'BharatRohan to offer parametric crop cover to farmers',
  //   image: image5,
  //   desc: 'Agritech start-up BharatRohan has introduced ‘Pravir Shield’, an initiative to provide parametric crop insurance to farmers in collaboration with ICICI Lombard and rural insuretech broker Gramcover.',
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Aug 22, 2022',
  //   link: 'https://www.thehindubusinessline.com/economy/agri-business/agtech-firm-bharatrohan-to-offer-parametric-crop-cover-to-farmers/article65716268.ece',
  //   logo: logo11,
  //   alt: 'The Hindu Business Line',
  // },
  // {
  //   title: '40 Under 40: BharatRohan is changing farming',
  //   image: image8,
  //   desc: "BharatRohan is a unique agritech startup that tries to identify a farmer’s problems using hyperspectral imaging cameras. Find out why they are a part of Fortune India's 40 Under 40 list for 2021.",
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Jun 9, 2021',
  //   link: 'https://www.fortuneindia.com/enterprise/40-under-40-how-bharatrohan-is-changing-farming/105537',
  //   logo: logo12,
  //   alt: 'Fortune India',
  // },
  // {
  //   title: 'The duo using drones to help farmers',
  //   image: image6,
  //   desc: 'Aeronautical engineers Amandeep Panwar and Rishabh Choudhary started interacting with farmers during their undergraduate studies in Lucknow. They were testing drones in fields and soon realised that farmers were facing trouble understanding and identifying crop threats',
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Feb 04, 2021',
  //   link: 'https://www.forbesindia.com/article/30-under-30-2021/the-duo-using-drones-to-help-farmers/66247/1',
  //   logo: logo13,
  //   alt: 'Forbes India',
  // },
  // {
  //   title: "Leveraging business tools to solve the region's problems",
  //   image: image7,
  //   desc: 'BharatRohan uses drones and hyperspectral imaging to identify crops that may be suffering from pests, diseases or nutrient deficiency. It offers that information to farmers who can then adjust their use of chemicals and pesticides.',
  //   tag: 'Spotlight',
  //   postedOn: 'posted on 2021',
  //   link: 'https://www.forbes.com/30-under-30/2021/asia/social-impact?profile=bharatrohan',
  //   logo: logo14,
  //   alt: 'Forbes',
  // },
  // {
  //   title: "5 tech based drone startups in india transforming the industry",
  //   image: image13,
  //   desc: "India's drone industry is experiencing a revolutionary transformation thanks to innovative startups leveraging cutting-edge technology.",
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Dec 8, 2023',
  //   link: 'https://helloentrepreneurs.com/technology/5-tech-based-drone-startups-in-india-transforming-the-industry-31799/',
  //   logo: logo15,
  //   alt: 'Hello Entrepreneurs',
  // },
  // {
  //   title: "Digital Transformation of Agriculture with Drones in 2023 and Forecasting Trends for 2024",
  //   image: image14,
  //   desc: "Agricultural drones are revolutionizing farming with precision and efficiency, projected to reach a market value of $4.8 billion by 2024.",
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Dec 11, 2023',
  //   link: 'https://www.agrinewsnetwork.in/article-details/104',
  //   logo: logo16,
  //   alt: 'Agriculture Today Group',
  // },
  // {
  //   title: "Drone Didi Yojana 2024: Women will earn lakhs of rupees by becoming Drone Didi, apply like this",
  //   image: image15,
  //   desc: "Drone Didi Scheme has been launched by Prime Minister Narendra Modi on 28 November 2023. Through this scheme, approval has been given to provide drones to 15,000 women self-help groups.",
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Feb 26, 2024',
  //   link: 'https://pmmodiyojana.in/pm-drone-didi-yojana/',
  //   logo: logo17,
  //   alt: 'Pm Modi Yojana',
  // },


  //-->start New Added Ones.
  {
    title: "Voices of Change: R Shankar, Independent Director",
    image: image40,
    desc: "R Shankar is an Independent Director at Bharat Rohan. He is an accomplished Commercial Banker, Finance Professional, and C-suite management Advisor with almost three decades of diverse experience with leading International Banks, Technology companies, and Consulting Companies.",
    tag: 'Spotlight',
    postedOn: 'posted on Oct 2024',
    link: 'https://bloggersalliance.medium.com/voices-of-change-10-r-shankar-independent-director-d8107a74868e',
    logo: logo32,
    alt: 'Medium',
  },
  {
    title: "Drone-based agritech startup BharatRohan raises $2 million",
    image: image29,
    desc: "BharatRohan, a startup, raised $2.3 million pre-IPO for developing advanced drones for crop monitoring.",
    tag: 'Spotlight',
    postedOn: 'posted on May 2024',
    link: 'https://economictimes.indiatimes.com/tech/technology/drone-based-agritech-startup-bharatrohan-raises-2-million-in-pre-ipo-round/articleshow/109920786.cms?from=mdr',
    logo: logo26,
    alt: 'Agro Spectrum India',
  },

  {
    title: "Agri-Tech Startup BharatRohan's Seed Trial Service Integrates Drone Survey, Data Analytics",
    image: image30,
    desc: "has launched a new version of SeedAssure, a service for seed companies for conducting hybrid evaluation trials.",
    tag: 'Spotlight',
    postedOn: 'posted on Aug 2024',
    link: 'https://www.ndtvprofit.com/technology/agri-tech-startup-bharatrohans-seed-trial-service-integrates-drone-survey-data-analytics',
    logo: logo27,
    alt: 'Agro Spectrum India',
  },

  {
    title: "BharatRohan’s Drone Technology Equipping Farmers with Data-Driven Insights and Sustainable Solutions",
    image: image31,
    desc: "CXOToday has engaged in an exclusive interview with Rishabh Choudhary, Co-founder & Director, BharatRohan.",
    tag: 'Spotlight',
    postedOn: 'posted on May 2024',
    link: 'https://cxotoday.com/interviews/bharatrohans-drone-technology-equipping-farmers-with-data-driven-insights-and-sustainable-solutions/',
    logo: logo19,
    alt: 'Agro Spectrum India',
  },
  {
    title: "BharatRohan and Kosher Climate Collaborate on AWD Project to Conserve Water and Reduce Methane Emissions in Barabanki, Uttar Pradesh",
    image: image33,
    desc: "BharatRohan, a pioneering agritech company, in collaboration with Kosher Climate, announces the launch of a groundbreaking Alternate Wetting and Drying (AWD) project. Spanning across 12,000 acres and benefiting over 6,000 local farmers in Barabanki, Uttar Pradesh.",
    tag: 'Spotlight',
    postedOn: 'posted on Aug 2024',
    link: 'https://theprint.in/ani-press-releases/bharatrohan-and-kosher-climate-collaborate-on-awd-project-to-conserve-water-and-reduce-methane-emissions-in-barabanki-uttar-pradesh/2201985/',
    logo: logo10,
    alt: 'Agro Spectrum India',
  },

  {
    title: "BharatRohan partners AgHub to develop tech for pest, disease detection in paddy, cotton",
    image: image32,
    desc: "Drone-based crop monitoring services provider BharatRohan has announced a co-innovation partnership with AgHub, the agri-innovation hub of Professor Jayashankar Telangana State Agricultural University (PJTSAU).",
    tag: 'Spotlight',
    postedOn: 'posted on Feb 2024',
    link: 'https://www.thehindubusinessline.com/economy/agri-business/bharatrohan-partners-aghub-to-develop-tech-for-pest-disease-detection-in-paddy-cotton/article67894804.ece',
    logo: logo28,
    alt: 'Agro Spectrum India',
  },

  {
    title: "BharatRohan to diversify into exports of farm produce",
    image: image29,
    desc: "Agtech start-up BharatRohan, which provides drone-based precision farming services to farmers, is diversifying into exports of cash crops such as cumin (jeera), coriander (dhaniya), chilli and ginger.",
    tag: 'Spotlight',
    postedOn: 'posted on Nov 2023',
    link: 'https://www.thehindubusinessline.com/economy/agri-business/bharatrohan-to-diversify-into-exports-of-farm-produce/article67554455.ece',
    logo: logo28,
    alt: 'Agro Spectrum India',
  },

  {
    title: "The Nexus of Technology and Sustainability: Revolutionizing Agriculture through Innovative Solutions: Amandeep Panwar, Co-founder & Director, BharatRohan – Agri-tech Drone Startup",
    image: image12,
    desc: "Rural regions, which are central to agriculture, frequently face deficits in digital literacy, an essential component for effectively leveraging technology in farming.",
    tag: 'Spotlight',
    postedOn: 'posted on Mar 2024',
    link: 'https://cionews.co.in/revolutionizing-agriculture-by-innovative-solutions/',
    logo: logo29,
    alt: 'Agro Spectrum India',
  },

  {
    title: "BharatRohan raises seed funding to expand its Drone-based crop monitoring services in Rajasthan & Gujarat",
    image: image33,
    desc: "BharatRohan Airborne Innovations, Delhi-based agritech startup, has raised seed funding investments in a round led by Upaya Social Ventures and Acumen Fund Inc.",
    tag: 'Spotlight',
    postedOn: 'posted on Mar 2022',
    link: 'https://www.business-standard.com/content/press-releases-ani/bharatrohan-raises-seed-funding-to-expand-its-drone-based-crop-monitoring-services-in-rajasthan-gujarat-122030900525_1.html',
    logo: logo30,
    alt: 'Agro Spectrum India',
  },

  {
    title: "The duo using drones to help farmers",
    image: image6,
    desc: "Aeronautical engineers Amandeep Panwar and Rishabh Choudhary started interacting with farmers during their undergraduate studies in Lucknow.",
    tag: 'Spotlight',
    postedOn: 'posted on Mar 2021',
    link: 'https://www.forbesindia.com/article/30-under-30-2021/the-duo-using-drones-to-help-farmers/66247/1',
    logo: logo13,
    alt: 'Agro Spectrum India',
  },

  //-->end New Added Ones.

  // ---
  {
    title: "Is smart farming a bet?",
    image: image23,
    desc: "In the dynamic landscape of Indian agriculture, the dawn of smart farming technologies heralds a new era of innovation and effeciency.",
    tag: 'Spotlight',
    postedOn: 'posted on Mar 2024',
    link: 'https://agrospectrumindia.com/e-magazine',
    logo: logo3,
    alt: 'Agro Spectrum India',
  },
  {
    title: "HOW EDGE COMPUTING CAN ANALYSE REAL-TIME DATA FROM DRONES IN AGRICULTURAL APPLICATIONS",
    image: image0,
    desc: "One promising innovation is edge computing—a decentralised approach to data processing that brings computational power closer to the source—right to the heart of the fields.",
    tag: 'Tech & innovation',
    postedOn: 'posted on Dec 18, 2023',
    link: 'https://yourstory.com/2023/12/how-edge-computing-can-analyse-real-time-data-from-drones-in-agricultural-applications',
    logo: logo6,
    alt: 'Your Story',
  },
  // ---

  // {
  //   title: "Empower farmers and Enhance Agricultural Productivity – BharatRohan",
  //   image: image16,
  //   desc: "BharatRohan utilizes drone-based hyperspectral technology to empower farmers, prioritize data security, address rural connectivity challenges, promote sustainable agriculture, and prepare for future technological trends.",
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Jan 28, 2024',
  //   link: 'https://www.techiexpert.com/empower-farmers-and-enhance-agricultural-productivity-bharatrohan/',
  //   logo: logo18,
  //   alt: 'Techiexpert',
  // },
  // --
  {
    title: "Forecasting Fiscal Fortunes: Pre-Budget Perspectives and Insights from Industry Leaders",
    image: image17,
    desc: "This blog discusses the anticipation surrounding the 2024 interim Union Budget, highlighting the government's focus on leveraging drone technology to revolutionize agriculture and foster innovation in the sector.",
    tag: 'Spotlight',
    postedOn: 'posted on Jan 29, 2024',
    link: 'https://cxotoday.com/corner-office/forecasting-fiscal-fortunes-pre-budget-perspectives-and-insights-from-industry-leaders/',
    logo: logo19,
    alt: 'CXO Today',
  },
  {
    title: "BRIEF24 | Amandeep Panwar, BharatRohan: Crucial to embrace change and invest in learning new technologies",
    image: image18,
    desc: "Amandeep Panwar, Director & Co-founder of the agri-tech drone startup BharatRohan, writes on invitation and exclusively for our special series BRIEF24.",
    tag: 'Spotlight',
    postedOn: 'posted on Feb 01, 2024',
    link: 'https://mediabrief.com/brief24-amandeep-panwar-bharatrohan/',
    logo: logo20,
    alt: 'Media Brief',
  },
  // ---
  // {
  //   title: "Budget 2024 Reactions From India's Leading Entrepreneurs, Policy Makers & Economists (Good, Bad Or Ugly?)",
  //   image: image19,
  //   desc: "Finance Minister Nirmala Sitharaman tabled the Interim Union Budget 2024-25 in Parliament today. This was the current government’s last budget ahead of 2024 Lok Sabha polls and FM Sitharaman’s sixth overall.",
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Feb 01, 2024',
  //   link: 'https://trak.in/stories/budget-2024-reactions-from-indias-leading-entrepreneurs-policy-makers-economists-good-bad-or-ugly/',
  //   logo: logo21,
  //   alt: 'Trak.in',
  // },
  // {
  //   title: "INTERIM BUDGET 2024 || POST BUDGET REACTIONS AROUND EDUCATION, MSME, AGRI TECH STARTUP, AUTO AND PERSONAL FINANCE SECTOR",
  //   image: image20,
  //   desc: "AS AN ADVOCATE FOR SUSTAINABLE AGRICULTURE AND TECHNOLOGICAL ADVANCEMENT, I WANT TO EXPRESS MY PROFOUND APPRECIATION FOR THE RECENT FINANCIAL BUDGET ANNOUNCEMENT BY THE GOVERNMENT OF INDIA.",
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Feb 01, 2024',
  //   link: 'https://www.itvoice.in/interim-budget-2024-post-budget-reactions-around-education-msme-agri-tech-startup-auto-and-personal-finance-sector',
  //   logo: logo22,
  //   alt: 'IT Voics',
  // },
  // {
  //   title: "Budget 2024: Post Budget Reactions from Tech Experts",
  //   image: image21,
  //   desc: "The 20 lakh crore INR allocation for targeted agricultural credit and the launch of the Agriculture Accelerator Fund are commendable.",
  //   tag: 'Spotlight',
  //   postedOn: 'posted on Feb 01, 2024',
  //   link: 'https://www.thehansindia.com/technology/tech-news/budget-2024-post-budget-reactions-from-tech-experts-855719',
  //   logo: logo23,
  //   alt: 'Hans India',
  // },
//  --
  {
    title: "Budget 24 demonstrates a promising path forward for tech-driven agritech initiatives: Agritech industry",
    image: image22,
    desc: "Union Finance Minister Nirmala Sitharaman presented the interim Union Budget for forthcoming financial year (2024-25) in the parliament.",
    tag: 'Spotlight',
    postedOn: 'posted on Feb 02, 2024',
    link: 'https://agrospectrumindia.com/2024/02/02/budget-24-demonstrates-a-promising-path-forward-for-tech-driven-agritech-initiatives-agritech-industry.html',
    logo: logo3,
    alt: 'Agro Spectrum India',
  },
// --
  // {
  //   title: "Digital Transformation of Agriculture with Drones in 2023 and Forecasting Trends for 2024",
  //   image: image24,
  //   desc: "The digital revolution has advanced India Inc. on various fronts over the past two decades. With India being an agrarian country largely",
  //   tag: 'Tech & innovation',
  //   postedOn: 'posted on Dec 14, 2023',
  //   link: 'https://www.expresscomputer.in/guest-blogs/digital-transformation-of-agriculture-with-drones-in-2023-and-forecasting-trends-for-2024/107047/',
  //   logo: logo24,
  //   alt: 'Express Computer',
  // },
  // {
  //   title: "We feel pleased to share the February 2024 edition of Agriculture Today Group on Climate Change and Agriculture - POST COP 28",
  //   image: image25,
  //   desc: "Climate change and agriculture are intrinsically related. Agriculture contributes to one third of the anthropogenic Green House Gas emissions.",
  //   tag: 'Spotlight',
  //   postedOn: 'posted on 2024',
  //   link: 'https://www.expresscomputer.in/guest-blogs/digital-transformation-of-agriculture-with-drones-in-2023-and-forecasting-trends-for-2024/107047/',
  //   logo: logo16,
  //   alt: 'Agriculture Today Group',
  // },
  // {
  //   title: "BharatRohan enhances R&D with AgHub at PJTSAU to develop hyperspectral libraries for Paddy and Cotton Crop",
  //   image: image26,
  //   desc: "BharatRohan, a pioneer in providing hyper spectral imagining enabled drone based crop monitoring services is excited to announce a co-innovation partnership with AgHub",
  //   tag: 'Growth partnerships',
  //   postedOn: 'posted on Feb 2024',
  //   link: 'https://cxotoday.com/press-release/bharatrohan-enhances-rd-with-aghub-at-pjtsau-to-develop-hyperspectral-libraries-for-paddy-and-cotton-crop/',
  //   logo: logo19,
  //   alt: 'CXO Today',
  // },

  // --
  {
    title: "Agricultural Drone: 1 घंटे में 250 एकड़ फसल के रोग के बारे में बताने वाला ड्रोन तैयार ! | Kisan Tak",
    image: image28,
    desc: "Agricultural Drone: किसानों के काम को आसान बनाने के लिए 2 युवा एरॉनोटिक इंजीनियर ने कमाल का ड्रोन बनाया है. भारतरोहण(Bharatrohan) के फाउंडर अमनदीप और ऋषभ ने एक ऐसा एग्रीकल्चरल ड्रोन बनाया है जो सिर्फ 1 घंटे में 250 एकड़ एरिया के खेतों की पूरी रिपोर्ट देता है. 3 महीने में 399 रुपये में किसान(farmer) इस ड्रोन की सर्विस ले सकते हैं. ",
    tag: 'Spotlight',
    postedOn: 'posted on Feb 2024',
    link: 'https://www.youtube.com/watch?v=rq7Oo4uIyRU',
    logo: logo31,
    alt: 'Kisan Tak',
  },
  {
    title: "Agri Tech startup: दो युवा इंजीनियर दोस्तों ने खेती से पूरा किया अपने कॉलेज टाइम का सपना | Kisan Tak",
    image: image27,
    desc: "Agricultural Startup: भारतरोहण(Bharatrohan) के फाउंडर अमनदीप और ऋषभ  एयरॉनोटिक इंजीनियर (Engineer) हैंं और कॉलेज के टाइम से दोस्त हैं. कॉलेज के दौरान उन्होंने जब ड्रोन(drone) बनाए तो सोचा क्यों ना कुछ ऐसा काम किया जाए जिससे देश के किसानों को भी तकनीक से जोड़ा जा सके",
    tag: 'Spotlight',
    postedOn: 'posted on Feb 2024',
    link: 'https://www.youtube.com/watch?app=desktop&v=sEpuTaCnneI',
    logo: logo31,
    alt: 'Kisan Tak',
  },
  
]

const WhatsNew = ({
  title,
  desc,
  list,
  theme,
  toShowButton,
}: {
  title: string
  desc: string
  toShowButton?: boolean
  list: CaseStudyType[]
  theme: 'light' | 'dark'
}) => {
  const navigate = useNavigate()

  return (
    <section
      className={`py-16 md:py-20  ${
        theme === 'dark' ? 'bg-green' : 'bg-lightCream'
      }`}
    >
      <section className="w-container-lg xl:w-container 2xl:w-container-lg">
        <div className="">
          <h2
            className={`text-3xl text-center font-semibold md:text-left xl:text-4xl 2xl:text-[3rem] ${
              theme === 'dark' ? 'text-cream3 ' : 'text-green '
            }`}
          >
            {title}
          </h2>
        </div>
        <div className=" flex flex-col justify-center items-center gap-y-6 md:gap-0 md:items-end md:flex-row md:justify-between">
          <p
            dangerouslySetInnerHTML={{ __html: desc }}
            className={`text-center pt-4 mx-auto max-w-sm md:text-left md:mx-0 xl:text-lg xl:max-w-md 2xl:max-w-xl ${
              theme === 'dark' ? 'text-cream3 ' : 'text-black '
            }`}
          />
          {toShowButton && (
            <div>
              <button
                aria-label="Go to blogs page"
                onClick={() => {
                  navigate('/blogs-and-updates')
                }}
                className="flex items-center gap-3 font-medium hover:text-yellow text-green text-sm cursor-pointer p-2.5 px-4 rounded-full border-green border-1 bgyellow stroke-green hover:bg-green hover:stroke-yellow focus-visible:bggreen focus-visible:stroke-green outline-none transition-all "
              >
                Go to blogs page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="inherit"
                  className="w-4 h-4 xl:h-5 xl:w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
        <section className="">
          {/* <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 pt-6 pb-3 xl:gap-x-12 xl:gap-y-10 2xl:gap-x-20"> */}
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            slidesPerView={1}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 1.5,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 1.8,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2.01,
                spaceBetween: 60,
              },
              1175: {
                slidesPerView: 2.5,
                spaceBetween: 60,
              },
              1600: {
                slidesPerView: 3.01,
                spaceBetween: 60,
              },
            }}
            pagination={{
              bulletActiveClass: '!bg-green !opacity-100',
              clickable: true,
            }}
            // loop
            autoplay={{
              delay: 2000,
            }}
            speed={1000}
            navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev' }}
            className="pb-12 xl:pb-16 h-full min-h-ful cursor-grab"
          >
            {list.map((update, index) => {
              return (
                <SwiperSlide key={index}>
                  <BlogCard
                    title={update.title}
                    image={update.image}
                    desc={update.desc}
                    tag={update.tag}
                    postedOn={update.postedOn}
                    theme={theme}
                    // large={true} // Uncomment this to make 2 column UI
                    link={update.link}
                    logo={update.logo}
                    alt={update.alt}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>
      </section>
    </section>
  )
}

export default WhatsNew
