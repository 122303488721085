import Franchise from 'organisms/franchise'
import React from 'react'
import bg from 'assets/home/franchise/franchise.png'

const HomeFranchise = () => {
  return (
    <Franchise
      title={
        <div className="text-green lg:max-w-3xl lg:mx-auto xl:max-w-5xl 2xl:max-w-7xl min-[1700px]:max-w-none">
          Keep an eye: The forthcoming of BharatRohan's technology{' '}
          <span className="text-yellow">franchise</span>
        </div>
      }
      bgImage={bg}
      bgColor={'bg-cream3'}
    />
  )
}

export default HomeFranchise
