import React from 'react'
import stat01 from 'assets/cropAssure/stats/stat01.svg'
import stat02 from 'assets/cropAssure/stats/stat02.svg'
import stat03 from 'assets/cropAssure/stats/stat03.svg'
import stat04 from 'assets/cropAssure/stats/stat04.svg'
import bg1 from 'assets/cropAssure/stats/top-left.png'
import bg2 from 'assets/cropAssure/stats/bottom-left.png'

const statsList = [
  {
    percent: '26%',
    text: 'Average reduction in crop loss',
    image: stat01,
  },
  {
    percent: '30%',
    text: 'Average increase in profit margins',
    image: stat02,
  },
  {
    percent: '32%',
    text: 'Reduction in input costs for farmers',
    image: stat04,
  },
  {
    percent: '92%',
    text: 'Accurate crop health advisories',
    image: stat03,
  },
]

function Stats() {
  return (
    <section className="py-16 bg-lightCream relative">
      <img
        src={bg1}
        aria-hidden
        alt="leaves"
        className="absolute top-0 left-0 h-36 xl:h-56 2xl:h-80 w-auto object-contain object-center"
      />
      <img
        src={bg2}
        aria-hidden
        alt="leaves"
        className="absolute bottom-0 right-0 h-48 xl:h-56 2xl:h-80 w-auto"
      />
      <section className="sm:grid sm:place-content-center sm:grid-cols-2 md:flex md:justify-evenly ">
        {statsList.map((elem, index) => {
          const key = `${index}`
          return (
            <StatsList
              key={key}
              percent={elem.percent}
              text={elem.text}
              image={elem.image}
            />
          )
        })}
      </section>
    </section>
  )
}

export default Stats

function StatsList({
  percent,
  text,
  image,
}: {
  percent: string
  text: string
  image: string
}) {
  return (
    <article className="flex justify-center items-center py-4 md:mx-4 lg:mx-0 flex-col m-auto sm:max-w-[11.5rem] md:m-0">
      <picture className="rounded-full bg-[#CAE578] p-4 h-24 w-24 xl:h-32 xl:w-32">
        <img src={image} alt={text} className=" lg:p-1 mix-blend-darken" />
      </picture>
      <h3 className="text-green font-extrabold py-4 text-3xl xl:text-4xl lg:py-6">
        {percent}
      </h3>
      <p className="text-green font-medium text-lg pb-4 text-center xl:text-xl -mt-4 max-w-[13rem] 2xl:-mt-6">
        {text}
      </p>
    </article>
  )
}
