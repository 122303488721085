import React from 'react'
import revoltion from 'assets/investors/mission_investors.png'

export default function Revolution() {
  return (
    <div className="bg-lightCream">
      <div className="w-container-lg 2xl:w-container blade-top-padding blade-bottom-padding  flex flex-col-reverse lg:grid grid-cols-1 lg:grid-cols-2 gap-y-5 gap-x-6 lg:gap-x-8">
        <div className="flex flex-col justify-center gap-5">
          <h3 className="font-semibold xl:hidden leading-tight max-w-md lg:max-w-none pb-3  text-green -mt-3 xl:-mt-4">
            Heading a revolution with a nexus of believers and a shared vision
          </h3>
          <h2 className="font-semibold xl:block hidden leading-tight max-w-md lg:max-w-none pb-3  text-green -mt-3 xl:-mt-4">
            Heading a revolution with a nexus of believers and a shared vision
          </h2>

          <img
            src={revoltion}
            alt="man holding a pen showing revolution in the BharatRohan's tech"
            className="max-w-lg w-full pb-3 lg:w-full lg:hidden h-full object-contain object-center lg:h-auto"
          />

          <span className=" font-normal leading-snug block lg:text-base  text-sm min-[1280px]:w-10/12 2xl:text-lg pr-6 min-[1280px]:pr-0">
            Untying the knots of the agri value chain and creating impact
            sustainably requires the right technological intervention and
            precision. Answering this call, the agri tech industry has emerged
            as a ray of hope to ensure food security, industrial prosperity, and
            global sustainability.
          </span>

          <span className=" font-normal leading-snug block lg:text-base  text-sm min-[1280px]:w-10/12 2xl:text-lg pr-6 min-[1280px]:pr-0">
            At BharatRohan, the confluence of technology and sustainability
            revitalises the agri value chain inside out. Our team navigates
            through the challenges with their innovation and adaptability. It
            maximises the purview of impact and also helps us acclimatise to the
            unknown.
          </span>

          <span className=" font-normal leading-snug block lg:text-base  text-sm  min-[1280px]:w-10/12 2xl:text-lg pr-6 min-[1280px]:pr-0">
            Taking the agri value chain to new heights with farmers, sourcing
            professionals, and seed growers is what we have been doing and will
            continue with. After all, sky is the limit.
          </span>
        </div>
        <div className="lg:grid place-content-start blade-top-padding-sm lg:!pt-0 hidden">
          <img
            src={revoltion}
            alt="man holding a pen showing revolution in the BharatRohan's tech"
            className="px-3 sm:w-11/12 lg:w-full mx-auto h-full object-contain object-center lg:h-auto"
          />
        </div>
      </div>
    </div>
  )
}
