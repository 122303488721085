import { Listbox, Transition } from '@headlessui/react'
import { useState } from 'react'
const currentYear = new Date().getFullYear()
const yearsArray = Array.from(
  { length: currentYear - 2019 },
  (_, index) => currentYear - index,
)
const months = [
  'January - March',
  'April - June',
  'July - September',
  'October-December',
]

const data = [
  {
    title: 'Annual Report FY23-24',
    // month : months[0],
    files: './assets/finances/AnnualReportFY23-24.pdf',
  },
  {
    title: 'Standalone Financials FY23-24',
    // month : months[0],
    files: './assets/finances/FS_FY23-24.pdf',
  },
  // {
  //   year : 2023,
  //   month : months[1],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // },
  // {
  //   year : 2023,
  //   month : months[2],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // },
  // {
  //   year : 2023,
  //   month : months[3],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // },
  // {
  //   year : 2024,
  //   month : months[0],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // },
  // {
  //   year : 2024,
  //   month : months[1],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // },
  // {
  //   year : 2024,
  //   month : months[2],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // },
  // {
  //   year : 2024,
  //   month : months[3],
  //   files : ['https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
  //     'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf'
  //   ]
  // }
]

export default function Financial01() {
  const [selected, setSelected] = useState<number>(0)
  const [year, setYear] = useState<any>(0)
  const [month, setMonth] = useState<any>(0)

  return (
    
    <div className="py-16 bg-cream lg:pb-20">
      <div className="w-container-lg">
        <div className="flex flex-col lg:flex-row items-start gap-x-12 xl:gap-x-16 2xl:gap-x-20 gap-y-6 md:gap-y-12">
          <div className="grid  w-full blade-top-padding-sm lg:pt-0 lg:w-[100%] gap-y-6 lg:gap-y-12 flex-col">
            {data.map((item, index) => (
              <div className="w-full" key={index}>
                <div
                  onClick={() => {
                    setSelected(index)
                  }}
                  className={`flex w-full py-4 border px-4 sm:px-6 md:px-8 lg:px-4 xl:px-6 border-green items-center justify-between cursor-pointer transition-al duration-1000 ${
                    selected === index
                      ? 'bg-transparent cursor-auto pt-6'
                      : 'bg-transparent'
                  }`}
                >
                  <a
                    href={item.files}
                    target="_blank"
                    className="grid w-full grid-cols-[3.5rem_1fr_auto] sm:grid-cols-[4rem_1fr_auto] xl:grid-cols-[6rem_1fr_auto]"
                  >
                    <span
                      className={`text-2xl lg:text-3xl xl:text-4xl opacity-25 font-bold transition-all duration-1000 ${
                        selected === index ? 'text-black' : 'text-black'
                      }`}
                    >{`0${index + 1}`}</span>
                    <h4
                      className={`${
                        selected === index ? 'text-green' : 'text-green'
                      } font-semibold text-left transition-all duration-1000 self-center 2xl:text-2xl`}
                    >
                      {item.title}
                    </h4>
                    {/* text-white */}
                    {/* <span
                    className={`self-center ${
                      selected === index ? 'text-green' : 'text-green'
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-6 h-6 ml-2 lg:w-8 lg:h-8 2xl:w-10 2xl:h-10 transition-all duration-1000 ${
                        selected === index ? 'rotate-0' : 'rotate-[135deg]'
                      }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                  <div
                    className={`col-span-3 lg:col-start-2 text-white grid transition-all duration-1000 grid-rows-[0fr] ${
                      selected === index ? 'grid-rows-[1fr] pt-5' : ''
                    }`}
                  >
                    <div style={{display : `${selected === index? 'block' : 'none'}`}}>
                      <ul>
                        <li><a className='underline text-green' href={item.files} target='_blank'>Click here to view the pdf file</a></li>
                      </ul>
                    </div>
                  </div> */}{' '}
                    {/* Instead of a tag here was div tag */}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

{ 


  // <div className='p-3 bg-cream ' >
  //   //   <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-3'>
  //   //   <label htmlFor="year">
  //   //     <span className="text-base md:text-lg">Select Year </span>
  //   //     <Listbox
  //   //       value={year}
  //   //       onChange={(elem) => setYear(elem)}
  //   //     >
  //   //       <div className="relative mt-1">
  //   //         <Listbox.Button className=" rounded-md bg-cream2  px-2 md:px-3 py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40 w-full text-left focus:outline-violet">
  //   //           <span className="block truncate ">
  //   //             <span>{year!=0 ? year : 'Select Year'}</span>
  //   //           </span>
  //   //           <span className="p-[2px]">
  //   //             <svg
  //   //               xmlns="http://www.w3.org/2000/svg"
  //   //               fill="none"
  //   //               viewBox="0 0 24 24"
  //   //               strokeWidth="3"
  //   //               stroke="currentColor"
  //   //               className=" h-4 w-4 md:w-5 md:h-5"
  //   //             >
  //   //               <path
  //   //                 strokeLinecap="round"
  //   //                 strokeLinejoin="round"
  //   //                 d="M19.5 8.25l-7.5 7.5-7.5-7.5"
  //   //               />
  //   //             </svg>
  //   //           </span>
  //   //         </Listbox.Button>
  //   //         <Transition

  //   //           leave="transition ease-in duration-100"
  //   //           leaveFrom="opacity-100"
  //   //           leaveTo="opacity-0"
  //   //         >
  //   //           <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
  //   //             {yearsArray.map((year, index) => (
  //   //               <Listbox.Option
  //   //                 key={index}
  //   //                 className={({ active }) =>
  //   //                   `relative cursor-default hover:bg-lightOrange select-none text-base ${
  //   //                     active ? 'bg-yellow' : 'text-black'
  //   //                   }`
  //   //                 }
  //   //                 value={year}
  //   //               >
  //   //                 {({ selected }) => (
  //   //                   <span
  //   //                     className={`block truncate  pl-6 pr-4 py-3 ${
  //   //                       selected
  //   //                         ? 'font-semibold text-white bg-green bg-opacity-90'
  //   //                         : 'font-normal'
  //   //                     }`}
  //   //                   >
  //   //                     {year}
  //   //                   </span>
  //   //                 )}
  //   //               </Listbox.Option>
  //   //             ))}
  //   //           </Listbox.Options>
  //   //         </Transition>
  //   //       </div>
  //   //     </Listbox>
  //   //   </label>

  //   //   <label htmlFor="month">
  //   //     <span className="text-base md:text-lg">Select Month </span>
  //   //     <Listbox
  //   //       value={month}
  //   //       onChange={(elem) => setMonth(elem)}
  //   //     >
  //   //       <div className="relative mt-1">
  //   //         <Listbox.Button className=" rounded-md bg-cream2  px-2 md:px-3 py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40 w-full text-left focus:outline-violet">
  //   //           <span className="block truncate ">
  //   //             <span>{month!=0 ? month : 'Select Duration'}</span>
  //   //           </span>
  //   //           <span className="p-[2px]">
  //   //             <svg
  //   //               xmlns="http://www.w3.org/2000/svg"
  //   //               fill="none"
  //   //               viewBox="0 0 24 24"
  //   //               strokeWidth="3"
  //   //               stroke="currentColor"
  //   //               className=" h-4 w-4 md:w-5 md:h-5"
  //   //             >
  //   //               <path
  //   //                 strokeLinecap="round"
  //   //                 strokeLinejoin="round"
  //   //                 d="M19.5 8.25l-7.5 7.5-7.5-7.5"
  //   //               />
  //   //             </svg>
  //   //           </span>
  //   //         </Listbox.Button>
  //   //         <Transition

  //   //           leave="transition ease-in duration-100"
  //   //           leaveFrom="opacity-100"
  //   //           leaveTo="opacity-0"
  //   //         >
  //   //           <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
  //   //             {months.map((month_, index) => (
  //   //               <Listbox.Option
  //   //                 key={index}
  //   //                 className={({ active }) =>
  //   //                   `relative cursor-default hover:bg-lightOrange select-none text-base ${
  //   //                     active ? 'bg-yellow' : 'text-black'
  //   //                   }`
  //   //                 }
  //   //                 value={month_}
  //   //               >
  //   //                 {({ selected }) => (
  //   //                   <span
  //   //                     className={`block truncate  pl-6 pr-4 py-3 ${
  //   //                       selected
  //   //                         ? 'font-semibold text-white bg-green bg-opacity-90'
  //   //                         : 'font-normal'
  //   //                     }`}
  //   //                   >
  //   //                     {month_}
  //   //                   </span>
  //   //                 )}
  //   //               </Listbox.Option>
  //   //             ))}
  //   //           </Listbox.Options>
  //   //         </Transition>
  //   //       </div>
  //   //     </Listbox>

  //   //   </label>

  //   //   </div>
  //   //   <div className='py-10'>
  //   //         {
  //   //           year!=0 && month!=0 ?
  //   //           <div>
  //   //             {
  //   //               financials.findIndex((item) => item.year === year && item.month === month) === -1 ?
  //   //               'No Records to show' :
  //   //               <div>
  //   //                 {
  //   //                   financials.map((item, index) =>
  //   //                     <div key={index}>
  //   //                       { item.year === year && item.month === month ?
  //   //                         <div>
  //   //                           {
  //   //                             item.files.map((file) => <div>
  //   //                               <p className='mt-2'><a className='underline' href={file} target='_blank'>Financial Record for Financial year {item.year} for duration {item.month}.</a></p>
  //   //                             </div>)
  //   //                           }
  //   //                         </div>
  //   //                       : null }
  //   //                     </div>
  //   //                   )
  //   //                 }
  //   //               </div>
  //   //             }
  //   //           </div>
  //   //           : 'Select year and month to view the financial reports.'
  //   //         }
  //   //   </div>
  //   // </div>




    
  /* <div className='text-green' style={{display : `${selected === index? 'block' : 'none'}`}}>
                        <h6>Quaterly</h6>
                        <ul>
                          <li><a className='underline' href='https://oyo-investor-relations.s3.ap-southeast-1.amazonaws.com/Financial+Statements/OSPL+Consolidated/Oravel+Stays+Limited+Consolidated+March+2022.pdf' target='_blank'>Click here to view the pdf file</a></li>
                        </ul>
                        <h6>Half Yearly</h6>
                        <ul>
                          <li><a className='underline' href='https://oyo-investor-relations.s3.ap-southeast-1.amazonaws.com/Financial+Statements/OSPL+Consolidated/Oravel+Stays+Limited+Consolidated+March+2022.pdf' target='_blank'>Click here to view the pdf file</a></li>
                        </ul>
                        <h6>Annual</h6>
                        <ul>
                          <li><a className='underline' href='https://oyo-investor-relations.s3.ap-southeast-1.amazonaws.com/Financial+Statements/OSPL+Consolidated/Oravel+Stays+Limited+Consolidated+March+2022.pdf' target='_blank'>Click here to view the pdf file</a></li>
                        </ul>
                      </div> */
}
