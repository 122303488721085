import React, { useLayoutEffect } from 'react'
import Banner from './layout/banner'
import Details from './layout/details'
import banner from 'assets/product/fennel/fennel-bg.webp'
import aside from 'assets/product/fennel/fennel.webp'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

// use types to ensure correct props
import { IGeoData } from './layout/types'
import OtherProducts from './layout/products'

const geoData: IGeoData = [
  {
    type: 'Country',
    value: 'India',
  },
  {
    type: 'State',
    value: 'Rajasthan',
  },
  {
    type: 'Region',
    value: 'Jodhpur',
  },
]

const tableHeadings = [
  {
    title: 'varient',
    classNames: 'w-12 text-left pl-2 lg:min-w-[9vw] xl:min-w-[12vw] xl:pl-7',
  },
  {
    title: 'Package Size',
    classNames: 'w-20 text-center lg:min-w-[9vw] xl:min-w-[10vw]',
  },
  {
    title: 'Moisture',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[6vw]',
  },
  {
    title: 'Purity',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[7vw]',
  },
  {
    title: 'Description',
    classNames: 'text-left',
  },
]

const variants = [
  {
    varient: 'Singapore 99',
    package: 'Custom',
    moisture: '7-9%',
    purity: '99%',
    desc: 'A premium fennel variety with a distinct sweet-anise flavor, crisp texture, and aromatic leaves, perfect for culinary enhancements.',
  },
  {
    varient: 'Euro 99',
    package: 'Custom',
    moisture: '7-9%',
    purity: '99%',
    desc: 'An exquisite European fennel variety, celebrated for its superior taste and texture, ideal for gourmet cooking and fine dining experiences',
  },
  {
    varient: 'Janta',
    package: 'Custom',
    moisture: '7-9%',
    purity: '99%',
    desc: 'A popular and versatile fennel variety, acclaimed for its robust flavor and adaptability in diverse culinary applications',
  },
  // {
  //   varient: 'saunf for powder purpose',
  //   package: '25 Kg or 50 Kg',
  //   moisture: '8%',
  //   purity: '-',
  //   desc: '-',
  // },
  // {
  //   varient: 'saunf europe 99%',
  //   package: '25 Kg or 50 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: '-',
  // },
  // {
  //   varient: 'saunf semi europe 99%',
  //   package: '25 Kg or 50 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: '-',
  // },
  // {
  //   varient: 'saunf singapore 99%',
  //   package: '25 Kg or 50 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: '-',
  // },
  // {
  //   varient: 'saunf lucknavi (strawberry)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Bold quality for natural mouth freshener 99% purity, 1% max. impurity',
  // },
  // {
  //   varient: 'saunf for sweet saunf (green valley)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Average quality for making sweet saunf , 1% max. impurity',
  // },
  // {
  //   varient: 'saunf tulsi (b)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Premium natural green extra bold quality , 1% max. impurity',
  // },
  // {
  //   varient: 'saunf double parrot (o)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Natural parrot green superior quality , 1% max. impurity',
  // },
  // {
  //   varient: 'saunf mango (c)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Plus green colour premium packing quality , 1% max. impurity',
  // },
  // {
  //   varient: 'saunf orange (m) sortex',
  //   package: '25 Kg',
  //   moisture: '8%',
  //   purity: '99.5%',
  //   desc: 'Green colour packing quality , 0.50% max. impurity',
  // },
  // {
  //   varient: 'saunf orange (m)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Green colour packing quality , 1% max. impurity',
  // },
  // {
  //   varient: 'saunf eagle (d)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Reguler green, 1% max. impurity',
  // },
  // {
  //   varient: 'saunf deepak (p)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Light green & yellow colour , 1% max. impurity',
  // },
  // {
  //   varient: 'saunf lovely (g)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Average green & yellow colour & small and average size seeds. 1% max. impurity',
  // },
  // {
  //   varient: 'saunf luv kush (x)',
  //   package: '30 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Average green & yellow colour & small and average size seeds. 1% max. impurity',
  // },
]

export default function Fennel() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <div>
      <Banner
        bgImage={banner}
        title="Fennel (Whole)"
        desc="Finest fennel seeds sourced from the Blue City of India from our partner farms are full of essence and quality. With up to 99.5% purity, these fennel seeds are residue-free and comply with global quality and regulatory standards."
      />
      <Details
        tableHeading={tableHeadings}
        variantDetails={variants}
        asideImage={aside}
        geoData={geoData}
        title="Fennel (Whole)"
      />
      <OtherProducts />
    </div>
  )
}
