import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import method01 from 'assets/ipm/methods/method01.webp'
import method02 from 'assets/ipm/methods/method02.webp'
import method03 from 'assets/ipm/methods/method03.webp'
import method04 from 'assets/ipm/methods/method04.webp'
// import methodMob from 'assets/ipm/methods/method-mob.png'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

type MList = {
  title: string
  desc: string
  image: string
}
const mList: MList[] = [
  {
    title: 'Kisan Sehyogi',
    desc: 'Trained village-level microentrepreneur provides on-ground support to our partner farmers at every step. Each village cluster has a dedicated Kisan Sehyogi assigned to it. This Sehyogi reports to a BharatRohan Kisan Safalta Kendra (district hub).',
    image: method01,
  },
  {
    title: 'On-ground role',
    desc: 'The Sehyogis help farmers with advisory implementation which the farmers receive through the BharatRohan chatbot after the survey.',
    image: method02,
  },
  {
    title: 'Error-free operations and traceability',
    desc: 'The Sehyogis monitor and ensure on-point advisory implementation by farmers. They upload the data of advisory implementation and agri input usage to the CRM. The extensive database forms the backbone of end-to-end transparency and traceability.',
    image: method03,
  },
  {
    title: 'Agri input demand-supply management',
    desc: 'The Sehyogis collaborate seamlessly with the Kisan Safalta Kendra to ensure a steady supply of agri inputs to our partner farmers. They tailor the supply based on individual farmer requirements. Additionally, farmers have the option to order directly via the BharatRohan chatbot.',
    image: method04,
  },
]

const Methods = () => {
  const contentRef = useRef<any>(null)
  const [currActiveIndex, setCurrActiveIndex] = useState(0)
  const [refreshGsap, setRefreshGsap] = useState(false)
  const [scrollController, setScrollController] = useState<any>(null)

  setTimeout(() => {
    setRefreshGsap(true)
  }, 100)

  useEffect(() => {
    if (window.innerWidth < 1024) {
      return
    }

    const ctx = gsap.context(() => {
      const anim = gsap.from('.methods-section-ipm', {})
      const controller = ScrollTrigger.create({
        animation: anim,
        trigger: '.methods-section-ipm',
        start: 'top top',
        end: `bottom -${mList.length * 30}%`,
        scrub: true,
        pin: true,
        onUpdate: (e: any) => {
          const progress: number = +(e.progress * 100).toFixed(2) ?? 0
          progressHandler(progress)
        },
      })
      setScrollController(controller)
    })

    return () => {
      ctx.revert()
    }
  }, [refreshGsap])

  // useEffect(() => {
  //   const right: MList[] = mList.slice(currActiveIndex + 1)
  //   const left: MList[] = mList.slice(0, currActiveIndex + 1)

  //   setMethodsList([...right, ...left])
  // }, [currActiveIndex])

  const progressHandler = useCallback((progress: number) => {
    const onePartOfWholeProgress = +(100 / mList.length).toFixed(2)
    const progressIndex = Math.round(progress / onePartOfWholeProgress)
    // console.log(progressIndex)
    if (progressIndex === mList.length) {
      return
    }
    setCurrActiveIndex(progressIndex)
  }, [])

  const setPosition = (update: number) => {
    scrollController?.scroll(
      scrollController.start +
        (update / mList.length) *
          (scrollController.end - scrollController.start),
    )
  }

  return (
    <section className="methods-section-ipm bg-green text-white border-b-1 border-solid border-cream border-opacity-30">
      {/* for mobile and tablets*/}
      <section className="py-16 md:py-20 lg:hidden">
        <section className=" w-container-lg text-center ">
          <h2 className="font-medium pb-6 max-w-sm mx-auto sm:max-w-xl text-[1.9rem] md:text-3xl">
            Fueling change with an on-ground commitment to impact
          </h2>
          <p className="max-w-md font-light mx-auto sm:max-w-lg">
            Revitalising the agri value change means its full-scale
            future-proofing. This starts from our Kisan Sehyogis and ends with
            error-free precision agriculture by our partner farmers. All of this
            with a hub-and-spokes approach.
          </p>
        </section>
        <section className="ipm-methods-swiper w-container-lg ">
          <Swiper
            modules={[Navigation, Pagination]}
            autoHeight
            pagination={{
              clickable: true,
            }}
            speed={600}
            spaceBetween={20}
            className=""
          >
            {mList.map((method, index) => {
              return (
                <SwiperSlide key={index} className="pt-16 pb-12">
                  <MethodCard
                    image={method.image}
                    title={method.title}
                    desc={method.desc}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>
      </section>

      {/* for desktops */}
      <section className="hidden lg:grid h-screen w-full min-h-[400px] grid-cols-[56%_44%]">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={currActiveIndex + 'article'}
            timeout={300}
            classNames={'fade-show'}
            nodeRef={contentRef}
          >
            <article ref={contentRef} className="relative overflow-hidden">
              <img
                src={mList[currActiveIndex].image}
                alt={mList[currActiveIndex].title}
                className="h-full w-full object-cover"
              />
              <div className="absolute left-0 bottom-0 text-white bg-green max-w-md p-8 rounded-tr-[5rem] min-[1800px]:max-w-lg min-[1800px]:p-10">
                <h3 className="-mt-2 pb-4 font-medium text-2xl xl:text-3xl min-[1800px]:text-[2.4rem]">
                  {mList[currActiveIndex].title}
                </h3>
                <p className="font-extralight text-sm xl:text-base">
                  {mList[currActiveIndex].desc}
                </p>
              </div>
            </article>
          </CSSTransition>
        </SwitchTransition>
        <section className="flex flex-col justify-evenly px-12 xl:px-16 min-[1800px]:px-24 gap-y-6">
          <article className="">
            {/* <article className="px-12 h-fit my-auto xl:px-16 min-[1800px]:px-24"> */}
            <h2 className="font-medium leading-tight text-[1.9rem] xl:text-[2.1rem] min-[1800px]:text-5xl 2xl:max-w-[40rem]">
              Fueling change with an on-ground commitment to impact
            </h2>
            <p className="font-light pt-6 max-w-lg text-sm xl:text-lg xl:leading-relaxed min-[1800px]:pt-8 2xl:text-xl 2xl:max-w-xl">
              Revitalising the agri value change means its full-scale
              future-proofing. This starts from our Kisan Sehyogis and ends with
              error-free precision agriculture by our partner farmers. All of
              this with a hub-and-spokes approach.
            </p>
          </article>
          <div className=" flex flex-col gap-4">
            {mList.map((listItem, index) => {
              return (
                <div
                  onClick={() => setPosition(index)}
                  className={`flex gap-3 2xl:gap-4 items-start transition-all duration-500 ${
                    index === currActiveIndex
                      ? 'opacity-100 fill-yellow'
                      : 'opacity-50 fill-white hover:opacity-100 cursor-pointer'
                  }`}
                  key={index}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="32"
                    viewBox="0 0 28 32"
                    fill="inherit"
                    className="h-4 mt-1 xl:h-5 2xl:h-7 2xl:mt-2"
                  >
                    <rect
                      y="0.536377"
                      width="10.4199"
                      height="10.9161"
                      fill="inherit"
                    ></rect>
                    <rect
                      x="-0.000183105"
                      y="20.3101"
                      width="10.4199"
                      height="10.9161"
                      fill="inherit"
                    ></rect>
                    <rect
                      x="11.442"
                      y="12.1414"
                      width="16.1252"
                      height="7.48477"
                      fill="inherit"
                    ></rect>
                  </svg>
                  <h5
                    className={`lg:text-lg xl:text-xl 2xl:text-2xl font-semibold leading-tight transition-all duration-500 ${
                      index === currActiveIndex && 'text-yellow'
                    }`}
                  >
                    {listItem.title}
                  </h5>
                </div>
              )
            })}
            {/* <SwitchTransition mode="out-in">
              <CSSTransition
                key={currActiveIndex + 'sliders'}
                timeout={300}
                classNames={'fade-show'}
                nodeRef={sliderRef}
              >
                <article ref={sliderRef} className="overflow-hidden">
                  <div className="flex ">
                    {methodsList.map((listItem, index) => {
                      return (
                        <div className="shrink-0 relative" key={index}>
                          <img
                            src={listItem.image}
                            alt={listItem.title}
                            className="h-60 xl:h-72 2xl:h-[22rem] w-auto"
                          />
                          <div className="absolute bg-black bg-opacity-50 w-full h-full z-10 inset-0"></div>
                          <h5 className=" lg:text-lg xl:text-xl 2xl:text-2xl absolute bottom-0 px-8 py-6 z-20 font-semibold leading-tight min-[1800px]:text-[1.7rem]">
                            {listItem.title}
                          </h5>
                        </div>
                      )
                    })}
                  </div>
                </article>
              </CSSTransition>
            </SwitchTransition> */}
          </div>
        </section>
      </section>
    </section>
  )
}
export default Methods
function MethodCard({
  title,
  desc,
  image,
}: {
  title: string
  desc: string
  image: string
}) {
  return (
    <article className="text-center max-w-lg mx-auto">
      <img src={image} alt={title} className="rounded-lg" />
      <h3 className="font-medium py-4">{title}</h3>
      <p className="font-light">{desc}</p>
    </article>
  )
}
