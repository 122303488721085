import { useEffect, useRef } from 'react'
import client1 from 'assets/aboutUs/clients/upl.svg'
import client2 from 'assets/aboutUs/clients/itc.svg'
import client3 from 'assets/aboutUs/clients/mega-store.svg'
import client4 from 'assets/aboutUs/clients/aachi.svg'
import client5 from 'assets/aboutUs/clients/suhana.svg'
import client6 from 'assets/aboutUs/clients/advanta.svg'
import client7 from 'assets/aboutUs/clients/dabur.svg'
import baseMap from 'assets/aboutUs/clients/worldmap.webp'
import overlayMap from 'assets/aboutUs/clients/worldmapdark.webp'

import topLeft from 'assets/aboutUs/clients/top-left.svg'
import bottomRight from 'assets/aboutUs/clients/bottom-right.svg'

const clientList = [
  {
    logo: client1,
    alt: 'UPL',
  },
  {
    logo: client2,
    alt: 'ITC Limited',
  },
  {
    logo: client3,
    alt: 'More Quality Megastore',
  },
  {
    logo: client4,
    alt: 'Aachi - The mother of good taste',
  },
  {
    logo: client5,
    alt: 'Suhana - maza aa gaya',
  },
  {
    logo: client6,
    alt: 'Advanta',
  },
  {
    logo: client7,
    alt: 'Dabur',
  },
  {
    logo: client1,
    alt: 'UPL',
  },
  {
    logo: client2,
    alt: 'ITC Limited',
  },
  {
    logo: client3,
    alt: 'More Quality Megastore',
  },
  {
    logo: client4,
    alt: 'Aachi - The mother of good taste',
  },
  {
    logo: client5,
    alt: 'Suhana - maza aa gaya',
  },
  {
    logo: client6,
    alt: 'Advanta',
  },
  {
    logo: client7,
    alt: 'Dabur',
  },
]

export default function ClientBase() {
  const imageRef = useRef<HTMLImageElement>(null)
  //   observing targetRef instead of imageRef because if a image is clipped completely then it will not be visible to observer
  const targetRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            imageRef.current?.classList.add('remove-clip')
          } else {
            imageRef.current?.classList.remove('remove-clip')
          }
        })
      },
      { rootMargin: '0px', threshold: 0.45 },
    )

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  return (
    <section className="py-16 md:py-20 bg-cream3 relative">
      <img
        src={topLeft}
        aria-hidden
        alt="Background element - Leaves"
        className="absolute top-0 left-0 h-32 sm:h-44 xl:h-60 2xl:h-72 w-auto"
      />
      <img
        src={bottomRight}
        aria-hidden
        alt="Background element - Leaves"
        className="absolute bottom-0 right-0 h-32 sm:h-44 xl:h-60 2xl:h-72 w-auto"
      />

      <section className="w-container w-full xl:max-w-[60rem] 2xl:max-w-[80rem]">
        <h2 className="text-center font-semibold text-green">
          A strong client base
        </h2>
        <div className="my-14 w-full relative overflow-hidden">
          <div className="absolute inset-0 left-0 clients-gradient z-10"></div>
          <div className="flex gap-16 xl:gap-20 lg:gap-8 w-max marquee-aboutUs ">
            {clientList.concat(clientList).map((client, index) => (
              <img
                key={`aboutus-${index}-${client}}`}
                src={client.logo}
                alt={client.alt}
                aria-hidden={index > clientList.length}
                className="h-16 xl:h-[5rem] w-24 xl:w-32 mix-blend-darken"
              />
            ))}
          </div>
        </div>

        <section className="w-[min(90%,1440px)] mx-auto">
          <div className="mt-20 relative">
            <img
              src={overlayMap}
              ref={imageRef}
              className="absolute top-0 z-20 clipImage"
              alt="Global clients"
            />
            <img ref={targetRef} src={baseMap} alt="map" />
          </div>
        </section>
      </section>
    </section>
  )
}
