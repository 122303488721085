import Partition from 'molecules/partition'
import bottomRight from 'assets/aboutUs/milestones/bottom-left.png'

export default function Journey() {
  return (
    <section className="bg-lightCream py-16 md:py-20 relative overflow-hidden">
      <img
        src={bottomRight}
        alt="Background element"
        className="absolute -bottom-20 left-0 h-96 hidden md:block w-auto"
      />
      <section className="w-container-lg">
        <Partition color="dark" text="Milestones" />
        <article className="pt-8 md:grid md:grid-cols-2 gap-x-4 text-center md:text-left">
          <h2 className="font-medium text-3xl xl:text-4xl 2xl:text-[3rem]">
            A journey in the making
          </h2>
          <div className="pt-6 md:pt-3 xl:text-lg flex flex-col gap-6 max-w-md mx-auto md:max-w-none">
            <p>
              Two emerging aeronautical engineers, Amandeep Panwar and Rishabh
              Choudhary, stumbled upon farmers' plight while testing drones in
              farms of Barabanki, Uttar Pradesh. The idea of creating an
              end-to-end decision support system for farmers practising
              conventional farming sprouted in their curious minds.
            </p>
            <p>
              With research and expert guidance, they spotted a solution in
              drone-based hyperspectral imagery technology.
            </p>
            <p>The rest, as they say, is history in the making.</p>
          </div>
        </article>
      </section>{' '}
    </section>
  )
}
