import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Studies from './studies'
import './index.css'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const CaseStudies = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <main>
      <Banner />
      <Studies />
    </main>
  )
}

export default CaseStudies
