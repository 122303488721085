import React from 'react'
import seedManagement from 'assets/seedAssure/management/management.png'
import bg from 'assets/seedAssure/management/bottom-right.png'
import logo01 from 'assets/seedAssure/management/logo01.svg'
import logo02 from 'assets/seedAssure/management/logo02.svg'
import logo03 from 'assets/seedAssure/management/logo03.svg'
import logo04 from 'assets/seedAssure/management/logo04.svg'
import Management from 'organisms/management'
import Partition from 'molecules/partition'

const seedManagementList = [
  {
    text: 'Field trial monitoring using drone technology.',
    image: logo01,
  },
  {
    text: 'Plot-specific data collection.',
    image: logo02,
  },
  {
    text: 'Timeline-centric database for comparative analysis of all varieties.',
    image: logo03,
  },
  {
    text: 'Faster and more accurate data collection with unmanned scouting.',
    image: logo04,
  },
]

const SeedManagement = () => {
  return (
    <section className="bg-cream3 relative">
      <img
        src={bg}
        alt="leaves"
        aria-hidden
        className="absolute bottom-0 right-0 hidden h-28 w-auto lg:h-40 xl:h-60 2xl:h-72 md:block"
      />
      <section className="py-16 px-4 w-container-lg">
        <Partition color="dark" text="Clearer insights, better developments" />
        <div className="pb-8"></div>
        <Management
          title={
            <span className="text-green">
              A <span className="text-yellow">germination-to-yield</span>{' '}
              <br className="hidden 2xl:block" /> seed trial solution
            </span>
          }
          para1={
            <span className="2xl:max-w-xl 2xl:text-xl inline-block">
              SeedAssure® empowers seed companies to develop superior-quality
              hybrid seeds enhancing food security from its roots. It tracks and
              shares precise phenotypical data (eight critical parameters) of
              every single seed variety on the plot throughout the trial for
              performance evaluation.
            </span>
          }
          para2={
            <span className="2xl:max-w-xl 2xl:text-xl inline-block">
              Routine drone surveys followed by data validation by agronomists
              form the foundation of this accurate trial mechanism for Seed
              Companies.
            </span>
          }
          image={seedManagement}
          list={seedManagementList}
          component="seed"
        />
      </section>
    </section>
  )
}

export default SeedManagement
