import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'

import bottomRight from 'assets/aboutUs/team/bottom-right.png'
import topLeft from 'assets/aboutUs/team/top-left.png'
import image01 from 'assets/aboutUs/team/ourTeam/01.jpg'
import image02 from 'assets/aboutUs/team/ourTeam/02.jpg'
import image03 from 'assets/aboutUs/team/ourTeam/03.jpg'
import image04 from 'assets/aboutUs/team/ourTeam/04.jpg'

const teams = [
  { alt: 'Bharatrohan Team', image: image01 },
  { alt: 'Bharatrohan Team', image: image03 },
  { alt: 'Bharatrohan Team', image: image04 },
  { alt: 'Bharatrohan Team', image: image02 },
]

export default function ChangeMakers() {
  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <section className="py-16 md:py-20 bg-lightCream relative">
      <img
        src={topLeft}
        alt="Background element"
        className="absolute top-0 left-0 h-28 md:h-40 2xl:h-auto w-auto"
      />
      <img
        src={bottomRight}
        alt="Background element"
        className="absolute bottom-0 right-0 h-40 hidden xl:block 2xl:h-auto w-auto"
      />

      <section className="w-container-lg">
        <div className="text-center font-semibold text-2xl text-yellow">
          Our team
        </div>
        <h2 className="font-semibold text-green pt-4 text-3xl text-center max-w-sm mx-auto sm:max-w-lg lg:max-w-none xl:text-4xl 2xl:text-[3rem]">
          Changemakers in action
        </h2>
        <section className="teams-slider mx-auto select-none relative -mb-52 sm:-mb-60 md:-mb-72 lg:-mb-80 xl:-mb-[22rem] 2xl:-mb-96 lg:max-w-[57rem] xl:max-w-[62rem] 2xl:max-w-[76rem]">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            centeredSlides
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            // draggable={false}
            // allowTouchMove={false}
            speed={600}
            loop
            onSlideChange={(swiper) => {
              setActiveSlide(swiper.activeIndex)
            }}
            slidesPerView={1}
            spaceBetween={20}
            navigation={{
              nextEl: '.team-next-btn',
              prevEl: '.team-prev-btn',
            }}
            className="pt-10"
          >
            {teams.map((team, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="h-full grid place-content-center pb-12"
                >
                  <img
                    src={team.image}
                    alt={team.alt}
                    className="rounded-lg lg:rounded-[2rem] "
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>

          {/* Buttons */}
          <div className="hidden absolute bottom-20 left-8 z-20 lg:block">
            <div className="rounded-full flex justify-between gap-10 m-auto px-2 py-2 sm:max-w-max bg-yellow">
              <button className="timeline-mobile-prev-btn group disabled:cursor-not-allowed disabled:opacity-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  className="team-prev-btn stroke-green  h-9 w-9 lg:h-8 xl:h-10 xl:w-10 lg:w-8 rounded-full p-1.5 bg-yellow hover:bg-cream transition-colors active:bg-green"
                >
                  <path
                    className="group-hover:stroke-green transition-colors"
                    fill="none"
                    stroke="inherit"
                    // stroke={`${activeSlide === 0 ? '#163E33' : '#fff'}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="M244 400L100 256l144-144M120 256h292"
                  />
                </svg>
              </button>

              <button className="team-next-btn group disabled:cursor-not-allowed disabled:opacity-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  className="h-9 w-9 lg:h-8 xl:h-10 xl:w-10 lg:w-8 rounded-full  rotate-180 p-1.5 bg-green hover:bg-cream transition-colors active:bg-green"
                  // className={`h-9 w-9 lg:h-8 xl:h-10 xl:w-10 lg:w-8 rounded-full  rotate-180 p-1.5  ${
                  //   activeSlide === teams.length - 1
                  //     ? 'bg-none'
                  //     : 'bg-green hover:bg-[#266957] active:bg-green'
                  // }`}
                >
                  <path
                    className="group-hover:stroke-green transition-colors"
                    fill="none"
                    stroke="#E8BA3A"
                    // stroke={`${
                    //   activeSlide === teams.length - 1 ? '#163E33' : '#fff'
                    // }`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="M244 400L100 256l144-144M120 256h292"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>
      </section>
    </section>
  )
}
