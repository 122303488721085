import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import Partition from 'molecules/partition'

import '../screens/cropAssure/cropAssure.css'

export type GalleryList = {
  title: string
  image: string
  desc: JSX.Element
}

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({
  toggleActions: 'restart pause resume none',
})
ScrollTrigger.config({ limitCallbacks: true })

function Gallery({
  divider,
  galleryList,
}: {
  divider: string
  galleryList: GalleryList[]
}) {
  const sliderRef = useRef<any>(null)
  const sliderParentRef = useRef<any>(null)
  const partitionRef = useRef<any>(null)

  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    let ctx = gsap.context(() => {
      let panels: any = gsap.utils.toArray('.gallery-article')
      gsap.to(panels, {
        x: -width + window.innerWidth,
        // ease: 'none',
        ease: 'power1.inOut',
        scrollTrigger: {
          trigger: sliderParentRef.current,
          pin: true,
          scrub: 1,
          end: () => '+=200%',
          fastScrollEnd: true,
        },
      })
    }, sliderParentRef)
    return () => ctx.revert()
  }, [width, height])

  useEffect(() => {
    if (!sliderRef.current || !partitionRef.current) return

    new ResizeObserver((entries: any) => {
      setWidth(Math.trunc(entries?.[0]?.contentRect?.width))
    }).observe(sliderRef.current)

    new ResizeObserver((entries: any) => {
      const cardHeight =
        window.innerHeight -
        Math.trunc(entries?.[0]?.borderBoxSize?.[0]?.blockSize)
      setHeight(cardHeight)
    }).observe(partitionRef.current)
  }, [])

  return (
    <section className="overflow-hidden">
      <section className="bg-green " ref={sliderParentRef}>
        <div
          ref={partitionRef}
          className="py-8  lg:py-9 2xl:py-12 w-container-lg lg:pb-6"
        >
          <Partition color="light" text={divider} />
        </div>
        <section className="hide-scrollbar  !border-transparent h-full max-h-fit overflow-hidden">
          <div
            ref={sliderRef}
            className={`flex min-w-max overflow-hidden`}
            style={{ maxHeight: height }}
          >
            {galleryList.map((item, index) => {
              return (
                <GallerySlider
                  key={index}
                  title={item.title}
                  image={item.image}
                  desc={item.desc}
                  index={index}
                  height={height}
                />
              )
            })}
          </div>
        </section>
      </section>
    </section>
  )
}

export default Gallery

function GallerySlider({
  title,
  image,
  desc,
  index,
  height,
}: {
  title: string
  image: string
  desc: JSX.Element
  height: number
  index: number
}) {
  return (
    <article
      className={`gallery-article group relative select-none text-cream3 lg:max-w-md`}
      style={{ height: height }}
    >
      <div
        className={`h-1/2 md:border-t-1 border-slate-400 z-30 p-8 w-full absolute inset-x-0 bg-green lg:border-transparent lg:opacity-0 lg:text-xl flex flex-col justify-center group-hover:opacity-100 transition-all duration-500 top-0`}
      >
        <h3 className="text-2xl min-[1800px]:text-3xl font-medium pb-4 leading-tight">
          {title}
        </h3>
        <p className="font-extralight text-sm min-[1400px]:text-base opacity-80">
          {desc}
        </p>
        <a
          href="#cta"
          className="max-w-fit z-100 rounded-full px-8 py-2 text-sm inline-block mt-6 text-green bg-yellow font-bold hover:bg-cream3 transition"
        >
          Know more
        </a>
      </div>
      <div className="hidden lg:block absolute inset-0 opacity-50 group-hover:opacity-0 transition-all duration-500 bg-black z-20"></div>
      <div
        className={`w-full top-1/2 lg:top-0 min-w-max relative max-h-screen transition-all duration-500 lg:group-hover:top-1/2`}
      >
        <img
          src={image}
          alt={title}
          className="sm:max-w-md w-screen max-h-screen object-cover transition-all duration-500 "
          style={{ height: height }}
        />
      </div>
    </article>
  )
}
