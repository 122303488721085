import React, { useEffect, useState } from 'react'
import Banner from './shared/banner'
import Revolution from './shared/revolution'
import Awards from './shared/awards'
import Updates from './shared/updates'

import amandeep from 'assets/investors/board/amandeep.webp'
import rishab from 'assets/investors/board/rishab.webp'
import mukesh from 'assets/investors/board/mukesh.webp'
// import placeHolder from 'assets/investors/board/imagePlaceholder.svg'

const directorsList = [
  {
    title: 'Amandeep Panwar',
    post: 'Director & Co-founder of BharatRohan',
    desc: [
      "Amandeep's remarkable achievements have earned him recognition as a Forbes 30U30 India & Asia and Fortune 40U40 honoree.",
      "He is a dedicated advocate for technology in agriculture. With a background in aeronautical engineering, he leads BharatRohan's pioneering efforts to revolutionize the farming industry. His driving mission is to create a sustainable food supply chain while increasing farmers' profits through the innovative use of drone technology.",
      'He passionately talks about the transformative potential of precision agriculture, Hyperspectral Imaging, and the pivotal role of UAVs in reshaping the farming landscape. His discussions underscore the immense possibilities that these technologies hold to enhance crop yields, minimize losses, and secure a long-term and sustainable food supply chain for the future.',
    ],
    image: amandeep,
  },
  {
    title: 'Rishabh Choudhary',
    post: 'Director and Co-founder of BharatRohan',
    desc: [
      "Rishabh's remarkable achievements have earned him recognition as a Forbes 30U30 India & Asia and Fortune 40U40 honoree, solidifying his status as a visionary in the field of ag-tech.",
      "He brings a deep passion for drones to the table. With a strong background in aeronautical engineering, he plays a pivotal role in BharatRohan's technological intervention endeavors aimed at making farming sustainable and profitable.",
      'He is at the forefront of harnessing technology to revolutionize the agricultural industry. He frequently engages in discussions about drones, farming, autonomous vehicles, and hyper spectral imaging, emphasizing their potential to transform the agricultural landscape.',
    ],
    image: rishab,
  },
  {
    title: 'Mukesh Panwar',
    post: 'Director',
    desc: [
      'He served DRDO for 10 years as a Senior Scientific Assistant before running and scaling his family leather manufacturing businesses in Delhi and Chennai. Mukesh is a seasoned businessman with a core understanding of supply chain business. He later joined BharatRohan and supported the young entrepreneurs in establishing the company.',
    ],
    image: mukesh,
  },
]

export default function Investors() {
  return (
    <>
      <Banner />
      <Revolution />
      <Awards />
      <Updates current="board" view={<Board />} />
    </>
  )
}

function Board() {
  return (
    <section className="py-6">
      <div className="flex flex-col gap-y-16">
        {directorsList.map((item, index) => {
          return (
            <DirectorCard
              key={index}
              title={item.title}
              desc={item.desc}
              image={item.image}
              post={item.post}
            />
          )
        })}
      </div>
    </section>
  )
}

function DirectorCard({
  title,
  post,
  desc,
  image,
}: {
  title: string
  post: string
  desc: string[]
  image: string
}) {
  const [lineClamper, setLineClamper] = useState(true)
  const [lineClampTimer, setLineClampTimer] = useState(false)

  useEffect(() => {
    if (!lineClamper) {
      setLineClampTimer(false)
      return
    }
    const timeoutId = setTimeout(() => {
      setLineClampTimer(true)
    }, 300)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [lineClamper])

  return (
    <article className="md:flex gap-6">
      <img
        src={image}
        alt={title}
        className="md:h-44 md:w-auto rounded-xl md:rounded-[1.25rem] md:border-x-8 border-green 2xl:h-[11.5rem]"
      />
      <div className="h-min transition-all duration-500">
        <h4 className="pt-6 md:pt-0 font-semibold text-green text-[1.7rem] lg:-mt-1.5 2xl:text-3xl">
          {title}
        </h4>

        <h6 className="font-semibold text-green text-base">{post}</h6>
        <div className="mt-2 px-4 bg-lightCream pb-3 border-t border-r border-green relative">
          {/* <div className="mt-2 bg-lightCream px-4 pb-3 rounded-xl border border-green border-opacity-20"> */}
          <div className="absolute w-40 bottom-0 right-0 bg-opacity-50 border-b border-green before:content-[''] before:absolute before:h-2 before:w-2 before:bg-green before:rounded-full before:-top-1"></div>
          <div
            className={`transition-all duration-500 ease-in-out overflow-hidden grid grid-rows-[0fr]
              ${!lineClamper && 'max-h-[100%] grid-rows-[1fr]'}
            `}
          >
            <div
              className={`min-h-[3.75rem] 2xl:min-h-[4rem] line-clamp-2
                ${!lineClampTimer && 'line-clamp-none  '}
              `}
            >
              {desc.map((item, index) => {
                return (
                  <p key={index} className="2xl:text-lg pt-3 ">
                    {item}
                  </p>
                )
              })}
            </div>
          </div>
          <button
            onClick={() => {
              setLineClamper((prev) => !prev)
            }}
            className="text-green font-semibold flex items-center mt-2"
          >
            {lineClamper ? 'Read more ' : 'Read less '}
            {lineClamper ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1024 1024"
                className=" h-4"
              >
                <path fill="currentColor" d="m192 384l320 384l320-384z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1024 1024"
                className="rotate-180 h-4"
              >
                <path fill="currentColor" d="m192 384l320 384l320-384z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    </article>
  )
}
