import React, { useEffect, useRef } from 'react'
import Partition from 'molecules/partition'
import Top1 from 'assets/sourceAssure/coming-soon/top-1.png'
import Top2 from 'assets/sourceAssure/coming-soon/top-2.png'
// import Bottom1 from 'assets/sourceAssure/coming-soon/bottom-1.png'
import Bottom2 from 'assets/sourceAssure/coming-soon/bottom-2.png'
import bg from 'assets/sourceAssure/coming-soon/bottom-left.png'

import Top1Mob from 'assets/sourceAssure/coming-soon/top-1-mob.png'
import Top2Mob from 'assets/sourceAssure/coming-soon/top-2-mob.png'
import Bottom1Mob from 'assets/sourceAssure/coming-soon/bottom-1-mob.png'
import Bottom2Mob from 'assets/sourceAssure/coming-soon/bottom-2-mob.png'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

export const ComingSoon = () => {
  const container = useRef<any>(null)

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (window.innerWidth > 767) {
        //! For desktop
        // ? Stick to actual position from bottom
        gsap.from('.img-coming-soon', {
          duration: 1.2,
          top: '800',
          stagger: 0.15,
          scrollTrigger: {
            trigger: '.coming-soon-text-desktop',
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
            // markers: true,
          },
        })
      } else {
        //? animation for pinning section to top
        gsap.fromTo(
          '.coming-soon-container',
          {},
          {
            // duration: 1,
            scrollTrigger: {
              trigger: '.coming-soon-container',
              pin: true,
              start: 'top top',
              // pinSpacing: false,
              // fastScrollEnd: true,
              end: 'bottom top',
              scrub: 1,
              // toggleActions: 'play none none reverse',
              // markers: true,
            },
          },
        )

        // ? Scroll animation for top images
        gsap.fromTo(
          '.image-set-top',
          {
            xPercent: 70,
          },
          {
            // duration: 1,
            xPercent: -70,
            scrollTrigger: {
              trigger: '.coming-soon-container',
              start: 'top top',
              scrub: 1,
              end: 'bottom top',
              toggleActions: 'play none none reverse',
            },
          },
        )

        // ? Scroll animation for top images
        gsap.fromTo(
          '.image-set-bottom',
          {
            xPercent: -70,
          },
          {
            // duration: 1,
            xPercent: 70,
            scrollTrigger: {
              // fastScrollEnd: 1,
              trigger: '.coming-soon-container',
              start: 'top top',
              scrub: 3,
              end: 'bottom top',
              toggleActions: 'play none none reverse',
            },
          },
        )
      }
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section
      ref={container}
      className="coming-soon-container bg-lightCream relative blade-bottom-padding-sm min-h-screen md:min-h-full overflow-hidden"
    >
      <img
        src={bg}
        alt="plants"
        className="absolute bottom-0 left-0 h-52 2xl:h-72"
      />
      <div className="lg:px-20 h-full flex flex-col justify-around items-center md:block">
        <div className="w-container-lg w-full md:pt-12 lg:pt-0 ">
          <Partition text="Coming Soon" color="dark" />
        </div>

        {/* sm screen */}
        <div className="max-w-full w-screen overflow-hidden md:hidden flex flex-col items-center justify-center">
          <div className="image-set-top flex min-w-max gap-[30vw] flex-nowrap overflow-x-hidden">
            <img src={Top1Mob} alt="Turmeric" className="h-52" />
            <img src={Top2Mob} alt="Millets" className="h-52" />
          </div>
        </div>
        <h1 className="text-green z-10 font-bold text-[2.8rem] leading-9 pb-2 md:hidden">
          Coming Soon
        </h1>
        <div className="max-w-full w-screen overflow-hidden mb-6 md:hidden flex flex-col items-center justify-center">
          <div className="image-set-bottom flex min-w-max gap-[30vw] flex-nowrap overflow-x-hidden">
            {/* <img src={Bottom1Mob} alt="Ginger" className="h-52" /> */}
            <img src={Bottom2Mob} alt="Nuts" className="h-52" />
          </div>
        </div>

        {/* lg screen */}
        <div className="coming-soon  hidden md:flex relative blade-top-padding-sm max-w-fit flex-col items-center justify-center m-auto my-40 lg:my-52 xl:mt-44 xl:mb-48 2xl:mt-52 2xl:mb-60">
          <div>
            <img
              src={Top1}
              alt="Turmeric"
              className="img-1 img-coming-soon h-44 absolute top-[-62%] lg:top-[-58%] xl:top-[-38%] 2xl:top-[-40%] left-[0%] lg:h-56 xl:left-[2%] 2xl:h-72"
            />
            <img
              src={Top2}
              alt="Millets"
              className="img-2 img-coming-soon h-52 absolute top-[-75%] lg:top-[-65%] xl:top-[-42%] left-[74.5%] lg:left-[76%] lg:h-64 xl:left-[79.5%] 2xl:left-[79%] 2xl:h-80"
            />
          </div>
          <h1 className="coming-soon-text-desktop text-green z-10 font-bold text-[6rem] lg:text-[8rem] md:text-center xl:text-[10rem] 2xl:text-[12rem]">
            Coming Soon
          </h1>
          <div>
            {/* <img
              src={Bottom1}
              alt="Ginger"
              className="img-3 img-coming-soon h-48 absolute top-[75%] left-[27%] lg:left-[27.5%] lg:h-60 xl:left-[29%] 2xl:h-80 2xl:left-[28.2%] 2xl:top-[77%]"
            /> */}
            <img
              src={Bottom2}
              alt="Nuts"
              className="img-4 img-coming-soon  h-44 absolute  top-[70%] 2xl:top-[88%] left-[51%] lg:h-56 xl:left-[52%] 2xl:h-72"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
