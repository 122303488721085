import Partition from 'molecules/partition'
import React, { useRef, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

export type Testimonial = {
  feedback: string
  name: string
  role?: string | JSX.Element
}

export default function TestimonialsStatic({
  testimonialsList,
}: {
  testimonialsList: Testimonial[]
}) {
  const [viewIndex, setViewIndex] = useState(0)
  const nodeRef = useRef<any>()

  function slideHandler(type: string) {
    if (type === '+') setViewIndex((prev) => ++prev % testimonialsList.length)
    else
      setViewIndex((prev) =>
        prev - 1 === -1 ? testimonialsList.length - 1 : --prev,
      )
  }

  return (
    <section className="lg:px-2 ">
      <section className="max-w-5xl 2xl:max-w-7xl px-1 md:w-11/12  rounded-xl mx-auto">
        <section
          className={`rounded-lg relative min-h-[22rem] sm:min-h-[18rem] md:min-h-[22rem] lg:min-h-[23rem] 2xl:min-h-[21.5rem]
          ${
            !testimonialsList[0].role &&
            'min-h-[20rem] sm:min-h-[16rem] md:min-h-[19rem] lg:min-h-[21rem] 2xl:min-h-[19.5rem]'
          }
        `}
        >
          <div className="absolute hidden top-1/2 right-0 -translate-y-1/2 md:flex flex-col gap-2">
            {testimonialsList.map((_, index) => {
              return (
                <div
                  key={index}
                  className={`rounded-[0.75em] w-[0.75em] transition-all duration-500 ${
                    viewIndex === index
                      ? 'h-12 bg-green'
                      : 'h-[0.75em] bg-green bg-opacity-30'
                  }`}
                ></div>
              )
            })}
          </div>

          <SwitchTransition>
            <CSSTransition
              key={viewIndex}
              nodeRef={nodeRef}
              timeout={300}
              classNames="slide"
              unmountOnExit
            >
              <div
                className="flex py-4 md:p-7 lg:p-9 px-3 lg:px-0"
                ref={nodeRef}
              >
                <div className="flex-1">
                  <div className="pt-4">
                    <h6 className="font-normal text-base  md:text-lg lg:text-xl xl:text-xl leading-normal md:px-6 xl:px-10 mx-auto max-w-3xl 2xl:max-w-4xl text-center">
                      <i>{testimonialsList[viewIndex].feedback}</i>
                    </h6>
                  </div>
                  <div className="pt-6 md:pt-8 xl:pt-10 grid gap-1 place-content-center text-center">
                    <h6 className="font-semibold">
                      {testimonialsList[viewIndex].name}
                    </h6>
                    {/* <span className="text-dark text-sm md:text-base"> */}
                    {testimonialsList[viewIndex].role && (
                      <div className="text-dark">
                        {testimonialsList[viewIndex].role}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="grid place-content-start">
                  <img
                    src="https://res.cloudinary.com/rakesh-gupta/image/upload/v1692183459/img_main-case-logo_i60d5x.png"
                    alt=""
                    className="2xl:h-auto xl:h-48 h-20 md:h-36"
                  />
                </div> */}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </section>
        <section className="px-9 mt-1 grid place-content-center">
          <div className=" p-[6px] flex gap-4 md:gap-8 items-center rounded-full border-1 border-solid border-green">
            <button
              aria-label="Slide to previous testimonial"
              onClick={() => slideHandler('-')}
              type="button"
              // disabled={viewIndex == 0}
              className=" disabled:opacity-30 disabled:cursor-not-allowed p-2 stroke-green hover:fill-green hover:stroke-green rounded-full focus-visible:fill-green focus-visible:stroke-green focus-visible:bg-yellow fill-yellow hover:bg-yellow transition-colors duration-300 outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="inherit"
                className="w-5 h-5 2xl:w-6 2xl:h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
            <div className="font-regular md:hidden">
              {viewIndex + 1}/{testimonialsList.length}
            </div>
            <button
              aria-label="Slide to next testimonial"
              // disabled={viewIndex === testimonialsList.length - 1}
              onClick={() => slideHandler('+')}
              className=" disabled:opacity-30 disabled:cursor-not-allowed  p-2 stroke-green hover:fill-green hover:stroke-green rounded-full focus-visible:fill-green focus-visible:stroke-green focus-visible:bg-yellow fill-yellow hover:bg-yellow transition-colors duration-300 outline-none"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="inherit"
                className="w-5 h-5 2xl:w-6 2xl:h-6 rotate-180"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
          </div>
        </section>
      </section>
    </section>
  )
}
