import axios from "axios"
const URL = "https://records-collection.bharatrohan.in/api/bharatrohan"

export const PostCareerForm = async (body) => {
    try {
        const response = await axios.post(`${URL}/career-single`, body, { headers: { 'Content-Type': 'multipart/form-data'} } )
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const PostContactForm = async (body) => {
    try {
        const response = await axios.post(`${URL}/contact-single`, body );
        return response.data;
    } catch (error) {
        console.log(error);
    }
}