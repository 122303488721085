
import amandeep from 'assets/investors/board/executive/amandeep-copy.jpg'
import rishab from 'assets/investors/board/executive/Rishabh.jpg'

const executiveDirectorsList = [
    {
      id: 'amandeep',
      title: 'Amandeep Panwar',
      post: 'CEO & Chairman',
      desc: [
        "Amandeep's remarkable achievements have earned him recognition as a Forbes 30U30 India & Asia and Fortune 40U40 honoree.",
        "He is a dedicated advocate for technology in agriculture. With a background in aeronautical engineering, he leads BharatRohan's pioneering efforts to revolutionize the farming industry. His driving mission is to create a sustainable food supply chain while increasing farmers' profits through the innovative use of drone technology.",
        'He passionately talks about the transformative potential of precision agriculture, Hyperspectral Imaging, and the pivotal role of UAVs in reshaping the farming landscape. His discussions underscore the immense possibilities that these technologies hold to enhance crop yields, minimize losses, and secure a long-term and sustainable food supply chain for the future.',
      ],
      image: amandeep,
      linkedin : 'https://www.linkedin.com/in/amandeeppanwar/'
    },
    {
      id: 'rishabh',
      title: 'Rishabh Choudhary',
      post: 'Whole Time Director & CTO',
      desc:[
        "Rishabh's remarkable achievements have earned him recognition as a Forbes 30U30 India & Asia and Fortune 40U40 honoree, solidifying his status as a visionary in the field of ag-tech.",
        "He brings a deep passion for drones to the table. With a strong background in aeronautical engineering, he plays a pivotal role in BharatRohan's technological intervention endeavors aimed at making farming sustainable and profitable.",
        'He is at the forefront of harnessing technology to revolutionize the agricultural industry. He frequently engages in discussions about drones, farming, autonomous vehicles, and hyper spectral imaging, emphasizing their potential to transform the agricultural landscape.',
      ],
      image: rishab,
      linkedin : 'https://www.linkedin.com/in/choudharyrish/'
    },
  ]

  

export default executiveDirectorsList