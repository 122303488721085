import React, { useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import Partition from 'molecules/partition'
import Image1 from 'assets/sourceAssure/accordian/image 1.jpg'
import Image2 from 'assets/sourceAssure/accordian/image 2.webp'
import Image3 from 'assets/sourceAssure/accordian/image 3.jpg'
// import Image4 from 'assets/sourceAssure/accordian/image 4.png'
import Image5 from 'assets/sourceAssure/accordian/image 5.jpg'

const data = [
  {
    id: 0,
    title: 'Reliable vast farmer network',
    para: 'Our resolve to revitalise Indian agriculture finds shape in the support of our 19,000 partner farmers who work hard to ensure the best harvest every season.',
    img: Image1,
  },
  {
    id: 1,
    title: 'Quality control and consistency',
    para: 'We closely monitor all on-farm practices. To ensure that the farmers adopt precision farming, we work in close collaboration with them for accurate advisory implementation, quality consistency, and residue-free produce.',
    img: Image2,
  },
  {
    id: 2,
    title: 'Sourcing capabilities and supply chain management',
    para: 'With an end-to-end grip on the supply chain, we ensure timely and consistent supply of the produce at the best prices for our clients minus the irregularities of the existing supply chain.',
    img: Image3,
  },
  // {
  //   id: 3,
  //   title: 'Robust quality control mechanisms',
  //   // para: 'To consistently deliver what we intend to, we work in close collaboration with farmers and ensure the implementation of agricultural advisories for the best quality harvest free from residues.',
  //   para: 'To consistently deliver residue-free produce, we work in close collaboration with farmers and ensure the implementation of agricultural advisories.',
  //   img: Image4,
  // },
  {
    id: 4,
    title: 'Efficient logistics',
    para: 'Supported by efficient and professional logistics partners, our delivery system operates with a plan and consistent tracking updates for the timely delivery of consignments.',
    img: Image5,
  },
]

export const Accordian = () => {
  const [selected, setSelected] = useState<number>(0)

  // console.log(selected)

  return (
    <div className="py-16 bg-cream lg:pb-20">
      <div className="w-container-lg">
        <Partition text="What it brings" color="dark" />
        <h2 className="font-semibold text-green text-3xl text-center pt-6 pb-4 lg:pb-8 max-w-xs mx-auto md:text-left md:mx-0 md:max-w-md xl:pt-6 xl:pb-12 xl:text-4xl 2xl:text-[3rem] xl:max-w-lg 2xl:max-w-xl">
          Ensuring excellence and transparency in{' '}
          <strong className="text-yellow">sourcing</strong>
        </h2>
        <div className="flex flex-col lg:flex-row items-start gap-x-12 xl:gap-x-16 2xl:gap-x-20 gap-y-6 md:gap-y-12">
          <div className="hidden lg:block lg:h-[580px] lg:sticky top-[100px] xl:h-[650px] 2xl:h-[800px] relative w-[50%]">
            {data.map((item, index) => {
              return (
                <img
                  key={index}
                  className={`${
                    selected === index ? 'opacity-100' : 'opacity-0'
                  } h-full object-cover object-center absolute top-[0px] transition-all duration-1000 ease-in-out`}
                  src={item.img}
                  alt={item.title}
                />
              )
            })}
          </div>
          <div className="grid  w-full blade-top-padding-sm lg:pt-0 lg:w-[50%] gap-y-6 lg:gap-y-12 flex-col">
            {data.map((item, index) => (
              <div className="w-full" key={index}>
                <div
                  onClick={() => {
                    setSelected(index)
                  }}
                  className={`flex w-full py-4 border px-4 sm:px-6 md:px-8 lg:px-4 xl:px-6 border-green items-center justify-between cursor-pointer transition-al duration-1000 ${
                    selected === index
                      ? 'bg-green cursor-auto pt-6'
                      : 'bg-transparent'
                  }`}
                >
                  <div className="grid w-full grid-cols-[3.5rem_1fr_auto] sm:grid-cols-[4rem_1fr_auto] xl:grid-cols-[6rem_1fr_auto]">
                    {/* <div className="flex items-center flex-row d w-full"> */}
                    <span
                      className={`text-2xl lg:text-3xl xl:text-4xl opacity-25 font-bold transition-all duration-1000 ${
                        selected === index ? 'text-white' : 'text-black'
                      }`}
                    >{`0${index + 1}`}</span>
                    <h4
                      className={`${
                        selected === index ? 'text-white' : 'text-green'
                      } font-semibold text-left transition-all duration-1000 self-center 2xl:text-2xl`}
                    >
                      {item.title}
                    </h4>

                    <span
                      className={`self-center ${
                        selected === index ? 'text-white' : 'text-green'
                      } `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`w-6 h-6 ml-2 lg:w-8 lg:h-8 2xl:w-10 2xl:h-10 transition-all duration-1000 ${
                          selected === index ? 'rotate-0' : 'rotate-[135deg]'
                        }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                    <div
                      className={`col-span-3 lg:col-start-2 text-white grid transition-all duration-1000 grid-rows-[0fr] ${
                        selected === index ? 'grid-rows-[1fr] pt-5' : ''
                      }`}
                    >
                      <div className="min-h-0 overflow-hidden">
                        <span className="lg:text-sm xl:text-[1rem] inline-block font-light pb-2">
                          {item.para}
                        </span>
                        <div className="w-full py-4 lg:hidden h-[300px] sm:h-[500px]">
                          <img
                            className="w-full h-full object-cover object-center"
                            src={item.img}
                            alt={item.title}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
