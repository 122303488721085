// import testimonial from 'assets/cropAssure/testimonials/testimonial.jpg'
import Partition from 'molecules/partition'
import bg1 from 'assets/cropAssure/testimonials/bottom-left.png'
import bg2 from 'assets/cropAssure/testimonials/top-right.png'
import TestimonialsStatic, { Testimonial } from 'organisms/testimonialsStatic'

const testimonialsList: Testimonial[] = [
  {
    feedback:
      '"The next few years are going to be crucial for the procurement of good quality seed spices with minimal residues. With direct sourcing from farmers and the introduction of contract farming through their CropAssure® services, BharatRohan will emerge as a gamechanger in the industry.”',
    name: 'Jayprakash Gurgela',
    role: (
      <>
        Sr. Purchase Manager, <br className="sm:hidden" /> Aachi Special Foods
      </>
    ),
  },
  {
    feedback:
      '"Quality has never been an issue with BharatRohan. It always matches our purchase parameters. With them on our side, we are assured of sustainably sourced practices grown employing good farming practices."',
    name: 'Basant Yadav',
    role: (
      <>
        Sr. Spices Purchase Manager, <br className="sm:hidden" /> MORE Retail
        Pvt Ltd
      </>
    ),
  },
]

const SourceTestimonials = () => {
  const title = (
    <>
      Does it actually work? <br className="" />
      Hear from our
      <span className="font-semibold text-yellow"> clients</span>
    </>
  )

  return (
    <section className={`py-16 bg-cream relative`}>
      <img
        src={bg1}
        aria-hidden
        alt="leaves"
        className="absolute bottom-0 left-0 h-48 lg:h-64 2xl:h-80 w-auto"
      />
      <img
        src={bg2}
        aria-hidden
        alt="leaves"
        className="absolute top-0 right-0 h-48 lg:h-64 2xl:h-80 w-auto"
      />
      {/* <Testimonials title={title} testimonialsList={testimonialsList} /> */}
      <section className="w-container-lg">
        <Partition
          text="Testimonials"
          color="dark"
          // title=" Stories of impact straight from the ground"
        />
        <h2 className="font-semibold tracking-tight text-center text-3xl mx-auto pt-4 text-green max-w-sm md:text-left md:mx-0 md:max-w-none xl:text-4xl 2xl:text-[3rem]">
          {title}
        </h2>
      </section>
      <TestimonialsStatic testimonialsList={testimonialsList} />
    </section>
  )
}

export default SourceTestimonials
