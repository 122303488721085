import React from 'react'
import sourceManagement from 'assets/sourceAssure/management/management.png'
import logo01 from 'assets/sourceAssure/management/logo01.svg'
import logo02 from 'assets/sourceAssure/management/logo02.svg'
import logo03 from 'assets/sourceAssure/management/logo03.svg'
import logo04 from 'assets/sourceAssure/management/logo04.svg'
import Management from 'organisms/management'
import Partition from 'molecules/partition'
import bg from 'assets/sourceAssure/management/bottom-right.png'

const sourceManagementList = [
  {
    text: 'Systematic procurement of residue-free produce.',
    image: logo01,
  },
  {
    text: 'Assurance of quality produce.',
    image: logo02,
  },
  {
    text: 'Transparency on the source and farming practices followed.',
    image: logo03,
  },
  {
    text: 'Minimised quality compliance risks.',
    image: logo04,
  },
]

const SourceManagement = () => {
  return (
    <section className="bg-cream3 relative">
      <img
        src={bg}
        alt="leaves"
        aria-hidden
        className="absolute bottom-0 right-0 hidden h-28 w-auto lg:h-32 xl:h-52 2xl:h-72 md:block"
      />
      <section className="py-16 px-4 w-container sm:w-container-sm md:w-container">
        <Management
          title={
            <span className="text-green">
              A <strong className="text-yellow">seed-to-harvest</strong>{' '}
              <br className="hidden xl:block" /> traceability system for
              sustainable procurement
            </span>
          }
          para1={
            <span className="inline-block xl:max-w-md 2xl:max-w-lg min-[1800px]:max-w-xl 2xl:leading-relaxed ">
              SourceAssure® facilitates data from our partner farms covering
              each agricultural activity from pre-sowing to harvest. The data is
              a product of routine drone surveys, execution of farming
              advisories that follow, and efficient record keeping.
            </span>
          }
          para2={
            <span className="inline-block xl:max-w-md 2xl:max-w-lg min-[1800px]:max-w-xl 2xl:leading-relaxed ">
              Leveraging <strong className="text-green">qualitative</strong> and{' '}
              <strong className="text-green">quantitative</strong> data, our
              procurement partners get the upper hand in supply chain
              management.
            </span>
          }
          image={sourceManagement}
          list={sourceManagementList}
          component="source"
        />
      </section>
    </section>
  )
}

export default SourceManagement
