import logo01 from 'assets/aboutUs/valueSystem/logo01.svg'
import logo02 from 'assets/aboutUs/valueSystem/logo02.svg'
import logo03 from 'assets/aboutUs/valueSystem/logo03.svg'
import Partition from 'molecules/partition'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect, useRef, useState } from 'react'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const valueSystemList = [
  {
    title: 'Unshakable empathy',
    desc: 'We listen. We listen to our farmers, customers, consumers and vendors. We listen to everyone we meet and absorb.',
    logo: logo01,
  },
  {
    title: 'Celebrate differences',
    desc: 'At BharatRohan, we embrace diversity, recognising that it is the cornerstone of innovation and progress, and we wholeheartedly celebrate the uniqueness that each individual adds to our mission.',
    logo: logo02,
  },
  {
    title: 'Learn and be curious',
    desc: 'We are flexible and are not afraid to unlearn our past beliefs and practices. We are not afraid to let go of our old habits, certainties, convictions, exclamation marks, paradigms and dogmas.',
    logo: logo03,
  },
]

export default function ValueSystem() {
  const [refreshGsap, setRefreshGsap] = useState(false)
  const articleTriggerRef = useRef<any>(null)

  setTimeout(() => {
    setRefreshGsap(true)
  }, 2000)

  useEffect(() => {
    const ctx = gsap.context(() => {
      // const articles = document.querySelectorAll('.ourvalue-article')
      // articles.forEach((article) => {
      gsap.fromTo(
        // articles,
        '.ourvalue-article',
        {
          y: 150,
        },
        {
          duration: 1.5,
          y: 0,
          ease: 'back.out',
          stagger: 0.1,
          scrollTrigger: {
            trigger: articleTriggerRef.current,
            start: 'top 50%',
            toggleActions: 'restart none none reverse',
          },
        },
      )
      // })
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className="bg-green py-16 md:py-20">
      <section className="w-container-lg text-cream3 ">
        <Partition color="light" text="Our value system" />
        <div className="md:grid md:grid-cols-2 justify-between">
          <h2 className="font-medium max-w-[20rem] pt-8 text-3xl -mt-2 text-center mx-auto md:text-left md:mx-0 xl:max-w-lg xl:text-4xl 2xl:text-[3rem] 2xl:max-w-xl">
            Turning inward for impact outward
          </h2>
          <p
            ref={articleTriggerRef}
            className=" font-light text-center max-w-[20rem] py-6 lg:py-8 lg:ml-auto mx-auto sm:max-w-sm md:text-left md:mx-0 xl:text-xl xl:max-w-md 2xl:max-w-xl"
          >
            To ensure an undefeated journey to our vision, we turned to our
            morality. We found our core values and nurtured them.
          </p>
        </div>

        <section className="grid md:grid-cols-2 lg:divide-x-[1px] lg:grid-cols-3 divide-cream3 divide-opacity-20 lg:pt-12">
          {valueSystemList.map((listItem, index) => {
            return (
              <ListCard
                key={index}
                desc={listItem.desc}
                logo={listItem.logo}
                title={listItem.title}
                index={index}
              />
            )
          })}
        </section>
      </section>
    </section>
  )
}

function ListCard({
  logo,
  title,
  desc,
  index,
}: {
  logo: string
  title: string
  desc: string
  index: number
}) {
  return (
    <article
      className={`ourvalue-article flex flex-col items-center md:items-start md:pr-12 lg:px-6 mt-8 xl:px-8 ${
        index % 3 === 0 && 'lg:!pl-0'
      }`}
    >
      <img src={logo} alt={title} className="pt-4" />
      <h3 className="md:text-left py-4 lg:text-2xl 2xl:text-[2rem] lg:pb-6">
        {title}
      </h3>
      <p className="md:text-left font-light text-center max-w-sm mx-auto md:max-w-none md:mx-0 2xl:text-xl pb-6 2xl:leading-8 2xl:max-w-2xl">
        {desc}
      </p>
    </article>
  )
}
