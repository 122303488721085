import React, { useLayoutEffect } from 'react'
import './index.css'
import IPMBanner from './banner'
import Management from './management'
import Methods from './methods'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const IPM = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <main>
      <IPMBanner />
      <Management />
      <Methods />
    </main>
  )
}

export default IPM
