import React, { useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
const currentYear = new Date().getFullYear()
const yearsArray = Array.from(
  { length: currentYear - 2019 },
  (_, index) => currentYear - index,
)

const financials = [
  {
    year: 2024,
    files: [
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
    ],
  },
  {
    year: 2023,
    files: [
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
    ],
  },
  {
    year: 2022,
    files: [
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
    ],
  },
  {
    year: 2021,
    files: [
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
      'https://www.tcs.com/content/dam/tcs/investor-relations/financial-statements/2023-24/ar/annual-report-2023-2024.pdf',
    ],
  },
]

export default function Financial03() {
  const [year, setYear] = useState<any>(0)

  return (
    <div className="p-3 bg-cream ">
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-3">
        <label htmlFor="year">
          <span className="text-base md:text-lg">Select Year </span>
          <Listbox value={year} onChange={(elem) => setYear(elem)}>
            <div className="relative mt-1">
              <Listbox.Button className=" rounded-md bg-cream2  px-2 md:px-3 py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40 w-full text-left focus:outline-violet">
                <span className="block truncate ">
                  <span>{year != 0 ? year : 'Select Year'}</span>
                </span>
                <span className="p-[2px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                    className=" h-4 w-4 md:w-5 md:h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </Listbox.Button>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
                  {yearsArray.map((year, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-default hover:bg-lightOrange select-none text-base ${
                          active ? 'bg-yellow' : 'text-black'
                        }`
                      }
                      value={year}
                    >
                      {({ selected }) => (
                        <span
                          className={`block truncate  pl-6 pr-4 py-3 ${
                            selected
                              ? 'font-semibold text-white bg-green bg-opacity-90'
                              : 'font-normal'
                          }`}
                        >
                          {year}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </label>
      </div>
      <div className="py-10">
        {year != 0 ? (
          <div>
            {financials.findIndex((item) => item.year === year) === -1 ? (
              'No Records to show'
            ) : (
              <div>
                {financials.map((item, index) => (
                  <div key={index}>
                    {item.year === year ? (
                      <div>
                        {item.files.map((file) => (
                          <div>
                            <p className="mt-2">
                              <a
                                className="underline"
                                href={file}
                                target="_blank"
                              >
                                Financial Record for Financial year {item.year}.
                              </a>
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          'Select year to view the financial reports.'
        )}
      </div>
    </div>
  )
}
