import './blogCard.css'
import defaultImg from 'assets/blogsAndUpdates/updates/default.jpg'

export default function BlogCard({
  title,
  image,
  desc,
  tag,
  postedOn,
  theme,
  large,
  link,
  logo,
  alt,
}: {
  title: string
  image: string
  desc: string
  tag: string
  postedOn: string
  theme: string
  large?: boolean
  link?: string
  logo?: string
  alt?: string
}) {
  return (
    <article
      className={`max-w-md  mx-auto xl:max-w-none select-none
      ${theme === 'dark' && 'text-white'} 
      ${large && 'max-w-lg mx-auto md:max-w-fit'} 

    `}
    >
      <a href={link} aria-label={title} target="_blank">
        <img
          src={image || defaultImg}
          alt={title}
          style={{height : '320px', width : '100%'}}
          className={`blog-card-hover-effect rounded-xl mt-8 mb-6
        ${large && 'h-auto w-full'}
      `}
        />
      </a>
      <div className="flex justify-between items-center ">
        <span
          className={`text-green
          ${theme === 'light' && 'font-semibold'}
        `}
        >
          <span className="text-[13px] block">{tag.toUpperCase()}</span>
        </span>
        <span
          className={`text-sm  lg:text-[0.75rem] xl:text-sm
          ${theme == 'dark' ? 'text-white font-light' : 'text-black'}
        `}
        >
          {postedOn}
        </span>
      </div>
      <div className="h-24 flex">
        <h4
          className={`font-bold text-2xl -mt-1.5 mb-3 pt-6  lg:text-[1.4rem] xl:text-[1.45rem] line-clamp-2
        ${theme == 'dark' ? 'text-white ' : 'text-black'}
        ${large && 'max-w-md 2xl:text-[1.7rem] 2xl:max-w-xl'}

      `}
        >
          {title}
        </h4>
      </div>
      <p
        className={`font-medium text-sm lg:text-base line-clamp-2 pt-1 mb-6
        ${
          theme == 'dark'
            ? 'text-white font-light text-opacity-70'
            : 'text-darkGrey'
        } 
        ${large && ' !max-w-md 2xl:text-[1.1rem] 2xl:!max-w-lg'}

      `}
      >
        {desc}
      </p>
      <div className="flex items-center justify-between">
        <button
          onClick={() => {
            window.open(link, '_blank')
          }}
          aria-label="Read more about this article"
          className={`outline-none border-1 border-transparent font-semibold rounded-full px-8 py-2 cursor-pointer text-sm transition 
        ${
          theme === 'dark'
            ? 'font-bold text-green bg-yellow hover:bg-green hover:font-semibold hover:text-white hover:border-white'
            : 'font-bold text-green bg-yellow hover:bg-green hover:text-yellow'
        }
      `}
        >
          Read more
        </button>
        <img
          src={logo}
          alt={alt}
          title={'Posted by ' + alt}
          className="h-5 md:h-6 2xl:h-7 w-auto"
        />
      </div>
    </article>
  )
}
