import bg1 from 'assets/cropAssure/testimonials/bottom-left.png'
import bg2 from 'assets/cropAssure/testimonials/top-right.png'
import TestimonialsStatic, { Testimonial } from 'organisms/testimonialsStatic'
import Partition from 'molecules/partition'

const testimonialsList: Testimonial[] = [
  {
    feedback:
      '"Earlier, I used to consult local pesticide shopkeepers after identifying visible crop damage on my farm. They lacked scientific knowledge about pests. Ever since I started using BharatRohan’s CropAssure® services, damage control has been very easy. I get early alerts and scientific advisories to restrict damages well in advance. It guides me about the timing, quantity, and names of the inputs to be applied."',
    name: 'Ashish Kumar, Barabanki ',
  },
  {
    feedback:
      '"BharatRohan CropAssure® services provide me with ground-level support for my crops. It helps me resolve challenges across all growth stages. I have thus been able to deliver the exact variety and quality as asked for by large buyers. BharatRohan’s promptness has helped me earn better and make farming profitable."',
    name: 'Umesh Kumar, Barabanki',
  },
]

const CropTestimonials = () => {
  const title = (
    <span className="text-green">
      Does it actually work? <br />
      Hear from our
      <span className="font-bold text-yellow"> farmers</span>
    </span>
  )

  return (
    <section className={`py-16 bg-lightCream relative`}>
      <img
        src={bg1}
        aria-hidden
        alt="leaves"
        className="absolute bottom-0 left-0 h-48 lg:h-64 2xl:h-80 w-auto"
      />
      <img
        src={bg2}
        aria-hidden
        alt="leaves"
        className="absolute top-0 right-0 h-48 lg:h-64 2xl:h-80 w-auto"
      />
      {/* <Testimonials title={title} testimonialsList={testimonialsList} /> */}
      <section className="w-container-lg">
        <Partition
          text="Testimonials"
          color="dark"
          // title=" Stories of impact straight from the ground"
        />
        <h2 className="font-semibold text-center tracking-tight grid place-content-center leading-tight text-3xl mx-auto pt-4 text-green max-w-sm md:text-left md:mx-0 md:max-w-none xl:text-4xl 2xl:text-[3rem]">
          {title}
        </h2>
      </section>
      <TestimonialsStatic testimonialsList={testimonialsList} />
    </section>
  )
}

export default CropTestimonials
