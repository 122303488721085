import React from 'react'
import Partition from 'molecules/partition'
import TestimonialsStatic, { Testimonial } from 'organisms/testimonialsStatic'

const testimonialsList: Testimonial[] = [
  {
    feedback:
      '"Earlier, I used to consult local pesticide shopkeepers after identifying visible crop damage on my farm. They lacked scientific knowledge about pests. Ever since I started using BharatRohan’s CropAssure® services, damage control has been very easy. I get early alerts and scientific advisories to restrict damages well in advance. It guides me about the timing, quantity, and names of the inputs to be applied."',
    name: 'Ashish Kumar, Barabanki ',
    role: '(Beneficiary of CropAssure® programme)',
  },
  {
    feedback:
      '"BharatRohan CropAssure® services provide me with ground-level support for my crops. It helps me resolve challenges across all growth stages. I have thus been able to deliver the exact variety and quality as asked for by large buyers. BharatRohan’s promptness has helped me earn better and make farming profitable."',
    name: 'Umesh Kumar, Barabanki',
    role: '(Beneficiary of CropAssure® programme)',
  },
  {
    feedback:
      '"The next few years are going to be crucial for the procurement of good quality seed spices with minimal residues. With direct sourcing from farmers and the introduction of contract farming through their CropAssure® services, BharatRohan will emerge as a gamechanger in the industry.”',
    name: 'Jayprakash Gurgela',
    role: (
      <>
        Sr. Purchase Manager, <br className="sm:hidden" /> Aachi Special Foods
        <br />
        (Beneficiary of SourceAssure® programme)
      </>
    ),
  },
  {
    feedback:
      '"Quality has never been an issue with BharatRohan. It always matches our purchase parameters. With them on our side, we are assured of sustainably sourced practices grown employing good farming practices."',
    name: 'Basant Yadav',
    role: (
      <>
        Sr. Spices Purchase Manager, <br className="sm:hidden" /> MORE Retail
        Pvt Ltd
        <br />
        (Beneficiary of SourceAssure® programme)
      </>
    ),
  },
]

export default function Testimonials() {
  return (
    <section className="bg-cream3 py-16">
      <section className="w-container-lg">
        <Partition text="Testimonials" color="dark" title=" " />
      </section>
      <section className="w-container grid place-content-center text-center pt-3 pb-1">
        <div>
          <h3 className={` text-green font-semibold`}>
            Stories of impact straight from the ground
          </h3>
        </div>
      </section>
      <TestimonialsStatic testimonialsList={testimonialsList} />
    </section>
  )
}
