import React, { useEffect, useRef } from 'react'

import ringImage from 'assets/aboutUs/banner/Ellipse 101.svg'

// import img1 from 'assets/aboutUs/team/image (1).png'
// import img2 from 'assets/aboutUs/team/image (2).png'
// import img3 from 'assets/aboutUs/team/image (3).png'

import img1 from 'assets/aboutUs/team/image01.webp'
import img2 from 'assets/aboutUs/team/image02.webp'
import img3 from 'assets/aboutUs/team/image03.webp'
import img4 from 'assets/aboutUs/team/image04.webp'
import img5 from 'assets/aboutUs/team/image05.webp'
import img6 from 'assets/aboutUs/team/image06.webp'
import img7 from 'assets/aboutUs/team/image07.webp'
import img8 from 'assets/aboutUs/team/image08.webp'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useNavigate } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const members = [
  {
    image: img1,
    position: '-top-[15%] left-[15%] xl:left-[20%] h-28 w-28',
  },
  {
    image: img2,
    position: '-top-[17%] right-[5%] xl:right-[10%] h-40 w-40',
  },
  {
    image: img3,
    position: 'top-[30%] left-[3%] h-24 w-24 xl:left-[8%]',
  },
  {
    image: img4,
    position: 'top-[50%] left-[18%] xl:left-[20%] h-20 hidden lg:block w-20',
  },
  {
    image: img5,
    position: 'top-[30%] right-[18%] xl:right-[20%] h-20 hidden lg:block w-20',
  },
  {
    image: img6,
    position: 'top-[50%] right-[3%] h-24 w-24 xl:right-[8%]',
  },
  {
    image: img7,
    position: '-bottom-[20%] left-[8%] xl:left-[13%] h-40 w-40',
  },
  {
    image: img8,
    position: '-bottom-[15%] right-[10%] xl:right-[15%] h-28 w-28',
  },
]

export default function JoinUs() {
  const navigate = useNavigate()
  const triggerRef = useRef<any>()

  useEffect(() => {
    const ctx = gsap.context(() => {
      const member = document.querySelectorAll('.member-anim')
      member.forEach((elem) => {
        gsap.from(elem, {
          top: '50%',
          left: '50%',
          xPercent: -50,
          yPercent: -50,
          height: '20px',
          width: '20px',
          opacity: 0.5,
          duration: 1.5,
          stagger: 0.05,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'center 85%',
            toggleActions: 'restart none none reverse',
            // fastScrollEnd: true,
          },
        })
      })
    })
    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className="bg-cream py-16 md:py-20 ">
      <section className="md:pt-16 lg:pt-24 md:pb-28 lg:pb-16 2xl:pb-24">
        <section className="w-container-lg mt-32 sm:mt-40 md:mt-56 lg:mt-40 relative xl:mt-52 2xl:mt-64">
          <div className="relative bg-green justify-center mx-auto flex z-10 items-center rounded-full w-[240px] h-[240px] min-[320px]:h-[280px] min-[320px]:w-[280px] md:h-[400px] md:w-[400px] 2xl:h-[550px] 2xl:w-[550px] ">
            <img
              src={ringImage}
              alt="ring"
              aria-hidden
              className="absolute bottom-2 left-2 md:left-3 h-auto w-full rotate-6 md:hidden lg:block"
            />
            <div ref={triggerRef} className="member-anim-trigger">
              <h4 className="w-2/3 mx-auto text-white text-center font-medium text-[1.5rem] md:font-semibold md:text-[2rem]">
                Want to be one among us?
              </h4>
              <button
                onClick={() => {
                  navigate('/careers')
                }}
                className="block rounded-full mx-auto w-max text-center bg-yellow hover:bg-white hover:text-green px-10 py-2.5 mt-6 text-green font-semibold relative z-40 2xl:px-12 2xl:py-3 transition-colors "
              >
                Join us
              </button>
            </div>
          </div>
          <div className="hidden md:block">
            {members.map((member, index) => {
              return (
                <div
                  key={index}
                  className={`member-anim absolute ${member.position}`}
                >
                  {member.image && (
                    <img
                      src={member.image}
                      alt={'Member'}
                      className="h-full w-full hover:scale-110 transition duration-500"
                    />
                  )}
                  {!member.image && (
                    <div className="h-12 xl:h-16 w-12 xl:w-16 bg-yellow bg-opacity-50 rounded-full"></div>
                  )}
                </div>
              )
            })}
          </div>
        </section>
      </section>
    </section>
  )
}
