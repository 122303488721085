import React from 'react'
import awdVideo from 'assets/carbonFarming/720.mp4'

const AWDVideo = () => {
  return (
    <section className="min-h-[156px] py16 lg:py-0 bg-green">
      <video
        autoPlay
        controls
        loop
        muted
        playsInline
        className="h-full w-full 2xl:h-screen object-cover mx-auto"
      >
        <source src={awdVideo} />
      </video>
    </section>
  )
}

export default AWDVideo
