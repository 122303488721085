import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Meaning from './meaning'
import CaseStudy from './caseStudy'
import Cta from 'organisms/cta'
import ctaImage from 'assets/cropAssure/cta/cta.png'

import bg from 'assets/cropAssure/cta/left.png'

import Process, { ProcessList } from 'organisms/process'

import image01 from 'assets/supplyChain/collection.png'
import image02 from 'assets/supplyChain/sorting.png'
import image03 from 'assets/supplyChain/packaging.png'
import image04 from 'assets/supplyChain/dashboard_process.png'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

import './index.css'

const processList: ProcessList[] = [
  {
    desc: (
      <>
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Farming data collection
        </strong>{' '}
        <br />
        <span className="opacity-70 block leading-normal pt-2   lg:opacity-100 text-darkGrey lg:text-[0.95rem] xl:text-[1.1rem] lg:font-semibold 2xl:text-[1.4rem] lg:pl-0">
          Sequentially listing all agricultural activities in the respective
          farm’s database (origin, fertiliser, IPM practices, etc.). Each farm
          has its unique QR code.
        </span>
      </>
    ),
    image: image01,
    navText: 'Data Collection',
  },
  {
    desc: (
      <>
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Procurement and sorting <br />
        </strong>{' '}
        <span className="opacity-70 block leading-normal pt-2   lg:opacity-100 text-darkGrey lg:text-[0.95rem] xl:text-[1.1rem] lg:font-semibold 2xl:text-[1.4rem] lg:pl-0">
          Harvesting of the produce followed by rigorous sorting adhering to the
          stringent quality benchmarks.
        </span>
      </>
    ),
    image: image02,
    navText: 'Sorting',
  },
  {
    desc: (
      <>
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Packaging with QR code
        </strong>{' '}
        <span className="opacity-70 block leading-normal pt-2   lg:opacity-100 text-darkGrey lg:text-[0.95rem] xl:text-[1.1rem] lg:font-semibold 2xl:text-[1.4rem] lg:pl-0">
          Incorporation of the farm QR codes on the final packaging for that
          just-a-scan reference.
        </span>
      </>
    ),
    image: image03,
    navText: 'Packaging',
  },
  {
    desc: (
      <>
        <strong className="text-green lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Dashboard <br />
        </strong>
        <span className="opacity-70 pt-2 block leading-tight  lg:opacity-100 text-darkGrey lg:text-[0.95rem] xl:text-[1.1rem] lg:font-semibold 2xl:text-[1.4rem] lg:pl-0">
          Redirection to the farm’s online repository featuring the produce’s
          journey from seed-to-sac
        </span>{' '}
      </>
    ),

    image: image04,
    navText: 'Dashboard',
  },
]

export default function SupplyChain() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <div>
      <Banner />
      <Meaning />
      <Process
        partition="How it happens"
        title={
          <span className="lg:inline-block lg:max-w-xs lg:mt-1 2xl:max-w-xl">
            Simplifying tracing like never before
          </span>
        }
        processList={processList}
      />
      {/* <CaseStudy /> */}

      <section className="bg-lightCream py-16 md:py-24 xl:py-28 2xl:py-32 px-4 relative overflow-hidden cta-section xl:bg-[url(assets/globals/cta-bg.svg)] bg-[-80px_-80px] min-[1600px]:bg-[-50px_-150px] bg-contain bg-no-repeat ">
        <Cta
          title={
            <span className="text-green">
              Want to delve into our
              <span className="text-yellow"> SCM</span> and
              <span className="text-yellow"> traceability space? </span>
            </span>
          }
          image={ctaImage}
          target="/contact-us"
        />
      </section>
    </div>
  )
}
