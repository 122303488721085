import React from 'react'
import img01 from 'assets/carbonFarming/benefits/image01.jpg'
import img02 from 'assets/carbonFarming/benefits/image02.jpg'
import img03 from 'assets/carbonFarming/benefits/image03.jpg'
import imgMob01 from 'assets/carbonFarming/benefits/mobile/image01.jpg'
import imgMob02 from 'assets/carbonFarming/benefits/mobile/image02.jpg'
import imgMob03 from 'assets/carbonFarming/benefits/mobile/image03.jpg'
import topRight from 'assets/carbonFarming/benefits/top-right.svg'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'

export const benefitsList = [
  {
    text: 'Saving up to 30% on overall irrigation expenses',
    textJSX: (
      <>
        Saving up to <TextHighlighter text="30%" /> on overall irrigation
        expenses
      </>
    ),
    image: img01,
    imageMob: imgMob01,
  },
  {
    text: 'Saving up to 10-15% on overall labour expenses',
    textJSX: (
      <>
        Saving up to <TextHighlighter text="10-15%" /> on overall labour
        expenses
      </>
    ),
    image: img02,
    imageMob: imgMob02,
  },
  {
    text: 'Saving up to 10-20% on overall diesel expenses',
    textJSX: (
      <>
        Saving up to <TextHighlighter text="10-20%" /> on overall diesel
        expenses
      </>
    ),
    image: img03,
    imageMob: imgMob03,
  },
]

function TextHighlighter({ text }: { text: string }) {
  return (
    <span className="font-bold text-2xl xl:text-[1.75rem] 2xl:text-4xl text-yellow lg:text-darkGrey">
      {text}
    </span>
  )
}

const Benefits = () => {
  return (
    <section className="bg-green py-16 relative  border-b-1 border-solid border-lightCream border-opacity-30">
      <img
        src={topRight}
        alt="Background element - planting"
        className="absolute top-3 right-0 hidden md:block md:h-36 h-56 2xl:h-64 w-auto"
      />
      <section className="w-container-2xl ">
        <div className="text-white max-w-sm text-center mx-auto md:mx-0 md:text-left md:max-w-lg lg:max-w-xl 2xl:max-w-3xl">
          <h2 className="font-medium">
            <span className="text-yellow">Farmer benefits</span> - Why choose
            carbon farming
          </h2>
          <p className="text-base md:text-lg pt-8 pb-2 lg:pb-12 2xl:text-xl 2xl:max-w-2xl">
            A switch to precision agriculture-based carbon farming rewards
            farmers in ways more than one. It leads to targeted threat
            mitigation and farming with measured and need-based operations.
          </p>
        </div>

        {/* For desktop */}
        <section className="lg:grid-cols-3 gap-4 pb-4 hidden lg:grid">
          {benefitsList.map((benefit, index) => {
            return (
              <article
                className={`flex gap-y-4 flex-col ${
                  index % 2 !== 0 ? 'lg:flex-col' : 'lg:flex-col-reverse'
                }`}
                key={index}
              >
                <img
                  src={benefit.image}
                  alt={benefit.text}
                  className={`rounded-xl object-cover h-40 xl:h-48 2xl:h-64 `}
                />
                <div className="bg-cream rounded-xl grid place-content-center h-40 xl:h-48 2xl:h-64">
                  <h3 className="text-center font-medium text-xl xl:text-2xl 2xl:text-[1.8rem] px-3 max-w-sm 2xl:px-0">
                    {benefit.textJSX}
                  </h3>
                </div>
              </article>
            )
          })}
        </section>

        {/* for mobile */}
        <section className="benefit-swiper lg:hidden mx-auto">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
            }}
            speed={600}
            spaceBetween={20}
            className=""
            breakpoints={{
              640: {
                slidesPerView: 1.5,
                centeredSlides: true,
              },
              768: {
                slidesPerView: 1.8,
                spaceBetween: 60,
                centeredSlides: true,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 60,
                centeredSlides: true,
              },
            }}
          >
            {benefitsList.map((benefit, index) => {
              return (
                <SwiperSlide key={index} className="h-full pb-12">
                  <article className="pt-12 md:pt-20 text-lg text-white">
                    <img
                      src={benefit.imageMob}
                      alt={benefit.text}
                      className="rounded-xl"
                    />
                    <h3 className="text-xl text-center px-3 sm:px-0 pt-5 max-w-sm mx-auto">
                      {benefit.textJSX}
                    </h3>
                  </article>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>
      </section>
    </section>
  )
}

export default Benefits
