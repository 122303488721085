import React from 'react'
import Partition from 'molecules/partition'

import client1 from 'assets/home/clients/upl.svg'
import client2 from 'assets/home/clients/itc.svg'
import client3 from 'assets/home/clients/mega-store.svg'
import client4 from 'assets/home/clients/aachi.svg'
import client5 from 'assets/home/clients/suhana.svg'
import client6 from 'assets/home/clients/advanta.svg'
import client7 from 'assets/home/clients/dabur.svg'
import client8 from 'assets/home/clients/pi.svg'

const clientList = [
  {
    logo: client1,
    alt: 'UPL',
  },
  {
    logo: client2,
    alt: 'ITC Limited',
  },
  {
    logo: client3,
    alt: 'More Quality Megastore',
  },
  {
    logo: client4,
    alt: 'Aachi - The mother of good taste',
  },
  {
    logo: client5,
    alt: 'Suhana - maza aa gaya',
  },
  {
    logo: client6,
    alt: 'Advanta',
  },
  {
    logo: client7,
    alt: 'Dabur',
  },
  {
    logo: client8,
    alt: 'PI - Inspired by Science',
  },
  {
    logo: client1,
    alt: 'UPL',
  },
  {
    logo: client2,
    alt: 'ITC Limited',
  },
  {
    logo: client3,
    alt: 'More Quality Megastore',
  },
  {
    logo: client4,
    alt: 'Aachi - The mother of good taste',
  },
  {
    logo: client5,
    alt: 'Suhana - maza aa gaya',
  },
  {
    logo: client6,
    alt: 'Advanta',
  },
  {
    logo: client7,
    alt: 'Dabur',
  },
  {
    logo: client8,
    alt: 'PI - Inspired by Science',
  },
]

export default function Clients() {
  return (
    <section className="bg-lightCream py-16 home-clients">
      <section className="blade-bottom-padding-sm w-container">
        <section className="">
          <section>
            <Partition
              title=" Enriching the agri value chain one client at a time"
              color="dark"
              text="Clients"
            />
          </section>
        </section>
        <section className="blade-top-padding">
          <div className="mt-8 xl:my-6 w-full relative overflow-hidden">
            <div className="absolute w-[101%] h-full -inset-[1px] clients-gradient z-10"></div>
            <div className="flex gap-16 md:gap-20 lg:gap-24 xl:gap-28 w-max marquee-home">
              {clientList.concat(clientList).map((client, index) => (
                <img
                  key={`home-${index}-${client}}`}
                  src={client.logo}
                  alt={client.alt}
                  aria-hidden={index > clientList.length}
                  className="h-16 xl:h-[5.5rem] w-28 xl:w-40 mix-blend-darken"
                />
              ))}
            </div>
          </div>
        </section>
      </section>
    </section>
  )
}
