import image from 'assets/blogsAndUpdates/blogs/blog.jpg'
import BlogCard from 'molecules/blogCard'
import { Blogcard } from 'organisms/blogs'
import { CaseStudyType } from 'organisms/caseStudy'
import { useEffect, useState } from 'react'

const blogsList: CaseStudyType[] = [
  {
    title: 'How to build a carbon credit portfolio that impact',
    image: image,
    desc: 'Fasal Amrit helped reduce the leaching losses and achieve a 5% decrement in fertilizer use. ',
    tag: 'agriculture',
    postedOn: 'posted on 2023',
  },
  {
    title: 'How to build a carbon credit portfolio that impact',
    image: image,
    desc: 'Fasal Amrit helped reduce the leaching losses and achieve a 5% decrement in fertilizer use. ',
    tag: 'agriculture',
    postedOn: 'posted on 2023',
  },
  {
    title: 'How to build a carbon credit portfolio that impact',
    image: image,
    desc: 'Fasal Amrit helped reduce the leaching losses and achieve a 5% decrement in fertilizer use. ',
    tag: 'agriculture',
    postedOn: 'posted on 2023',
  },
]

const apiUrl =
  // 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/bharatrohan-blog'
  'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/bharatrohan-blog'

const Blogs = () => {
  const [blogs, setBlogs] = useState<any>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((resData) => {
        setBlogs(resData.items || [])
        console.log(resData.items)
        setLoading(false)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  return (
    <section className="py-16 md:py-20 bg-cream3">
      <section className="w-container-lg">
        <h2 className="text-3xl text-center text-green font-semibold md:text-left xl:text-4xl 2xl:text-[3rem]">
          Blogs
        </h2>
        <p className="text-center pt-4 mx-auto max-w-sm md:text-left md:mx-0 xl:text-lg xl:max-w-md 2xl:max-w-xl">
          Stay on top of your farming practices with interesting and beneficial
          agricultural insights and know-how.
        </p>

        <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 pt-6 pb-3 xl:gap-x-12 xl:gap-y-8 2xl:gap-x-16">
          {loading && <div className="py-32">Loading...</div>}
          {!loading &&
            blogs.map((elem: any, index: number) => {
              return (
                <div key={index} className="pt-8">
                  <Blogcard data={elem} theme="light" />
                </div>
              )
            })}
        </section>
      </section>
    </section>
  )
}

export default Blogs
