import React, { useEffect, useRef, useState } from 'react'
import border from 'assets/cropAssure/process/border2.svg'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import 'swiper/css/autoplay'

import './process.css'

import { A11y, Autoplay, Pagination } from 'swiper'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Partition from 'molecules/partition'

export type ProcessList = {
  desc: JSX.Element
  image: string
  navText: string
}

let intervalId: any

const Process = ({
  partition,
  title,
  processList,
}: {
  partition: string
  title: string | JSX.Element
  processList: ProcessList[]
}) => {
  const [currActiveIndex, setCurrActiveIndex] = useState<number>(0)
  const titleRef = useRef<any>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  function slideHandler(currSlide: number, reset?: boolean) {
    if (!reset) {
      setCurrActiveIndex(currSlide)
    }
    clearInterval(intervalId)

    intervalId = setInterval(() => {
      setCurrActiveIndex((currActive) => {
        if (currActive === processList.length - 1) {
          return 0
        }

        return currActive + 1
      })
    }, 5000)
  }

  function slideHandlerBtn(type: string) {
    switch (type) {
      case '+':
        setCurrActiveIndex((prev) => ++prev % processList.length)
        slideHandler(0, true)
        break
      case '-':
        setCurrActiveIndex((prev) => {
          if (prev - 1 < 0) {
            return processList.length - 1
          }
          return --prev
        })
        slideHandler(0, true)

        break

      default:
        break
    }
  }

  useEffect(() => {
    intervalId = setInterval(() => {
      setCurrActiveIndex((currActive) => {
        if (currActive === processList.length - 1) {
          return 0
        }

        return currActive + 1
      })
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <section className="bg-cream pb-16 lg:pb-0">
      <div className="pt-16 pb-8 lg:pb-10 sm:px-6 md:px-0 w-container-lg">
        <Partition color="dark" text={partition} />
      </div>
      <section className="relative">
        <section className="swiper-container w-container select-none sm:w-container-sm md:w-container lg:w-container-lg lg:px-0">
          <span className="font-semibold z-20 -mt-3 tracking-tight max-w-xs text-green text-[1.6rem] sm:text-3xl sm:max-w-[25rem] lg:absolute lg:max-w-[23.5rem] lg:text-[1.8rem] xl:max-w-[28.5rem] xl:text-[2rem] 2xl:text-[2.15rem] leading-tight 2xl:max-w-[35rem] min-[1800px]:text-[2.25rem] min-[1800px]:max-w-[35.75rem]">
            {title}
          </span>
          <Swiper
            id="swiper"
            modules={[Pagination, Autoplay, A11y]}
            slidesPerView={1}
            // spaceBetween={50}
            autoHeight
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop
            pagination={{ clickable: true }}
            className="lg:hidden pt-8"
          >
            {processList.map((item, index) => {
              return (
                //! Never render SwiperSlide inside extracted component, it doesn't work there
                <SwiperSlide key={index}>
                  <ProcessList
                    index={index}
                    image={item.image}
                    desc={item.desc}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <section className={`hidden lg:block lg:-mb-12 2xl:mb-0`}>
            <article className="relative">
              <button
                aria-label="Previous slide"
                className="absolute top-[50%] left-8 hidden lg:block cursor-pointer z-10 2xl:left-12"
                onClick={() => {
                  slideHandlerBtn('-')
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  className="group h-9 w-9 lg:h-8 xl:h-12 xl:w-12 lg:w-8 rounded-full p-1.5 bg-yellow hover:bg-green active:bg-yellow transition-colors duration-200"
                >
                  <path
                    fill="none"
                    stroke="#193C34"
                    className="group-hover:stroke-yellow transition-colors duration-200"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="M244 400L100 256l144-144M120 256h292"
                  />
                </svg>
              </button>
              <button
                aria-label="Next slide"
                className="absolute top-[50%] right-8 hidden lg:block cursor-pointer z-10 2xl:right-12"
                onClick={() => {
                  slideHandlerBtn('+')
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  className="group h-9 w-9 lg:h-8 xl:h-12 xl:w-12 lg:w-8 rounded-full p-1.5 rotate-180 bg-yellow hover:bg-green active:bg-yellow transition-colors duration-200"
                >
                  <path
                    fill="none"
                    stroke="#193C34"
                    className="group-hover:stroke-yellow transition-colors duration-200"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="M244 400L100 256l144-144M120 256h292"
                  />
                </svg>
              </button>

              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={currActiveIndex + 'article'}
                  timeout={300}
                  classNames={'fade-show'}
                  nodeRef={titleRef}
                >
                  <h3
                    ref={titleRef}
                    className="absolute top-[50%] leading-[2rem] -translate-y-1/3 left-[6rem] text-green max-w-sm xl:max-w-md xl:left-[8.5rem] 2xl:left-[12rem] 2xl:max-w-xl 2xl:leading-[2.5rem]"
                  >
                    <span className="opacity-20 text-green block leading-[7rem] font-semibold text-[3.5rem] xl:text-[5rem] 2xl:leading-[9rem] 2xl:text-[6rem]">
                      0{currActiveIndex + 1}
                    </span>
                    {processList[currActiveIndex].desc}
                  </h3>
                </CSSTransition>
              </SwitchTransition>
              <img src={border} alt="border" className="w-full h-full" />
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={currActiveIndex + 'article'}
                  timeout={300}
                  classNames={'fade-show'}
                  nodeRef={imageRef}
                >
                  <img
                    ref={imageRef}
                    src={processList[currActiveIndex].image}
                    alt={`Process ${currActiveIndex}`}
                    className="absolute top-1/4 right-[5%] -translate-y-1/4 sm:max-w-xl lg:max-w-[22rem] lg:right-[10%] xl:right-[13%] xl:max-w-md 2xl:max-w-[35rem] 2xl:right-[12%] "
                  />
                </CSSTransition>
              </SwitchTransition>
            </article>

            <nav
              className={`flex items-center -translate-y-1/2 ${
                processList.length < 5
                  ? 'justify-center gap-12'
                  : 'justify-evenly'
                // 'justify-center gap-3 xl:gap-5'
              }`}
            >
              {processList.map((process, index) => {
                return (
                  <ProcessNavigatorCard
                    key={index}
                    index={index}
                    title={process.navText}
                    image={process.image}
                    currActiveIndex={currActiveIndex}
                    onClickHandler={slideHandler}
                  />
                )
              })}
            </nav>
          </section>
        </section>
      </section>
    </section>
  )
}

export default Process

function ProcessList({
  index,
  image,
  desc,
}: {
  index: number
  image: string
  desc: JSX.Element
}) {
  return (
    <article className="swiper-slide min-h-fit">
      <img
        src={image}
        alt={`Process ${index}`}
        className="m-auto px-4 max-w-md w-full md:max-w-xl"
      />
      <h4 className="text-center pt-6 text-lg max-w-xs m-auto sm:text-xl md:text-2xl sm:max-w-sm ">
        {desc}
      </h4>
    </article>
  )
}

function ProcessNavigatorCard({
  index,
  currActiveIndex,
  image,
  title,
  onClickHandler,
}: {
  index: number
  currActiveIndex: number
  image: string
  title: string
  onClickHandler: (slide: number) => void
}) {
  const isActive = currActiveIndex === index

  return (
    <button
      aria-label="Tab"
      className="group relative cursor-pointer"
      onClick={() => {
        onClickHandler(index)
      }}
    >
      <svg
        width="263"
        height="166"
        viewBox="0 0 263 166"
        fill={`${isActive ? '#193C34' : 'transparent'}`}
        xmlns="http://www.w3.org/2000/svg"
        className="w-[11rem] xl:w-[13rem] 2xl:w-[16rem] 2xl:h-full group-hover:fill-green transition-all duration-300"
      >
        <path
          d="M0 0H222.5L263 35.5V166H41L0 132V0Z"
          fill={`${isActive ? 'none' : '#FFFDEF'}`}
        />
        <path
          d="M1 1H222.124L262 35.9532V165H41.3607L1 131.53V1Z"
          stroke="#22574D"
          strokeOpacity="0.3"
          strokeWidth="2"
        />
      </svg>
      {/* shadow for above image */}
      {isActive && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="254"
          height="167"
          viewBox="0 0 263 166"
          fill="none"
          className="w-[10.5rem] absolute top-[4%] left-[7%] right-0 bottom-0 -z-10 xl:w-[12.5rem] xl:left-[6%] 2xl:w-[15.5rem] 2xl:left-[5.5%] 2xl:h-full "
        >
          <path d="M0 0H222.5L263 35.5V166H41L0 132V0Z" fill="#E8BA3A" />
        </svg>
      )}
      <h6
        className={`absolute top-10 left-3 text-lg font-bold tracking-tighter xl:top-6 2xl:top-2 2xl:text-2xl group-hover:text-white group-hover:opacity-50 transition-colors duration-300 ${
          isActive ? 'text-white opacity-50' : 'opacity-20'
        }`}
      >
        {'0' + (index + 1)}
      </h6>
      <img
        src={image}
        alt={title}
        className="absolute top-[45%] w-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mix-blend-normal"
      />
      <p
        className={`w-full absolute top-[65%] text-center text-sm left-1/2 -translate-x-1/2 transition-colors duration-300 group-hover:text-white font-medium xl:text-base xl:top-[70%] 2xl:text-lg 2xl:top-[78%] ${
          isActive ? 'text-white' : 'text-darkGrey'
        }`}
      >
        {title}
      </p>
    </button>
  )
}
