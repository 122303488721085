import React from 'react'
import CEOImg from 'assets/sustainability/founders/CEO.png'
import CTOImg from 'assets/sustainability/founders/CTO.png'

import topRight from 'assets/sustainability/founders/top-right.svg'
import bottomLeft from 'assets/sustainability/founders/bottom-left.svg'

const foundersList = [
  {
    name: 'Amandeep Panwar',
    post: 'Co-founder and CEO',
    image: CEOImg,
    linkedin: 'https://www.linkedin.com/in/amandeeppanwar/',
    desc: [
      '“We are proud to be at the cusp of revolutionising the agri value chain. Our team is dedicated to supporting farmers with innovative approaches and solutions, on-ground and beyond, in the face of climate change.',
      'Thorough tracking and recordkeeping of sustainable farming practices facilitate end-to-end traceability. This accounts for a transparent and accountable food supply chain, backing consumer safety and trust.',
      'Join us in this transformative journey of building a super-resilient agri value chain with innovation, sustainability, and traceability hand in hand.”',
    ],
  },
  {
    name: 'Rishabh Choudhary',
    post: 'Co-founder and CTO',
    linkedin: 'https://www.linkedin.com/in/choudharyrish/',
    image: CTOImg,
    desc: [
      '“We aim to revitalise the agri value chain by revolutionising sustainable farming practices across the globe. Our work starts with empowering growers to efficiently combat pests, diseases, nutrient deficiency issues, etc. with our drone-based crop monitoring services and go beyond to provide our clients access to crystal-clear traceability data.',
      'With a robust value chain in place, we intend to inspire new generations to pursue farming and agricultural research sealing the far-reaching benefits of technology-driven agriculture. It ensures increased profitability, better planet health, and enhanced food security for humanity.”',
    ],
  },
]

const Founders = () => {
  return (
    <section className="bg-cream2 py-16 relative">
      <img
        src={topRight}
        alt="Background-element"
        className="hidden md:block h-40 w-auto absolute top-4 right-0 lg:h-52 2xl:h-80"
      />
      <img
        src={bottomLeft}
        alt="Background-element"
        className="hidden lg:block w-auto absolute bottom-4 left-0 lg:h-28 2xl:h-72"
      />

      <section className="w-container-2xl relative z-20">
        <div>
          <h2 className="font-semibold text-green text-center max-w-xs mx-auto lg:text-left lg:mx-0 lg:max-w-sm 2xl:max-w-lg">
            BharatRohan's take on{' '}
            <span className="text-yellow font-bold">sustainability</span>
          </h2>
        </div>

        <section className="lg:flex lg:flex-col gap-y-10 xl:gap-y-6 2xl:gap-y-0">
          {foundersList.map((founder, index) => {
            return (
              <FoundersLister
                key={index}
                linkedin={founder.linkedin}
                desc={founder.desc}
                image={founder.image}
                name={founder.name}
                post={founder.post}
                index={index}
              />
            )
          })}
        </section>
      </section>
    </section>
  )
}

export default Founders

function FoundersLister({
  name,
  post,
  image,
  desc,
  index,
  linkedin,
}: {
  linkedin: string
  name: string
  image: string
  post: string
  desc: string[]
  index: number
}) {
  return (
    <article
      className={`pt-16 lg:flex lg:gap-x-8 xl:gap-x-16 2xl:pt-20 2xl:pb-8 ${
        index % 2 !== 0 && 'lg:flex-row-reverse'
      }`}
    >
      <img
        src={image}
        alt={post}
        className="w-full max-w-[38rem] mx-auto lg:max-w-sm lg:max-h-96 lg:mx-0 2xl:max-w-fit 2xl:max-h-fit"
      />
      <div className="text-left">
        <div className="py-6 max-w-[39rem] mx-auto lg:pt-0 lg:mx-0 2xl:pb-8">
          <h4 className="font-semibold text-green text-[1.7rem] lg:-mt-1.5 2xl:text-3xl">
            {name}
          </h4>
          <h5 className="text-green font-medium md:font-semibold text-base lg:font-bold 2xl:text-lg">
            {post}
          </h5>
        </div>
        <div className="max-w-[39rem] lg:max-w-md xl:max-w-[38.5rem] flex flex-col gap-y-5 text-base mx-auto xl:text-lg xl:leading-8 2xl:text-[1.4rem] 2xl:font-medium 2xl:max-w-2xl 2xl:gap-y-8">
          {desc.map((item, index) => {
            return (
              <p key={index}>
                <i>{item}</i>
              </p>
            )
          })}
        </div>
        <div className="py-3  flex items-start">
          <a
            href={linkedin}
            target="_blank"
            className="fill-green  transition-colors duration-300 ease-in-out  outline-offset-4 hover:fill-yellow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x={0}
              y={0}
              viewBox="0 0 382 382"
              xmlSpace="preserve"
              className="h-7 w-7 fill-inherit"
            >
              <g>
                <path
                  d="M347.445 0H34.555C15.471 0 0 15.471 0 34.555v312.889C0 366.529 15.471 382 34.555 382h312.889C366.529 382 382 366.529 382 347.444V34.555C382 15.471 366.529 0 347.445 0zM118.207 329.844c0 5.554-4.502 10.056-10.056 10.056H65.345c-5.554 0-10.056-4.502-10.056-10.056V150.403c0-5.554 4.502-10.056 10.056-10.056h42.806c5.554 0 10.056 4.502 10.056 10.056v179.441zM86.748 123.432c-22.459 0-40.666-18.207-40.666-40.666S64.289 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.91 330.654a9.247 9.247 0 0 1-9.246 9.246H286.73a9.247 9.247 0 0 1-9.246-9.246v-84.168c0-12.556 3.683-55.021-32.813-55.021-28.309 0-34.051 29.066-35.204 42.11v97.079a9.246 9.246 0 0 1-9.246 9.246h-44.426a9.247 9.247 0 0 1-9.246-9.246V149.593a9.247 9.247 0 0 1 9.246-9.246h44.426a9.247 9.247 0 0 1 9.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.131 68.716 73.131 106.472v86.846z"
                  style={{}}
                  fill="inherit"
                  data-original="#0077b7"
                  className=""
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </article>
  )
}
