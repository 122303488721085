import React, { useEffect, useRef, useState } from 'react'
import img01 from 'assets/carbonFarming/strengths/OUTLINE.png'
// import img01 from 'assets/carbonFarming/strengths/OUTLINE.svg'
import img02 from 'assets/carbonFarming/strengths/02.svg'
import img03 from 'assets/carbonFarming/strengths/03.svg'
import img04 from 'assets/carbonFarming/strengths/04.svg'
import img05 from 'assets/carbonFarming/strengths/05.svg'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})
ScrollTrigger.config({ limitCallbacks: true })

const strengthsList = [
  {
    logo: img01,
    text: 'Strengthened ecological balance and food security.',
  },
  {
    logo: img02,
    text: 'Higher farmer profitability with enhanced soil fertility, health, and productivity.',
  },
  {
    logo: img03,
    text: 'Resilient food systems.',
  },
  {
    logo: img04,
    text: 'Agri input savings with mindful use of resources.',
  },
  {
    logo: img05,
    text: 'Reduced CO2 emissions from farms.',
    textJSX: (
      <>
        Reduced CO<sub>2</sub> emissions from farms.
      </>
    ),
  },
]

const Strengths = () => {
  const scrollParentRef = useRef<any>(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    function mouseMoveFunc(evt: any) {
      const maxX: any = gsap.getProperty('.strengths-scroll-cards', 'width')
      const scrollMaxX: any = gsap.getProperty(
        '.farming-strength-scrollable',
        'width',
      )
      const percent = gsap.utils.normalize(
        0,
        window.innerWidth,
        evt.pageX - (screenWidth < 1500 ? 250 : 300),
      )
      gsap.to('.strengths-scroll-cards', {
        duration: 0.4,
        x: -percent * maxX * (screenWidth < 1500 ? 0.8 : 0.6),
        overwrite: true,
      })
      gsap.to('.farming-strength-scrollbar', {
        duration: 0.4,
        x: percent < 0 ? 0 : (percent + 0.1) * scrollMaxX,
        overwrite: true,
      })
    }

    scrollParentRef?.current?.addEventListener('mousemove', mouseMoveFunc)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        scrollParentRef?.current?.removeEventListener(
          'mousemove',
          mouseMoveFunc,
        )
      }
    })
  }, [screenWidth])

  useEffect(() => {
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  function resizeListener() {
    setScreenWidth(window.innerWidth)
  }

  return (
    <section className="strengths-section bg-green py-16 bg-[url(assets/carbonFarming/strengths/bg.webp)] bg-cover">
      <section className="w-container-2xl">
        <div className="text-white text-center md:mx-0 md:text-left">
          <h2 className="font-medium max-w-md md:max-w-2xl 2xl:max-w-4xl mx-auto md:mx-0">
            <span className="text-yellow">Carbon farming</span> - A dependable
            climate crisis mitigation force
          </h2>
          <p className="text-base font-light max-w-md mx-auto md:mx-0 md:max-w-xl lg:max-w-xl md:text-lg pt-8 pb-12 2xl:text-xl 2xl:max-w-[37rem]">
            We are empowering farmers, the environment, and the agri value chain
            holistically with carbon farming amidst ruthless climate change. We
            promote sustainable farming practices and methods such as the
            Alternate Wetting and Drying System (AWD) for sequestering
            atmospheric carbon into the soil.
          </p>
        </div>

        {/* For phone */}
        <section className="benefit-swiper relative xl:hidden">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            speed={600}
            spaceBetween={20}
            className=""
            autoplay={{
              delay: 2000,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1.5,
                centeredSlides: true,
              },
              768: {
                slidesPerView: 1.8,
                spaceBetween: 60,
                centeredSlides: true,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 60,
                centeredSlides: true,
              },
            }}
          >
            {strengthsList.map((strength, index) => {
              return (
                <SwiperSlide key={index} className="pb-12">
                  <StrengthCard
                    index={index}
                    logo={strength.logo}
                    text={strength.text}
                    textJSX={strength.textJSX}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>

        {/* For desktop */}
        <section
          ref={scrollParentRef}
          className="farming-strength-scrollable hidden xl:block overflow-hidden md:mb-4 2xl:pt-8"
        >
          <div className="strengths-scroll-cards flex min-w-max gap-x-20">
            {strengthsList.map((strength, index) => {
              return (
                <StrengthCard
                  key={index}
                  index={index}
                  logo={strength.logo}
                  text={strength.text}
                  textJSX={strength.textJSX}
                />
              )
            })}
          </div>
        </section>
        <div className="farming-strength-scrollbar w-20 h-3 bg-white rounded-full hidden xl:block mt-8"></div>
      </section>
    </section>
  )
}

export default Strengths

function StrengthCard({
  index,
  logo,
  text,
  textJSX,
}: {
  index: number
  logo: string
  text: string
  textJSX: undefined | JSX.Element
}) {
  return (
    <article className="bg-white relative rounded-xl border-yellow border-2 p-6 pt-8 flex flex-col items-center justify-center md:justify-start h-72 md:h-[15rem] overflow-y-auto max-w-[360px] md:w-[360px] 2xl:h-[16rem] mx-auto">
      <img src={logo} alt={text} className="h-20 md:h-24 lg:h-20" />
      <h6 className="font-bold text-green text-opacity-70 text-2xl md:text-opacity-60 absolute top-3 left-5">
        0{index + 1}
      </h6>
      <p className="text-lg text-center font-medium max-w-[16rem] pt-8 md:pt-5 2xl:text-xl">
        {textJSX ? textJSX : text}
      </p>
    </article>
  )
}
