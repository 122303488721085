import React from 'react'
import Aside from './aside'
import ProductData from './Productdata'
import { IGeoData, IVariant, ITableHeading } from './types'
import cuminBanner from 'assets/product/cumin_banner.png'
import asideImage from 'assets/product/product-image-green.png'

export default function ProductDetails({
  asideImage,
  geoData,
  variantDetails,
  tableHeading,
  title,
}: {
  asideImage: string
  variantDetails: Array<IVariant>
  tableHeading: Array<ITableHeading>
  title: string
  geoData: IGeoData
}) {
  return (
    <div
      className={`bg-cream3 xl:flex relative min-h-screen md:h-full lg:flex lg:gap-x-5`}
    >
      <Aside productImage={asideImage} />
      <ProductData
        tableHeading={tableHeading}
        variantDetails={variantDetails}
        geoData={geoData}
        title={title}
      />
    </div>
  )
}
