import React from 'react'
import cropManagement from 'assets/cropAssure/management/management.png'
import logo01 from 'assets/cropAssure/management/logo01.svg'
import logo02 from 'assets/cropAssure/management/logo02.svg'
import logo03 from 'assets/cropAssure/management/logo03.svg'
import logo04 from 'assets/cropAssure/management/logo04.svg'
import bg from 'assets/cropAssure/management/bg.png'
import Management from 'organisms/management'

const cropManagementList = [
  {
    text: 'Proactive identification of threats with drone crop monitoring.',
    image: logo01,
  },
  {
    text: 'On-farm implementation assistance by Kisan Sehyogis (village-level entrepreneurs).',
    image: logo02,
  },
  {
    text: 'Generation of advisories based on drone survey.',
    image: logo03,
  },
  {
    text: 'Reduction of farming input efforts and costs.',
    image: logo04,
  },
]

const ManagementCrop = () => {
  return (
    <section className="bg-cream3 relative">
      <img
        src={bg}
        alt="plants"
        className="absolute bottom-3 right-0 hidden h-28 w-auto opacity-[15%] lg:h-32 2xl:h-40 md:block min-[1800px]:h-60"
      />
      <section className="py-16 px-4 w-container sm:w-container-sm md:w-container">
        <Management
          title={
            <span className="text-green">
              The <strong className="text-yellow">seed-to-shelf </strong> crop
              management solution every farmer needs
            </span>
          }
          para1={
            <>
              CropAssure® ensures residue-free and bountiful harvests for
              farmers and Farmer Produce Organisations (FPOs) by giving them
              control of the entire crop cycle. It introduces a{' '}
              <strong className="text-green">preventive </strong>
              outlook to agriculture as a data-backed Decision Support System
              (DSS).
            </>
          }
          para2={
            <>
              Farmers harvest the rewards of precision farming starting from
              soil-based cultivar selection in pre-sowing till post harvest
              market linkage.
            </>
          }
          image={cropManagement}
          list={cropManagementList}
          component="crop"
        />
      </section>
    </section>
  )
}

export default ManagementCrop
