import React, { createContext, useContext, useState, ReactNode } from 'react'

interface PopupContextType {
  isPoppedUp: boolean
  setIsPoppedUp: React.Dispatch<React.SetStateAction<boolean>>
}

const PopupContext = createContext<PopupContextType | undefined>(undefined)

interface PopupProviderProps {
  children: ReactNode
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [isPoppedUp, setIsPoppedUp] = useState<boolean>(false)

  return (
    <PopupContext.Provider value={{ isPoppedUp, setIsPoppedUp }}>
      {children}
    </PopupContext.Provider>
  )
}

// Custom hook to use the context
export const usePopup = (): PopupContextType => {
  const context = useContext(PopupContext)
  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider')
  }
  return context
}
