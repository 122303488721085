import Banner from 'organisms/banner'
import banner from 'assets/ipm/banner/banner.webp'

function IPMBanner() {
  return (
    <Banner
      highlighted="Assure"
      align="left"
      bgPosition="!object-[80%]"
      title={
        <span className="inline-block max-w-sm md:max-w-lg 2xl:max-w-3xl">
          Laying the foundation for a{' '}
          <span className="text-yellow">prosperous</span> and{' '}
          <span className="text-yellow">healthy</span> agri value chain
        </span>
      }
      desc={
        <span className="inline-block max-w-xs sm:max-w-[28rem] lg:max-w-[26rem] xl:max-w-lg 2xl:max-w-xl">
          Uplifting residue-free farming through integrated pest management
          (IPM) to help farmers grow better and consumers access eco-friendly
          produce.
        </span>
      }
      banner={banner}
    />
  )
}

export default IPMBanner
