import Banner, { BannerHighlightedText } from 'organisms/banner'
import banner from 'assets/cropAssure/banner/banner.webp'

function CropBanner() {
  return (
    <Banner
      highlighted="CropAssure banner"
      title={
        <span className="inline-block max-w-sm sm:max-w-lg md:max-w-2xl 2xl:max-w-3xl">
          <BannerHighlightedText highlighted="Assure" />
          -ing farmer success with drone-backed precision
        </span>
      }
      desc={
        <span className="inline-block max-w-xs sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg">
          Scripting a saga of prosperity for India's farmers with maximised
          yield and minimised inputs.
        </span>
      }
      banner={banner}
      align="left"
    />
  )
}

export default CropBanner
