import Banner from 'organisms/banner'
import banner from 'assets/blogsAndUpdates/banner/banner.jpg'

function CropBanner() {
  return (
    <Banner
      highlighted="Assure"
      // align="left"
      title={
        <span className="inline-block font-semibold ">
          A for <span className="text-yellow">Awareness</span>,{' '}
          <br className="md:hidden" /> A for{' '}
          <span className="text-yellow">Agriculture</span>
        </span>
      }
      desc={
        <span className="inline-block max-w-[18rem] sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg">
          Your go-to source for comprehensive updates and understandings from
          the field of agriculture.
        </span>
      }
      banner={banner}
    />
  )
}

export default CropBanner
