import Banner from 'organisms/banner'
import banner from 'assets/investors/banner.jpg'

function CropBanner() {
  return (
    <Banner
      highlighted="Assure"
      align="left"
      title={
        <span className="inline-block font-semibold max-w-[20.5rem] sm:max-w-xl lg:max-w-xl 2xl:max-w-2xl">
          <span className="text-yellow">Investor relations </span>- Sharing
          vision, realising dreams, fueling impact… together
        </span>
      }
      desc={
        <span className="inline-block max-w-[17rem] sm:max-w-[24rem] lg:max-w-[29rem] xl:max-w-lg">
          The centre stage for information, resources, and details pertaining to
          investment activities.
        </span>
      }
      banner={banner}
    />
  )
}

export default CropBanner
