import Banner from 'organisms/banner'
import banner from 'assets/carbonFarming/banner/banner.jpg'

function CropBanner() {
  return (
    <Banner
      highlighted="Assure"
      align="left"
      title={
        <span className="inline-block max-w-sm sm:max-w-lg md:max-w-2xl 2xl:max-w-3xl">
          Flipping the script for climate change-induced crop damage with carbon
          farming
        </span>
      }
      desc={
        <span className="inline-block max-w-xs sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg 2xl:max-w-xl">
          Employing sustainable agricultural practices to enhance soil health by
          storing atmospheric carbon in plants and soil to combat climate
          change.
        </span>
      }
      banner={banner}
    />
  )
}

export default CropBanner
