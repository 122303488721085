import React, { useLayoutEffect } from 'react'
import Banner from './banner'
// import SustainabilityCaseStudy from './caseStudy'
import Founders from './founders'
import Process from './process'

import './index.css'
import Goals from './goals'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

const Sustainability = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <main>
      <Banner />
      <Goals />
      <Process />
      <Founders />
      {/* <SustainabilityCaseStudy /> */}
    </main>
  )
}

export default Sustainability
