import React from 'react'
import banner from 'assets/technology/banner/banner.jpg'
import banner2 from 'assets/technology/banner/banner2.webp'
// import banner from 'assets/technology/banner/banner.jpg'
import Banner from 'organisms/banner'

const TechBanner = () => {
  return (
    <section className="relative bg-green ">
      <Banner
        highlighted=""
        align="left"
        title={
          <span className="inline-block tracking-normal max-w-sm sm:max-w-lg md:max-w-xl 2xl:max-w-xl">
            Tackling on-ground agricultural challenges with{' '}
            <span className="text-yellow">sky-high</span> tech
          </span>
        }
        desc={
          <span className="inline-block max-w-[21rem] sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg 2xl:max-w-xl">
            Adding all-round precision to farming practices, our airborne
            technology provides impetus to the first level of the agri value
            chain. And, impact stems thereon.
          </span>
        }
        banner={banner}
      />
      <div className=" bg-[url(assets/technology/banner/banner2.webp)] bg-cover bg-right lg:bg-bottom">
        <div className="w-container-lg py-32 lg:py-44 2xl:py-64 flex flex-col items-center lg:items-end lg:justify-center 2xl:justify-en">
          <div>
            <h1
              className={`text-appear-anim font-medium text-white px-3 leading-tight text-center text-[2rem] sm:text-3xl md:text-4xl md:pb-2 lg:text-left 2xl:text-5xl `}
            >
              <span className="inline-block max-w-sm sm:max-w-lg md:max-w-xl 2xl:max-w-[44rem]">
                <span className="text-yellow font-semibold">
                  Our technology
                </span>{' '}
                - Driven by innovation, characterised by solutions
              </span>
            </h1>
            <h3
              className={`text-appear-anim-delayed mx-auto lg:mx-0 px-3 text-[1rem] text-center sm:text-base text-white text-opacity-100 lg:text-opacity-90 pt-5 max-w-md lg:text-left lg:text-lg lg:max-w-lg xl:max-w-xl xl:text-xl 2xl:text-[1.35rem] 2xl:max-w-[38rem]`}
            >
              With a bird's eye view of farms, comprehensive tech solves
              long-standing farming challenges. It employs a mix of advanced
              hardware and software to give farmers control over uncertainties
              with precision agriculture.
            </h3>
          </div>
        </div>
      </div>

      {/* <div className="min-h-[70rem] lg:min-h-[160vh] bg-[url(assets/technology/banner/banner.jpg)] bg-cover bg-center">
        <div className="w-container-lg ">
          <div className="h-screen flex flex-col justify-center items-center lg:items-start">
            <h1
              className={`text-appear-anim text-white px-3 leading-tight text-center font-medium text-[2rem] sm:text-3xl md:text-4xl md:pb-2 lg:text-left 2xl:text-5xl `}
            >
              <span className="inline-block max-w-sm sm:max-w-lg md:max-w-xl 2xl:max-w-2xl">
                Tackling on-ground agricultural challenges with{' '}
                <span className="text-yellow">sky-high</span> tech
              </span>
            </h1>
            <h3
              className={`text-appear-anim-delayed px-3 text-[1rem] text-center sm:text-base text-white text-opacity-100 lg:text-opacity-90 pt-5 max-w-md lg:text-left lg:text-lg lg:max-w-lg xl:max-w-xl xl:text-xl 2xl:text-[1.35rem]`}
            >
              Adding all-round precision to farming practices, our airborne
              technology provides impetus to the first level of the agri value
              chain. And, impact stems thereon.
            </h3>
          </div>
          <div className="min-h-[35rem] flex flex-col items-center lg:items-end lg:justify-center 2xl:justify-en">
            <div>
              <h1
                className={`text-appear-anim text-white px-3 leading-tight text-center font-medium text-[2rem] sm:text-3xl md:text-4xl md:pb-2 lg:text-left 2xl:text-5xl `}
              >
                <span className="inline-block max-w-sm sm:max-w-lg md:max-w-xl 2xl:max-w-[44rem]">
                  <span className="text-yellow">Our technology</span> - Driven
                  by innovation, characterised by solutions
                </span>
              </h1>
              <h3
                className={`text-appear-anim-delayed px-3 text-[1rem] text-center sm:text-base text-white text-opacity-100 lg:text-opacity-90 pt-5 max-w-md lg:text-left lg:text-lg lg:max-w-lg xl:max-w-xl xl:text-xl 2xl:text-[1.35rem] 2xl:max-w-[38rem]`}
              >
                With a bird's eye view of farms, comprehensive tech solves
                long-standing farming challenges. It employs a mix of advanced
                hardware and software to give farmers control over uncertainties
                with precision agriculture.
              </h3>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  )
}

export default TechBanner
