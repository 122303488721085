import React, { useEffect, useState, useLayoutEffect } from 'react'
import CropBanner from './cropBanner'
import ManagementCrop from './managementCrop'
import Stats from './stats'
import CropGallery from './cropGallery'
import CropTestimonials from './cropTestimonials'
import CropCaseStudy from './cropCaseStudy'
import CropCTA from './cropCTA'
import CropProcess from './cropProcess'
import Pricing from './pricing'

// import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin'
import PopUpViewer from 'molecules/popUpViewer'

gsap.registerPlugin(ScrollToPlugin)

function CropAssure() {
  const [gsapRefresher, setGsapRefresher] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      setGsapRefresher(true)
    }, 100)
  }, [])

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (location?.hash) {
        gsap.to(window, {
          scrollTo: location?.hash,
          ease: 'Back.out',
        })
      } else {
        gsap.to(window, { scrollTo: 0 })
      }
    })

    return () => ctx.revert()
  })

  return (
    <main>
      {/* <PopUpViewer /> */}
      <CropBanner />
      <ManagementCrop />
      <Stats />
      <CropGallery />
      <CropProcess />
      <CropTestimonials />
      <Pricing />
      {/* <CropCaseStudy /> */}
      <CropCTA />
    </main>
  )
}

export default CropAssure
