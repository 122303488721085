import React, { useLayoutEffect } from 'react'
import Banner from './layout/banner'
import Details from './layout/details'
import banner from 'assets/product/coriander/coriander-bg.webp'
import aside from 'assets/product/coriander/coriander.webp'

// use types to ensure correct props
import { IGeoData } from './layout/types'
import OtherProducts from './layout/products'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const geoData: IGeoData = [
  {
    type: 'Country',
    value: 'India',
  },
  {
    type: 'State',
    value: 'Rajasthan',
  },
  {
    type: 'Region',
    value: 'Kota',
  },
]

const tableHeadings = [
  {
    title: 'Variant',
    classNames: 'w-12 text-left pl-2 lg:min-w-[9vw] xl:min-w-[12vw] xl:pl-7',
  },
  {
    title: 'Package Size',
    classNames: 'w-20 text-center lg:min-w-[9vw] xl:min-w-[10vw]',
  },
  {
    title: 'Moisture',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[6vw]',
  },
  {
    title: 'Purity',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[7vw]',
  },
  {
    title: 'Description',
    classNames: 'text-left',
  },
]

const variants = [
  {
    varient: 'dhaniya eagle plus',
    package: 'Custom',
    moisture: '8-10%',
    purity: '98-99%',
    desc: 'Regular green & yellow colour & mix colour, 1% max. impurity.',
  },
  {
    varient: 'dhaniya scooter',
    package: 'Custom',
    moisture: '8-10%',
    purity: '98-99%',
    desc: 'Medium green & yellow colour, 1% max. impurity & max. 7% split.',
  },
  // {
  //   varient: 'dhaniya katori',
  //   package: '25 Kg',
  //   moisture: '8%',
  //   purity: '98%',
  //   desc: 'Outer cover of dhaniya',
  // },
  // {
  //   varient: 'dhaniya fada single parrot',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Superior green colour , 1% max. impurity',
  // },
  // {
  //   varient: 'dhaniya fada scooter',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Medium green & yellow colour , 1% max. impurity',
  // },
  // {
  //   varient: 'dhaniya fada eagle plus',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Regular green & yellow colour & mix colour, 1% max. impurity',
  // },
  // {
  //   varient: 'dhaniya fada eagle',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '98%',
  //   desc: 'Regular green & yellow colour & mix colour, .2% max. impurity',
  // },
  // {
  //   varient: 'dhani double parrot',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Premium green colour, 1% max.impurity & max 7% split',
  // },
  // {
  //   varient: 'dhani single parrot',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Superior green colour , 1% max.impurity & max 7% split',
  // },
  // {
  //   varient: 'dhani scooter',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Medium green & yellow colour , 1% max. impurity & max 7% split',
  // },
  // {
  //   varient: 'dhaniya whole double parrot',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Premium green colour , 1% max.impurity & max 7% split',
  // },
  // {
  //   varient: 'dhaniya whole single parrot',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Superior green colour, 1% max.impurity & max 7% split',
  // },
  // {
  //   varient: 'dhaniya whole eagle plus sortex',
  //   package: '25 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Medium green & yellow colour , 1% max.impurity & max 7% split',
  // },
  // {
  //   varient: 'dhaniya whole scooter',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Medium green & yellow colour , 1% max. impurity & max 7% split',
  // },
  // {
  //   varient: 'dhaniya whole eagle plus',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Egular green & yellow colour & mix colour 1% max. impurity & max 7% split',
  // },
  // {
  //   varient: 'dhaniya whole eagle',
  //   package: '40 Kg',
  //   moisture: '8%',
  //   purity: '98%',
  //   desc: 'Average green & yellow colour & mix colour 2% max.impurity & max 8% split',
  // },
]

export default function Coriander() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])
  return (
    <div>
      <Banner
        bgImage={banner}
        title="Coriander (Whole)"
        desc="As a widely known speciality condiment from the Indian subcontinent, coriander seeds feature as a staple across cuisines – Indian, Latin American, and Middle Eastern. Sourced from the land of Rajasthan, our dried coriander seeds leave a distinct aroma and a strong flavour across preparations."
      />
      <Details
        tableHeading={tableHeadings}
        variantDetails={variants}
        asideImage={aside}
        title="Coriander (Whole)"
        geoData={geoData}
      />
      <OtherProducts />
    </div>
  )
}
