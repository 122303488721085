import React, { useRef, useState, useLayoutEffect } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import saas from 'assets/technology/tech/saas.jpg'
import uav from 'assets/technology/tech/uav.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const techList = [
  {
    title: 'Unmanned Aerial Vehicles (UAVs) / Drones',
    desc: 'Equipped with hyperspectral cameras, our UAVs gather on-farm images at different wavelengths. This forms the backbone of precision agriculture that helps in the early diagnosis of problems before they spin out of control.',
    image: uav,
  },
  {
    title: 'SaaS',
    desc: 'Diving headfirst into the collected images, our software systems take the baton to finish the job. They analyse images and create precision agriculture advisories for growers.',
    image: saas,
    list: [
      {
        title: 'UAV-based Hyperspectral Imaging Tech',
        desc: 'The collected images form a hyperspectral cube with different colour bands (120+). It records the smallest physiological and phenological changes in crops in every survey.',
      },
      {
        title: 'Crop-specific spectral vegetation indices',
        desc: 'These models create spectral libraries for every crop and undertake comparative analyses of the spectrum variations and data points. The variations enable a clear assessment of the crop health and intensity of pest and disease severity, water stress, nutrient deficiency, etc. over time.',
      },
      {
        title: 'Dashboard',
        desc: 'The entire cycle of drone surveys and corresponding prescription maps is recorded sequentially for every farm. It also includes the advisory implementation history of the growers. This database is accessible by sourcing partners using farm-specific QR codes for a traceable agri value chain.',
      },
    ],
  },
]

const Technologies = () => {
  const [currActiveIndex, setCurrActiveIndex] = useState(0)
  const nodeRef = useRef(null)

  function slideHandler() {
    setCurrActiveIndex((prevIndex) => {
      if (prevIndex === techList.length - 1) return 0
      return ++prevIndex
    })
  }

  function moveToPrev() {
    setCurrActiveIndex((prevIndex) => {
      if (prevIndex === 0) return techList.length - 1
      return --prevIndex
    })
  }

  useLayoutEffect(() => {
    const searchParam = window.location.search.replace('?tech=', '')
    if (searchParam === 'saas') setCurrActiveIndex(1)
    else setCurrActiveIndex(0)

    const ctx = gsap.context(() => {
      if (window.location.search.includes('tech')) {
        gsap.to(window, { scrollTo: '#technologies' })
      }
    })

    return () => ctx.revert()
  }, [window.location.search])

  return (
    <section className="bg-green" id="technologies">
      {/* For mobile */}
      <section className="lg:hidden flex flex-col gap-12 py-16 md:py-20 w-container text-center max-w-sm mx-auto sm:max-w-lg ">
        {techList.map((tech, index) => {
          return (
            <section key={index} className="">
              <h2 className="font-semibold lg:-mt-2 pb-4 text-white text-center text-[1.75rem] sm:text-3xl mx-auto leading-tight sm:max-w-md">
                {tech.title}
              </h2>
              <p className="text-white font-light text-opacity-80">
                {tech.desc}
              </p>
              {tech.list?.length && (
                <section className="technology-swiper">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    speed={600}
                    spaceBetween={20}
                    autoHeight
                    className="mb-3"
                  >
                    {tech.list?.map((tech, index) => {
                      return (
                        <SwiperSlide key={index} className="pt-12">
                          <article className="bg-cream text-green py-6 px-8 rounded-lg 2xl:px-12">
                            <h3 className="max-w-xs sm:max-w-non mx-auto pb-5 font-semibold">
                              {tech.title}
                            </h3>
                            <p className="font-light pb-3 max-w-sm mx-auto text-sm sm:text-base">
                              {tech.desc}
                            </p>
                          </article>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </section>
              )}
            </section>
          )
        })}
      </section>

      {/* For desktop */}
      <section className="hidden lg:block">
        <SwitchTransition>
          <CSSTransition
            key={currActiveIndex + 'image'}
            timeout={300}
            classNames={'fade-show'}
            nodeRef={nodeRef}
          >
            <div ref={nodeRef} className="relative">
              <img
                src={techList[currActiveIndex].image}
                alt={techList[currActiveIndex].title}
                className="min-h-[800px] h-full w-full object-cover object-right-top"
              />

              <div className="tech-title-clipped absolute top-0 left-0 bg-green p-8 xl:px-12 xl:py-16 2xl:py-20 2xl:px-16">
                <h2 className="font-medium lg:-mt-2 pb-4 text-white text-center text-3xl max-w-md mx-auto leading-tight lg:text-left lg:mx-0 xl:pb-6 xl:text-4xl 2xl:text-[3rem] xl:max-w-xl 2xl:max-w-2xl">
                  {techList[currActiveIndex].title}
                </h2>
                <p className="text-white font-light text-opacity-80 max-w-sm xl:text-lg xl:max-w-md 2xl:max-w-xl">
                  {techList[currActiveIndex].desc}
                </p>
              </div>

              <button
                onClick={slideHandler}
                className="tech-slider-btn absolute group top-1/2 right-10 p-3 xl:right-16 rounded-full bg-yellow hover:bg-green transition-all duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 24"
                  fill="none"
                  className="h-8 w-8 xl:h-10 xl:w-10 2xl:h-12 2xl:w-12"
                >
                  <path
                    d="M35.0372 13.0607C35.623 12.4749 35.623 11.5251 35.0372 10.9393L25.4913 1.3934C24.9055 0.807611 23.9557 0.807611 23.37 1.3934C22.7842 1.97919 22.7842 2.92893 23.37 3.51472L31.8552 12L23.37 20.4853C22.7842 21.0711 22.7842 22.0208 23.37 22.6066C23.9557 23.1924 24.9055 23.1924 25.4913 22.6066L35.0372 13.0607ZM0.289062 13.5H33.9766V10.5H0.289062V13.5Z"
                    fill="#193C34"
                    className="group-hover:fill-yellow transition-all duration-300"
                  />
                </svg>
              </button>
              <button
                onClick={moveToPrev}
                className="tech-slider-btn absolute group top-1/2 left-10 p-3 xl:left-16 rounded-full bg-yellow hover:bg-green transition-all duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 24"
                  fill="none"
                  className="h-8 w-8 xl:h-10 xl:w-10 2xl:h-12 2xl:w-12 rotate-180"
                >
                  <path
                    d="M35.0372 13.0607C35.623 12.4749 35.623 11.5251 35.0372 10.9393L25.4913 1.3934C24.9055 0.807611 23.9557 0.807611 23.37 1.3934C22.7842 1.97919 22.7842 2.92893 23.37 3.51472L31.8552 12L23.37 20.4853C22.7842 21.0711 22.7842 22.0208 23.37 22.6066C23.9557 23.1924 24.9055 23.1924 25.4913 22.6066L35.0372 13.0607ZM0.289062 13.5H33.9766V10.5H0.289062V13.5Z"
                    fill="#193C34"
                    className="group-hover:fill-yellow transition-all duration-300"
                  />
                </svg>
              </button>

              {techList[currActiveIndex].list?.length && (
                <div className="absolute bottom-0 w-full gap-x-8 grid grid-cols-3 xl:gap-x-16 2xl:gap-x-24 min-[1800px]:gap-x-32">
                  {techList[currActiveIndex]?.list?.map((item, index) => {
                    return (
                      <article
                        key={index}
                        className="bg-green text-white py-6 px-8 rounded-t-xl 2xl:px-12"
                      >
                        <h5 className="max-w-xs pb-5">{item.title}</h5>
                        <p className="font-light text-white text-opacity-80 text-sm xl:text-base">
                          {item.desc}
                        </p>
                      </article>
                    )
                  })}
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </section>
    </section>
  )
}

export default Technologies
