import Partition from 'molecules/partition'
import droneImg from 'assets/aboutUs/intent/droneimg.png'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect, useState } from 'react'
import Management from 'organisms/management'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

export default function Intent() {
  const [refreshGsap, setRefreshGsap] = useState(false)

  setTimeout(() => {
    setRefreshGsap(true)
  }, 100)

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.intent-article',
        {
          opacity: 0,
        },
        {
          duration: 1,
          opacity: 1,
          delay: 0.5,
          ease: 'back.out',
          scrollTrigger: {
            trigger: '.intent-article-parent',
            start: 'top 90%',
            toggleActions: 'restart none none reverse',
          },
        },
      )
    })

    return () => {
      ctx.revert()
    }
  }, [refreshGsap])

  return (
    <section className="bg-lightCream relative">
      <section className="py-16 px-4 w-container-lg pb-20">
        <Partition color="dark" text="The intent" />
        <div className="pb-8"></div>
        <Management
          title={
            <span className="text-green inline-block 2xl:max-w-lg">
              There's a lot to be done in very little time
            </span>
          }
          para1={
            <span className="inline-block 2xl:max-w-xl 2xl:text-xl">
              Deepening climate change, loss-heavy conventional farming, and
              flawed agri value chains threaten food security and profitability.
              Our intent finds shelter in value-driven change-making for crop
              production and management.
            </span>
          }
          para2={
            <span className="inline-block 2xl:max-w-xl 2xl:text-xl lg:pr-4">
              We collectivise, integrate, and augment conventional farming
              entirely with tech and data-based sustainable practices for better
              outcomes. The quest to revive conventional farming inside out for
              growers, seed developers, and procurers continues.
            </span>
          }
          image={droneImg}
          // component="seed"
          hideList
          extras={
            <div className="intent-article-parent flex justify-evenly lg:justify-between pt-8 lg:max-w-2xl xl:max-w-xl 2xl:max-w-3xl ">
              <h2 className="intent-article text-green text-center font-semibold lg:text-left">
                50,000
                <div className="text-black text-lg font-medium max-w-[8rem] lg:max-w-fit xl:text-xl 2xl:text-[1.4rem]">
                  heads-strong farmer base
                </div>
              </h2>
              <h2 className="intent-article text-green text-center font-semibold lg:text-left xl:mr-12 2xl:mr-32">
                2,00,000
                <div className="text-black text-lg font-medium max-w-[8rem] lg:max-w-fit xl:text-xl 2xl:text-[1.4rem]">
                  acres of farm coverage
                </div>
              </h2>
            </div>
          }
        />
      </section>
    </section>
  )
}
