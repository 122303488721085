import Banner, { BannerHighlightedText } from 'organisms/banner'
import banner from 'assets/seedAssure/banner.webp'

function SeedBanner() {
  return (
    <Banner
      highlighted="SeedAssure banner"
      title={
        <span className="inline-block max-w-sm sm:max-w-xl md:max-w-3xl 2xl:max-w-4xl">
          <BannerHighlightedText highlighted="Assure" />
          -ing resilience in hybrid yield trials for enhanced food security with
          drone-backed precision
        </span>
      }
      desc={
        <span className="inline-block max-w-xs sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg 2xl:max-w-xl">
          Paving the way for streamlined hybridisation evaluation with reliable
          and efficient plot-level data accuracy for the longer run in the agri
          value chain.
        </span>
      }
      banner={banner}
      align="left"
    />
  )
}

export default SeedBanner
