// import processMain from 'assets/cropAssure/process/process-main.svg'

import Process, { ProcessList } from 'organisms/process'

import image01 from 'assets/cropAssure/process/image01.png'
import image02 from 'assets/cropAssure/process/image02.png'
import image03 from 'assets/cropAssure/process/image03.png'
import image04 from 'assets/cropAssure/process/image04.png'
import image05 from 'assets/cropAssure/process/image05.png'

const processList: ProcessList[] = [
  {
    desc: (
      <>
        <strong className="text-[#22574D] lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Drone Pilot
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          collects the data
        </span>
      </>
    ),
    image: image01,
    navText: 'Drone pilot',
  },
  {
    desc: (
      <>
        <strong className="text-[#22574D] lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Remote Sensing Engineer <br />
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          processes it
        </span>
      </>
    ),
    image: image02,
    navText: 'Remote sensing',
  },
  {
    desc: (
      <>
        <strong className="text-[#22574D] lg:text-[1.8rem] 2xl:text-[2.5rem]">
        Agronomist
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          generates the advisory
        </span>
      </>
    ),
    image: image03,
    navText: 'Agronomist',
  },
  {
    desc: (
      <>
        <strong className="text-[#22574D] lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Advisory is received by the farmer
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          on WhatsApp
        </span>
      </>
    ),
    image: image04,
    navText: 'Advisory',
  },
  {
    desc: (
      <>
        <strong className="text-[#22574D] lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Implementation <br />
        </strong>
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          by the farmer with assistance from the{' '}
          <br className="sm:block hidden" />
        </span>{' '}
        <strong className="text-[#22574D] lg:text-[1.8rem] 2xl:text-[2.5rem]">
          Kisan Sehyogi
        </strong>{' '}
        <span className="opacity-70 lg:opacity-100 text-darkGrey lg:text-[1.1rem] lg:font-semibold 2xl:text-2xl lg:pl-0">
          (ground staff).
        </span>
      </>
    ),

    image: image05,
    navText: 'Implementation',
  },
]

const CropProcess = () => {
  return (
    <Process
      partition="How it happens"
      title="Unleashing the true potential of drone-based hyperspectral imagery"
      processList={processList}
    />
  )
}

export default CropProcess
