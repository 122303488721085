import React, { useEffect, useRef, useState } from 'react'

import leftS1 from 'assets/sustainability/goals/slide1/left.webp'
import rightS1 from 'assets/sustainability/goals/slide1/right.webp'
import goalS1 from 'assets/sustainability/goals/slide1/goal.webp'
import leftS2 from 'assets/sustainability/goals/slide2/left.webp'
import rightS2 from 'assets/sustainability/goals/slide2/right.webp'
import goalS2 from 'assets/sustainability/goals/slide2/goal.webp'
import leftS3 from 'assets/sustainability/goals/slide3/left.webp'
import rightS3 from 'assets/sustainability/goals/slide3/right.webp'
import goalS3 from 'assets/sustainability/goals/slide3/goal.webp'
import leftS4 from 'assets/sustainability/goals/slide4/left.webp'
import rightS4 from 'assets/sustainability/goals/slide4/right.webp'
import goalS4 from 'assets/sustainability/goals/slide4/goal.webp'
import leftS5 from 'assets/sustainability/goals/slide5/left.webp'
import rightS5 from 'assets/sustainability/goals/slide5/right.webp'
import goalS5 from 'assets/sustainability/goals/slide5/goal.webp'
import leftS6 from 'assets/sustainability/goals/slide6/left.webp'
import rightS6 from 'assets/sustainability/goals/slide6/right.webp'
import goalS6 from 'assets/sustainability/goals/slide6/goal.webp'
import leftS7 from 'assets/sustainability/goals/slide7/left.webp'
import rightS7 from 'assets/sustainability/goals/slide7/right.webp'
import goalS7 from 'assets/sustainability/goals/slide7/goal.webp'
import leftS8 from 'assets/sustainability/goals/slide8/left.webp'
import rightS8 from 'assets/sustainability/goals/slide8/right.webp'
import goalS8 from 'assets/sustainability/goals/slide8/goal.webp'
import leftS9 from 'assets/sustainability/goals/slide9/left.webp'
import rightS9 from 'assets/sustainability/goals/slide9/right.webp'
import goalS9 from 'assets/sustainability/goals/slide9/goal.webp'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

const goalsList = [
  {
    goal: goalS1,
    left: leftS1,
    right: rightS1,
    color: '#EF353C',
    alt: 'Goal 1: No poverty',
  },
  {
    goal: goalS2,
    left: leftS2,
    right: rightS2,
    color: '#DAA23D',
    alt: 'Goal 2: Zero hunger',
  },
  {
    goal: goalS3,
    left: leftS3,
    right: rightS3,
    color: '#479E54',
    alt: 'Goal 3: Good health and well-being',
  },
  {
    goal: goalS4,
    left: leftS4,
    right: rightS4,
    color: '#943245',
    alt: 'Goal 8: Decent work and economic growth',
  },
  {
    goal: goalS5,
    left: leftS5,
    right: rightS5,
    color: '#CD8C2E',
    alt: 'Goal 12: Responsible consumption and production',
  },
  {
    goal: goalS6,
    left: leftS6,
    right: rightS6,
    color: '#4E7A47',
    alt: 'Goal 13: Climate action',
  },
  {
    goal: goalS7,
    left: leftS7,
    right: rightS7,
    color: '#007CBB',
    alt: 'Goal 14: Life below water',
  },
  {
    goal: goalS8,
    left: leftS8,
    right: rightS8,
    color: '#3DAE4A',
    alt: 'Goal 15: Life on land',
  },
  {
    goal: goalS9,
    left: leftS9,
    right: rightS9,
    color: '#28426E',
    alt: 'Goal 17: Partnership for goals',
  },
]

const Goals = () => {
  const [currActiveIndex, setCurrActiveIndex] = useState<number>(0)
  const swiperRef = useRef<any>(null)

  return (
    <section className="">
      {/* For desktop */}
      <section className="hidden lg:block relative max-h-screen">
        <Swiper
          className="relative max-h-screen"
          direction="vertical"
          // autoHeight
          speed={700}
          navigation={{
            nextEl: '.goals-sus-next-btn',
            prevEl: '.goals-sus-prev-btn',
          }}
          onSlideChange={(swiper) => {
            setCurrActiveIndex(() => swiper.activeIndex)
          }}
          modules={[Navigation, Pagination]}
          initialSlide={0}
          slidesPerView={1}
        >
          {goalsList.map((goal, index) => {
            return (
              <SwiperSlide key={index} className="">
                <GoalLister
                  key={index}
                  goal={goal.goal}
                  alt={goal.alt}
                  color={goal.color}
                  left={goal.left}
                  right={goal.right}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        {/* Swiper control buttons */}
        <div className="absolute px-2.5 py-3.5 xl:px-3 xl:py-4 backdrop-blur-sm bg-dark bg-opacity-40 border-2 border-white top-1/2 -translate-y-1/2 right-12 text-white z-10 flex flex-col rounded-full overflow-hidden">
          <button
            className={`goals-sus-prev-btn p-3 rounded-full transition-all duration-300
            ${
              currActiveIndex === 0
                ? 'bg-transparent cursor-not-allowed'
                : 'bg-yellow hover:bg-green active:bg-[#22772693]'
            }
          `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 24"
              fill="none"
              className="rotate-180  h-4 xl:h-6"
            >
              <path
                d="M9.61627 1.87329C9.61627 1.04058 10.2913 0.365537 11.124 0.365537C11.9567 0.365537 12.6318 1.04058 12.6318 1.87329L9.61627 1.87329ZM12.1902 23.4412C11.6014 24.03 10.6467 24.03 10.0579 23.4412L0.462592 13.8459C-0.126223 13.2571 -0.126223 12.3024 0.462592 11.7136C1.05141 11.1248 2.00606 11.1248 2.59488 11.7136L11.124 20.2427L19.6532 11.7136C20.242 11.1248 21.1966 11.1248 21.7855 11.7136C22.3743 12.3024 22.3743 13.2571 21.7855 13.8459L12.1902 23.4412ZM12.6318 1.87329L12.6318 22.375L9.61627 22.375L9.61627 1.87329L12.6318 1.87329Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="py-5 flex flex-col justify-center items-center text-lg xl:text-xl font-medium">
            <span className="text-yellow">0{currActiveIndex + 1}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3"
              height="14"
              viewBox="0 0 3 14"
              fill="none"
              className="my-1.5"
            >
              <path
                d="M1.5 1.44922L1.5 12.5504"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <span className="text-white text-opacity-60">
              0{goalsList.length}
            </span>
          </div>
          <button
            className={`goals-sus-next-btn p-3 rounded-full transition-all duration-300
            ${
              currActiveIndex === goalsList.length - 1
                ? 'bg-transparent cursor-not-allowed'
                : 'bg-yellow hover:bg-green active:bg-[#22772693]'
            }
          `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 24"
              fill="none"
              className=" h-4 xl:h-6"
            >
              <path
                d="M9.61627 1.87329C9.61627 1.04058 10.2913 0.365537 11.124 0.365537C11.9567 0.365537 12.6318 1.04058 12.6318 1.87329L9.61627 1.87329ZM12.1902 23.4412C11.6014 24.03 10.6467 24.03 10.0579 23.4412L0.462592 13.8459C-0.126223 13.2571 -0.126223 12.3024 0.462592 11.7136C1.05141 11.1248 2.00606 11.1248 2.59488 11.7136L11.124 20.2427L19.6532 11.7136C20.242 11.1248 21.1966 11.1248 21.7855 11.7136C22.3743 12.3024 22.3743 13.2571 21.7855 13.8459L12.1902 23.4412ZM12.6318 1.87329L12.6318 22.375L9.61627 22.375L9.61627 1.87329L12.6318 1.87329Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </section>

      {/* For Mobile */}
      <section className="lg:hidden goals py-16 md:py-20 bg-cream">
        <section className="w-container max-w-[27rem] md:max-w-lg mx-auto">
          <h2 className="font-semibold text-[1.75rem] md:text-3xl text-center">
            SDG goals and us - Going the whole nine yards
          </h2>
          <p className="font-medium mt-2 text-center py-5">
            United by a shared vision of a healthier planet and a brighter
            future for humanity, our partners are playing a pivotal role in
            advancing us towards the UN's Sustainable Development Goals (SDGs).
            From championing sustainable farming and effective crop protection
            management to ensuring transparency in the supply chain, we are
            steering agriculture towards a greener tomorrow together.
          </p>
          <Swiper
            className="relative max-h-screen py-6"
            speed={700}
            navigation={{
              nextEl: '.goal-next-btn',
              prevEl: '.goal-prev-btn',
            }}
            onSlideChange={(swiper) => {
              setCurrActiveIndex(swiper.activeIndex % goalsList.length)
            }}
            modules={[Navigation, Pagination]}
            initialSlide={0}
            slidesPerView={1}
            pagination={true}
            spaceBetween={20}
          >
            {goalsList.map((goal, index) => {
              return (
                <SwiperSlide key={index} className="">
                  <div className="relative border rounded-xl overflow-hidden mb-6">
                    <img
                      src={goal.right}
                      alt={goal.alt}
                      className="h-full w-full object-cover"
                    />
                    <img
                      src={goal.goal}
                      alt={goal.alt}
                      className="absolute bottom-0 left-0 h-auto w-40 xl:48 2xl:w-60"
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>
      </section>
    </section>
  )
}

export default Goals

function GoalLister({
  left,
  right,
  goal,
  color,
  alt,
}: {
  left: string
  right: string
  goal: string
  color: string
  alt: string
}) {
  return (
    <section className="grid grid-cols-[45%_55%] h-screen w-full relative">
      <div className="grid grid-rows-[47%_53%] place-content-center h-screen">
        <div
          className="py-6 px-4 xl:py-6 xl:px-16 2xl:px-28 2xl:py-8"
          style={{ background: color }}
        >
          <h2 className="font-medium text-white text-2xl xl:text-3xl 2xl:text-4xl">
            SDG goals and us - Going the whole nine yards
          </h2>
          <p
            className={`2xl:font-medium text-white text-sm mt-2 xl:mt-4 2xl:text-lg
            ${window.innerHeight < 700 ? 'xl:text-sm' : 'xl:text-[1.05rem]'}
          `}
          >
            United by a shared vision of a healthier planet and a brighter
            future for humanity, our partners are playing a pivotal role in
            advancing us towards the UN's Sustainable Development Goals (SDGs).
            From championing sustainable farming and effective crop protection
            management to ensuring transparency in the supply chain, we are
            steering agriculture towards a greener tomorrow together.
          </p>
        </div>
        <div className="">
          <img src={left} alt={alt} className="h-full w-full object-cover" />
          <img
            src={goal}
            alt={alt}
            className="absolute bottom-0 left-0 h-auto w-40 xl:48 2xl:w-60"
          />
        </div>
      </div>
      <div className="">
        <img src={right} alt={alt} className="max-h-screen h-full w-full" />
      </div>
    </section>
  )
}
