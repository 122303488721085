import React, { useState } from 'react'
// import productImage from 'assets/product/product-image-green.png'
import { IGeoData, IVariant, ITableHeading } from './types'
import { useNavigate } from 'react-router-dom'

const canceldetails = [
  'Orders cannot be cancelled after confirmation and successful payment. Customers are not eligible for a refund.',
  <>
    We always stand for quality. Please reach out to us directly at{' '}
    <a
      href="mailto:sales@bharatrohan.in"
      aria-label="sales@bharatrohan.in"
      className="border-b border-green border-solid font-semibold "
    >
      sales@bharatrohan.in
    </a>{' '}
    in case of any quality issues. We will be glad to assist you and resolve the
    issue.
  </>,
  <>
    For any cancellation-related queries, please write to us at{' '}
    <a
      href="mailto:info@bharatrohan.in"
      aria-label="info@bharatrohan.in"
      className="border-b border-green border-solid font-semibold"
    >
      info@bharatrohan.in
    </a>
    .
  </>,
]

const shippingdetails = [
  'All orders are subject to availability and confirmation of order price. We inform our customers by SMS or e-mail on order confirmation.',
  'Orders are shipped in 7 business days after confirmation. The shipping address can not be altered after the order is shipped.',
  <>
    For any shipping-related questions and queries, please contact us at{' '}
    <a
      href="mailto:info@bharatrohan.in"
      className="border-b border-green border-solid font-semibold"
    >
      info@bharatrohan.in
    </a>
    .
  </>,
]

export default function Details({
  geoData,
  title,
  variantDetails,
  tableHeading,
}: {
  title: string
  geoData: IGeoData
  variantDetails: Array<IVariant>
  tableHeading: Array<ITableHeading>
}) {
  const [activeSection, setActiveSection] = useState<number>(0)
  const navigate = useNavigate()
  const handleSectionClick = (section: number) => {
    setActiveSection(section)
  }

  return (
    <section className="w-container w-full overflow-x-hidden md:w-container lg:w-[65vw] lg:pr-24 2xl:w-[62vw] 2xl:w-container-2xl 2xl:pr-32">
      <section className="blade-top-margin">
        <h2 className="font-semibold text-3xl text-green xl:text-4xl 2xl:text-[3rem]">
          {title}
        </h2>
      </section>
      <div className="w-full h-[2px] bg-[#0000004D] blade-top-margin blade-bottom-margin lg:mt-[25px] lg:mb-[25px] xl:mt-[30px] xl:mb-[30px]"></div>
      <section>
        <h5 className="text-green text-2xl font-medium md:text-3xl">Origin</h5>
        <div className="flex items-center flex-wrap gap-3 justify-between mt-4 xl:justify-start xl:gap-[104px]">
          {geoData.map((dets, idx) => {
            return (
              <p
                key={idx}
                className="font-medium flex text-md md:text-[18px] xl:text-xl"
              >
                <span className="opacity-70">{dets.type}:</span> {dets.value}
              </p>
            )
          })}
        </div>
      </section>
      <div className="w-full h-[2px] bg-[#0000004D] blade-top-margin blade-bottom-margin lg:mt-[25px] lg:mb-[25px] xl:mt-[30px]"></div>
      <button
        onClick={() => {
          navigate('/contact-us')
        }}
        className="outline-lime-400 outline-offset-4 blade-bottom-margin-sm text-lg text-white py-2.5 px-16 rounded-3xl font-semibold bg-green hover:bg-yellow hover:text-green transition duration-300 ease-in-out w-full md:py-[10px] md:px-[40px] md:w-[204px] md:text-lg xl:mb-0 2xl:mb-0"
      >
        Buy now
      </button>
      <section className="">
        <section className="navigator z-10">
          <section className="mb-8 hidden xl:flex bg-cream3 items-start gap-x-16 2xl:gap-x-20 text-center z-40 pt-16 lg:pt-8 xl:pt-12 xl:pb-[0px] xl:border-b-[1px] border-[#0000004D]">
            <button
              // tabIndex={0}
              onClick={() => handleSectionClick(0)}
              className={`outline-lime-400 outline-offset-4 text-sm font-medium cursor-pointer transition-border duration-400 lg:text-lg xl:text-[1.3rem] pb-[10px] 2xl:text-2xl  ${
                activeSection === 0
                  ? 'border-b-1 border-black'
                  : 'border-none opacity-40'
              }`}
            >
              VARIANT DETAILS
            </button>
            <button
              // tabIndex={0}
              onClick={() => handleSectionClick(1)}
              className={`outline-lime-400 outline-offset-4 pb-[10px] text-sm font-medium cursor-pointer transition-border duration-400 lg:text-lg xl:text-[1.3rem] 2xl:text-2xl  ${
                activeSection === 1
                  ? 'border-b-1 border-black'
                  : 'border-none opacity-40'
              }`}
            >
              CANCELLATION & REFUND
            </button>
            <button
              // tabIndex={0}
              onClick={() => handleSectionClick(2)}
              className={`outline-lime-400 outline-offset-4 pb-[10px] text-sm font-medium cursor-pointer transition-border duration-400 lg:text-lg xl:text-[1.3rem] 2xl:text-2xl  ${
                activeSection === 2
                  ? 'border-b-1 border-black'
                  : 'border-none opacity-40'
              }`}
            >
              SHIPPING POLICY
            </button>
          </section>
        </section>

        <div className="relative">
          {/* Varient Details Box */}
          <div id="varient" className={`tab`}>
            {/* desktop view table */}
            <h6 className="font-medium xl:hidden text-2xl mt-10 text-green md:text-[30px] md:blade-top-margin-sm xl:text-xl 2xl:text-2xl mb-3 lg:mb-5">
              VARIANT DETAILS
            </h6>
            <table
              className={`hidden w-full lg:block transition-opacity duration-500 blade-bottom-margin ${
                activeSection === 0 ? 'xl:opacity-100 z-50' : 'xl:opacity-0'
              }`}
            >
              <thead>
                <tr className="bg-lightCream h-[50px] table-container">
                  {tableHeading.map((dets, idx) => {
                    return (
                      <th
                        key={idx}
                        className={`text-[12px] font-semibold lg:text-base xl:text-[18px] 2xl:text-[1.1rem] ${dets.classNames}`}
                      >
                        {dets.title}
                      </th>
                    )
                  })}
                </tr>
              </thead>

              <tbody>
                {variantDetails.map((dets, i) => {
                  return (
                    <tr className="even:bg-lightCream" key={i}>
                      <td className="text-[12px] py-3 pl-2 lg:text-base xl:text-[14px] xl:py-4 xl:font-medium xl:pl-7 2xl:text-[1.1rem] capitalize">
                        {dets.varient}
                      </td>
                      <td className="text-[12px] py-3 lg:text-base xl:text-[14px] text-center xl:font-medium xl:py-4 2xl:text-[1.1rem]">
                        {dets.package}
                      </td>
                      <td className="text-[12px] py-3 lg:text-base xl:text-[14px] text-center xl:font-medium xl:py-4 2xl:text-[1.1rem]">
                        {dets.moisture}
                      </td>
                      <td className="text-[12px] py-3 lg:text-base xl:text-[14px] text-center xl:font-medium xl:py-4 2xl:text-[1.1rem]">
                        {dets.purity}
                      </td>
                      <td className="text-[12px] py-3 lg:text-base xl:text-[14px] xl:font-medium xl:py-4 xl:pr-5 2xl:text-[1.1rem]">
                        {dets.desc}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {/* mobile view table */}
            <table className="lg:hidden">
              <tbody>
                {variantDetails.map((dets, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="w-[50vw] align-top py-3">
                        <p className="font-semibold text-lg leading-5 mb-3 md:text-xl xl:text-2xl capitalize">
                          {dets.varient}
                        </p>
                        <p className="text-base">
                          <span className="font-normal">Package Size -</span>{' '}
                          {dets.package}
                        </p>
                        <p className="text-base">
                          <span className="font-normal">Moisture -</span>{' '}
                          {dets.moisture}
                        </p>
                        <p className="text-base">
                          <span className="font-normal">Purity -</span>{' '}
                          {dets.purity}
                        </p>
                      </td>
                      <td className="w-[50vw] align-top py-3">
                        <p className="font-semibold text-lg leading-5 mb-3 md:text-xl xl:text-2xl">
                          Description
                        </p>
                        <p className="text-base">{dets.desc}</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {/* Cancel Details Box */}
          <div
            id="cancel"
            className={`blade-top-margin flex flex-col 2xl:w-full gap-3 xl:mt-0 xl:absolute xl:top-0 transition-opacity duration-500 ${
              activeSection === 1 ? 'xl:opacity-100 z-50' : 'xl:opacity-0'
            }`}
          >
            <h6 className="mb-1 xl:hidden font-medium text-2xl text-green md:text-[30px] xl:text-xl 2xl:text-2xl">
              CANCELLATION & REFUND
            </h6>
            {canceldetails.map((dets, index) => {
              return (
                <div
                  key={index}
                  className="flex text-xl gap-2 font-medium md:text-xl 2xl:text-[22px] "
                >
                  <small className="">{index + 1}.</small>
                  <small>{dets}</small>
                </div>
              )
            })}
          </div>

          {/* Shipping Details Box */}
          <div
            id="shipping"
            className={`blade-bottom-margin blade-top-margin-lg blade-bottom-padding-lg flex 2xl:w-full flex-col gap-3 xl:absolute xl:top-0 xl:mt-0 transition-opacity duration-500 ${
              activeSection === 2 ? 'xl:opacity-100 z-50' : 'xl:opacity-0'
            }`}
          >
            <h6 className="mb-1 font-medium text-2xl text-green md:text-[30px] mdblade-top-margin-sm xl:text-xl 2xl:text-2xl xl:hidden">
              SHIPPING POLICY
            </h6>
            {shippingdetails.map((dets, index) => {
              return (
                <div
                  key={index}
                  className="flex text-xl gap-2 font-medium md:text-xl 2xl:text-[22px]"
                >
                  <small>{index + 1}.</small>
                  <small>{dets}</small>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </section>
  )
}
