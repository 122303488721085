import Banner, { BannerHighlightedText } from 'organisms/banner'
import banner from 'assets/sustainability/banner/banner.jpg'

function CropBanner() {
  return (
    <Banner
      highlighted="Assure"
      align="left"
      title={
        <span className="inline-block font-semibold max-w-[20.5rem] sm:max-w-xl lg:max-w-2xl 2xl:max-w-4xl">
          Catalysing the push for a{' '}
          <span className="text-yellow">sustainable</span> world with
          drone-backed <br className="hidden lg:block" /> precision agriculture
        </span>
      }
      desc={
        <span className="inline-block max-w-[17rem] sm:max-w-[24rem] lg:max-w-[26rem] xl:max-w-lg">
          Aligning agriculture and Sustainable Development Goals (SDG) to
          elevate farmers, mankind, and the environment collectively.
        </span>
      }
      banner={banner}
    />
  )
}

export default CropBanner
