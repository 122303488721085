import React, { ReactNode, useEffect, useState } from 'react'
import bottomElement from 'assets/investors/bottom-element.png'
import { Link } from 'react-router-dom'
import TabPanel from './tabPanelUI'

export default function Updates({
  current,
  view,
}: {
  view: ReactNode
  current: string
}) {
  const [active, setActive] = useState<string | undefined>('board')

  return (
    <div className="py-16 md:py-20 bg-cream3 relative bg-no-repeat">
      <div className="w-container max-w-6xl 2xl:max-w-[90rem] z-10 relative">
        <div className="pb-4 pt-7 lg:border-b-1 lg:border-solid lg:border-[#D9D9D9]">
          <h2 className="font-semibold text-green uppercase leading-none whitespace-nowrap">
            Updates
          </h2>
        </div>

        <TabPanel />
      </div>

      <div className="-z-[0] absolute bottom-16 md:bottom-20">
        <img
          src={bottomElement}
          alt="Background element"
          className=" w-full "
        />
      </div>
    </div>
  )
}

type IViewOptions = {
  text: string
  target: string
  current: string
  index?: string
  active: string | undefined
  sm?: boolean
  setActive: React.Dispatch<React.SetStateAction<string | undefined>>
}
function ViewOption({
  text,
  target,
  current,
  index,
  active,
  setActive,
  sm,
}: IViewOptions) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <li className="py-3 px-0 ml-0  w-container-lg last:border-none lg:pl-3  block w-full">
      <button
        onClick={() => setActive(target)}
        className={`${
          active === target ? 'text-yellow' : 'text-green'
        } font-semibold w-full lg:text-lg text-3xl block`}
      >
        {sm ? (
          <h6 className="lg:max-w-[90%] text-base text-left border">{text}</h6>
        ) : (
          <h5
            className={`flex gap-2 relative  w-full  ${
              target === active ? 'items-center' : 'items-start'
            }`}
          >
            <span className="">{index}.</span>
            <span className="lg:max-w-[90%] ">{text}</span>
            <svg
              className={`${
                active === target
                  ? 'rotate-90 stroke-yellow fill-yellow'
                  : 'stroke-none fill-black'
              } absolute top-0 bottom-0 my-auto right-5`}
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="16"
              viewBox="0 0 10 16"
            >
              <path d="M8.6541 6.71154L2.34915 0.406583C2.23158 0.28901 2.092 0.195746 1.93838 0.132116C1.78477 0.0684864 1.62012 0.0357361 1.45385 0.0357361C1.11805 0.0357361 0.795995 0.169134 0.558546 0.406583C0.440973 0.524156 0.347709 0.663735 0.284079 0.817352C0.220449 0.970968 0.187699 1.13561 0.187699 1.30189C0.187699 1.63769 0.321097 1.95974 0.558546 2.19719L5.9808 7.60684L0.558546 13.0165C0.440355 13.1337 0.346545 13.2732 0.282526 13.4268C0.218507 13.5805 0.185547 13.7453 0.185547 13.9118C0.185547 14.0783 0.218507 14.2431 0.282526 14.3967C0.346545 14.5504 0.440355 14.6899 0.558546 14.8071C0.675771 14.9253 0.815238 15.0191 0.968901 15.0831C1.12256 15.1471 1.28738 15.1801 1.45385 15.1801C1.62031 15.1801 1.78513 15.1471 1.9388 15.0831C2.09246 15.0191 2.23193 14.9253 2.34915 14.8071L8.6541 8.50214C8.77229 8.38492 8.86611 8.24545 8.93013 8.09179C8.99414 7.93812 9.0271 7.7733 9.0271 7.60684C9.0271 7.44037 8.99414 7.27555 8.93013 7.12189C8.86611 6.96823 8.77229 6.82876 8.6541 6.71154Z" />
            </svg>
          </h5>
        )}
      </button>
    </li>
  )
}
