import React, { useLayoutEffect } from 'react'
import Banner from './layout/banner'
import Details from './layout/details'
import cuminBanner from 'assets/product/cumin/cumin-bg.webp'
import asideImage from 'assets/product/cumin/cumin.webp'
import { IGeoData, IVariant, ITableHeading } from './layout/types'
import OtherProducts from './layout/products'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
gsap.registerPlugin(ScrollToPlugin)

const geoData: IGeoData = [
  {
    type: 'Country',
    value: 'India',
  },
  {
    type: 'State',
    value: 'Rajasthan',
  },
  {
    type: 'Region',
    value: 'Pali',
  },
]

const tableHeadings: Array<ITableHeading> = [
  {
    title: 'Variant',
    classNames: 'w-12 text-left pl-2 lg:min-w-[9vw] xl:min-w-[12vw] xl:pl-7',
  },
  {
    title: 'Package Size',
    classNames: 'w-20 text-center lg:min-w-[9vw] xl:min-w-[10vw]',
  },
  {
    title: 'Moisture',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[6vw]',
  },
  {
    title: 'Purity',
    classNames: 'w-[45px] lg:min-w-[7vw] text-center xl:min-w-[7vw]',
  },
  {
    title: 'Description',
    classNames: 'text-left',
  },
]

const variants: Array<IVariant> = [
  {
    varient: 'Jeera Marwar',
    package: 'Custom',
    moisture: '8-10%',
    purity: '99%',
    desc: 'Machine clean packing quality good colour & medium size seeds.',
  },
  {
    varient: 'Jeera Bold',
    package: 'Custom',
    moisture: '8-10%',
    purity: '99%',
    desc: 'Bold bright colour & bold size seeds with cutting material.',
  },
  // {
  //   varient: 'Jeera Dolphin (R)',
  //   package: '30 kg',
  //   moisture: '8%',
  //   purity: '99%',
  //   desc: 'Regular colour & medium size seeds with cutting material, below 6% much,',
  // },
  // {
  //   varient: 'Jeera Dolphin (R) Sortex',
  //   package: '30 kg',
  //   moisture: '8%',
  //   purity: '99.75%',
  //   desc: 'Machine clean packing quality good colour & medium size seeds with cutting material',
  // },
  // {
  //   varient: 'Jeera Gold (G)',
  //   package: '30 kg',
  //   moisture: '8%',
  //   purity: '99.5%',
  //   desc: 'Bold bright colour & bold size seeds with cutting material, below 6% much. 0.50% max.impurity',
  // },
  // {
  //   varient: 'Jeera Heritage (F)',
  //   package: '25 kg',
  //   moisture: '8%',
  //   purity: '99.5%',
  //   desc: 'Machine clean packing quality good colour & medium size seeds',
  // },
  // {
  //   varient: 'Jeera JP Silver (P)',
  //   package: '30 kg',
  //   moisture: '8%',
  //   purity: '99.5%',
  //   desc: 'Premium packing bright colour & medium size seeds with cutting material',
  // },
  // {
  //   varient: 'Jeera Sunflower(S)',
  //   package: '30 kg',
  //   moisture: '10%',
  //   purity: '99%',
  //   desc: 'Average colour & size seeds 1% max.impurity',
  // },
  // {
  //   varient: 'Jeera Taj',
  //   package: '30 kg',
  //   moisture: '10%',
  //   purity: '98%',
  //   desc: 'Average colour & size seeds lowest machine clean quality',
  // },
  // {
  //   varient: 'Jeera Tiptop (V)',
  //   package: '30 kg',
  //   moisture: '8%',
  //   purity: '99.5%',
  //   desc: 'Semi bold bright colour & medium bold size seeds with cutting material',
  // },
  // {
  //   varient: 'Jeera VA (O)',
  //   package: '25 kg',
  //   moisture: '8%',
  //   purity: '99.5%',
  //   desc: 'Extra bold bright colour & extra bold size seeds with cutting material',
  // },
]

export default function Cumin() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <div>
      <Banner
        bgImage={cuminBanner}
        title="Cumin (Whole)"
        desc="Sourced from our partner farms from the Marwar region (Rajasthan, India), we bring you residue-free pungent-flavoured cumin seeds of different sizes and colours that are second to none. From tacos to curries, from soups to savouries, our range of cumin seeds adheres to global quality and food safety mandates."
      />
      <Details
        tableHeading={tableHeadings}
        variantDetails={variants}
        asideImage={asideImage}
        title="Cumin (Whole)"
        geoData={geoData}
      />
      <OtherProducts />
    </div>
  )
}
