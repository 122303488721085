import React, { useEffect } from 'react'
import clock from 'assets/careers/perks_icons/clock.svg'
import lifeProtection from 'assets/careers/perks_icons/life-protection.svg'
import workLifeBalance from 'assets/careers/perks_icons/work_life_balance.svg'
import recognition_program from 'assets/careers/perks_icons/recognition_programs.svg'
import report from 'assets/careers/perks_icons/report_1267083.svg'
import subsidies from 'assets/careers/perks_icons/subsidies.svg'
import teambuilding from 'assets/careers/perks_icons/team_building.svg'
import professionalDev from 'assets/careers/perks_icons/professional_dev.svg'

import bottomRight from 'assets/careers/perks_icons/bottom-right.png'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const data = [
  {
    coverImage: lifeProtection,
    title: 'Health insurance',
    subtitle:
      "Get your 'Om' on with comprehensive health coverage because a balanced chakra means a balanced you.",
  },
  {
    coverImage: teambuilding,
    title: 'Continuous Learning',
    subtitle:
      'Reimbursement for further education, access to online courses, or in-house training sessions.',
  },
  {
    coverImage: subsidies,
    title: 'Transportation subsidies',
    subtitle:
      'Wave goodbye to commuting nightmares. Our transportation subsidies will have you commuting like a breeze, maybe even on a unicorn.',
  },

  {
    coverImage: report,
    title: 'Vacation and paid leaves',
    subtitle:
      'Take that well-deserved vacation with paid leaves that give you more freedom than a hot air balloon in the sky.',
  },
  {
    coverImage: recognition_program,
    title: 'Employee recognition programs',
    subtitle:
      'Feeling like a superstar? We think so too! Our recognition programs are here to celebrate your wins and make you feel like the rockstar you are.',
  },
  {
    coverImage: professionalDev,
    title: 'Professional development',
    subtitle:
      'Fuel your professional journey with our development programs that will have you blooming like a superhero in no time.',
  },
  {
    coverImage: workLifeBalance,
    title: 'Work-life balance',
    subtitle:
      'We believe in cracking jokes while crunching numbers. Embrace the ultimate work-life tango with policies that let you live your best life outside the office too.',
  },
  // {
  //   coverImage: teambuilding,
  //   title: 'Team building activities:',
  //   subtitle:
  //     'We are not just colleagues. We are a team on a mission to have the most fun ever. Join us for epic team-building adventures that will leave you with stories for ages.',
  // },
  {
    coverImage: clock,
    title: 'Flexible hours',
    subtitle:
      'Early bird or night owl, we have got your back! Enjoy flexi-hours that let you work when you are at your A-game, whether it is dawn, dusk, or disco time.',
  },
]

type IValue = (typeof data)[0]
export default function Values() {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.career-perks',
        {
          y: 100,
          opacity: 0,
          scale: 0.5,
          // rotateY: 180,
        },
        {
          y: 0,
          opacity: 1,
          scale: 1,
          // rotateY: 0,
          duration: 1.5,
          ease: '',
          stagger: 0.2,
          scrollTrigger: {
            trigger: '.career-perks',
            start: 'top 85%',
            toggleActions: 'play none none reverse',
          },
        },
      )
    })

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section className="py-16 md:py-20 bg-cream relative">
      <img
        src={bottomRight}
        alt="background"
        className="absolute bottom-12 right-0 hidden xl:block xl:h-40 w-auto 2xl:h-auto max-w-lg 2xl:max-w-xl min-[1800px]:max-w-2xl"
      />
      <section className="grid place-content-center text-center gap-3 ">
        <h3 className="font-semibold text-green px-3">
          Perks that put the <br className="md:block hidden" /> "
          <span className="text-yellow font-bold">Whee!</span>" in the workplace
        </h3>
      </section>
      <section className="grid md:grid-cols-2 grid-cols-1 md:gap-4 xl:gap-6 gap-y-5 xl:grid-cols-3 w-container pt-16">
        {data.map((elem: IValue, index: number) => {
          const key = `${index}`
          return <Card data={elem} key={key} />
        })}
      </section>
    </section>
  )
}

function Card({ data }: { data: IValue }) {
  const { coverImage, title, subtitle } = data
  return (
    <article className="career-perks bg-green px-5 md:px-5 lg:px-9 py-6 md:py-6 lg:py-10 flex flex-col items-start rounded-xl bg-[url('assets/careers/card_bg.png')] bg-right-bottom bg-contain bg-no-repeat max-w-lg mx-auto">
      <div className="grid place-content-center pt-3 md:pt-0">
        <img src={coverImage} alt={title} className="md:h-16 h-12 " />
      </div>
      <div className="text-white flex-1 flex flex-col gap-2 md:gap-3 pt-5 md:pt-5 lg:pt-7 pb-3">
        <h4 className="leading-tight">{title}</h4>
        <span className="text-white font-light text-opacity-70 w-11/12 text-base md:text-lg  leading-normal md:block hidden">
          {subtitle}
        </span>
        <span className="text-white font-light text-opacity-70 w-11/12 leading-normal md:hidden block text-sm md:text-base ">
          {subtitle}
        </span>
      </div>
    </article>
  )
}
