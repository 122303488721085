import React from 'react'

import image01 from 'assets/cropAssure/gallery/image01.jpg'
import image02 from 'assets/cropAssure/gallery/image02.jpg'
import image03 from 'assets/cropAssure/gallery/image03.jpg'
import image04 from 'assets/cropAssure/gallery/image04.jpg'
import image05 from 'assets/cropAssure/gallery/image05.jpg'
import image06 from 'assets/cropAssure/gallery/image06.jpg'

import Gallery from 'organisms/gallery'
// import './cropAssure.css'

const galleryList = [
  {
    title: 'Crop Protection and Management',
    image: image01,
    desc: (
      <>
        Farm health assessment with regular drone surveys for threat detection
        and mitigation (pests, diseases, and nutrient issues) with plot-specific
        advisories and recommendations from agronomists.
      </>
    ),
  },
  {
    title: 'Soil Testing',
    image: image02,
    desc: (
      <>
        Analysis of soil health and condition (pH level, soil nutrition, etc.)
        to recommend the right crop along with basal dose advisory.
      </>
    ),
  },
  {
    title: 'Certified Seed Delivery',
    image: image03,
    desc: (
      <>
        Supply of best-quality seeds from trusted seed companies to farmers
        based on the soil test covering vital parameters.
      </>
    ),
  },
  {
    title: 'Weather Advisory',
    image: image04,
    desc: (
      <>
        Weekly weather alerts to farmers for better planning and implementation
        of on-farm activities.
      </>
    ),
  },
  {
    title: 'Market Linkage',
    image: image05,
    desc: (
      <>
        Buyback of harvest from farmers at better-than-market rates with grading
        support to ensure a fine finish of the crop cycle.
      </>
    ),
  },
  {
    title: 'The Pravir Shield',
    image: image06,
    desc: (
      <>
        A range of agri inputs (biofertilizers and pesticides) for better
        conditioning of the crop with quality products from BharatRohan’s
        in-house brand, <i>Pravir</i> – HumeShakti (growth promoter), JadShakti
        (growth promoter), and Silver shakti (pesticide).
      </>
    ),
  },
]

function CropGallery() {
  return <Gallery divider="What it covers" galleryList={galleryList} />
}

export default CropGallery
